import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import TabbarSecondary from "../../../components/tab-bar/tabbar-secondary/TabbarSecondary";
import { GridLayout } from "../../../layouts";
import useTabNavigation from "../../../common/hooks/navigation/useTabNavigation";
import { RootState } from "../../../redux/store";

const PeopleHome = () => {
  const { t: translate } = useTranslation();
  // const tabs = ["staffing_module", "Attendance", "Absense"];

  const paths = ["/people/staffing", "/people/attendence", "/people/absence"];

  // Redux state start

  const isGlobalAdminAccess = useSelector(
    (state: RootState) => state.permission.globalSettingEnabled
  );

  const [tabs, setTabs] = useState<string[]>([]);

  const { selectedTabIndex, navigateToTab } = useTabNavigation(paths);

  const handleTabbarClick = (clickedIndex: number): void => {
    navigateToTab(clickedIndex);
  };

  useEffect(() => {
    settingTabs();
  }, [isGlobalAdminAccess]);

  const settingTabs = () => {
    const newTabs: string[] = [translate("staffing_module")];

    if (isGlobalAdminAccess) {
      newTabs.push(translate("FollowUp"));
      newTabs.push(translate("Absence"));
    }

    setTabs(newTabs);
  };

  return (
    <GridLayout additionalStyles="mt-0">
      <div className="col-start-1 col-end-13 mt-0">
        <div className="flex items-center justify-between">
          <TabbarSecondary
            selectedTabIndex={selectedTabIndex}
            tabs={tabs}
            onClick={handleTabbarClick}
          />
        </div>
        <div className="mt-0 h-[86vh] rounded-b rounded-r border-2 border-solid">
          <Outlet />
        </div>
      </div>
    </GridLayout>
  );
};

export default PeopleHome;
