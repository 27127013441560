import React from "react";
import Button from "../Button/Button";
import Input from "../Input/Input";
import { useTranslation } from "react-i18next";
import "./text-box-modal.css";
import { TextBoxModalProps } from "../../interfaces/props";

const TextBoxModal = ({
  leftButtonLabel,
  rightButtonLabel,
  onPrimaryClick,
  onSecondryClick,
  handleInputChange,
  inputValue,
  inputPlaceholder,
  IsinputDisabled,
  title,
  errorLabel,
}: TextBoxModalProps) => {
  const { t: translate } = useTranslation();
  return (
    <div className="notification-box">
      {title && (
        <div>
          <span className="p-1-v-2">{title}</span>
        </div>
      )}
      <div>
        <Input
          name="modalInputBox"
          value={inputValue}
          handleInputChange={handleInputChange}
          placeholder={translate(inputPlaceholder)}
          disabled={IsinputDisabled}
          error={errorLabel}
        />
      </div>

      <div className="notification-box__buttons--primary">
        <Button
          additionalStyles="notification-box__buttons__left"
          variant="secondary"
          text={leftButtonLabel}
          onClick={onPrimaryClick}
        />

        <Button
          additionalStyles="notification-box__buttons__right"
          variant="territary"
          text={rightButtonLabel}
          onClick={onSecondryClick}
        />
      </div>
    </div>
  );
};

export default TextBoxModal;
