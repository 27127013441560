import HttpCommonService from "./httpCommonService";

const DashboardCommentService = {
  getComment: async (id: number) => {
    return await HttpCommonService.getAsync(`api/comment/${id}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveComment: async (id: number | null, careUnitId: number, commentBody: string) => {
    const payload = {
      id,
      careUnitId,
      commentBody,
    };

    return await HttpCommonService.postAsync(`api/comment/save-comment`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default DashboardCommentService;
