import { HotTableClass as HotTable } from "@handsontable/react";
import React from "react";
import ActivityAnalysisService from "../../../../../services/activityAnalysisService";

const useGetActivityAnalysisProductionPlanForFilterGroup = async (
  careUnitId: number,
  scenarioId: number,
  year: number,
  filterGroupId: number,
  dataTable: React.RefObject<HotTable | null>,
  modifiedRow: number
) => {
  await ActivityAnalysisService.getProductionPlanForFilterGroup(careUnitId, filterGroupId, scenarioId, year).then(
    (res) => {
      dataTable.current?.hotInstance?.setDataAtCell(modifiedRow, 3, res);
    }
  );
};

export default useGetActivityAnalysisProductionPlanForFilterGroup;
