import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, Select } from "../../../../../components";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import { ISelectOption } from "../../../../../interfaces";
import {
  CareLevel,
  CareUnitPropertyStateType,
  CareUnitStateType,
  CareUnitType,
  MedicalSpeciality,
  Site,
} from "../../../../../interfaces/orgstructure";
import {
  CareLevelDto,
  CareUnitTypeDto,
  MedicalSpecialityDto,
  SiteDto,
} from "../../../../../interfaces/orgstructure/dtos";
import { CareUnitFormProps } from "../../../../../interfaces/props/org-structure-scrollbar";
import { ScrollBarConstants } from "../../../utils/constants";
import MultiSelectListWithSearch from "../../../../user-management/components/care-unit-search/care-unit-search";
import { CreateCareUnitDto, GetCareUnitDto } from "../../../../../interfaces/orgstructure/dtos/Care-Unit-Dto";
import OrgTreeDropdownController from "../../../../../components/OrgTreeDropdownController";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { OrgNodeResponse } from "../../../../../interfaces/responses/org-structure-tree-response";
import OrgService from "../../../../../services/orgService";
import CareUnitDataSourceDrawer from "../../CareUnitDataSourceDrawer/CareUnitDataSourceDrawer";
import DataSourceService from "../../../../../services/dataSourceService";
import { CARE_UNIT_TYPES } from "../../../../../utils/constants/careUnitTypes";
import { ToastContainer, toast } from "react-toastify";
import { DataSource } from "../../../../../interfaces/orgstructure/dataSource";
import { isEqual } from "lodash";

const CareUnitFormV2 = ({
  addNewCareUnitProperty,
  setAddNewCareUnitProperty,
  variant,
  selectedCareUnitGroup,
  selectedEditCareUnit,
  setCareUnits,
  setIsScrollBarOpen,
  selectedHospital,
  setSuccessNotification,
  setIsPhysicianCareUnit,
  medicalSpecialitiesProp,
  careLevelsProp,
  careUnitTypesProp,
  selectablePhysicianCareUnits,
  isScrollBarOpen,
}: CareUnitFormProps) => {
  const { t: translate } = useTranslation();

  const [careUnitTypes, setCareUnitTypes] = useState<CareUnitType[]>([]);

  const [checklistError] = useState<boolean>(false);
  const [careUnitsForSelect, setCareUnitsForSelect] = useState<ISelectOption[]>([]);
  const [newCareUnitProperty, setNewCareUnitProperty] = useState<CareUnitPropertyStateType>({
    id: 0,
    name: "",
  });
  const [isAddSiteFailed, setIsAddSiteFailed] = useState<boolean>(false);
  const [isParentSelectionFailed, setIsParentSelectionFailed] = useState<boolean>(false);
  const [sitesList, setSitesList] = useState<ISelectOption[]>([]);
  const [careUnitTypesList, setCareUnitTypesList] = useState<ISelectOption[]>([]);
  const [careLevels, setCareLevels] = useState<CareLevel[]>([]);
  const [medicalSpecialities, setMedicalSpecialities] = useState<MedicalSpeciality[]>([]);
  const [IsPhysicianLoad, setIsPhysicianLoad] = useState(false);

  const [medicalSpecialityList, setMedicalSpecialityList] = useState<ISelectOption[]>([]);

  const [careLevelsList, setCareLevelsList] = useState<ISelectOption[]>([]);

  const [isAddCareUnitFailed, setIsAddCareUnitFailed] = useState<boolean>(false);

  const [selectedSite, setSelectedSite] = useState<ISelectOption | undefined>();

  const [selectedCareUnitType, setSelectedCareUnitType] = useState<ISelectOption | undefined>();

  const [selectedCareLevel, setSelectedCareLevel] = useState<ISelectOption | undefined>(undefined);

  const [selectedPhysicianCareUnit, setSelectedPhysicianCareUnit] = useState<ISelectOption[]>([]);

  const [selectedMedicalSpeciality, setSelectedMedicalSpeciality] = useState<ISelectOption | undefined>(undefined);

  const [selectedExistingDataSources, setSelectedExistingDataSources] = useState<ISelectOption[]>([]);

  const [selectedDataSources, setSelectedDataSources] = useState<ISelectOption[]>([]);

  const [dataSources, setDataSources] = useState<DataSource[]>([]);

  const [isDataSourceDrawerOpen, setIsDataSourceDrawerOpen] = useState<boolean>(false);

  const setParentCareUnit = (obj: { parents: number[]; name: string | null; value: number | null }) => {
    setCareUnit((prevState) => {
      return {
        ...prevState,
        parentId: obj.value ? obj.value : null,
        parentName: obj.name ? obj.name : "",
        grandParents: obj.parents,
      };
    });
  };

  const [, setInitialLoadingForCareUnitList] = useState<boolean>(true);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const setSelectedCareUnitsForSubmit = (options: ISelectOption[]) => {
    setInitialLoadingForCareUnitList(false);
    setSelectedPhysicianCareUnit(options);
  };

  const handleSelectToggle = (value: string) => {
    setCareUnitsForSelect((current) =>
      current.map((obj) => {
        if (obj.value === value) {
          return {
            ...obj,
            label: obj.label,
            value: obj.value,
            isSelected: !obj.isSelected,
          };
        }

        return obj;
      })
    );
  };

  const handleCareUnitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCareUnit((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const [sites, setSites] = useState<Site[]>([]);

  const loginState: boolean = useSelector((state: RootState) => state.auth.isLoggedIn);

  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const [orgHierachy, setOrgHierachy] = useState<OrgNodeResponse[]>([]);

  const getOrgStructureTree = () => {
    if (careProviderId) {
      OrgService.getOrgChildrenHierarchyAsync(careProviderId, year)
        .then((res) => {
          setOrgHierachy(res);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    if (loginState === true && careProviderId !== null) {
      getOrgStructureTree();
    }
  }, [year]);

  useEffect(() => {
    if (sitesList.length > 0) {
      sitesList.push({
        value: sites[sites.length - 1].id.toString(),
        label: sites[sites.length - 1].siteName,
      });
    } else {
      const sitesList = sites.map((site: Site) => {
        return {
          value: site.id.toString(),
          label: site.siteName,
        };
      });

      setSitesList(sitesList);
    }
  }, [sites]);

  useEffect(() => {
    if (medicalSpecialitiesProp !== undefined) {
      setMedicalSpecialities(medicalSpecialitiesProp);
    }
    if (careLevelsProp !== undefined) {
      setCareLevels(careLevelsProp);
    }
    if (careUnitTypesProp !== undefined) {
      setCareUnitTypes(careUnitTypesProp);
    }
  }, [medicalSpecialitiesProp, careLevelsProp, careUnitTypesProp]);

  useEffect(() => {
    if (variant === ScrollBarConstants.UPDATE_CARE_UNIT) {
      if (selectedEditCareUnit !== undefined) {
        setCareUnit({
          id: selectedEditCareUnit.id,
          name: selectedEditCareUnit.name,
          siteId: selectedEditCareUnit.siteId,
          careLevelId: selectedEditCareUnit.careLevelId,
          careUnitTypeId: selectedEditCareUnit.careUnitTypeId,
          medicalSpecialityId: selectedEditCareUnit.medicalSpecialityId,
          grandParents: [], // TODO
          parentId: selectedEditCareUnit.parentId,
          parentName: "",
        });

        setIsParentSelectionFailed(false);

        setIsAddCareUnitFailed(false);
      }
    } else {
      setCareUnit({
        id: 0,
        name: "",
        siteId: 0,
        careLevelId: null,
        careUnitTypeId: null,
        medicalSpecialityId: null,
        grandParents: [],
        parentId: null,
        parentName: "",
      });

      setIsParentSelectionFailed(false);

      setSelectedCareUnitType(undefined);

      setSelectedSite(undefined);

      setSelectedCareLevel(undefined);

      setSelectedMedicalSpeciality(undefined);

      setIsAddCareUnitFailed(false);
    }
  }, [selectedEditCareUnit, variant, isScrollBarOpen]);

  const handleCareUnitTypeSelectChange = (option: ISelectOption) => {
    setCareUnit((prevState) => {
      return {
        ...prevState,
        careUnitTypeId: parseInt(option.value),
      };
    });

    setSelectedCareUnitType(option);
  };

  const handleMedicalSpecSelectChange = (option: ISelectOption) => {
    setCareUnit((prevState) => {
      return {
        ...prevState,
        medicalSpecialityId: parseInt(option.value),
      };
    });

    setSelectedMedicalSpeciality(option);
  };

  const isChild = (nodes: OrgNodeResponse[], searchNodeId: number): boolean => {
    for (const node of nodes) {
      if (node.careUnitId === searchNodeId) {
        return true;
      }

      if (node.children && isChild(node.children, searchNodeId)) {
        return true;
      }
    }

    return false;
  };

  const findChild = (hierarchy: OrgNodeResponse[], nodeId: number): OrgNodeResponse | null => {
    for (const child of hierarchy) {
      if (child.careUnitId === Number(nodeId)) {
        return child;
      } else if (child.children && child.children.length > 0) {
        const result = findChild(child.children, nodeId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const handleCareUnitSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const careUnitDto: CreateCareUnitDto = {
      id: careUnit.id,
      careUnitName: careUnit.name,
      physicalCareUnitId: selectedCareUnitGroup?.id,
      siteId: careUnit.siteId,
      careLevelId: careUnit.careLevelId,
      careUnitTypeId: careUnit.careUnitTypeId,
      medicalSpecialityId: careUnit.medicalSpecialityId,
      year: year,
      parentId: careUnit.parentId,
    };

    let isValid = true;

    if (orgHierachy) {
      const node = findChild(orgHierachy, careUnit.id);

      const isAChild = node && careUnit.parentId ? isChild([node], careUnit.parentId) : false;

      if (isAChild) {
        setIsParentSelectionFailed(true);
        isValid = false;
      } else {
        setIsParentSelectionFailed(false);
      }
    }

    if (isValid && variant === ScrollBarConstants.ADD_CARE_UNIT) {
      const physicianCareUnits =
        selectedPhysicianCareUnit.length > 0 ? selectedPhysicianCareUnit.map((c) => Number(c.value)) : null;

      try {
        const res = await OrgService.createCareUnitAsync(careUnitDto, physicianCareUnits);

        if (setCareUnits)
          setCareUnits((prevArray) => [
            ...prevArray,
            {
              id: res.id,
              careUnitName: careUnit.name,
            },
          ]);
        
        if(!(isEqual(selectedExistingDataSources.map((r) => Number(r.value)),
          selectedDataSources.map((r) => Number(r.value)))))
          {
            await assignDatasourcesForCareUnit(
              res?.id,
              selectedDataSources.map((d) => Number(d.value))
            );
          }

        setSelectedPhysicianCareUnit([]);

        setIsAddCareUnitFailed(false);

        setIsScrollBarOpen(false);

        setCareUnit({
          id: 0,
          name: "",
          siteId: 0,
          careUnitTypeId: null,
          careLevelId: null,
          medicalSpecialityId: null,
          grandParents: [],
          parentId: null,
          parentName: "",
        });

        setSelectedSite(undefined);
        setSelectedCareUnitType(undefined);
        setSelectedCareLevel(undefined);
        setSelectedMedicalSpeciality(undefined);

        setSuccessNotification && setSuccessNotification("Care Unit");
      } catch (err) {
        setIsAddCareUnitFailed(true);
        throw err;
      } finally {
        if (loginState === true && careProviderId !== null) {
          getOrgStructureTree();
        }
      }
    }

    if (isValid && variant === ScrollBarConstants.UPDATE_CARE_UNIT) {
      const physicianCareUnits =
        selectedPhysicianCareUnit.length > 0 ? selectedPhysicianCareUnit.map((c) => Number(c.value)) : null;
      
        const toastId = toast(
          <NotificationFloat varaint="loading" content={translate("data_is_saving")} />,
          {
            icon: false,
            className: "custom-toast",
            containerId: "notificationBox",
            autoClose: false,
          }
        );

      try {
        await OrgService.updateCareUnitAsync(careUnitDto, physicianCareUnits);

        if (careUnitDto.id && !(isEqual(selectedExistingDataSources.map((r) => Number(r.value)),
          selectedDataSources.map((r) => Number(r.value))))) {
          const result = await assignDatasourcesForCareUnit(
            careUnitDto?.id,
            selectedDataSources.map((d) => Number(d.value)),

          );

          if(result){
            toast.update(toastId, {
              render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
              type: "success",
              autoClose: 3000,
            });
          }
          else{
            toast.update(toastId, {
              render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
              type: "error",
              autoClose: 3000,
            });
          }
        }
        else{
          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });
        }

        setSelectedPhysicianCareUnit([]);

        setIsAddCareUnitFailed(false);
        setIsScrollBarOpen(false);

        setCareUnit({
          id: 0,
          name: "",
          siteId: 0,
          careUnitTypeId: null,
          careLevelId: null,
          medicalSpecialityId: null,
          grandParents: [],
          parentId: null,
          parentName: "",
        });
        setSelectedSite(undefined);
        setSelectedCareUnitType(undefined);
        setSelectedCareLevel(undefined);
        setSelectedMedicalSpeciality(undefined);

      } catch (err) {
        setIsAddCareUnitFailed(true);

        toast.update(toastId, {
          render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
          type: "error",
          autoClose: 3000,
        });
        
        throw err;
      } finally {
        if (loginState === true && careProviderId !== null) {
          getOrgStructureTree();
        }
      }
    }
  };

  const [careUnit, setCareUnit] = useState<CareUnitStateType>({
    id: 0,
    name: "",
    siteId: 0,
    careUnitTypeId: null,
    careLevelId: null,
    medicalSpecialityId: null,
    grandParents: [],
    parentId: null,
    parentName: "",
  });

  useEffect(() => {
    const createOptionsAndSelectedDataSources = (data: DataSource[]) => {
      data.sort((a, b) => {
        if (a.isAssign === b.isAssign) {
          return 0;
        }
        return a.isAssign ? -1 : 1;
      });

      const selectedDatasources: ISelectOption[] = data
        .filter((datasource) => datasource.isAssign === true)
        .map((dataSource) => {
          return {
            label: `${dataSource.uniqueIdentifier} - ${dataSource.name}`,
            value: String(dataSource.id),
            isSelected: dataSource.isAssign,
          };
        });

      setDataSources(data);
      setSelectedExistingDataSources(selectedDatasources);
    };

    const fetchDataSourcesForUpdateCareUnit = async () => {
      const res: AxiosResponse<
        {
          id: number;
          name: string;
          uniqueIdentifier: string;
          careUnitTypeId: number;
          isAssign: boolean;
        }[]
      > = await DataSourceService.getDataSources(Number(selectedCareUnitType?.value), careUnit.id);

      if (res.data) {
        createOptionsAndSelectedDataSources(res.data);
      }
    };

    const fetchDataSourcesForCreateCareUnit = async () => {
      const res: AxiosResponse<
        {
          id: number;
          name: string;
          uniqueIdentifier: string;
          careUnitTypeId: number;
          isAssign: boolean;
        }[]
      > = await DataSourceService.getDataSourcesForCreateCareUnit(Number(selectedCareUnitType?.value));

      if (res.data) {
        createOptionsAndSelectedDataSources(res.data);
      }
    };

    if (variant === ScrollBarConstants.ADD_CARE_UNIT && Number(selectedCareUnitType?.value) > 0 && isDataSourceDrawerOpen) {
      fetchDataSourcesForCreateCareUnit();
    }

    if (variant === ScrollBarConstants.UPDATE_CARE_UNIT && careUnit.id > 0 && Number(selectedCareUnitType?.value) > 0 && isDataSourceDrawerOpen) {
      fetchDataSourcesForUpdateCareUnit();
    }
  }, [careUnit, selectedCareUnitType, variant, isDataSourceDrawerOpen]);

  const assignDatasourcesForCareUnit = async (careUnitId: number, dataSources: number[]) => {

    try {
      if (Number(selectedCareUnitType?.value) === CARE_UNIT_TYPES.INPATIENT) {
        await DataSourceService.assignDatasourcesToInpatientCareUnit(careUnitId, dataSources);   
        return true;

      } else if (Number(selectedCareUnitType?.value) === CARE_UNIT_TYPES.OUTPATIENT) {
        await DataSourceService.assignDatasourcesToOutpatientCareUnit(careUnitId, dataSources);
          toast.warning(
            <div className="flex justify-center">
              <div className="m-1">
                <span>{translate("datasource_assignment_warning")}</span>
              </div>
            </div>,
            { icon: false }
          );

          return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const handleCUPropertyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setNewCareUnitProperty((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddCUPropertySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    switch (addNewCareUnitProperty) {
      case "site": {
        const siteDto: SiteDto = {
          siteDto: {
            siteName: newCareUnitProperty.name,
            careProviderId: String(selectedHospital?.id),
          },
        };

        // Add the careProvider id logic from here
        await OrgService.createSiteAsync(siteDto)
          .then((res) => {
            setSites((prevArray) => [
              ...prevArray,
              {
                id: res.id,
                siteName: newCareUnitProperty.name,
              },
            ]);
            setNewCareUnitProperty({
              id: 0,
              name: "",
            });

            setAddNewCareUnitProperty("");
            setIsAddSiteSuccess(true);
          })
          .catch((err) => {
            setIsAddSiteFailed(true);
            throw err;
          });
        break;
      }

      case "care_level": {
        const careLevelDto: CareLevelDto = {
          careLevelDto: {
            levelName: newCareUnitProperty.name,
          },
        };
        await OrgService.createCareLevelAsync(careLevelDto)
          .then((res) => {
            setCareLevels((prevArray) => [
              ...prevArray,
              {
                id: res.id,
                levelName: newCareUnitProperty.name,
              },
            ]);
            setNewCareUnitProperty({
              id: 0,
              name: "",
            });

            setAddNewCareUnitProperty("");
            setIsAddSiteSuccess(true);
          })
          .catch((err) => {
            setIsAddSiteFailed(true);
            throw err;
          });
        break;
      }

      case "care_unit_type": {
        const careUnitTypeDto: CareUnitTypeDto = {
          createCareUnitTypeDto: {
            typeName: newCareUnitProperty.name,
          },
        };
        await OrgService.createCareUnitTypeAsync(careUnitTypeDto)
          .then((res) => {
            setCareUnitTypes((prevArray) => [
              ...prevArray,
              {
                id: res.id,
                typeName: newCareUnitProperty.name,
              },
            ]);
            setNewCareUnitProperty({
              id: 0,
              name: "",
            });

            setAddNewCareUnitProperty("");
            setIsAddSiteSuccess(true);
          })
          .catch((err) => {
            setIsAddSiteFailed(true);
            throw err;
          });
        break;
      }

      case "medical_speciality": {
        const medicalSpecialityDto: MedicalSpecialityDto = {
          createMedicalSpecialityDto: {
            medicalSpecialityName: newCareUnitProperty.name,
          },
        };
        await OrgService.createMedicalSpecialityAsync(medicalSpecialityDto)
          .then((res) => {
            setMedicalSpecialities((prevArray) => [
              ...prevArray,
              {
                id: res.id,
                medicalSpecialityName: newCareUnitProperty.name,
              },
            ]);
            setNewCareUnitProperty({
              id: 0,
              name: "",
            });

            setAddNewCareUnitProperty("");
            setIsAddSiteSuccess(true);
          })
          .catch((err) => {
            setIsAddSiteFailed(true);
            throw err;
          });
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    careUnitTypesList.length > 0
      ? careUnitTypesList.push({
          value: careUnitTypes[careUnitTypes.length - 1].id.toString(),
          label: translate(careUnitTypes[careUnitTypes.length - 1].typeName),
        })
      : careUnitTypes.map((careunittype) => {
          careUnitTypesList.push({
            value: careunittype.id.toString(),
            label: translate(careunittype.typeName),
          });
        });
  }, [careUnitTypes]);

  const handleCareUnitSiteSelectChange = (option: ISelectOption) => {
    setCareUnit((prevState) => {
      return {
        ...prevState,
        siteId: parseInt(option.value),
      };
    });

    setSelectedSite(option);
  };

  const handleCareLevelSelectChange = (option: ISelectOption) => {
    setCareUnit((prevState) => {
      return {
        ...prevState,
        careLevelId: parseInt(option.value),
      };
    });

    setSelectedCareLevel(option);
  };

  const handleDataSourceSelect = (selectedSources: ISelectOption[]) => {
    setSelectedDataSources(selectedSources);
  };

  useEffect(() => {
    if (selectedHospital) {
      OrgService.getSitesAsync(selectedHospital.id)
        .then((res: AxiosResponse<Site[]>) => {
          setSitesList([]);
          setSites(res.data);
        })
        .catch((e) => {
          throw e;
        });
    }
  }, [selectedHospital?.id]);

  const [isAddSiteSuccess, setIsAddSiteSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (isAddSiteSuccess) {
      const timer = setTimeout(() => {
        setIsAddSiteSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isAddSiteSuccess]);

  useEffect(() => {
    medicalSpecialityList.length > 0
      ? medicalSpecialityList.push({
          value: medicalSpecialities[medicalSpecialities.length - 1].id.toString(),
          label: medicalSpecialities[medicalSpecialities.length - 1].medicalSpecialityName,
        })
      : medicalSpecialities.map((medicalspeciality) => {
          medicalSpecialityList.push({
            value: medicalspeciality.id.toString(),
            label: medicalspeciality.medicalSpecialityName,
          });
        });
  }, [medicalSpecialities]);

  useEffect(() => {
    careLevelsList.length > 0
      ? careLevelsList.push({
          value: careLevels[careLevels.length - 1].id.toString(),
          label: careLevels[careLevels.length - 1].levelName,
        })
      : careLevels.map((carelevel) => {
          careLevelsList.push({
            value: carelevel.id.toString(),
            label: carelevel.levelName,
          });
        });
  }, [careLevels]);

  useEffect(() => {
    const getCareUnitPropertyArrays = async () => {
      setSitesList([]);
      setCareUnitTypesList([]);
      setCareLevelsList([]);
      setMedicalSpecialityList([]);
      setSites([]);
    };

    getCareUnitPropertyArrays().catch((err) => {
      throw err;
    });
  }, []);

  useEffect(() => {
    if (variant === ScrollBarConstants.UPDATE_CARE_UNIT) {
      let editableCareUnit: CareUnitStateType;
      if (selectedEditCareUnit !== undefined) {
        editableCareUnit = selectedEditCareUnit;
        setCareUnit({
          id: editableCareUnit.id,
          name: editableCareUnit.name,
          siteId: editableCareUnit.siteId,
          careLevelId: editableCareUnit.careLevelId,
          careUnitTypeId: editableCareUnit.careUnitTypeId,
          medicalSpecialityId: editableCareUnit.medicalSpecialityId,
          grandParents: editableCareUnit.grandParents,
          parentId: editableCareUnit.parentId,
          parentName: editableCareUnit.parentName,
        });

        setSelectedSite((prevSelectedSite) => {
          const selectedSite = sitesList.find((x) => x.value === selectedEditCareUnit.siteId.toString());
          return selectedSite || prevSelectedSite;
        });

        setSelectedCareUnitType(
          careUnitTypesList.find(
            (x) =>
              x.value ===
              (selectedEditCareUnit.careUnitTypeId !== null ? selectedEditCareUnit.careUnitTypeId.toString() : 0)
          )
        );

        setSelectedCareLevel((prevSelectedSite) => {
          const selectedCareLevel = careLevelsList.find(
            (x) => x.value === selectedEditCareUnit.careLevelId?.toString()
          );
          return selectedCareLevel || prevSelectedSite;
        });

        setSelectedMedicalSpeciality((prevSelectedSite) => {
          const selectedMedicalSpeciality = medicalSpecialityList.find(
            (x) => x.value === selectedEditCareUnit.medicalSpecialityId?.toString()
          );
          return selectedMedicalSpeciality || prevSelectedSite;
        });
      }
    }
  }, [selectedEditCareUnit, sitesList, careUnitTypesList, careLevelsList, medicalSpecialityList]);

  useEffect(() => {
    const getCareUnitsByCareProviderIds = async (): Promise<void> => {
      const careUnits: ISelectOption[] = [];
      setCareUnitsForSelect([]);

      if (selectablePhysicianCareUnits) {
        selectablePhysicianCareUnits.forEach((unit: GetCareUnitDto) => {
          if (unit.id !== selectedEditCareUnit?.id && variant === ScrollBarConstants.UPDATE_CARE_UNIT) {
            const newSelectOption: ISelectOption = {
              value: unit.id.toString(),
              label: unit.careUnitName,
              isSelected: unit.isAssigned,
            };
            careUnits.push(newSelectOption);
          }
          if (variant === ScrollBarConstants.ADD_CARE_UNIT) {
            const newSelectOption: ISelectOption = {
              value: unit.id.toString(),
              label: unit.careUnitName,
              isSelected: unit.isAssigned,
            };
            careUnits.push(newSelectOption);
          }
        });
      }

      careUnits.sort((a, b) => {
        if (a.isSelected === b.isSelected) {
          return 0;
        }
        return a.isSelected ? -1 : 1;
      });

      setCareUnitsForSelect(careUnits);
    };

    getCareUnitsByCareProviderIds().catch((err) => {
      throw err;
    });

    if (careUnit !== undefined) {
      setIsPhysicianCareUnit && setIsPhysicianCareUnit(careUnit.careUnitTypeId === 3);
    }
  }, [selectablePhysicianCareUnits, careUnit.careUnitTypeId, careUnit]);

  useEffect(() => {
    setIsPhysicianLoad((prev) => !prev);
    setSelectedPhysicianCareUnit([]);
  }, [selectablePhysicianCareUnits, careUnit.careUnitTypeId]);

  return (
    <>
      {/* Handle Add new Site/ care_level / medical_speciality / care_unit_type */}
      {addNewCareUnitProperty != "" ? (
        <>
          {isAddSiteFailed && (
            <div className="my-3 h-12">
              <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
            </div>
          )}

          <div className="flex h-full w-full flex-col justify-center">
            <h3 className="heading-3">
              {variant === ScrollBarConstants.ADD_CARE_UNIT ? translate("add_a_new") + " " : translate("edit") + " "}
              {translate(addNewCareUnitProperty)}
            </h3>
            <form onSubmit={handleAddCUPropertySubmit}>
              <div>
                <Input
                  name="name"
                  value={newCareUnitProperty.name}
                  placeholder={
                    addNewCareUnitProperty == "site"
                      ? translate(addNewCareUnitProperty) + " Name"
                      : translate(addNewCareUnitProperty)
                  }
                  handleInputChange={handleCUPropertyInputChange}
                  error=""
                  success=""
                  type="text"
                />
              </div>
              <Button
                additionalStyles="w-1/2 h-11"
                variant="primary"
                text={translate("create_" + addNewCareUnitProperty)}
              />
            </form>
          </div>
        </>
      ) : (
        <>
          <CareUnitDataSourceDrawer
            isDataSourceDrawerOpen={isDataSourceDrawerOpen}
            setIsDataSourceDrawerOpen={setIsDataSourceDrawerOpen}
            onDataSourcesSelect={handleDataSourceSelect}
            dataSources={dataSources}
          />

          <div className="mx-20 flex h-full w-auto ">
            {isAddSiteSuccess && (
              <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
                <NotificationFloat varaint="success" content={translate("save_site_successful")} />
              </div>
            )}
            {isAddCareUnitFailed && (
              <div className="my-6 h-12">
                <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
              </div>
            )}
            <div
              className={`${
                careUnit.careUnitTypeId === 3 ? "w-1/2" : "w-full"
              } flex h-full w-full flex-col justify-center`}
            >
              <h3 className="heading-3">
                {variant === ScrollBarConstants.ADD_CARE_UNIT ? translate("add_a_new") + " " : translate("edit") + " "}
                {translate("care_unit")}
              </h3>
              <form onSubmit={handleCareUnitSubmit}>
                <div>
                  <Input
                    name="name"
                    value={careUnit.name}
                    placeholder={translate("name")}
                    handleInputChange={handleCareUnitInputChange}
                    error=""
                    success=""
                    type="text"
                    disabled={variant === ScrollBarConstants.UPDATE_CARE_UNIT}
                  />
                </div>

                <div className="my-4 h-11">
                  <OrgTreeDropdownController
                    placeholder={translate("parent")}
                    value={careUnit.parentId}
                    onValueChange={setParentCareUnit}
                    error={isParentSelectionFailed}
                  />
                </div>
                {isParentSelectionFailed && (
                  <div className="h-11">
                    <div className="input__notification p-2-v-2 input__notification--error">
                      {translate("invalid_parent_care_unit_selection")}
                    </div>
                  </div>
                )}
                <div className="my-2 h-11">
                  <Select
                    selectedOption={selectedSite}
                    placeholder={translate("select_site")}
                    options={sitesList}
                    onSelectOption={handleCareUnitSiteSelectChange}
                  />
                </div>

                <div className="add_newsite flex w-full justify-end lg:pb-2">
                  <span
                    className="add-newsite__text link p-2-v-1"
                    onClick={() => {
                      setAddNewCareUnitProperty("site");
                    }}
                  >
                    {translate("add_new_site")}
                  </span>
                </div>

                <div className="my-4 h-11">
                  <Select
                    placeholder={translate("care_level")}
                    options={careLevelsList}
                    onSelectOption={handleCareLevelSelectChange}
                    selectedOption={selectedCareLevel}
                  />
                </div>

                <div className="add_newsite flex w-full justify-end lg:pb-2">
                  <span
                    className="add-newsite__text link p-2-v-1"
                    onClick={() => {
                      setAddNewCareUnitProperty("care_level");
                    }}
                  >
                    {translate("add_new_care_level")}
                  </span>
                </div>

                <div className="my-4 h-11">
                  <Select
                    placeholder={translate("medical_speciality")}
                    options={medicalSpecialityList}
                    onSelectOption={handleMedicalSpecSelectChange}
                    selectedOption={selectedMedicalSpeciality}
                  />
                </div>

                <div className="add_newsite flex w-full justify-end">
                  <span
                    className="add-newsite__text link p-2-v-1"
                    onClick={() => {
                      setAddNewCareUnitProperty("medical_speciality");
                    }}
                  >
                    {translate("add_new_medical_speciality")}
                  </span>
                </div>

                <div className="my-4 h-11">
                  <Select
                    placeholder={translate("select_care_unit_type")}
                    options={careUnitTypesList}
                    onSelectOption={handleCareUnitTypeSelectChange}
                    selectedOption={selectedCareUnitType}
                  />
                </div>

                {Number(selectedCareUnitType?.value) !== 3 ? (
                  <div className="add_newsite my-2 flex w-full cursor-pointer justify-start">
                    <span
                      className="add-newsite__text link p-2-v-1"
                      onClick={() => {
                        setIsDataSourceDrawerOpen(true);
                      }}
                    >
                      Assign Data Source
                    </span>
                  </div>
                ) : null}

                <Button
                  additionalStyles="w-1/2 h-11"
                  variant="primary"
                  text={variant === ScrollBarConstants.ADD_CARE_UNIT ? translate("save_care_unit") : translate("save")}
                />
              </form>
            </div>
            {careUnit.careUnitTypeId === 3 && (
              <div className="mb-10 ml-10 w-full border-l-2 pr-12">
                <MultiSelectListWithSearch
                  key={IsPhysicianLoad ? "a" : "b"}
                  variant="CARE_UNIT_USER"
                  error={checklistError}
                  list={careUnitsForSelect}
                  onItemsSelect={setSelectedCareUnitsForSubmit}
                  isMulti={true}
                  title={translate("search_care_unit")}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CareUnitFormV2;
