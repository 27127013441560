import React from "react";
import { KanbanComponent, ColumnsDirective, ColumnDirective, ActionEventArgs } from "@syncfusion/ej2-react-kanban";
import { registerLicense, L10n } from "@syncfusion/ej2-base";
import KanbanCard from "../components/KanbanCard/KanbanCard";
import KanbanService from "../../../../services/kanbanService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import KanbanDialogTemplate from "../components/KanbanDialog/KanbanDialogTemplate";
import { KanbanCard as Card } from "../../../../interfaces/kanban/models/kanbanTask";
import { AddCardPayload } from "../../../../interfaces/kanban/payloads/addCardPayload";
import { UpdateCardPayload } from "../../../../interfaces/kanban/payloads/updateCardPayload";
import useKanbanBoardData from "./hooks/useKanbanBoardData";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import { kanBanBoardTranslations } from "../../../../utils/kanban/translations";

registerLicense(process.env.REACT_APP_KANBAN_LICENCE || "");

L10n.load(kanBanBoardTranslations);

const KanbanBoard = () => {
  const { kanbanStatuses, boardTasks, fetchTasks } = useKanbanBoardData();
  const { t: translate, i18n } = useTranslation();

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const handleAddCard = async (args: ActionEventArgs) => {
    let card = args.addedRecords && (args.addedRecords[0] as AddCardPayload);

    if (careUnitId && card) {
      card = {
        ...card,
        title: card.title,
        description: card.description,
        assigneeId: card.assigneeId,
        careUnitId: careUnitId,
        statusId: card.statusId,
      };

      try {
        await KanbanService.addKanbanCard(card);
        fetchTasks(careUnitId);
      } catch (error) {
        toast(<NotificationFloat varaint={"error"} content={"Something went wrong!"} />, {
          icon: false,
          className: "custom-toast",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  };

  const handleEditCard = async (args: ActionEventArgs) => {
    let card = args.changedRecords && (args.changedRecords[0] as UpdateCardPayload);

    if (careUnitId && card) {
      card = {
        ...card,
        id: card.id,
        title: card.title,
        description: card.description,
        assigneeId: card.assigneeId,
        careUnitId: careUnitId,
        statusId: card.statusId,
      };

      try {
        await KanbanService.updateKanbanCard(card);
        fetchTasks(careUnitId);
      } catch (error) {
        toast(<NotificationFloat varaint={"error"} content={"Something went wrong!"} />, {
          icon: false,
          className: "custom-toast",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  };

  const handleDeleteCard = async (args: ActionEventArgs) => {
    const card = args.deletedRecords && (args.deletedRecords[0] as Card);

    if (card && careUnitId) {
      try {
        await KanbanService.deleteKanbanCard(card.id);
        fetchTasks(careUnitId);
      } catch (error) {
        toast(<NotificationFloat varaint={"error"} content={"Something went wrong!"} />, {
          icon: false,
          className: "custom-toast",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <div className="h-screen  ">
      <ToastContainer style={{ width: "400px" }} position="top-right" hideProgressBar={true} closeButton={false} />
      <KanbanComponent
        locale={i18n.language === "en" ? "en-US" : "sv-SE"}
        id="kanban"
        keyField={"statusId"}
        dataSource={boardTasks}
        cardSettings={{
          headerField: "id",
          template: KanbanCard,
        }}
        actionComplete={async (args: ActionEventArgs) => {
          const isCardAdded = args.requestType === "cardCreated";

          const isCardEdited = args.requestType === "cardChanged";

          const isCardDeleted = args.requestType === "cardRemoved";

          if (isCardAdded) {
            await handleAddCard(args);
          }

          if (isCardEdited) {
            await handleEditCard(args);
          }

          if (isCardDeleted) {
            await handleDeleteCard(args);
          }
        }}
        width="100%"
        height="100%"
        dialogSettings={{
          template: KanbanDialogTemplate,
        }}
      >
        <ColumnsDirective>
          {kanbanStatuses.length > 0 &&
            kanbanStatuses.map((status) => (
              <ColumnDirective
                key={status.id}
                showAddButton={status.id === 1 && true}
                headerText={translate(status.name)}
                keyField={status.id}
                showItemCount={true}
              />
            ))}
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
};

export default KanbanBoard;
