import React from "react";
import { IconButtonProps } from "../../interfaces/props";
import "./delete-icon-button.css";
import DeleteIcon from "../icons/delete-icon/delete-icon";

const DeleteIconButton = ({ onClick, disabled }: IconButtonProps) => {
  return (
    <button
      className={
        disabled ? "delete-icon-button--disabled" : "delete-icon-button"
      }
      onClick={onClick}
      disabled={disabled}
    >
      <DeleteIcon width={16} height={16} />
    </button>
  );
};

export default DeleteIconButton;
