import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import useWeeksWithStaffingGaps from "./hooks/useWeeksWithStaffingGaps";
import useStaffGapByWeekChartConfigurator from "./hooks/useStaffGapByWeekChartConfigurator";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";

const StaffGapByWeekChart = ({
  careUnitId,
  scenarioId,
  fetchData,
  setFetchData,
  workCompetences,
  employmentTypes,
}: {
  careUnitId: number;
  scenarioId: number;
  fetchData: boolean;
  setFetchData: React.Dispatch<React.SetStateAction<boolean>>;
  workCompetences: number[];
  employmentTypes: number[];
}): JSX.Element => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options | null>(null);

  const isEmploymentTypeFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.EmploymentTypeMultiSelect);
  const isNoOfWeekendShiftsFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.NoOfWeekendShifts);

  const dataHandler = useWeeksWithStaffingGaps(careUnitId, scenarioId);

  const chartConfigurator = useStaffGapByWeekChartConfigurator(
    isEmploymentTypeFeatureFlagEnabled,
    isNoOfWeekendShiftsFeatureFlagEnabled
  );

  useEffect(() => {
    if (fetchData) {
      dataHandler.fetch(workCompetences, employmentTypes);
    }
  }, [fetchData]);

  useEffect(() => {
    if (!dataHandler.isLoading) {
      const options = chartConfigurator.getOptions(
        dataHandler.data.map((r) => r.weekId.toString()),
        dataHandler.data.map((r) => r.staffAvailability),
        dataHandler.data.map((r) => r.staffingTarget),
        dataHandler.data.map((r) => r.staffingFteOoh),
        dataHandler.data.map((r) => r.noOfWeekendShifts)
      );

      setChartOptions(options);
      setFetchData(false);
    }
  }, [dataHandler.data]);

  return (
    <>
      {chartOptions && (
        <HighchartsReact
          containerProps={{ style: { height: "100%" } }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </>
  );
};

export default StaffGapByWeekChart;
