import { useTranslation } from "react-i18next";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import {
  numberBetWeenZeroAndOneValidator,
  numberBetweenZeroandFourtyValidator,
  positiveNumberValidator,
} from "../../../../utils/handsontable-configuration/validators/validators";

const useGetCompensationGridColumns = (
  formalCompetences: string[],
  competences: string[],
  agreements: string[],
  culture: string
): HandsOnTableColumn[] => {
  const { t: translate } = useTranslation();

  let cols: HandsOnTableColumn[] = [];

  cols = [
    {
      meta: { data: "competenceName", type: "dropdown", source: competences },
      headerTitle: translate("formal-competence"),
      width: 200,
    },
    {
      meta: { data: "agreementName", type: "dropdown", source: agreements },
      headerTitle: translate("AgreementName"),
      width: 200,
    },
    {
      meta: {
        data: "salary",
        type: "numeric",
        validator: positiveNumberValidator,
      },
      headerTitle: translate("Salary"),
      width: 120,
    },
    {
      meta: {
        data: "sickLeave",
        type: "numeric",
        numericFormat: {
          culture: "sv-SE",
          pattern: {
            mantissa: 2,
          },
        },
        validator: numberBetWeenZeroAndOneValidator,
      },
      headerTitle: translate("SickLeave"),
      width: 100,
    },
    {
      meta: {
        data: "sickLeavePaidShare",
        type: "numeric",
        validator: numberBetWeenZeroAndOneValidator,
      },
      headerTitle: translate("SickLeavePaidShare"),
      width: 120,
    },
    {
      meta: {
        data: "oohReceivedAsTime",
        type: "numeric",
        validator: numberBetWeenZeroAndOneValidator,
      },
      headerTitle: translate("OohReceivedAsTime"),
      width: 120,
    },
    {
      meta: {
        data: "vacationDays",
        type: "numeric",
        validator: numberBetweenZeroandFourtyValidator,
      },
      headerTitle: translate("VacationDays"),
      width: 120,
    },
    {
      meta: {
        data: "overtimeCoverage",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            output: "percent",
            mantissa: 0,
          },
        },
        validator: numberBetWeenZeroAndOneValidator,
      },
      headerTitle: translate("overtime_coverage"),
      width: 120,
    },
    {
      meta: {
        data: "overtimeHourlyRate",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            output: "percent",
            mantissa: 0,
          },
        },
        validator: positiveNumberValidator,
      },
      headerTitle: translate("overtime_hourly_rate"),
      width: 120,
    },
    {
      meta: {
        data: "rentalCoverage",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            output: "percent",
            mantissa: 0,
          },
        },
        validator: numberBetWeenZeroAndOneValidator,
      },
      headerTitle: translate("rental_coverage"),
      width: 120,
    },
    {
      meta: {
        data: "rentalHourlyRate",
        type: "numeric",
        validator: positiveNumberValidator,
      },
      headerTitle: translate("rental_hourly_rate"),
      width: 120,
    },
  ];
  cols[0].meta.data = "formalCompetenceName";
  cols[0].meta.source = formalCompetences;

  return cols;
};

export default useGetCompensationGridColumns;
