import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Drawer from "../Drawer/Drawer";
import { OrganizationStructureItem } from "../../interfaces";
import { CareUnit } from "../../../../interfaces/orgstructure/Care-Unit";
import { CareLevel, CareUnitStateType, CareUnitType, MedicalSpeciality } from "../../../../interfaces/orgstructure";
import OrgService from "../../../../services/orgService";
import {
  CARE_LEVELS_ENDPOINT,
  CARE_UNIT_TYPES_ENDPOINT,
  MEDICAL_SPECIALITIES_ENDPOINT,
} from "../../../../utils/apiendpoints";
import { GetCareUnitDto } from "../../../../interfaces/orgstructure/dtos/Care-Unit-Dto";
import CareUnitFormV2 from "../forms/care-unit/CareUnitFormV2";

const CareUnitSlider = ({
  isOpen,
  setIsOpen,
  variant,
  selectedCareUnitGroup,
  setCareUnits,
  selectedHospital,
  setSuccessNotification,
  selectedEditableCareUnit,
  physicianCareUnit,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  variant: string;
  selectedCareUnitGroup?: OrganizationStructureItem;
  setCareUnits: Dispatch<SetStateAction<CareUnit[]>>;
  selectedHospital?: OrganizationStructureItem;
  setSuccessNotification?: Dispatch<SetStateAction<string>>;
  selectedEditableCareUnit?: CareUnitStateType;
  physicianCareUnit?: GetCareUnitDto[];
}) => {
  const [addNewCareUnitProperty, setAddNewCareUnitProperty] = useState<string>("");
  const [isPhysicianCareUnit, setIsPhysicianCareUnit] = useState<boolean>(false);

  const [careUnitTypes, setCareUnitTypes] = useState<CareUnitType[]>([]);
  const [medicalSpecialities, setMedicalSpecialities] = useState<MedicalSpeciality[]>([]);
  const [careLevels, setCareLevels] = useState<CareLevel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const careUnitTypeArray: CareUnitType[] = await OrgService.getOrgAsync(CARE_UNIT_TYPES_ENDPOINT);
      setCareUnitTypes(careUnitTypeArray);

      const medicalSpecialityArray: MedicalSpeciality[] = await OrgService.getOrgAsync(MEDICAL_SPECIALITIES_ENDPOINT);
      setMedicalSpecialities(medicalSpecialityArray);

      const careLevelArray: CareLevel[] = await OrgService.getOrgAsync(CARE_LEVELS_ENDPOINT);
      setCareLevels(careLevelArray);
    };

    fetchData();
  }, []);

  return (
    <>
      <Drawer
        onClose={() => {
          if (addNewCareUnitProperty !== "") {
            setAddNewCareUnitProperty("");
          } else {
            setIsOpen(false);
          }
        }}
        isOpen={isOpen}
        moreSpace={isPhysicianCareUnit}
      >
        <CareUnitFormV2
          addNewCareUnitProperty={addNewCareUnitProperty}
          setAddNewCareUnitProperty={setAddNewCareUnitProperty}
          variant={variant}
          selectedCareUnitGroup={selectedCareUnitGroup}
          setCareUnits={setCareUnits}
          setIsScrollBarOpen={setIsOpen}
          selectedHospital={selectedHospital}
          setSuccessNotification={setSuccessNotification}
          selectedEditCareUnit={selectedEditableCareUnit}
          setIsPhysicianCareUnit={setIsPhysicianCareUnit}
          medicalSpecialitiesProp={medicalSpecialities}
          careLevelsProp={careLevels}
          selectablePhysicianCareUnits={physicianCareUnit}
          careUnitTypesProp={careUnitTypes}
          isScrollBarOpen={isOpen}
        />
      </Drawer>
    </>
  );
};

export default CareUnitSlider;
