import React, { useEffect, useRef, useState } from "react";
import { GridLayout } from "../../../layouts";
import { HotTableClass as HotTable } from "@handsontable/react";
import WorkCompetence from "../../../interfaces/competence/WorkCompetence";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import _ from "lodash";
import { Button, DataTableAddButton } from "../../../components";
import { WorkCompetenceService } from "../../../services/workCompetenceService";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useGetWorkCompetenceMappingTableConfigs from "./hooks/useGetWorkCompetenceMappingTableConfigs";
import useGetWorkCompetencesData from "../../../common/hooks/data/useGetWorkCompetencesData";
import WorkCompetenceMappingConfigReturn from "../../../interfaces/parameters/workCompetenceMapping/WorkCompetenceMappingConfigReturn";
import useGetFormalCompetenceData from "./hooks/useGetFormalCompetenceData";

const WorkCompetenceMapping = () => {
  const { t: translate } = useTranslation();

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const dataTable = useRef<HotTable>(null);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const hook = useGetWorkCompetencesData(careUnitId);

  const formalCompetenceHook = useGetFormalCompetenceData();

  const configs: WorkCompetenceMappingConfigReturn = useGetWorkCompetenceMappingTableConfigs(
    dataTable,
    formalCompetenceHook.data,
    careUnitId ? careUnitId : 0
  );

  useEffect(() => {
    hook.fetch();
  }, [careUnitId]);

  const handleOnSaveClick = async () => {
    setIsSaveButtonDisabled(true);

    const tableData = dataTable.current?.hotInstance?.getData();

    let workCompetences: WorkCompetence[] = [];

    if (tableData) {
      workCompetences = tableData.map((row: any) => {
        return {
          careUnitId: careUnitId,
          name: row[1],
          shortName: row[2],
          formalCompetenceId: formalCompetenceHook.data.filter((rr) => rr.name == row[0])[0].id,
          formalCompetenceName: null,
          formalCompetenceShortName: null,
          formalCompetenceColor: null,
          id: row[3],
        };
      });
    }

    await WorkCompetenceService.postWorkCompetences(careUnitId as number, workCompetences)
      .then(() => {
        setIsSaveButtonDisabled(false);
        toast.success(
          <div className="flex justify-center">
            <div className="m-4">
              <span>{translate("general_successful_message")}</span>
            </div>
          </div>,
          { icon: false }
        );
        hook.fetch();
      })
      .catch(() => {
        setIsSaveButtonDisabled(false);
        toast.error(
          <div className="flex justify-center">
            <div className="m-4">
              <span>{translate("something_went_wrong")}</span>
            </div>
          </div>,
          { icon: false }
        );
      });
  };

  return (
    <>
      <ToastContainer position="top-right" hideProgressBar={true} closeButton={false} />
      <GridLayout additionalStyles="mt-0 border-2 rounded-r rounded-b h-[86vh] px-0 bg-[#F8F8F8]">
        <div className="col-start-1 col-end-13 ">
          <div className="flex items-center justify-between bg-white px-2 py-2">
            <div className="col-start-1 col-end-7 items-center justify-between gap-3"></div>
            <div className="col-start-7 col-end-13 flex">
              <div className="items-center justify-between gap-3">
                <Button
                  onClick={handleOnSaveClick}
                  text={"Save Changes"}
                  variant={"primary"}
                  disabled={isSaveButtonDisabled}
                  additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                />
              </div>
            </div>
          </div>
          <div className="relative px-2">
            <HotTable {...configs.props} data={hook.data} />
            <DataTableAddButton dataTable={dataTable} />
          </div>
        </div>
      </GridLayout>
    </>
  );
};

export default WorkCompetenceMapping;
