import React from "react";
import "./delete-button.css";
import { DeleteButtonProps } from "../../interfaces/props/comment-modal-button";

const DeleteButton = ({ onClick, disable }: DeleteButtonProps) => {
  return (
    <button
      className="delete-button ml-3 pl-3 pr-3"
      onClick={onClick}
      disabled={disable}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="delete-button__svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
        />
      </svg>
    </button>
  );
};

export default DeleteButton;
