import React, { useEffect, useState } from "react";
import OutpatientProductivityService from "../../../../services/outpatientProductivityService";
import { OutpatientProductivityAnalysis } from "../../../../interfaces/analytics/outpatientProductivityAnalysis";
import { ReturnGetProductivityAnalysisData } from "../../../../interfaces/analytics/returnGeProductivityAnalysisData";

const useGetOutpatientProdAnalysisData = (
  scenarioId: number,
  year: number
): ReturnGetProductivityAnalysisData<OutpatientProductivityAnalysis[]> => {
  const [productivityAnalysisData, setProductivityAnalysisData] = useState<OutpatientProductivityAnalysis[]>([]);

  const fetchData = async () => {
    await OutpatientProductivityService.getProductivityAnalysis(scenarioId, year)
      .then((res) => {
        const responseData: OutpatientProductivityAnalysis[] = res.data;
        setProductivityAnalysisData(responseData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [scenarioId, year]);

  return {
    data: productivityAnalysisData,
    fetch: fetchData,
  };
};

export default useGetOutpatientProdAnalysisData;
