import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useLastYearResult } from "../../Hooks/useLastYearResult";
import { usePlanvsActualResult } from "../../Hooks/usePlanvsActualResult";
import { AxiosResponse } from "axios";
import { OutpatientResultsResponse } from "../../../../../interfaces/responses";
import { ColumnChartData } from "../../../../../interfaces/production-plan";
import { OutPatientMultiSelectRequest } from "../../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { OutpatientPlanResultRequest } from "../../../../../interfaces/production-plan/outpatient/outpatientPlanResultRequest";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { OutpatientPlanService } from "../../../../../services";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";

const ResultsChart = ({
  outpatientMultiSelectFilterRequest,
}: {
  outpatientMultiSelectFilterRequest: OutPatientMultiSelectRequest | null;
}) => {
  const { t: translate } = useTranslation();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const productionYear = useSelector((state: RootState) => state.globalFilter.filterYear);
  const [outpatientResultsData, setOutpatientResultsData] = useState<ColumnChartData[]>([]);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.OutpatientResultMultiSelect);

  const [lastYearData] = useLastYearResult(careUnitId, scenarioId, productionYear, outpatientMultiSelectFilterRequest);
  const [planVsActual] = usePlanvsActualResult(lastYearData, outpatientResultsData[0]?.data);
  const [isChartLoading, setIsChartLoading] = useState(true);

  useEffect(() => {
    if (
      isFeatureFlagEnabled &&
      outpatientMultiSelectFilterRequest &&
      outpatientMultiSelectFilterRequest.medicalSpecialtyIds.length > 0
    ) {
      fetchOutpatientResultsData();
    } else if (!isFeatureFlagEnabled) {
      fetchOutpatientResultsData();
    }
  }, [careUnitId, scenarioId, year, outpatientMultiSelectFilterRequest, isFeatureFlagEnabled]);

  const getRoundedValue = (value: number | undefined) =>
    value === undefined ? 0 : isNaN(value) ? 0 : Math.round(value);

  const fetchOutpatientResultsData = () => {
    if (
      outpatientMultiSelectFilterRequest !== null &&
      outpatientMultiSelectFilterRequest.medicalSpecialtyIds.length > 0 &&
      careUnitId !== null &&
      careUnitId > 0 &&
      isFeatureFlagEnabled
    ) {
      setIsChartLoading(true);
      const outpatientResultRequest: OutpatientPlanResultRequest = {
        careUnitId: careUnitId,
        productionYear: year,
        scenarioId: scenarioId,
        professionIds:
          outpatientMultiSelectFilterRequest?.professionIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.professionIds,
        contactTypeIds:
          outpatientMultiSelectFilterRequest?.contactTypeIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.contactTypeIds,
        contactReasonGroupingIds:
          outpatientMultiSelectFilterRequest?.contactReasonGroupingIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.contactReasonGroupingIds,
        typesOfVisit:
          outpatientMultiSelectFilterRequest?.typesOfVisit[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.typesOfVisit,
        careTypeIds:
          outpatientMultiSelectFilterRequest?.careTypeIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.careTypeIds,
        typesOfEmergency:
          outpatientMultiSelectFilterRequest?.typesOfEmergency[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.typesOfEmergency,
        medicalSpecialtyIds:
          outpatientMultiSelectFilterRequest?.medicalSpecialtyIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.medicalSpecialtyIds,
      };

      OutpatientPlanService.getOutPatientResultsWithMultiSelect(outpatientResultRequest)
        .then((response: AxiosResponse<OutpatientResultsResponse>) => {
          const chartData = [
            {
              name: translate("no_of_visits_plan"),
              data: response.data.incomingVisits,
              color: "#4265A2",
            },
            {
              name: translate("queue_reduction_plan"),
              data: response.data.queueCuttingVisits,
              color: {
                pattern: {
                  backgroundColor: "#7EA4CF",
                  path: {
                    d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                    stroke: "white",
                  },
                  width: 10,
                  height: 10,
                },
              },
            },
          ];

          setOutpatientResultsData(chartData);
          setIsChartLoading(false);
        })
        .catch((ex) => {
          console.error(ex);
          setIsChartLoading(false);
        });
    } else if (!isFeatureFlagEnabled) {
      OutpatientPlanService.getOutPatientResults(careUnitId, productionYear, scenarioId)
        .then((response: AxiosResponse<OutpatientResultsResponse>) => {
          const chartData = [
            {
              name: translate("no_of_visits_plan"),
              data: response.data.incomingVisits,
              color: "#4265A2",
            },
            {
              name: translate("queue_reduction_plan"),
              data: response.data.queueCuttingVisits,
              color: {
                pattern: {
                  backgroundColor: "#7EA4CF",
                  path: {
                    d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
                    stroke: "white",
                  },
                  width: 10,
                  height: 10,
                },
              },
            },
          ];

          setOutpatientResultsData(chartData);
          setIsChartLoading(false);
        })
        .catch((ex) => {
          console.error(ex);
          setIsChartLoading(false);
        });
    }
  };

  const options = {
    chart: {
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    title: {
      text: undefined,
    },

    xAxis: {
      categories: _.range(1, 53),

      title: {
        text: translate("year") + " " + year + ", " + translate("week"),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        // step: 1, // - Show all the labels in the x axis,
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      // Primary yAxis
      reversedStacks: false,
      title: {
        text: undefined,
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        marker: {
          enabled: false,
        },
        borderWidth: 0,
      },
    },
    tooltip: {
      pointFormatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const x = this.x ?? 0;
        if (this.series.name === translate("carried_out_visits")) {
          const actual = getRoundedValue(lastYearData[Number(x)]);
          const planedResultForColumn = getRoundedValue(outpatientResultsData[0]?.data[Number(x)]);
          const queueCuttingPlanForColumn = getRoundedValue(outpatientResultsData[1]?.data[Number(x)]);
          const plan = planedResultForColumn + queueCuttingPlanForColumn;
          const plannedVsActualDifferenceForColumn = getRoundedValue(planVsActual[Number(x)]);
          const difference = plannedVsActualDifferenceForColumn + queueCuttingPlanForColumn;
          const differenceWithPlusAndMin = difference > 0 ? " +" + difference.toString() : "" + difference.toString();

          return (
            translate("contacts_plan") +
            " : " +
            plan +
            "</br>" +
            translate("contacts_actual") +
            " : " +
            actual +
            "</br>" +
            translate("contacts_difference") +
            " : " +
            differenceWithPlusAndMin
          );
        } else {
          return this.series.name + ":" + this.y + "</br>";
        }
      },
    },

    series: [
      {
        data: outpatientResultsData[0]?.data,
        type: "column",
        color: outpatientResultsData[0]?.color,
        name: outpatientResultsData[0]?.name,
      },
      {
        data: outpatientResultsData[1]?.data,
        type: "column",
        color: outpatientResultsData[1]?.color,
        name: outpatientResultsData[1]?.name,
      },
      {
        data: lastYearData,
        type: "line",
        color: "#AD1E23",
        name: translate("carried_out_visits"),
        tooltip: {
          valueDecimals: 0,
        },
      },
    ],
  };

  return (
    <div className="h-full">
      {isChartLoading ? (
        <div className="flex h-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      ) : (
        <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default ResultsChart;
