import { AxiosResponse } from "axios";
import { OutpatientPlanFilteredRequestPayload } from "../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import { OutpatientPlanResultRequest } from "../interfaces/production-plan/outpatient/outpatientPlanResultRequest";
import { OutpatientWeeklyStatRequest } from "../interfaces/production-plan/outpatient/outpatientWeeklyStatRequest";
import HttpCommonService from "./httpCommonService";
import { ReferrelsByProfessionData } from "../interfaces/production-plan/outpatient/referral-intake-chart-data";

const OutpatientHistoricalDataService = {
  getOutPatientDataDateRange: async (year: number) => {
    return await HttpCommonService.getAsync(`api/outpatientproduction/daterange/${year}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getHistoricalData: async (id: number) => {
    return await HttpCommonService.getAsync(`api/outpatientproduction/historicstat/${id}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getSumOfOutpatientOutcomeData: async (endPoint: string, payload: OutpatientPlanFilteredRequestPayload) => {
    return await HttpCommonService.postAsync(endPoint, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getAvgOfOutpatientOutcomeData: async (endPoint: string, payload: OutpatientPlanFilteredRequestPayload) => {
    return await HttpCommonService.postAsync(endPoint, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getAvgOfOutpatientOutcomeForecastData: async (id: number, year: number) => {
    return await HttpCommonService.getAsync(`api/outpatientproduction/forecast-rolling-avarage/${id}/${year}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getReferralOfOutpatientData: async (id: number, year: number) => {
    return await HttpCommonService.getAsync(`api/outpatientproduction/sumofoutpatientproductionreferrel/${id}/${year}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getReferrelsByProfessionsData: async (id: number, year: number) => {
    return await HttpCommonService.getAsync(
      `api/outpatientproduction/referrals/group/professions/careunit/${id}/year/${year}`
    )
      .then((response: AxiosResponse<ReferrelsByProfessionData<number>[]>) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getLastYearResult: async (payload: OutpatientPlanResultRequest) => {
    return await HttpCommonService.postAsync(`api/outpatientproduction/lastyear-plan`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getOutpatientWeeklyStats: async (payload: OutpatientWeeklyStatRequest) => {
    return await HttpCommonService.postAsync(`api/outpatientproduction/weekly-stat`, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default OutpatientHistoricalDataService;
