import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

import { AxisLabelObject } from "../../../../interfaces/highcharts/AxisLabelObject";
import { ProductionOutcomeReponse, ProductionPlanPhasedResponse } from "../../../../interfaces/responses";
import { ProductionVsPlanService } from "../../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useMediaQuery } from "../../../../hooks";
import { SCREEN_SIZES } from "../../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../../utils/highcharts/common";
import { RecoveryPlanReponse } from "../../../../interfaces/responses/productionvsanalyticsresponse";
import { ProductionVsPlanChartProps } from "../../../../interfaces/props";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";

const ProductionVsPlanDashboardChart = ({
  selectedTypeOfPlan,
  year,
  careUnitList,
  chartTitle,
  isPlacedOnDashboard,
  outPatientMultiSelectFilters,
  onChartDataLoading,
}: ProductionVsPlanChartProps) => {
  const { t: translate } = useTranslation();
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [chartLoaded, setChartLoaded] = useState<boolean>(false);
  const [recoveryPlan, setRecoveryPlan] = useState<RecoveryPlanReponse[]>();
  const [areaChartData, setAreaChartData] = useState<ProductionPlanPhasedResponse>([]);
  const [columnChartData, setColumnChartData] = useState<ProductionOutcomeReponse>();

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(isPlacedOnDashboard ? true : false, isLargeScreen);

  const generateLegendIcon = (name: string, isSeriesVisible: boolean) => {
    let imagePath = "";

    if (name === translate("plan")) {
      imagePath = "/images/circle.svg";
    } else if (name === translate("outcome")) {
      imagePath = "/images/circle-svg-colored.svg";
    } else if (name === translate("recovery_plan")) {
      imagePath = "/images/circle-svg-yellow.svg";
    }

    const imageDimension =
      name === translate("outcome") ? "width = '48px' height = '40px'>" : "width = '16px' height = '16px'>";

    const img: string =
      `<div class="highcharts-custom-legend ${isPlacedOnDashboard && "dashboard"} ${
        !isSeriesVisible && "highcharts-custom-selected"
      }"> <img src =` +
      imagePath +
      " " +
      imageDimension +
      "  " +
      name +
      "</div>";

    return img;
  };

  const areaChartOptions = {
    chart: {
      backgroundColor: "white",
      className: "product_vs_plan_chart",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: chartFontSize,
      },
    },

    title: {
      text: chartTitle,
      style: {
        fontSize: "20px",
      },
    },

    xAxis: {
      allowDecimals: false,
      max: 52,
      min: 1,
      startOnTick: false,
      tickInterval: 2,
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    yAxis: {
      title: {
        text: Number(selectedTypeOfPlan?.value) === 1 ? translate("no_of_visits") : translate("no_of_care_episodes"),
        style: {
          fontSize: chartFontSize,
        },
      },

      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },

    legend: {
      useHTML: true,
      verticalAlign: "top",
      symbolWidth: 0,
      margin: 30,
      itemStyle: {
        fontSize: chartFontSize,
      },

      style: {
        fontSize: chartFontSize,
      },

      labelFormatter: function (this: AxisLabelObject): string | undefined {
        const name = this.name;

        const chart = this.chart;

        let isPlanSeriesVisible = false;
        let isOutcomeSeriesVisible = false;
        let isRecoveryPlanSeriesVisible = false;

        chart.series.forEach((s: Highcharts.Series) => {
          if (s.name === translate("plan") && s.visible) {
            isPlanSeriesVisible = true;
          } else if (s.name === translate("outcome") && s.visible) {
            isOutcomeSeriesVisible = true;
          } else if (s.name === translate("recovery_plan") && s.visible) {
            isRecoveryPlanSeriesVisible = true;
          }
        });

        if (name === translate("plan")) {
          return generateLegendIcon(name, isPlanSeriesVisible);
        } else if (name === translate("outcome")) {
          return generateLegendIcon(name, isOutcomeSeriesVisible);
        } else if (name === translate("recovery_plan")) {
          return generateLegendIcon(name, isRecoveryPlanSeriesVisible);
        }
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        let tooltipInfo = "";
        const point = this.point as Highcharts.Point;
        const series = this.series as Highcharts.Series;
        const otherSeriesData = series.chart.series
          .filter((s) => s.name !== series.name)
          .map((s) => s.data.filter((d) => d.x === point.x));

        if (this.series.name === translate("outcome") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo = `${translate("week")} ${point.x} <br /> ${translate("plan")} ${
            otherSeriesData[0][0].y
          } <br /> ${translate("outcome")} ${point.y}`;
        } else if (this.series.name === translate("recovery_plan") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo = `${translate("week")} ${point.x} <br /> ${translate("plan")} ${
            otherSeriesData[0][0].y
          } <br /> ${translate("recovery_plan")} ${point.y}`;
        }
        return tooltipInfo;
      },
    },

    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 6,
      },
      column: {
        borderWidth: 0,
      },
    },

    series: [
      {
        type: "area",
        name: translate("plan"),
        data: areaChartData,
        color: "#BFBFBF",
        enableMouseTracking: false,
      },
      {
        type: "column",
        name: translate("outcome"),
        data: columnChartData?.outcome.map((outcome, index) => {
          return {
            y: outcome,
            color: columnChartData.isWithinLimit[index] ? "#4A6541" : "#BE2A23",
          };
        }),
        color: "#4A6541",
      },
      {
        type: "line",
        name: translate("recovery_plan"),
        data: recoveryPlan?.map((rp) => {
          return {
            y: rp.recoveryPlan,
            x: rp.weekId,
          };
        }),
        color: "#E5A32B",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  const fetchAreaChartData = async (
    productionPlanType: number,
    careUnitsList: number[],
    year: number,
    scenarioId: number
  ) => {
    onChartDataLoading && onChartDataLoading(true);
    setChartLoaded(false);

    await ProductionVsPlanService.getProductionVsPlanData(
      productionPlanType,
      careUnitsList,
      year,
      scenarioId,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.professionIds &&
          outPatientMultiSelectFilters.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.professionIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactTypeIds &&
          outPatientMultiSelectFilters.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactTypeIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfVisit &&
          outPatientMultiSelectFilters.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfVisit
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfEmergency &&
          outPatientMultiSelectFilters.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfEmergency
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactReasonGroupingIds &&
          outPatientMultiSelectFilters.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactReasonGroupingIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.careTypeIds &&
          outPatientMultiSelectFilters.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.careTypeIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.medicalSpecialtyIds &&
          outPatientMultiSelectFilters.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.medicalSpecialtyIds
        : null
    )
      .then((res: AxiosResponse<ProductionPlanPhasedResponse | ProductionOutcomeReponse>[]) => {
        const productionPhasedResponse = res[0].data as ProductionPlanPhasedResponse;
        const productionPhased = [];

        for (let i = 0; i < productionPhasedResponse.length + 1; i++) {
          if (i === 0) {
            productionPhased.push(0);
          } else {
            productionPhased.push(productionPhasedResponse[i - 1]);
          }
        }

        setAreaChartData(productionPhased);
        const outcome = res[1].data as ProductionOutcomeReponse;

        const outcomeSeries = [];
        const isWithinLimitSeries = [];

        for (let i = 0; i < outcome.outcome.length + 1; i++) {
          if (i === 0) {
            outcomeSeries.push(0);
          } else {
            outcomeSeries.push(outcome.outcome[i - 1]);
          }
        }

        for (let i = 0; i < outcome.isWithinLimit.length + 1; i++) {
          if (i === 0) isWithinLimitSeries.push(false);
          else isWithinLimitSeries.push(outcome.isWithinLimit[i - 1]);
        }

        outcome.outcome = outcomeSeries;
        outcome.isWithinLimit = isWithinLimitSeries;

        setColumnChartData(outcome);
      })
      .catch((err) => {
        console.error(err);
      });

    setChartLoaded(true);
    onChartDataLoading && onChartDataLoading(false);
  };

  const fetchRecoveryPlan = async (
    productionPlanType: number,
    careUnitsList: number[],
    year: number,
    scenarioId: number
  ) => {
    await ProductionVsPlanService.getRecoveryPlan(
      productionPlanType,
      careUnitsList,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.professionIds &&
          outPatientMultiSelectFilters.professionIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.professionIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactTypeIds &&
          outPatientMultiSelectFilters.contactTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactTypeIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfVisit &&
          outPatientMultiSelectFilters.typesOfVisit.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfVisit
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.typesOfEmergency &&
          outPatientMultiSelectFilters.typesOfEmergency.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.typesOfEmergency
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.contactReasonGroupingIds &&
          outPatientMultiSelectFilters.contactReasonGroupingIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.contactReasonGroupingIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.careTypeIds &&
          outPatientMultiSelectFilters.careTypeIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.careTypeIds
        : null,
      !isPlacedOnDashboard || productionPlanType === 1
        ? outPatientMultiSelectFilters?.medicalSpecialtyIds &&
          outPatientMultiSelectFilters.medicalSpecialtyIds.findIndex((r) => r === -1) > -1
          ? []
          : outPatientMultiSelectFilters?.medicalSpecialtyIds
        : null,
      year,
      scenarioId
    )
      .then((response: AxiosResponse<RecoveryPlanReponse[]>) => {
        const recoveryPlan = response.data.map((rp) => {
          return {
            recoveryPlan: rp.recoveryPlan !== null ? Math.round(parseFloat(rp.recoveryPlan.toString())) : null,
            weekId: rp.weekId,
          };
        });

        setRecoveryPlan(recoveryPlan);
      })
      .catch((error) => {
        setRecoveryPlan([]);
        console.error(error);
      });
  };

  const fetchChartData = (selectedTypeOfPlan: number) => {
    fetchAreaChartData(selectedTypeOfPlan, careUnitList, year, scenario);
    fetchRecoveryPlan(selectedTypeOfPlan, careUnitList, year, scenario);
  };

  const readyToFetch = (): boolean => {
    if (
      careUnitList &&
      careUnitList.length > 0 &&
      careUnitList[0] !== 0 &&
      year > 0 &&
      scenario > 0 &&
      selectedTypeOfPlan
    ) {
      if (selectedTypeOfPlan.value === "2" || isPlacedOnDashboard) {
        return true;
      } else {
        if (outPatientMultiSelectFilters) {
          return (
            outPatientMultiSelectFilters.professionIds.length > 0 &&
            outPatientMultiSelectFilters.contactTypeIds.length > 0 &&
            outPatientMultiSelectFilters.typesOfVisit.length > 0 &&
            outPatientMultiSelectFilters.typesOfEmergency.length > 0 &&
            outPatientMultiSelectFilters.contactReasonGroupingIds.length > 0 &&
            outPatientMultiSelectFilters.careTypeIds.length > 0 &&
            outPatientMultiSelectFilters.medicalSpecialtyIds.length > 0
          );
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (readyToFetch()) {
      if (careUnitList.length === 1) {
        fetchChartData(Number(selectedTypeOfPlan?.value));
      } else {
        fetchChartData(Number(selectedTypeOfPlan?.value));
      }
    }
  }, [scenario, careUnitList, selectedTypeOfPlan?.value]);

  return (
    <div className="!h-full max-h-full">
      {chartLoaded ? (
        <HighchartsReact
          containerProps={{ style: { height: "100%" } }}
          highcharts={Highcharts}
          options={areaChartOptions}
        />
      ) : (
        <div className="flex h-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
    </div>
  );
};

export default ProductionVsPlanDashboardChart;
