import _ from "lodash";
import { HandsOnTableColumn } from "../../../interfaces/handsOnTable/handsOnTableColumn";

export const adjustColumnHeaderColour = (
  col: number,
  TH: HTMLElement,
  colConfig: HandsOnTableColumn
) => {
  if (colConfig && colConfig.headerColor) {
    TH.style.backgroundColor = colConfig.headerColor;
    TH.style.color = "white";
  } else {
    TH.classList.add("light-blue-column");
  }

  if (colConfig && !colConfig.isFilterable) {
    TH.classList.add("remove-filter");
  }

  TH.classList.add("htMiddle");
};
