import Handsontable from "handsontable";
import { HandsontableEditor } from "handsontable/editors";
import { CellProperties } from "handsontable/settings";

class KeyValueListEditor extends HandsontableEditor {
  htOptions: any;

  prepare(
    row: number,
    col: number,
    prop: string | number,
    td: HTMLTableCellElement,
    value: any,
    cellProperties: CellProperties
  ) {
    super.prepare(row, col, prop, td, value, cellProperties);

    const width = cellProperties.editorParams.width;

    Object.assign(this.htOptions, {
      data: this.cellProperties?.source,
      columns: [
        {
          data: "_id",
        },
        {
          data: "label",
        },
      ],
      hiddenColumns: {
        columns: [1],
      },
      colWidths: width,

      beforeValueRender(
        value: string | number,
        { row, instance }: { row: number; instance: Handsontable }
      ): string | number {
        return instance.getDataAtRowProp(row, "label");
      },
    });

    if (cellProperties.keyValueListCells) {
      this.htOptions.cells = cellProperties.keyValueListCells;
    }
  }

  setValue(value: string | number): void {
    if (this.htEditor) {
      const index = this.htEditor
        .getDataAtProp("_id")
        .findIndex((id: string | number) => id === value);

      if (index !== -1) {
        value = this.htEditor.getDataAtRowProp(index, "label") as
          | string
          | number;
      }
    }
    if (value && !isNaN(Number(value))) {
      value = this.htOptions.data.find(
        (item: { _id: string | number; label: string }) =>
          item._id === Number(value)
      )?.label;
    }
    super.setValue(value);
  }

  getValue(): string | number {
    const value = super.getValue() as string | number;

    if (this.htEditor) {
      const labels = this.htEditor.getDataAtProp("label") as Array<
        string | number
      >;
      const row = labels.indexOf(value);

      if (row !== -1) {
        return this.htEditor.getDataAtRowProp(row, "_id") as string | number;
      }
    }

    return value;
  }
}

export default KeyValueListEditor;
