import React from "react";
import { useTranslation } from "react-i18next";

import "./staffing-kpis-styles.css";
import { KpiParameters } from "../../../interfaces/dashboard/staffing";

const StaffingKpis = ({ ModelValues, DiffValues }: KpiParameters) => {
  const { t: translate } = useTranslation();

  return (
    <div className="flex w-full flex-row justify-around ">
      <div className="bottomarea__text--container">
        <h1 className="bottomarea__text--title">{translate("Demand_RN")}</h1>
        <div className="bottomarea__text--value flex flex-row items-center justify-center">
          <div className="mr-2">{Math.round(ModelValues.SSK)}</div>(
          <div
            className={`bottomarea__text--value ${
              DiffValues.SSK - ModelValues.SSK < 0
                ? "bottomarea__text--value secondary danger"
                : "bottomarea__text--value secondary success"
            }`}
          >
            {DiffValues.SSK - ModelValues.SSK > 0 ? "+" : ""}
            {Math.round(DiffValues.SSK - ModelValues.SSK)}
          </div>
          )
        </div>
      </div>

      <div className="bottomarea__text--container">
        <h1 className="bottomarea__text--title">{translate("Demand_AN")}</h1>
        <div className="bottomarea__text--value flex flex-row items-center justify-center">
          <div className="mr-2">{Math.round(ModelValues.USK)}</div>(
          <div
            className={`bottomarea__text--value ${
              DiffValues.USK - ModelValues.USK < 0
                ? "secondary danger"
                : "secondary success"
            }`}
          >
            {DiffValues.USK - ModelValues.USK > 0 ? "+" : ""}
            {Math.round(DiffValues.USK - ModelValues.USK)}
          </div>
          )
        </div>
      </div>
      <div className="bottomarea__text--container">
        <h1 className="bottomarea__text--title">{translate("Demand_Other")}</h1>
        <div className="bottomarea__text--value flex flex-row items-center justify-center">
          <div className="mr-2">{Math.round(ModelValues.Other)}</div> (
          <div
            className={`bottomarea__text--value ${
              DiffValues.Other - ModelValues.Other < 0
                ? "secondary danger"
                : "secondary success"
            }`}
          >
            {DiffValues.Other - ModelValues.Other > 0 ? "+" : ""}
            {Math.round(DiffValues.Other - ModelValues.Other)}
          </div>
          )
        </div>
      </div>
    </div>
  );
};

export default StaffingKpis;
