import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HighchartsReact } from "highcharts-react-official";
import { v4 as uuid } from "uuid";

import {
  ChartDataObject,
  ChartDataResponse,
  BenchmarkDataObj,
  ChartData,
} from "../../../../interfaces/productivity/benchmarking-chart-types";
import { RootState } from "../../../../redux/store";
import ResultService from "../../../../services/resultService";
import { BenchmarkingChartProps } from "../../../../interfaces/props";
import { DashboardService } from "../../../../services";
import { useMediaQuery } from "../../../../hooks";
import { SCREEN_SIZES } from "../../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../../utils/highcharts/common";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { CARE_UNIT_TYPES, CareUnitTypeEnum } from "../../../../utils/constants/careUnitTypes";

const BenchmarkingChart = ({
  dateRange,
  selectedInput,
  selectedCompetenceInput,
  selectedCareLevel,
  selectedMedicalSpeciality,
  selectedCompetenceType,
  selectedContactType,
  selectedProductionTypes,
  afterFetchingChartData,
  onChartDataFetching,
  endDateErrors,
  setErrorNotification,
  careUnitsList,
  year,
  isPlacedOnDashboard,
  selectedCareUnitType,
}: BenchmarkingChartProps) => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.BenchmarkGoals);

  const isActualProductivityFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ActualProductivity);

  //chart data
  const [benchmarkChartData, setBenchmarkChartData] = useState<ChartData>({
    careUnits: [],
    chartData: [],
  });

  const competence_shares = ["SSK", "USK", "SPEC", "ST/LEG/AT/UL", "Övrigt"];

  const [chartDataLoaded, setChartDataLoaded] = useState<boolean>(false);

  // const orgStructure = useSelector((state: RootState) => state.orgUser);

  const globalFilter = useSelector((state: RootState) => state.globalFilter);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const { t: translate } = useTranslation();

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(isPlacedOnDashboard ? true : false, isLargeScreen);

  const axisItemsFontSize = !isPlacedOnDashboard ? 18 : chartFontSize;

  // --------DATA LOADING FUNCTIONS------
  //chart data loader
  const fetchBMChartData = async (selectedProdTypes: string[]) => {
    const productionTypeNames: string[] = selectedProdTypes;

    let result: ChartData = {
      chartData: [],
      careUnits: [],
    };

    const careLevel = selectedCareLevel && selectedCareLevel?.value === "" ? null : Number(selectedCareLevel?.value);

    const medicalSpeciality =
      selectedMedicalSpeciality && selectedMedicalSpeciality?.value === ""
        ? null
        : Number(selectedMedicalSpeciality?.value);

    const profession =
      selectedCompetenceType && selectedCompetenceType.value === "" ? null : selectedMedicalSpeciality?.value;
    const contactType = selectedContactType && selectedContactType.value === "" ? null : selectedContactType?.value;

    if (endDateErrors && endDateErrors.length < 1) {
      if (isPlacedOnDashboard) {
        if (careUnitsList && careUnitsList.length > 0) {
          await DashboardService.getDashboardBenchmarkingData(
            careUnitsList,
            year as number,
            selectedCompetenceInput.value,
            careLevel,
            medicalSpeciality,
            selectedInput.value,
            profession,
            contactType,
            productionTypeNames,
            scenario,
            Number(selectedCareUnitType?.value)
          )
            .then((res) => {
              const data: ChartDataResponse = res;

              data?.chartData?.sort((a: ChartDataObject, b: ChartDataObject) => {
                return competence_shares.indexOf(a.name) - competence_shares.indexOf(b.name);
              });
              result = data;
            })
            .catch(() => {
              setErrorNotification && setErrorNotification("form error");
            });
        }
      } else {
        if (globalFilter.careProviderId && globalFilter.careProviderId > 0) {
          await ResultService.getBenchmarkChartData(
            productionTypeNames,
            selectedInput.value,
            selectedCompetenceInput.value,
            globalFilter.careProviderId,
            selectedCareLevel?.value,
            selectedMedicalSpeciality?.value,
            getChartType(),
            selectedCompetenceType.value,
            selectedContactType.value,
            dateRange.startDate.toISOString(),
            dateRange.endDate.toISOString(),
            scenario
          )
            .then((res) => {
              const data: ChartDataResponse = res;

              data?.chartData?.sort((a: ChartDataObject, b: ChartDataObject) => {
                return competence_shares.indexOf(a.name) - competence_shares.indexOf(b.name);
              });
              result = data;
            })
            .catch(() => {
              setErrorNotification && setErrorNotification("form error");
            });
        }
      }
    }

    return result;
  };

  const getChartType = () => {
    if (globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === 1) {
      return "Outpatient";
    } else if (globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === 2) {
      return "Inpatient";
    } else if (globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === 4) {
      return "Emergency";
    } else {
      return "Doctors";
    }
  };

  //defining chart colors
  const processChartData = async (data: ChartDataResponse) => {
    const modifiedChartData: ChartData = {
      careUnits: data?.careUnits,
      chartData: [],
    };

    data?.chartData.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));

    data?.chartData.map((dataObj: ChartDataObject) => {
      const st: BenchmarkDataObj = {
        id: uuid(),
        color: dataObj.color,
        data: dataObj.data as number[],
        name: dataObj.name,
        sortOrder: dataObj.sortOrder,
      };
      modifiedChartData.chartData.push(st);
    });

    if (
      (isFeatureFlagEnabled &&
        isPlacedOnDashboard &&
        Number(selectedCareUnitType?.value) === CARE_UNIT_TYPES.INPATIENT) ||
      (isFeatureFlagEnabled && !isPlacedOnDashboard && globalFilter.filterCareUnitType === CareUnitTypeEnum.INPATIENT)
    ) {
      const targetProductivitySeries = {
        data: data.tagetProductivity?.map((tp) => {
          const careUnitName = tp.careUnitName;
          const index = modifiedChartData.careUnits.indexOf(careUnitName);
          return [index, tp.targetProductivity];
        }),
        name: translate("target_productivity"),
        id: uuid(),
        color: "#F25C05",
        type: "scatter",
        zIndex: 10000,
      };

      modifiedChartData.chartData.push(targetProductivitySeries);
    }

    if (
      isActualProductivityFeatureFlagEnabled &&
      isPlacedOnDashboard &&
      Number(selectedCareUnitType?.value) === CARE_UNIT_TYPES.INPATIENT &&
      careUnitsList?.length === 1
    ) {
      data.actualProuctivity.forEach((ap) => {
        const index = modifiedChartData.chartData.findIndex((cd) => cd.name === ap.formalCompetenceName);

        if (index !== -1) {
          (modifiedChartData.chartData[index].data as number[]).push(ap.actualProductivity);
        } else {
          const data = modifiedChartData.careUnits.length > 0 ? [0, ap.actualProductivity] : [ap.actualProductivity];

          const st: BenchmarkDataObj = {
            id: uuid(),
            color: ap.formalCompetenceColor,
            data: data,
            name: ap.formalCompetenceName,
            sortOrder: ap.sortOrder,
          };
          modifiedChartData.chartData.push(st);
        }
      });

      modifiedChartData.careUnits.push("Actual");
    }

    setBenchmarkChartData(modifiedChartData);
    setChartDataLoaded(true);
  };

  //calling APIs and response modifiers in initial load and care unit change
  useEffect(() => {
    // Effect for dashboard chart
    if (careUnitsList && careUnitsList.length > 0 && scenario > 0 && year && year > 0) {
      const fetchData = async () => {
        onChartDataFetching && onChartDataFetching(true);

        const productionTypes =
          Number(selectedCareUnitType?.value) === CARE_UNIT_TYPES.OUTPATIENT
            ? [
                "ProductionShareOutpatientVisit",
                "ProductionShareStExternalTraining",
                "ProductionShareManagement",
                "ProductionShareNonClinicalTime",
              ]
            : [
                "ProductionShareWard",
                "ProductionShareStExternalTraining",
                "ProductionShareManagement",
                "ProductionShareNonClinicalTime",
              ];

        const chartData = await fetchBMChartData(productionTypes);
        await processChartData(chartData as ChartDataResponse);

        onChartDataFetching && onChartDataFetching(false);
      };

      isPlacedOnDashboard && selectedCareUnitType !== null && fetchData();
    }
  }, [isPlacedOnDashboard, scenario, careUnitsList, selectedCareUnitType]);

  useEffect(() => {
    // Effect for main chart
    const fetchData = async () => {
      const chartData = await fetchBMChartData(selectedProductionTypes);
      await processChartData(chartData as ChartDataResponse);
    };

    selectedProductionTypes.length > 0 && !isPlacedOnDashboard && fetchData();

    if (afterFetchingChartData) {
      afterFetchingChartData();
    }
  }, [
    dateRange,
    globalFilter.careProviderId,
    globalFilter.filterCareUnitType,
    selectedInput.value,
    selectedMedicalSpeciality?.value,
    selectedCareLevel?.value,
    selectedProductionTypes,
    scenario,
  ]);

  const isWorkingHours = selectedInput.value === "working_hours";
  const isOrgUserType1 =
    globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === CareUnitTypeEnum.OUTPATIENT;
  const isOrgUserType2 =
    globalFilter.filterCareUnitType !== null && globalFilter.filterCareUnitType === CareUnitTypeEnum.INPATIENT;

  let result = "";

  if (isPlacedOnDashboard) {
    result = "";
  } else if (isOrgUserType1) {
    result = isWorkingHours ? translate("working_hours_per_visit") : translate("cost_per_visit");
  } else if (isOrgUserType2) {
    result = isWorkingHours ? translate("WorkingHoursPerDay") : translate("CostPerDay");
  } else {
    result = isWorkingHours ? translate("working_hours_per_ed_visit") : translate("cost_per_ed_visit");
  }

  const benchmarkingOptions = {
    chart: {
      type: "bar",
      height: "50%",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: benchmarkChartData.careUnits,
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          // eslint-disable-next-line
          // @ts-ignore
          const valueName = this.value.name;

          if (valueName !== undefined && valueName === globalFilter.filterCareUnitLabel) {
            return (
              `<div><span style="color: #548147; font-weight: bold; font-size: ${axisItemsFontSize}px">` +
              valueName +
              "</span></div>"
            );
          } else if (valueName !== undefined && valueName !== globalFilter.filterCareUnitLabel) {
            return `<span style="font-weight: normal; font-size: ${axisItemsFontSize}px">` + valueName + "</span>";
          } else {
            return valueName;
          }
        },
      },
      title: "",
    },
    yAxis: {
      reversedStacks: false,
      min: 0,
      title: {
        text: result,

        style: {
          fontSize: chartFontSize,
        },
      },
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: chartFontSize,
      },
      style: {
        fontSize: chartFontSize,
      },
    },
    plotOptions: {
      scatter: {
        marker: {
          symbol: "circle",
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: "{point.y}",
        },
      },
      series: {
        stacking: "normal",
        point: {},
      },
    },
    series: benchmarkChartData.chartData,
  };

  return (
    <div className="h-full w-full ">
      {chartDataLoaded ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={benchmarkingOptions}
          containerProps={{
            style: { height: "100%", width: "100%" },
          }}
        />
      ) : null}
    </div>
  );
};

export default BenchmarkingChart;
