import { useEffect, useState } from "react";
import { ChartDataYearFormat } from "../../../../interfaces/production-plan";
import { HighchartOptionsForWaitingList } from "../../../../interfaces/waiting-list/waiting-list-chart-type";
import { ChartData } from "../../../../interfaces/common/chartdata";

export const useChartObject = (
  queueDetails: ChartData[],
  queueCategories: ChartDataYearFormat[],
  isChartEmpty: boolean
) => {
  const [chartOptions, setChartOptions] =
    useState<HighchartOptionsForWaitingList>();

  const fetchChartInfo = async (): Promise<void> => {
    const options = {
      chart: {
        type: "column",
        showAxes: true,
        style: {
          fontFamily: "Open Sans",
        },
        backgroundColor: "transparent",
      },

      colors: [
        "#2C461E",
        "#638B53",
        "#739768",
        "#A9C9A0",
        "#F5C773",
        "#BD4B4F",
        "#8A181C",
        "#C9C9C9",
        "#747474",
      ],

      title: {
        text: undefined,
      },

      xAxis: {
        categories: queueCategories,
        title: {
          text: "",
          style: {
            fontSize: 12,
          },
        },
        labels: {
          style: {
            fontSize: 12,
          },
        },
      },

      yAxis: {
        reversedStacks: false,
        title: {
          text: "",
          style: {
            fontSize: 12,
          },
        },

        labels: {
          style: {
            fontSize: 12,
          },
        },
      },

      legend: {
        enabled: isChartEmpty ? false : true,
        verticalAlign: "top",
        itemStyle: {
          fontSize: 12,
        },
        style: {
          fontSize: 12,
        },
      },

      tooltip: {
        shared: false,
        formatter: function (
          this: Highcharts.TooltipFormatterContextObject
        ): string {
          const point = this.point as Highcharts.Point;
          const series = this.series as Highcharts.Series;
          const tooltipInfo = `${series.name} <br /> ${point.y}`;
          return tooltipInfo;
        },
      },

      plotOptions: {
        series: {
          stacking: "normal",
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 0,
          pointWidth: 8,
        },
      },

      series: queueDetails,
    };

    setChartOptions(options);
  };

  useEffect(() => {
    fetchChartInfo();
  }, [queueDetails, queueCategories]);

  return [chartOptions] as const;
};
