import React from "react";
import { useTranslation } from "react-i18next";

import "./plan-vs-real-kpis.css";
import { useNumberFormat } from "../../../hooks/use-number-format";

interface IProps {
  averageLosPlan: number | null;
  averageLosActual: number | null;
  occupancyAverage: number | null;
}

const PlanVsRealKpis: React.FC<IProps> = ({ averageLosPlan, averageLosActual, occupancyAverage }) => {
  const { t: translate, i18n } = useTranslation();
  const { localize } = useNumberFormat();

  return (
    <div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("Average_LoS_planned")}</h1>
        <p className="sidearea__text--value-v3">{localize(averageLosPlan || 0, i18n.language)}</p>
      </div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("Average_LoS_actual")}</h1>
        <p className="sidearea__text--value-v3">{localize(averageLosActual || 0, i18n.language)}</p>
      </div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("Occupancy_plan")}</h1>
        <p className="sidearea__text--value-v3">{occupancyAverage !== null ? `${occupancyAverage * 100} %` : 0}</p>
      </div>
    </div>
  );
};

export default PlanVsRealKpis;
