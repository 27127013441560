import { WorkCompetenceResult } from "../../../../../interfaces/competence/workCompetenceResult";

const useGetUniqueWorkComptences = (
  array: WorkCompetenceResult[]
): WorkCompetenceResult[] => {
  const uniqueWorkcompetencesIdsSet = new Set<number>();
  const resultArray: WorkCompetenceResult[] = [];

  array.forEach((item) => {
    if (!uniqueWorkcompetencesIdsSet.has(item.id)) {
      uniqueWorkcompetencesIdsSet.add(item.id);
      resultArray.push(item);
    }
  });

  return resultArray;
};

export default useGetUniqueWorkComptences;
