import { useState, Dispatch, SetStateAction, useRef } from "react";
import { UseActivityAnalysisNavigationReturnType } from "../../../../interfaces/analytics/activity-analysis-navigation-return-type";
import { ActivityAnalysisIsEditedRefType } from "../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";

const useActivityAnalysisNavigation = (
  setIsSaveNotificationOpen: Dispatch<SetStateAction<boolean>>
): UseActivityAnalysisNavigationReturnType => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [nextTabIndex, setNextTabIndex] = useState<number>(0);

  const isEditedRef = useRef<ActivityAnalysisIsEditedRefType>({
    withinShift: false,
    outsideShift: false,
    production: false,
  });

  const validateData = (): boolean => {
    if (Object.values(isEditedRef.current).includes(true)) {
      return true;
    } else {
      return false;
    }
  };

  const resetTheEditStatus = (): void => {
    isEditedRef.current = {
      withinShift: false,
      outsideShift: false,
      production: false,
    };
  };

  const redirectToRelavantTab = (): void => {
    if (nextTabIndex !== -1) setSelectedTabIndex(nextTabIndex);
  };

  const handleNextButtonClick = (): void => {
    const changes = validateData();

    if (changes) {
      setNextTabIndex(selectedTabIndex + 1);
      setIsSaveNotificationOpen(true);
      return;
    }

    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handlePrevButtonClick = (): void => {
    const changes = validateData();

    if (changes) {
      setNextTabIndex(selectedTabIndex - 1);
      setIsSaveNotificationOpen(true);
      return;
    }

    setSelectedTabIndex(selectedTabIndex - 1);
  };

  return {
    setSelectedTabIndex,
    setNextTabIndex,
    selectedTabIndex,
    nextTabIndex,
    validateData,
    resetTheEditStatus,
    redirectToRelavantTab,
    handleNextButtonClick,
    handlePrevButtonClick,
    isEditedRef,
  };
};

export default useActivityAnalysisNavigation;
