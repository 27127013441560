import React, { Fragment } from "react";
import { ButtonProps } from "../../interfaces/props";

const DownloadButton = ({
  text,
  additionalStyles,
  type,
  disabled,
  variant,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={
        disabled ? "downloadbutton-disabled flex" : "downloadbutton flex"
      }
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.456 22.046"
        className=" excel-download-icon"
      >
        <path
          id="Excel"
          d="M22.038,3.026H14.515V4.418h2.218v2.2H14.515v.7h2.218V9.528H14.515v.721h2.218v2.086H14.515v.836h2.218v2.09H14.515V16.1h2.218v2.1H14.515v1.534h7.523c.119-.036.218-.176.3-.419a2.052,2.052,0,0,0,.121-.6V3.281c0-.12-.041-.192-.121-.217A1.029,1.029,0,0,0,22.038,3.026ZM21.062,18.2H17.453V16.1h3.609v2.1Zm0-2.936H17.453V13.17h3.609Zm0-2.927H17.453V10.258h3.609v2.076Zm0-2.807H17.453v-2.2h3.609v2.2h0Zm0-2.918H17.453V4.419h3.609V6.609ZM0,2.679v17.4l13.249,2.291V.328L0,2.686ZM7.853,15.845q-.076-.2-.709-1.743c-.42-1.026-.674-1.622-.748-1.793H6.373L4.952,15.691l-1.9-.128,2.253-4.211L3.242,7.142l1.937-.1,1.28,3.295h.025L7.93,6.89l2-.126L7.548,11.321,10,15.969l-2.151-.126Z"
          transform="translate(0 -0.328)"
        />
      </svg>
      <div className="download-button-text">{text}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13.896 16.874"
        className="download-icon"
      >
        <path
          id="Download"
          d="M21.4,10.455h-3.97V4.5H11.47v5.955H7.5L14.448,17.4ZM7.5,19.389v1.985H21.4V19.389Z"
          transform="translate(-7.5 -4.5)"
        />
      </svg>
    </button>
  );
};

export default DownloadButton;
