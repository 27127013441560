import React, { useEffect, useState } from "react";
import { GridLayout } from "../../../../layouts";
import SidePanel from "../../components/sidepanel/sidepanel";
import OrgStructureItemLayout from "../../layouts/OrgStructureLayout/OrgStructureItemLayout";
import { ScrollBarConstants } from "../../utils/constants";
import {
  Hospital,
  Division,
  CareUnitStateType,
  MedicalSpeciality,
  CareLevel,
  CareUnitType,
} from "../../../../interfaces/orgstructure";
import { OrganizationStructureItem } from "../../interfaces";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import OrgService from "../../../../services/orgService";
import {
  CARE_LEVELS_ENDPOINT,
  CARE_UNIT_TYPES_ENDPOINT,
  HOSPITAL_ENDPOINT,
  MEDICAL_SPECIALITIES_ENDPOINT,
} from "../../../../utils/apiendpoints";
import NotificationBox from "../../../../components/NotificationBox/NotificationBox";
import { capitalize, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { GetCareUnitDto } from "../../../../interfaces/orgstructure/dtos/Care-Unit-Dto";
import CareUnitService from "../../../../services/careUnitService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Drawer from "../../components/Drawer/Drawer";
import { CareUnitForm } from "../../components/forms";
import CareUnitSlider from "../../components/CareUnitDrawer/CareUnitSlider";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { ToastContainer} from "react-toastify";

type EntityType = {
  entityId: number | undefined;
  entityType: string | undefined;
};

const OrgStrctureHome = () => {
  const { t: translate } = useTranslation();
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const [careunits, setCareunits] = useState<any[]>([]);
  const [physicalcareunits, setPhysicalcareunits] = useState<any[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [sideBarVariant, setSideBarVariant] = useState<string | undefined>();
  const [successNotification, setSuccessNotification] = useState("");
  const [clickedOrganizationLevel, setClickedOrganizationLevel] = useState<number>(1);
  const [selectedHospital, setSelectedHospital] = useState<OrganizationStructureItem | undefined>(undefined);
  const [selectedDivision, setSelectedDivision] = useState<OrganizationStructureItem | undefined>(undefined);
  const [selectedDepartment, setSelectedDepartment] = useState<OrganizationStructureItem | undefined>(undefined);
  const [selectedSection, setSelectedSection] = useState<OrganizationStructureItem | undefined>(undefined);
  const [selectedCareUnit, setSelectedCareUnit] = useState<OrganizationStructureItem | undefined>(undefined);
  const [selectedEditableCareUnit, setSelectedEditableCareUnit] = useState<CareUnitStateType>();
  const [selectedPhysicalCareUnit, setSelectedPhysicalCareUnit] = useState<OrganizationStructureItem | undefined>(
    undefined
  );

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const [notificationBoxState, setNotificationBoxState] = useState<{
    isOpen: boolean;
    entityType: string | undefined;
    entityName: string | undefined;
  }>({
    isOpen: false,
    entityType: undefined,
    entityName: undefined,
  });

  const [entityToDelete, setEntityToDelete] = useState<EntityType>({
    entityId: undefined,
    entityType: undefined,
  });

  const [orgStructureDeleteNotification, setOrgStructureDeleteNotification] = useState<string | undefined>("");

  const [medicalSpecialities, setMedicalSpecialities] = useState<MedicalSpeciality[]>([]);

  const [careUnitTypes, setCareUnitTypes] = useState<CareUnitType[]>([]);

  const [selectablePhysicianCreUnits, setSelectablePhysicianCreUnits] = useState<GetCareUnitDto[]>([]);

  const [careLevels, setCareLevels] = useState<CareLevel[]>([]);

  useEffect(() => {
    OrgService.getOrgAsync(HOSPITAL_ENDPOINT)
      .then((res) => {
        setHospitals(res);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  useEffect(() => {
    if (successNotification !== "") {
      const timer = setTimeout(() => {
        setSuccessNotification("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successNotification]);

  const deleteOrganizationStructureEntity = (orgStructureItemId: number, entityType: string) => {
    setEntityToDelete(() => ({
      entityType: entityType,
      entityId: orgStructureItemId,
    }));

    switch (entityType) {
      case "hospitals":
        setNotificationBoxState(() => ({
          entityName: "care provider",
          entityType: entityType,
          isOpen: true,
        }));
        break;

      case "divisions":
        setNotificationBoxState(() => ({
          entityName: "division",
          entityType: entityType,
          isOpen: true,
        }));
        break;

      case "departments":
        setNotificationBoxState(() => ({
          entityName: "department",
          entityType: entityType,
          isOpen: true,
        }));
        break;

      case "sections":
        setNotificationBoxState(() => ({
          entityName: "section",
          entityType: entityType,
          isOpen: true,
        }));

        break;

      case "physicalcareunits":
        setNotificationBoxState(() => ({
          entityName: "care unit group",
          entityType: entityType,
          isOpen: true,
        }));
        break;

      case "careunits":
        setNotificationBoxState(() => ({
          entityName: "care unit",
          entityType: entityType,
          isOpen: true,
        }));
        break;
    }
  };

  const editOrganizationStructureEntity = async (orgStructureItemId: number, entityType: string) => {
    await getCareUnitsByCareProviderIds(orgStructureItemId);
    if (entityType === "careunits" && careunits !== undefined) {
      const editableCareunit: GetCareUnitDto = await CareUnitService.getCareUnitById(orgStructureItemId, year).then(
        (res) => {
          if (res.data) {
            return res.data;
          }
        }
      );

      if (editableCareunit) {
        if (isCareunitDataSourceAssignmentFeatureFlagEnabled) {
          setIsCareUnitSidebarOpen(true);
        } else {
          setIsSideBarOpen(true);
        }

        setSideBarVariant(ScrollBarConstants.UPDATE_CARE_UNIT);
        setSelectedEditableCareUnit({
          id: editableCareunit.id,
          name: editableCareunit.careUnitName,
          siteId: editableCareunit.siteId !== undefined ? editableCareunit.siteId : 0,
          careLevelId: editableCareunit.careLevelId,
          careUnitTypeId: editableCareunit.careUnitTypeId,
          medicalSpecialityId: editableCareunit.medicalSpecialityId,
          grandParents: [],
          parentId: editableCareunit.parentId,
          parentName: "",
        });
      }
    }
  };

  const handleNotificationBoxLeftButtonClick = () => {
    resetOrgStructureDeleteProperties();
  };

  const resetOrgStructureDeleteProperties = () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityType: undefined,
      isOpen: false,
    }));

    setEntityToDelete(() => ({
      entityType: undefined,
      entityId: undefined,
    }));
  };

  const handleNotificationBoxRightButtonClick = () => {
    setNotificationBoxState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));

    if (entityToDelete.entityType !== undefined && entityToDelete.entityId !== undefined) {
      const deletedEntity = entityToDelete;

      OrgService.deleteOrgStrutctureItemAsync(entityToDelete?.entityType, entityToDelete.entityId)
        .then(() => {
          resetOrgStructureLevel(entityToDelete);
          const deletedEntityName = notificationBoxState.entityName;

          setOrgStructureDeleteNotification(`${capitalize(deletedEntityName)} deleted successfully`);
          setTimeout(() => {
            if (
              deletedEntity.entityId !== entityToDelete.entityId &&
              deletedEntity.entityType !== entityToDelete.entityType
            ) {
              resetOrgStructureDeleteProperties();
            }

            setOrgStructureDeleteNotification(undefined);
          }, 5000);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    const getCareUnitPropertyArrays = async () => {
      const careLevelArray: CareLevel[] = await OrgService.getOrgAsync(CARE_LEVELS_ENDPOINT);
      setCareLevels(careLevelArray);

      const careUnitTypeArray: CareUnitType[] = await OrgService.getOrgAsync(CARE_UNIT_TYPES_ENDPOINT);

      setCareUnitTypes(careUnitTypeArray);

      const medicalSpecialityArray: MedicalSpeciality[] = await OrgService.getOrgAsync(MEDICAL_SPECIALITIES_ENDPOINT);
      setMedicalSpecialities(medicalSpecialityArray);
    };

    getCareUnitPropertyArrays().catch((err) => {
      throw err;
    });
    if (sideBarVariant === ScrollBarConstants.ADD_CARE_UNIT) {
      getCareUnitsByCareProviderIds(0);
    }
  }, [selectedHospital, sideBarVariant]);

  const getCareUnitsByCareProviderIds = async (careunitId: number | null): Promise<void> => {
    const activeCareProviderId = selectedHospital?.id;
    if (activeCareProviderId) {
      const careUnitList: GetCareUnitDto[] = (
        await CareUnitService.getPhysicianCareUnits(activeCareProviderId, careunitId, true)
      ).data;
      setSelectablePhysicianCreUnits(careUnitList);
    }
  };

  const resetOrgStructureLevel = (deletedOrgStructureItem: EntityType) => {
    switch (deletedOrgStructureItem.entityType) {
      case "hospitals":
        setClickedOrganizationLevel(1);
        setHospitals(hospitals.filter((hospital) => hospital.id !== deletedOrgStructureItem.entityId));
        break;

      case "divisions":
        setClickedOrganizationLevel(2);
        setDivisions(divisions.filter((division) => division.id !== deletedOrgStructureItem.entityId));
        break;

      case "departments":
        setClickedOrganizationLevel(3);
        setDepartments(departments.filter((department) => department.id !== deletedOrgStructureItem.entityId));
        break;

      case "sections":
        setClickedOrganizationLevel(4);
        setSections(sections.filter((section) => section.id !== deletedOrgStructureItem.entityId));
        break;

      case "physicalcareunits":
        setClickedOrganizationLevel(5);
        setPhysicalcareunits(
          physicalcareunits.filter((physicalCareUnit) => physicalCareUnit.id !== deletedOrgStructureItem.entityId)
        );
        break;

      case "careunits":
        setCareunits(careunits.filter((careunit) => careunit.id !== deletedOrgStructureItem.entityId));
        break;
    }
  };

  const isCareunitDataSourceAssignmentFeatureFlagEnabled = useFeatureFlagIsEnabled(
    FeatureFlags.CareUnitDataSourceAssignment
  );

  const [isCareUnitSidebarOpen, setIsCareUnitSidebarOpen] = useState<boolean>(false);

  return (
    <>
      <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
        {orgStructureDeleteNotification && (
          <NotificationFloat varaint="success" content={orgStructureDeleteNotification} />
        )}
      </div>

      {notificationBoxState.isOpen && (
        <NotificationBox
          content={`Are you sure you want to delete this ${notificationBoxState.entityName}?`}
          title={`Delete ${startCase(notificationBoxState.entityName)}`}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxRightButtonClick}
          onRightButtonClick={handleNotificationBoxLeftButtonClick}
        />
      )}

      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />

      <GridLayout additionalStyles="mt-2">
        <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
          {successNotification !== "" && (
            <NotificationFloat varaint="success" content={translate("saved_successfully")} />
          )}
        </div>
        <div className="col-start-1 col-end-13">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between gap-3">
              <h1 className="heading-2 mb-4"> Organization Structure </h1>
            </div>
          </div>
        </div>

        <div className="col-start-1 col-end-3">
          {clickedOrganizationLevel >= 1 && (
            <OrgStructureItemLayout
              addButtonAction={ScrollBarConstants.ADD_HOSPITAL}
              clickedOrganizationLevel={clickedOrganizationLevel}
              editButtonAction={ScrollBarConstants.UPDATE_HOSPITAL}
              entityType="hospitals"
              isMaxLevel={true}
              oraganizationStructureLevel={1}
              orgStructureItems={hospitals}
              selectedItem={selectedHospital}
              setSelectedItem={setSelectedHospital}
              setClikedOrganizationLevel={setClickedOrganizationLevel}
              setSideBarOpen={setIsSideBarOpen}
              setSideBarVariant={setSideBarVariant}
              titleSingular="Care Provider"
              title="Care Provider"
              localizedTitle={translate("care_provider")}
              setDivisions={setDivisions}
              deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
              editOrganizationStructureEntity={editOrganizationStructureEntity}
            />
          )}
        </div>

        <div className="col-start-3 col-end-5">
          {clickedOrganizationLevel >= 2 && (
            <OrgStructureItemLayout
              addButtonAction={ScrollBarConstants.ADD_DIVISION}
              clickedOrganizationLevel={clickedOrganizationLevel}
              editButtonAction={ScrollBarConstants.UPDATE_DIVISION}
              entityType="divisions"
              orgStructureItems={divisions}
              oraganizationStructureLevel={2}
              selectedItem={selectedDivision}
              setSelectedItem={setSelectedDivision}
              setClikedOrganizationLevel={setClickedOrganizationLevel}
              setSideBarOpen={setIsSideBarOpen}
              setSideBarVariant={setSideBarVariant}
              title="Division"
              titleSingular="Division"
              localizedTitle={translate("division")}
              setDepartments={setDepartments}
              deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
              editOrganizationStructureEntity={editOrganizationStructureEntity}
            />
          )}
        </div>

        <div className="col-start-5 col-end-7">
          {clickedOrganizationLevel >= 3 && (
            <OrgStructureItemLayout
              addButtonAction={ScrollBarConstants.ADD_DEPARTMENT}
              clickedOrganizationLevel={clickedOrganizationLevel}
              entityType="departments"
              editButtonAction={ScrollBarConstants.UPDATE_DEPARTMENT}
              orgStructureItems={departments}
              oraganizationStructureLevel={3}
              selectedItem={selectedDepartment}
              setSelectedItem={setSelectedDepartment}
              setClikedOrganizationLevel={setClickedOrganizationLevel}
              setSideBarOpen={setIsSideBarOpen}
              setSideBarVariant={setSideBarVariant}
              title="Department"
              titleSingular="Department"
              localizedTitle={translate("department")}
              setSections={setSections}
              deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
              editOrganizationStructureEntity={editOrganizationStructureEntity}
            />
          )}
        </div>

        <div className="col-start-7 col-end-9">
          {clickedOrganizationLevel >= 4 && (
            <OrgStructureItemLayout
              addButtonAction={ScrollBarConstants.ADD_SECTION}
              clickedOrganizationLevel={clickedOrganizationLevel}
              editButtonAction={ScrollBarConstants.UPDATE_SECTION}
              entityType="sections"
              orgStructureItems={sections}
              oraganizationStructureLevel={4}
              selectedItem={selectedSection}
              setSelectedItem={setSelectedSection}
              setClikedOrganizationLevel={setClickedOrganizationLevel}
              setSideBarOpen={setIsSideBarOpen}
              setSideBarVariant={setSideBarVariant}
              title="Section"
              titleSingular="Section"
              localizedTitle={translate("section")}
              setPhysicalcareunits={setPhysicalcareunits}
              deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
              editOrganizationStructureEntity={editOrganizationStructureEntity}
            />
          )}
        </div>

        <div className="col-start-9 col-end-11">
          {clickedOrganizationLevel >= 5 && (
            <>
              <OrgStructureItemLayout
                addButtonAction={ScrollBarConstants.ADD_CARE_UNIT_GROUP}
                clickedOrganizationLevel={clickedOrganizationLevel}
                editButtonAction={ScrollBarConstants.UPDATE_CARE_UNIT_GROUP}
                entityType="physicalcareunits"
                orgStructureItems={physicalcareunits}
                oraganizationStructureLevel={5}
                selectedItem={selectedPhysicalCareUnit}
                setSelectedItem={setSelectedPhysicalCareUnit}
                setClikedOrganizationLevel={setClickedOrganizationLevel}
                setSideBarOpen={setIsSideBarOpen}
                setSideBarVariant={setSideBarVariant}
                title="Care Unit Group"
                titleSingular="Care Unit Group"
                localizedTitle={translate("care_unit_group")}
                setCareunits={setCareunits}
                deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
                editOrganizationStructureEntity={editOrganizationStructureEntity}
              />
            </>
          )}
        </div>
        <div className="col-start-11 col-end-13">
          {clickedOrganizationLevel >= 6 && (
            <>
              <OrgStructureItemLayout
                addButtonAction={ScrollBarConstants.ADD_CARE_UNIT}
                clickedOrganizationLevel={clickedOrganizationLevel}
                editButtonAction={ScrollBarConstants.UPDATE_CARE_UNIT}
                entityType="careunits"
                isArrowHidden={true}
                orgStructureItems={careunits}
                oraganizationStructureLevel={6}
                selectedItem={selectedCareUnit}
                setSelectedItem={setSelectedCareUnit}
                setClikedOrganizationLevel={setClickedOrganizationLevel}
                setSideBarOpen={
                  isCareunitDataSourceAssignmentFeatureFlagEnabled ? setIsCareUnitSidebarOpen : setIsSideBarOpen
                }
                setSideBarVariant={setSideBarVariant}
                title="Care unit"
                titleSingular="Care unit"
                localizedTitle={translate("care_unit")}
                deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
                editOrganizationStructureEntity={editOrganizationStructureEntity}
              />
            </>
          )}
        </div>

        {/* Right Side Bar */}
        <SidePanel
          isScrollBarOpen={isSideBarOpen}
          selectedDivision={selectedDivision}
          selectedHospital={selectedHospital}
          selectedDepartment={selectedDepartment}
          selectedSection={selectedSection}
          selectedCareUnitGroup={selectedPhysicalCareUnit}
          selectedEditableCareUnit={selectedEditableCareUnit}
          setIsScrollBarOpen={setIsSideBarOpen}
          setSideBarVariant={setSideBarVariant}
          variant={sideBarVariant}
          setHospitals={setHospitals}
          setDivisions={setDivisions}
          setDepartments={setDepartments}
          setSections={setSections}
          setCareUnits={setCareunits}
          setCareUnitGroups={setPhysicalcareunits}
          setSuccessNotification={setSuccessNotification}
          medicalSpecialitiesProp={medicalSpecialities}
          careLevelsProp={careLevels}
          selectablePhysicianCareUnits={selectablePhysicianCreUnits}
          careUnitTypesProp={careUnitTypes}
        />

        {isCareunitDataSourceAssignmentFeatureFlagEnabled && (
          <CareUnitSlider
            isOpen={isCareUnitSidebarOpen}
            setIsOpen={setIsCareUnitSidebarOpen}
            // TODO - Convert variant to an enum
            variant={sideBarVariant || ""}
            selectedCareUnitGroup={selectedPhysicalCareUnit}
            setCareUnits={setCareunits}
            selectedHospital={selectedHospital}
            physicianCareUnit={selectablePhysicianCreUnits}
            selectedEditableCareUnit={selectedEditableCareUnit}
            setSuccessNotification={setSuccessNotification}
          />
        )}
      </GridLayout>
    </>
  );
};

export default OrgStrctureHome;
