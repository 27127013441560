import { Middleware } from "redux";
import { GlobalFilterState, RootState } from "../store";
import { GLOBAL_FILTER_STORAGE_KEY } from "../../utils/constants/globalFilter";
import { loadState } from "../../utils/storage/loadStorage";

export const globalFilterLocalStorageMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (
    action.type === "globalFilter/setFilterYear" ||
    action.type === "globalFilter/setFilterScenario" ||
    action.type === "globalFilter/setCareProviderId" ||
    action.type === "globalFilter/setFilterCareUnit" ||
    action.type === "globalFilter/setFilterCareUnitType"
  ) {
    const state: RootState = storeAPI.getState();
    localStorage.setItem(GLOBAL_FILTER_STORAGE_KEY, JSON.stringify(state.globalFilter));
  }

  if (action.type === "globalFilter/setGlobalFilterIsLoadingTrue") {
    const localstorageGlobalFilter = loadState<GlobalFilterState>(GLOBAL_FILTER_STORAGE_KEY);

    if (localstorageGlobalFilter) {
      localstorageGlobalFilter.isLoading = true;
      localStorage.setItem(GLOBAL_FILTER_STORAGE_KEY, JSON.stringify(localstorageGlobalFilter));
    }
  }

  if (action.type === "globalFilter/setGlobalFilterIsLoadingFalse") {
    const localstorageGlobalFilter = loadState<GlobalFilterState>(GLOBAL_FILTER_STORAGE_KEY);

    if (localstorageGlobalFilter) {
      localstorageGlobalFilter.isLoading = false;
      localStorage.setItem(GLOBAL_FILTER_STORAGE_KEY, JSON.stringify(localstorageGlobalFilter));
    }
  }

  return result;
};
