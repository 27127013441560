import React, { Dispatch, SetStateAction } from "react";
import HistoricalData from "./HistoricalData";
import { Button, Input } from "../../../../../components";
import ControlIcon from "../../../Components/ControlIcon/ControlIcon";
import { ControlIconTypes } from "../../../Components/ControlIcon/IconTypes";
import { useTranslation } from "react-i18next";
import { OutpatientPlanPayload } from "../../../../../interfaces/productivity/outpatient-plan-data-props";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const nextIcon = <ControlIcon type={ControlIconTypes.next} />;

export interface DemandSidePanel {
  setOutpatientRequest: Dispatch<SetStateAction<OutpatientPlanPayload>>;
  outpatientRequest: OutpatientPlanPayload;
  outpatientRequestBeforeEdit: OutpatientPlanPayload;
  selectedTabIndex: number;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  setNextAvailableTabIndex: Dispatch<SetStateAction<number>>;
  setIsSaveConfirmationModalBoxOpen: Dispatch<SetStateAction<boolean>>;
  isEdited: boolean;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  isOutpatientParametersModifying: boolean;
}

const DemandSidePanel = ({
  outpatientRequest,
  setOutpatientRequest,
  selectedTabIndex,
  setSelectedTabIndex,
  outpatientRequestBeforeEdit,
  setNextAvailableTabIndex,
  setIsSaveConfirmationModalBoxOpen,
  isEdited,
  setIsEdited,
  isOutpatientParametersModifying,
}: DemandSidePanel) => {
  const { t: translate, i18n } = useTranslation();
  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const handleProductionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);
    const { name, value } = e.target;

    if (value === "") {
      setOutpatientRequest((prevState) => {
        return {
          ...prevState,
          [name]: parseInt("0"),
        };
      });
    } else {
      setOutpatientRequest((prevState) => {
        return {
          ...prevState,
          [name]: parseInt(value),
        };
      });
    }
  };

  const displayOutpatientPlanSaveNotification = async (nextTabIndex: number) => {
    if (isEdited && outpatientRequest.productionPlan !== outpatientRequestBeforeEdit.productionPlan) {
      setNextAvailableTabIndex(() => nextTabIndex);
      setIsSaveConfirmationModalBoxOpen(true);
      setIsEdited(false);
    } else {
      setSelectedTabIndex(() => nextTabIndex);
    }
  };

  return (
    <div>
      <div className="top-info"></div>
      <HistoricalData />
      <hr />
      <div className="input-area">
        <span className="graph-type-selector p-1-v-2 mb-4 mt-3 block">{translate("production_plan_year")}</span>
        <div className="mb-4">
          <Input
            name="productionPlan"
            value={outpatientRequest?.productionPlan?.toString()}
            handleInputChange={handleProductionInputChange}
            placeholder={translate("number_of_visits_plan")}
            disabled={isCareUnitReadOnly || isOutpatientParametersModifying ? true : false}
          />
        </div>
        <div className="mt-4">
          <Button
            onClick={() => displayOutpatientPlanSaveNotification(selectedTabIndex + 1)}
            text={translate("next")}
            variant={"button--secondary-optional-with-border"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem] float-right"}
            icon={nextIcon}
            disabled={isOutpatientParametersModifying}
          />
        </div>
      </div>
    </div>
  );
};

export default DemandSidePanel;
