import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { MultiSelectOption } from "../../../../interfaces";
import MultiSelectDropDown from "../../../../components/MultiselectDropdown/MultiSelectDropdown";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { isEqual } from "lodash";
import { useNumberFormat } from "../../../../hooks/use-number-format";

interface InpatientResultPaneProps {
  totalCareEpisodesForResults: number;
  occupancyRateForResults: number;
  averageLengthOfStayForResults: number;
  onWeekdaySelect: (values: MultiSelectOption[]) => void;
}

const InpatientResultPane = ({
  totalCareEpisodesForResults,
  occupancyRateForResults,
  averageLengthOfStayForResults,
  onWeekdaySelect,
}: InpatientResultPaneProps) => {
  const isInpatientWeekdayResultFeatureFlagEnabled = useFeatureFlagIsEnabled(
    FeatureFlags.inpatientResultWeekdayDropdown
  );

  const { t: translate, i18n } = useTranslation();
  const { localize } = useNumberFormat();

  const weekDays: MultiSelectOption[] = [
    {
      id: "1",
      label: translate("Mon"),
      value: "1",
    },

    {
      id: "2",
      label: translate("Tue"),
      value: "2",
    },

    {
      id: "3",
      label: translate("Wed"),
      value: "3",
    },

    {
      id: "4",
      label: translate("Thu"),
      value: "4",
    },

    {
      id: "5",
      label: translate("Fri"),
      value: "5",
    },

    {
      id: "6",
      label: translate("Sat"),
      value: "6",
    },

    {
      id: "7",
      label: translate("Sun"),
      value: "7",
    },
  ];

  const [weekdaySelectOptions] = useState<MultiSelectOption[]>(weekDays);

  const handleWeekdaySelect = (values: MultiSelectOption[]): void => {
    if (isEqual(values, weekDays) || values.length === 0) {
      onWeekdaySelect(weekDays);
      return;
    }

    onWeekdaySelect(values);
  };

  return (
    <div className="px-4">
      <div className="sidearea__text--container">
        <h1 className="sidearea__text--title">{translate("total_care_episodes")}</h1>
        <p className="sidearea__text--value">{localize(totalCareEpisodesForResults, i18n.language)}</p>

        <h1 className="sidearea__text--title">{translate("occupancy")}</h1>
        <p className="sidearea__text--value">{localize(occupancyRateForResults, i18n.language)}%</p>

        <h1 className="sidearea__text--title">{translate("average_length_of_stay")}</h1>
        <p className="sidearea__text--value">{localize(averageLengthOfStayForResults, i18n.language)}</p>
      </div>

      {isInpatientWeekdayResultFeatureFlagEnabled ? (
        <div>
          <MultiSelectDropDown
            label={translate("weekday")}
            options={weekdaySelectOptions}
            initialSelectedOptions={weekdaySelectOptions}
            onValueChange={handleWeekdaySelect}
          />
        </div>
      ) : null}
    </div>
  );
};

export default InpatientResultPane;
