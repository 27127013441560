import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "../../../../../components";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import { CareUnitGroupDto } from "../../../../../interfaces/orgstructure/dtos";
import { CareUnitGroupStateType } from "../../../../../interfaces/orgstructure/Physical-Care-Unit";
import { CareUnitGroupProps } from "../../../../../interfaces/props/org-structure-scrollbar";
import OrgService from "../../../../../services/orgService";
import { ScrollBarConstants } from "../../../utils/constants";

const CareUnitGroup = ({
  variant,
  selectedSection,
  setCareUnitGroups,
  setIsScrollBarOpen,
  setSuccessNotification,
}: CareUnitGroupProps) => {
  const { t: translate } = useTranslation();

  const [isAddCareUnitGroupFailed, setIsAddCareUnitGroupFailed] = useState<boolean>(false);

  const [careUnitGroup, setCareUnitGroup] = useState<CareUnitGroupStateType>({
    id: 0,
    name: "",
  });

  const handleCareUnitGroupInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCareUnitGroup((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddCareUnitGroupSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const physicalCareUnitDto: CareUnitGroupDto = {
      physicalCareUnitDto: {
        physicalCareUnitName: careUnitGroup.name,
        sectionId: selectedSection?.id,
      },
    };

    await OrgService.createPhysicalCareUnitAsync(physicalCareUnitDto)
      .then((res) => {
        if (setCareUnitGroups)
          setCareUnitGroups((prevArray) => [
            ...prevArray,
            {
              id: res.id,
              physicalCareUnitName: careUnitGroup.name,
            },
          ]);
        setCareUnitGroup({
          id: 0,
          name: "",
        });
        setIsAddCareUnitGroupFailed(false);
        setIsScrollBarOpen(false);
        setSuccessNotification && setSuccessNotification("Care Unit Group");
      })
      .catch((err) => {
        setIsAddCareUnitGroupFailed(true);
        throw err;
      });
  };

  return (
    <>
      {isAddCareUnitGroupFailed && (
        <div className="my-3 h-12">
          <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
        </div>
      )}
      <div className="flex h-screen w-full flex-col justify-center">
        <h3 className="heading-3">
          {variant === ScrollBarConstants.ADD_CARE_UNIT_GROUP ? translate("add_a_new") + " " : translate("edit") + " "}
          {translate("care_unit_group")}
        </h3>

        <form onSubmit={handleAddCareUnitGroupSubmit}>
          <div>
            <Input
              name="name"
              value={careUnitGroup.name}
              placeholder={translate("name")}
              handleInputChange={handleCareUnitGroupInputChange}
              error=""
              success=""
              type="text"
            />
          </div>

          <Button
            additionalStyles="w-2/3 h-11"
            variant="primary"
            text={
              variant === ScrollBarConstants.ADD_CARE_UNIT_GROUP
                ? translate("save_care_unit_group")
                : translate("edit_physical_care_unit")
            }
          />
        </form>
      </div>
    </>
  );
};

export default CareUnitGroup;
