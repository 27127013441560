import React, { useState } from "react";
import { DatePickerProps } from "../../interfaces/props";
import "./date-picker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";

registerLocale("sv", sv);
setDefaultLocale("sv");

const DatePickerInput = ({
  onFocus,
  handleInputChange,
  id,
  name,
  placeholder,
  value,
  placement,
  isClearable,
  handleClearIconClick,
  error,
}: DatePickerProps) => {
  const [hover, setHover] = useState<boolean>(false);

  const handleOnInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setHover(false);

    if (onFocus !== undefined) {
      onFocus(e);
    }
  };

  const handleDateChange = (selectedDate: any) => {
    const dateString = new Date(selectedDate);
    const year = dateString.getUTCFullYear();
    const month = dateString.getUTCMonth() + 1;
    const day = dateString.getUTCDate();
    const isoString = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}T${dateString.toISOString().substr(11, 8)}Z`;
    const dateObject = new Date(isoString);
    handleInputChange(dateObject);
  };

  return (
    <div className="input__dtp__container">
      <div className={`input__dtp ${error ? "input__dtp--error" : ""}`}>
        <DatePicker
          dateFormat={
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
              ? "dd/MM/yyyy"
              : "yyyy-MM-dd"
          }
          locale={
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
              ? "en"
              : "sv"
          }
          popperPlacement={placement}
          selected={value ? new Date(value) : null}
          onChange={handleDateChange}
          id={id}
          name={name}
          className={`input__dtp__element p-2-v-1 peer mt-2 ${
            hover && "input__dtp--hover"
          }`}
        />

        <div
          className={`date-picker-icon ${
            isClearable && value && "date-picker-icon--with-clear"
          }`}
          onFocus={handleOnInputFocus}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#747474"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
            />
          </svg>
        </div>

        {isClearable && value ? (
          <div
            className="input__dtp---icon-clear"
            onClick={handleClearIconClick}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#747474"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 9L9 15"
                stroke="#747474"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 9L15 15"
                stroke="#747474"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : null}

        <label
          htmlFor={id}
          className={`input__dtp__label ${
            value && "input__dtp__label--date-selected p-3-v-1"
          } p-2-v-1 peer-placeholder-shown:p-1-v-1 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base`}
        >
          {placeholder}
        </label>
      </div>
      {error && error?.length > 0 && (
        <small className="input__notification p-2-v-2  input__notification--error">
          <b> {error} </b>
        </small>
      )}
    </div>
  );
};

export default DatePickerInput;
