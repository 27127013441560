import { ICDAnalysisSidePanelActions } from "../../../../../common/enums/icdAnalysisSidePanelActions";
import { ICDSidePanelState } from "../../../../../interfaces/analytics/icdAnalysis/icdSidePanelState";
import { ICDAnalysisSidePanelAction } from "../../../../../types/analysis/icdAnalysis/icdAnalysisSidePanelAction";

export const icdAnalysisSidePanelReducer = (
  state: ICDSidePanelState,
  action: ICDAnalysisSidePanelAction
): ICDSidePanelState => {
  switch (action.type) {
    case ICDAnalysisSidePanelActions.SET_AGE_OPTIONS:
      return { ...state, ageOptions: action.payload };

    case ICDAnalysisSidePanelActions.SET_CHAPTER_OPTIONS:
      return { ...state, chapterOptions: action.payload };

    case ICDAnalysisSidePanelActions.SET_REGION_OPTIONS:
      return { ...state, regionOptions: action.payload };

    case ICDAnalysisSidePanelActions.SET_REFERENCEREGIONS_OPTIONS:
      return { ...state, referenceRegionOptions: action.payload };

    case ICDAnalysisSidePanelActions.SET_YEAR_OPTIONS:
      return { ...state, yearOptions: action.payload };

    case ICDAnalysisSidePanelActions.SET_GENDER_OPTIONS:
      return { ...state, genderOptions: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_TOP_OPTION:
      return { ...state, selectedTopOption: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_AGES:
      return { ...state, selectedAges: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_CHAPTERS:
      return { ...state, selectedChapters: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_REGION:
      return { ...state, selectedRegion: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_REFERNCEREGIONS:
      return { ...state, selectedReferenceRegions: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_YEARS:
      return { ...state, selectedYears: action.payload };

    case ICDAnalysisSidePanelActions.SET_SELECTED_GENDERS:
      return { ...state, selectedGenders: action.payload };

    default:
      return state;
  }
};
