import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ICDAnalysisChartProps } from "../../../../interfaces/props/icdAnalysisChartProps";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "../../../../hooks/use-number-format";

interface CustomPoint extends Highcharts.Point {
  z: number;
  diagId: string;
  potential: number;
}

const ICDAnalysisChart = ({
  data,
  xAxisName,
  yAxisName,
  isZeroAxisHighlighted,
  isAvgCareTimeVsInpatientCareEpisodes,
}: ICDAnalysisChartProps) => {
  const { t: translate, i18n } = useTranslation();
  const { localize } = useNumberFormat();

  const chartOptions = {
    chart: {
      type: "bubble",
      plotBorderWidth: 1,
      zoomType: "xy",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
      },
      backgroundColor: "transparent",
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as CustomPoint;
        const avgCareTimeVsInpatientCareEpisodesTooltip =
          `<p style='font-weight:bold'>${point.name} (${point.diagId})</p>` +
          "<br />" +
          "<br />" +
          `${translate("average_length_of_stay")}:` +
          "&nbsp" +
          `${this.point.y ? localize(this.point.y, i18n.language) : this.point.y}` +
          "<br />" +
          `${translate("care_episodes_per_100_000_population")}:` +
          "&nbsp" +
          `${this.point.x ? localize(this.point.x, i18n.language) : this.point.x}` +
          "<br />" +
          `${translate("inpatient_demand_number_of_beds")}:` +
          "&nbsp" +
          `${point.z ? localize(point.z, i18n.language) : point.z}`;

        const portentialChangeBasedOnCareTimeTooltip =
          `<p style='font-weight:bold'>${point.name} (${point.diagId})</p>` +
          "<br />" +
          "<br />" +
          `${translate("due_to_length_of_stay")}:` +
          "&nbsp" +
          `${this.point.y ? localize(this.point.y, i18n.language) : this.point.y}` +
          "<br />" +
          `${translate("due_to_prevalence_of_inpatient_care")}:` +
          "&nbsp" +
          `${this.point.x ? localize(this.point.x, i18n.language) : this.point.x}` +
          "<br />" +
          `${translate("total_potential")}:` +
          "&nbsp" +
          `${point.potential ? localize(point.potential, i18n.language) : point.potential}` +
          "<br />" +
          "<br />" +
          `${translate("negative_number_indicate_potential_saving_positive_number_indicate_strong_performance")}`;

        if (isAvgCareTimeVsInpatientCareEpisodes) {
          return avgCareTimeVsInpatientCareEpisodesTooltip;
        }

        return portentialChangeBasedOnCareTimeTooltip;
      },
    },

    title: {
      text: "",
      align: "left",
    },

    xAxis: {
      gridLineWidth: 1,
      accessibility: {
        rangeDescription: xAxisName,
      },
      title: {
        text: xAxisName,
        style: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      plotLines: isZeroAxisHighlighted
        ? [
            {
              value: 0,
              width: 3,
            },
          ]
        : [],
    },

    yAxis: {
      startOnTick: false,
      endOnTick: false,
      accessibility: {
        rangeDescription: yAxisName,
      },
      title: {
        text: yAxisName,
        style: {
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      plotLines: isZeroAxisHighlighted
        ? [
            {
              value: 0,
              width: 3,
            },
          ]
        : [],
    },

    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },

      bubble: {
        color: "#729667",
      },
    },

    series: [
      {
        data: data,
        name: undefined,
      },
    ],
  };

  return (
    <>
      <HighchartsReact containerProps={{ style: { height: "90%" } }} highcharts={Highcharts} options={chartOptions} />
    </>
  );
};

export default ICDAnalysisChart;
