import HttpCommonService from "./httpCommonService";

const CapacityService = {
  getPlannedCompPerHour: async (weekdays: number[], careUnitId: number | null, scenarioId: number) => {
    return await HttpCommonService.postAsync(`api/results/PlannedCompPerHour`, {
      plannedCompPerHour: {
        careUnitId: careUnitId,
        weekDays: weekdays,
        scenarioId: scenarioId,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getWeeklyWorkingHoursHeatMapData: async (competences: string[], careUnitId: number | null, scenarioId: number) => {
    return await HttpCommonService.postAsync(`api/results/weeklyworkinghoursheatmap/`, {
      weeklyWorkingHoursDto: {
        careUnitId,
        competences,
        scenarioId,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getWeeklyWorkingHoursOverWeelyLoadHeatMapData: async (
    competences: string[],
    careUnitId: number | null,
    scenarioId: number
  ) => {
    return await HttpCommonService.postAsync(`api/results/weekly-working-hours-over-workload-heatmap`, {
      careUnitId,
      competences,
      scenarioId,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getWeeklyWorkingHoursHeatMap2Data: async (id: number) => {
    return await HttpCommonService.getAsync(`api/results/workloadbyweekdayhour/${id}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default CapacityService;
