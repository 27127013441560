import React from "react";
import { UsersList } from "../components";

function AuthManagementHome() {
  return (
    <>
      <UsersList />
    </>
  );
}

export default AuthManagementHome;
