import { HotTableClass as HotTable } from "@handsontable/react";
import React, { RefObject } from "react";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import { useTranslation } from "react-i18next";
import Handsontable from "handsontable";
import { CellValue } from "hyperformula";
import { CellProperties } from "handsontable/settings";
import numbro from "numbro";

const useCareLevelTargetGridConfigs = (dataTable: RefObject<HotTable>) => {
  const { t: translate } = useTranslation();

  const getConfigs = (): DataTableProps => {
    const props: DataTableProps = {
      ref: dataTable,
      columns: [
        { data: "levelName", type: "text", width: 100 },
        {
          data: "targetProductivity",
          type: "text",
          width: 100,
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
      ],
      stretchH: "all",
      width: 250,
      autoWrapRow: true,
      colHeaders: [translate("care_level"), translate("productivity_goal")],
      afterGetColHeader: (col: number, TH) => {
        TH.classList.add("dark-blue-column");
        TH.classList.add("htMiddle");
      },
      cells: (row, col) => {
        type cp = {
          className?: string;
          readOnly?: boolean;
          editor?: boolean | string;
        };
        const cp: cp = {};
        if (col === 0) {
          cp.className = "medium-dark-grey-row";
        } else {
          cp.className = "htRight";
        }

        return cp;
      },
    };

    return props;
  };

  return { getConfigs };
};

export default useCareLevelTargetGridConfigs;
