import { HotTableClass as HotTable } from "@handsontable/react";
import clsx from "clsx";
import Handsontable from "handsontable";
import { RefObject } from "react";

const useRenderers = () => {

  function afterRenderer(
    TD: HTMLTableCellElement,
    row: number,
    column: number,
    dataTable: RefObject<HotTable>,
    totalRowTitle: string
  ) {
    const noOfRows = dataTable.current?.hotInstance?.countRows() || 0;
    const isTotalRowExists = dataTable.current?.hotInstance?.getSourceData().find((row) => row.id === "total_row");

    if (row === noOfRows - 1 && isTotalRowExists) {
      if (column !== 0) {
        TD.className = "htRight activity-result-sub-total htMiddle";
      } else if (column === 0) {
        TD.className = "htLeft activity-result-sub-total htMiddle";
        TD.textContent = totalRowTitle;
      }
    } else if (row < noOfRows - 1 && isTotalRowExists && TD.textContent === totalRowTitle) {
      TD.textContent = "";
    }
  }

  function removeTotalValidationErrorFromTextCellRenderer(
    this: Handsontable,
    instance: Handsontable.Core,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: Handsontable.CellValue,
    cellProperties: Handsontable.CellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    if (row === instance.countRows() - 1) {
      if (td.classList.contains("htInvalid")) {
        td.classList.remove("htInvalid");
      }
    }
  }

  function removeTotalValidationErrorFromDropdownCellRenderer(
    this: Handsontable,
    instance: Handsontable.Core,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: Handsontable.CellValue,
    cellProperties: Handsontable.CellProperties
  ) {
    Handsontable.renderers.AutocompleteRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    if (row === instance.countRows() - 1) {
      if (td.classList.contains("htInvalid")) {
        td.classList.remove("htInvalid");
      }
    }
  }

  function percentage(
    this: Handsontable,
    instance: Handsontable.Core,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: Handsontable.CellValue,
    cellProperties: Handsontable.CellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    const cp = "htRight";

    if (value === null || value === "") {
      td.innerHTML = "";
    } else {
      td.innerHTML = `${value} %`;

      td.className = clsx(td.className, cp);
    }
  }

  function yellowBackGround(
    this: Handsontable,
    instance: Handsontable.Core,
    td: HTMLTableCellElement,
    row: number,
    col: number,
    prop: string | number,
    value: Handsontable.CellValue,
    cellProperties: Handsontable.CellProperties
  ) {
    Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    let cp = "htMiddle htRight";

    //  get visual row index
    const visualRow = Number(instance?.rowIndexMapper?.getVisualFromPhysicalIndex(row));

    if (visualRow % 2 === 0) {
      cp = clsx(cp, "grey-row");
    }

    if (value === 0) {
      cp = clsx(cp, "white-font-color", "dark-yellow-cell");
    } else if (value > 0 && value < 1) {
      cp = clsx(cp, "white-font-color", "light-yellow-cell");
    }

    td.className = cp;
  }

  return {
    percentage,
    yellowBackGround,
    afterRenderer,
    removeTotalValidationErrorFromTextCellRenderer,
    removeTotalValidationErrorFromDropdownCellRenderer,
  };
};

export default useRenderers;
