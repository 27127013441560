import React from "react";
import "./iconbuttonsmall.css"

interface IconButtonProps {
  onClick: (e: React.FormEvent) => void;
}

const IconButtonSmall = ({ onClick }: IconButtonProps) => {
  return (
    <button className="icon-button-small group" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-button-small__svg"
        viewBox="0 0 23.543 23.542"
      >
        <path
          id="Plus"
          d="M21.861,11.5H14.294V3.932A1.682,1.682,0,0,0,12.612,2.25H10.931A1.682,1.682,0,0,0,9.249,3.932V11.5H1.682A1.682,1.682,0,0,0,0,13.18v1.682a1.682,1.682,0,0,0,1.682,1.682H9.249v7.567a1.682,1.682,0,0,0,1.682,1.682h1.682a1.682,1.682,0,0,0,1.682-1.682V16.544h7.567a1.682,1.682,0,0,0,1.682-1.682V13.18A1.682,1.682,0,0,0,21.861,11.5Z"
          transform="translate(0 -2.25)"
        />
      </svg>
    </button>
  );
};

export default IconButtonSmall;
