import { CellMeta } from "handsontable/settings";

import { ActivityAnalysisResultsType } from "../../../../../interfaces/analytics/activity-analysis-results";
import useRemoveDuplicateStrings from "../../../../../hooks/use-remove-duplicate-strings";

const useAdjustResultsCellHeader = (
  tableData: ActivityAnalysisResultsType[],
  row: number,
  column: number
): CellMeta => {
  const cellMeta: CellMeta = {};
  if (column !== 0) {
    cellMeta.className = "htMiddle activity-result-cell";
    if (
      row ===
      useRemoveDuplicateStrings(tableData.map((x) => x.productionType)).length -
        1
    ) {
      cellMeta.className =
        "htMiddle activity-result-sub-total activity-result-cell";
    }
    if (
      row ===
      useRemoveDuplicateStrings(
        tableData
          .filter((x) => x.type === "activity_result_mapping")
          .map((x) => x.productionType)
      ).length -
        1
    ) {
      cellMeta.className =
        "htMiddle activity-result-sub-total activity-result-cell";
    }
  } else {
    cellMeta.className = "htMiddle activity-category";
    if (
      row ===
      useRemoveDuplicateStrings(tableData.map((x) => x.productionType)).length -
        1
    ) {
      cellMeta.className = "htMiddle activity-result-sub-total";
    }
    if (
      row ===
      useRemoveDuplicateStrings(
        tableData
          .filter((x) => x.type === "activity_result_mapping")
          .map((x) => x.productionType)
      ).length -
        1
    ) {
      cellMeta.className = "htMiddle activity-result-sub-total";
    }
  }

  return cellMeta;
};

export default useAdjustResultsCellHeader;
