import HttpCommonService from "./httpCommonService";

const DataSourceService = {
  getDataSourcesForCreateCareUnit: async (careUnitTypeId: number) => {
    return await HttpCommonService.getAsync(`api/datasources/careUnitTypeId/${careUnitTypeId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getDataSources: async (careUnitTypeId: number, careunitId: number) => {
    return await HttpCommonService.getAsync(`api/datasources/careUnitTypeId/${careUnitTypeId}/careunitId/${careunitId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  assignDatasourcesToOutpatientCareUnit: async (careUnitId: number, datasources: number[]) => {
    const payload = {
      careUnitId: careUnitId,
      datasources: datasources,
    };

    return await HttpCommonService.putAsync("api/datasources/outpatient/assignments", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  assignDatasourcesToInpatientCareUnit: async (careUnitId: number, datasources: number[]) => {
    const payload = {
      careUnitId: careUnitId,
      datasources: datasources,
    };

    return await HttpCommonService.putAsync("api/datasources/inpatient/assignments", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default DataSourceService;
