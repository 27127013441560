import React from "react";
import { useTranslation } from "react-i18next";

import NotificationBox from "../NotificationBox/NotificationBox";

const SaveChangesConfirmation = ({
  isSaveChangesNotificationOpen,
  onLeftButtonClick,
  onRightButtonClick,
  onMiddleButtonClick,
}: {
  isSaveChangesNotificationOpen: boolean;
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
  onMiddleButtonClick?: () => void;
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className="z-[150]">
      {isSaveChangesNotificationOpen && (
        <NotificationBox
          content={translate("do_you_want_to_save_changes")}
          title={translate("save_changes")}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          middleButtonLabel={translate("cancel")}
          variant="primary"
          onLeftButtonClick={onLeftButtonClick}
          onRightButtonClick={onRightButtonClick}
          onMiddleButtonClick={onMiddleButtonClick}
        />
      )}
    </div>
  );
};

export default SaveChangesConfirmation;
