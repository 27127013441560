export const HOSPITAL_ENDPOINT = "api/hospitals";
export const DIVISION_ENDPOINT = "api/divisions";
export const DEPARTMENT_ENDPOINT = "api/departments";
export const SECTION_ENDPOINT = "api/sections";
export const SITE_ENDPOINT = "api/sites";
export const USER_ROLE_ENDPOINT = "api/roles";
export const USER_ENDPOINT = "api/users";
export const CARE_UNIT_ENDPOINT = "api/careunits";
export const PHYSICAL_CARE_UNIT_ENDPOINT = "api/physicalcareunits";
export const OOH_ADDER_ENDPOINT = "api/oohadders";
export const AGREEMENT_ENDPOINT = "api/agreements";
export const COMPETENCE_ENDPOINT = "api/competences";
export const COMPENSATION_ENDPOINT = "api/compensations";
export const EMERGENCY_DEPARTMENT_PRODUCTIONS_ENDPOINT =
  "api/emergencydepartmentproductions";
export const RESULT_ENDPOINT = "api/results";
export const CARE_LEVELS_ENDPOINT = "api/carelevels";
export const CARE_UNIT_TYPES_ENDPOINT = "api/careunittypes";
export const MEDICAL_SPECIALITIES_ENDPOINT = "api/medicalspecialities";
