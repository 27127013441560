import React, { useEffect, useState } from "react";
import { InpatientProductivityAnalysis } from "../../../../interfaces/analytics/inpatientProductivityAnalysis";
import InpatientProductivityService from "../../../../services/inpatientProductivityService";
import { ReturnGetProductivityAnalysisData } from "../../../../interfaces/analytics/returnGeProductivityAnalysisData";

const useGetInpatientProdAnalysisData = (
  careProviderId: number | null,
  scenarioId: number,
  year: number
): ReturnGetProductivityAnalysisData<InpatientProductivityAnalysis[]> => {
  const [productivityAnalysisData, setProductivityAnalysisData] = useState<InpatientProductivityAnalysis[]>([]);

  const fetchData = async () => {
    if (careProviderId) {
      await InpatientProductivityService.getProductivityAnalysis(careProviderId, scenarioId, year)
        .then((res) => {
          const responseData: InpatientProductivityAnalysis[] = res.data;
          setProductivityAnalysisData(responseData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [careProviderId, scenarioId, year]);

  return {
    data: productivityAnalysisData,
    fetch: fetchData,
  };
};

export default useGetInpatientProdAnalysisData;
