import HttpCommonService from "./httpCommonService";

const CareProvidersService = {
  getCareProviders: async () => {
    return await HttpCommonService.getAsync(`api/hospitals`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getCareUnitsByCareProviderId: async () => {
    return await HttpCommonService.getAsync(`api/hospitals`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default CareProvidersService;
