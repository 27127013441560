import React from "react";

const KanbanCard = ({
  id,
  title,
  description,
  assigneeId,
}: {
  id: number;
  title: string;
  description: string;
  assigneeId: string;
}) => {
  return (
    <div className="overflow-hidden rounded-lg border  border-gray-200">
      <div className="px-4 py-2">
        <div className="mb-2 flex items-center justify-between">
          <div>
            <div className="text-md p-2-v-1 text-gray-900">
              <span className="font-bold">{id}.</span>
              {title}
            </div>
          </div>
        </div>
        <div className="p-3-v-1">
          <p className="font-normal ">{description.substring(0, 50)}</p>
        </div>
      </div>
    </div>
  );
};

export default KanbanCard;
