import FormalCompetence from "../../../interfaces/competence/FormalCompetence";
import { HandsontableDataSchema } from "../../../interfaces/handsOnTable/handsontableDataSchema";
import { CARE_UNIT_TYPES } from "../../constants/careUnitTypes";

export const getWorkShiftDefaultDataSchema = (
  competences: string[],
  formalCompetences: FormalCompetence[],
  selectedCareUnitType: number
) => {
  const workshifts_data_schema: HandsontableDataSchema = {
    unit: null,
    shiftName: null,
    shiftStart: null,
    shiftStop: null,
    shiftBreak: null,
    activeWeekDayMon: 0,
    activeWeekDayTue: 0,
    activeWeekDayWed: 0,
    activeWeekDayThu: 0,
    activeWeekDayFri: 0,
    activeWeekDaySat: 0,
    activeWeekDaySun: 0,
    comment: null,
    totalCost: 0,
    totalFte: 0,
    weekendShifts: 0,
    fteWeekendShifts: 0,
    backfillPercentage: 0,
    onCall: false,
    onCallUtilization: null,
    onCallIdleComp: null,
    budget: true,
    staff: true,
    productionShareEdVisit: selectedCareUnitType === CARE_UNIT_TYPES.EMERGENCY ? 1 : 0,
    productionShareOutpatientVisit:
      selectedCareUnitType === CARE_UNIT_TYPES.OUTPATIENT || selectedCareUnitType === CARE_UNIT_TYPES.PHYSICIAN ? 1 : 0,
    productionShareWard: selectedCareUnitType === CARE_UNIT_TYPES.INPATIENT ? 1 : 0,
    productionShareSurgery: 0,
    productionShareConsultant: 0,
    productionShareStExternalTraining: 0,
    productionShareManagement: 0,
    productionShareNonClinicalTime: 0,
    activeWeek1: 1,
    activeWeek2: 1,
    activeWeek3: 1,
    activeWeek4: 1,
    activeWeek5: 1,
    activeWeek6: 1,
    activeWeek7: 1,
    activeWeek8: 1,
    activeWeek9: 1,
    activeWeek10: 1,
    activeWeek11: 1,
    activeWeek12: 1,
    activeWeek13: 1,
    activeWeek14: 1,
    activeWeek15: 1,
    activeWeek16: 1,
    activeWeek17: 1,
    activeWeek18: 1,
    activeWeek19: 1,
    activeWeek20: 1,
    activeWeek21: 1,
    activeWeek22: 1,
    activeWeek23: 1,
    activeWeek24: 1,
    activeWeek25: 1,
    activeWeek26: 1,
    activeWeek27: 1,
    activeWeek28: 1,
    activeWeek29: 1,
    activeWeek30: 1,
    activeWeek31: 1,
    activeWeek32: 1,
    activeWeek33: 1,
    activeWeek34: 1,
    activeWeek35: 1,
    activeWeek36: 1,
    activeWeek37: 1,
    activeWeek38: 1,
    activeWeek39: 1,
    activeWeek40: 1,
    activeWeek41: 1,
    activeWeek42: 1,
    activeWeek43: 1,
    activeWeek44: 1,
    activeWeek45: 1,
    activeWeek46: 1,
    activeWeek47: 1,
    activeWeek48: 1,
    activeWeek49: 1,
    activeWeek50: 1,
    activeWeek51: 1,
    activeWeek52: 1,
  };

  const firstWorkCompetencesInSelectedFormalCompetence: number[] = [];

  formalCompetences.forEach((formalCompetence) => {
    if (
      (selectedCareUnitType === CARE_UNIT_TYPES.INPATIENT && formalCompetence.id === 28) ||
      (selectedCareUnitType === CARE_UNIT_TYPES.OUTPATIENT && formalCompetence.id === 28) ||
      (selectedCareUnitType === CARE_UNIT_TYPES.EMERGENCY && formalCompetence.id === 28)
    ) {
      firstWorkCompetencesInSelectedFormalCompetence.push(formalCompetence.workCompetences[0].id || -1);
    } else if (selectedCareUnitType === CARE_UNIT_TYPES.PHYSICIAN && formalCompetence.id === 21) {
      firstWorkCompetencesInSelectedFormalCompetence.push(formalCompetence.workCompetences[0].id || -1);
    }
  });

  competences.forEach((competence) => {
    if (firstWorkCompetencesInSelectedFormalCompetence.includes(Number(competence))) {
      workshifts_data_schema[competence] = 1;
    } else {
      workshifts_data_schema[competence] = 0;
    }
  });
  return workshifts_data_schema;
};
