import { CellChange, ChangeSource } from "handsontable/common";
import { ActivityAnalysisProductionSchema } from "../../../../../interfaces/analytics/activity-analysis-production-schema";
import { HotTableClass as HotTable } from "@handsontable/react";
import { useTranslation } from "react-i18next";
import { UseAfterChangeReturnType } from "../../../../../interfaces/analytics/handsontable/use-afterchange-return-type";
import { FilterGroupSchema } from "../../../../../interfaces/filter-group/filter-group-schema";
import useGetActivityAnalysisProductionPlanForFilterGroup from "./useGetActivityAnalysisProductionPlan";
import { ActivityAnalysisIsEditedRefType } from "../../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";

const useAfterChange = (
  careUnitId: number,
  scenarioId: number,
  year: number,
  data: ActivityAnalysisProductionSchema[],
  filterGroups: FilterGroupSchema[],
  dataTable: React.RefObject<HotTable | null>,
  setData: React.Dispatch<
    React.SetStateAction<ActivityAnalysisProductionSchema[]>
  >,
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>
): UseAfterChangeReturnType => {
  const { t: translate } = useTranslation();

  const afterChange = async (
    changes: CellChange[] | null,
    source: ChangeSource
  ) => {
    if (source === "edit" || source === "CopyPaste.paste") {
      const isTotalRowExists = data.find((row) => row.id === "total_row");

      const noOfRows = data.length;

      if (data) {
        if (!isTotalRowExists) {
          const newRow = {
            id: "total_row",
            filterGroupId: translate("total"),
            workCompetenceId: 0,
            timePerContact: "",
            fteProductionPlan: `=SUM(G1:G${noOfRows})`,
            productionPlan: "",
            timeSpent: `=SUM(E1:E${noOfRows})`,
            weeklyWorkingHours: "",
          } as ActivityAnalysisProductionSchema;

          setData([...data, newRow]);
        }
      }
    }

    if (
      source === "edit" ||
      source === "Autofill.fill" ||
      source === "CopyPaste.paste"
    ) {
      dataTable.current?.hotInstance?.validateCells(async (valid) => {
        if (changes) {
          const [row, coulmnName, prevValue, value] = changes[0];

          if (coulmnName === "filterGroupId" && filterGroups !== undefined) {
            useGetActivityAnalysisProductionPlanForFilterGroup(
              careUnitId,
              scenarioId,
              year,
              value,
              dataTable,
              row
            );
          }
          if (valid) {
            if (prevValue !== value) {
              isEditedRef.current = {
                ...isEditedRef.current,
                production: true,
              };
            }
          }
        }
      });
    }
  };

  return { afterChange };
};

export default useAfterChange;
