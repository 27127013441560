import React from "react";

interface ScrollLayoutProps {
  children: React.ReactNode;
  isOpen: boolean;
  moreSpace?: boolean;
  onCloseIconClick?: (e: React.FormEvent) => void;
}

const ScrollLayout = ({
  children,
  isOpen,
  moreSpace,
  onCloseIconClick,
}: ScrollLayoutProps) => {
  return (
    <div
      className={`fixed ${
        isOpen ? "shadow-[-20px_0px_35px_rgba(0,0,0,0.16)]" : "translate-x-full"
      } right-0 top-0 z-10 flex h-screen ${
        moreSpace ? "w-8/12 pl-20" : "w-4/12 px-20"
      } flex-col bg-white transition-all duration-1000`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute top-5 ${
          moreSpace ? "left-5" : "right-5"
        } w-4 cursor-pointer`}
        viewBox="0 0 20.205 20.205"
        onClick={onCloseIconClick}
      >
        <path
          id="Close"
          d="M27.729,9.544l-2.02-2.02-8.082,8.082L9.544,7.523l-2.02,2.02,8.082,8.082L7.523,25.708l2.02,2.02,8.082-8.082,8.082,8.082,2.02-2.02-8.082-8.082Z"
          transform="translate(-7.523 -7.523)"
        />
      </svg>

      {children}
    </div>
  );
};

export default ScrollLayout;
