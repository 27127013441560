import React from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Select } from "../../../../components";
import { ISelectOption, MultiSelectOption } from "../../../../interfaces";

interface OutpatientFilterProps {
  typesOfInputs: ISelectOption[];
  competenceTypes: ISelectOption[];
  contactTypes: ISelectOption[];
  selectedProductionTypes: MultiSelectOption[];
  productionTypes: MultiSelectOption[];
  handleTypesOfInputSelect: (selectedOption: ISelectOption) => void;
  handleTypesOfCompetenceSelect: (selectedOption: ISelectOption) => void;
  handleTypesOfContactTypeSelect: (selectedOption: ISelectOption) => void;
  handleBenchmarkProductionTypeSelect: (option: MultiSelectOption) => void;
  handleBenchmarkProductionTypeDeSelect: (
    optionToDeselect: MultiSelectOption
  ) => void;
  selectedInput: ISelectOption;
  selectedCompetenceType: ISelectOption;
  selectedContactType: ISelectOption;
}

const OutpatientFilters = ({
  typesOfInputs,
  selectedInput,
  selectedProductionTypes,
  handleBenchmarkProductionTypeDeSelect,
  handleBenchmarkProductionTypeSelect,
  handleTypesOfInputSelect,
  productionTypes,
}: OutpatientFilterProps) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <div className="mb-4">
        <Select
          placeholder={translate("type_of_input")}
          options={typesOfInputs}
          onSelectOption={handleTypesOfInputSelect}
          selectedOption={selectedInput}
        />
      </div>

      <div className="custom-select mb-4">
        <MultiSelect
          label={translate("production_type")}
          selectedOptions={selectedProductionTypes}
          options={productionTypes}
          onSelectItem={handleBenchmarkProductionTypeSelect}
          onRemoveSelectItem={handleBenchmarkProductionTypeDeSelect}
        />
      </div>
    </>
  );
};

export default OutpatientFilters;
