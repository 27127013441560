import { useTranslation } from "react-i18next";
import { ActivityAnalysisResultsType } from "../../../../../interfaces/analytics/activity-analysis-results";
import useGetUniqueWorkComptences from "./useGetUniqueWorkComptences";
import { PivotGridColumnGroup, PivotGridColumnsConfig } from "../../../../../interfaces/analytics/pivotGridColumnGroup";
import { ColumnSettings } from "handsontable/settings";

const useGetResultsPivotNestedHeaders = (tableData: ActivityAnalysisResultsType[]): PivotGridColumnsConfig => {
  const { t: translate, i18n } = useTranslation();
  const columnHeaders: PivotGridColumnGroup[][] = [];
  const firstColumnHeaders: PivotGridColumnGroup[] = [{ label: "", colspan: 1 }];
  const secondColumnHeaders: PivotGridColumnGroup[] = [{ label: translate("category_only_presence"), colspan: 1 }];
  const columns: ColumnSettings[] = [{ type: "text" }];

  const workCompetences = tableData.map((result: ActivityAnalysisResultsType) => {
    return { id: result.workCompetenceId, name: result.workCompetenceName };
  });

  const uniqueWorkCompetences = useGetUniqueWorkComptences(workCompetences);
  const data = uniqueWorkCompetences.map((x) => x.name);

  for (const col of data) {
    if (firstColumnHeaders.indexOf({ label: col, colspan: 2 }) === -1) {
      const columnHeader: PivotGridColumnGroup = { label: col, colspan: 2 };
      firstColumnHeaders.push(columnHeader);

      const hoursHeader: PivotGridColumnGroup = {
        label: translate("activity_result_time_spent").replace("\n", "<br />"),
        colspan: 1,
      };

      secondColumnHeaders.push(hoursHeader);

      const fteHeader: PivotGridColumnGroup = {
        label: translate("FTE"),
        colspan: 1,
      };

      secondColumnHeaders.push(fteHeader);
    }
  }

  for (let index = 0; index < data.length; index++) {
    columns.push({ type: "numeric", numericFormat: { pattern: "0,0", culture: i18n.language } });
    columns.push({ type: "numeric", numericFormat: { pattern: "0,0", culture: i18n.language } });
  }

  columnHeaders.push(firstColumnHeaders);
  columnHeaders.push(secondColumnHeaders);

  return { columnHeaders: columnHeaders, columns: columns };
};

export default useGetResultsPivotNestedHeaders;
