import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { RootState } from "../../../redux/store";
import AbsenceService from "../../../services/absenceService";
import { AbsenceHourAnalysisResponse } from "../../../interfaces/absence/absence-hour-analysis-response";
import { useDate } from "../../../hooks/use-date";
import { ISelectOption, MultiSelectOption } from "../../../interfaces";
import AbsenceChartFilter from "./AbsenceChartFilter/AbsenceChartFilter";
import useGetFormalCompetencesByCareUnit from "../../../common/hooks/data/useGetFormalCompetencesByCareUnit";
import FormalCompetence from "../../../interfaces/competence/FormalCompetence";

interface ChartDataColumn {
  name: string;
  data: number[];
  color: string;
}

interface ChartData {
  series: ChartDataColumn[];
  columnName: string[];
}

const AbsencePlan: React.FC = () => {
  const { t: translate } = useTranslation();
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const seriesColors: string[] = ["#2C461E", "#638B53", "#A9C9A0", "#426FA2", "#7EA4CF", "#8A181C"];

  const chartTypes: ISelectOption[] = [
    {
      label: translate("hours"),
      value: "1",
    },
    {
      label: translate("fte"),
      value: "2",
    },
    {
      label: translate("%"),
      value: "3",
    },
  ];

  const [selectedChartType, setSelectedChartType] = useState<ISelectOption>(chartTypes[0]);

  const { getMonthMmm } = useDate();

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const { data: formalCompetences, isLoading: isFormalCompetencesLoading } =
    useGetFormalCompetencesByCareUnit(careUnitId);

  const [selectedCompetenceShareOptions, setSelectedCompetenceShareOptions] = useState<MultiSelectOption[]>([]);

  const [competenceShareOptions, setCompetenceShareOptions] = useState<MultiSelectOption[]>([]);

  useEffect(() => {
    if (careUnitId && year && scenarioId && selectedCompetenceShareOptions.length !== 0) {
      fetchData();
    }
  }, [year, careUnitId, scenarioId, selectedChartType, selectedCompetenceShareOptions]);

  useEffect(() => {
    if (formalCompetences && !isFormalCompetencesLoading) {
      const formalCompetencesOptions: MultiSelectOption[] = formalCompetences.map((competence: FormalCompetence) => {
        return {
          id: uuid(),
          label: competence.shortName,
          value: competence.id,
        };
      });

      setCompetenceShareOptions(formalCompetencesOptions);
      setSelectedCompetenceShareOptions(formalCompetencesOptions);
    }
  }, [isFormalCompetencesLoading, formalCompetences]);

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    title: {
      text: undefined,
    },

    xAxis: {
      categories: chartData?.columnName,

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: undefined,
      },
      stackLabels: {
        enabled: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          const value =
            selectedChartType.value === "3"
              ? Math.round((this.total ?? 0) * 100) + "%"
              : Math.round((this.total ?? 0) * 100) / 100;
          if (value === 0 || value === "0%") {
            return "";
          } else {
            return value;
          }
        },
      },
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          return selectedChartType.value === "3" ? Math.round(Number(this.value) * 100) + " %" : this.value;
        },
        style: {
          fontSize: 16,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },
    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const value =
          selectedChartType.value === "3"
            ? Math.round(((this.point as Highcharts.Point).y ?? 0) * 100) + "%"
            : Math.round(((this.point as Highcharts.Point).y ?? 0) * 100) / 100;
        const series = this.series as Highcharts.Series;
        const tooltipInfo = `${series.name} <br /> ${value}`;
        return tooltipInfo;
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 80,
      },
    },

    series: chartData?.series,
  };

  const fetchData = async () => {
    const competences = selectedCompetenceShareOptions.map((share) => {
      return share.value;
    });

    await AbsenceService.getAbsenceHourAnalysis(
      careUnitId,
      year,
      scenarioId,
      competences as number[],
      parseInt(selectedChartType.value)
    ).then((res) => {
      const data: ChartData = { series: [], columnName: [] };

      // push complementary leave series
      data.series.push({
        name: translate("complementary_leave"),
        color: seriesColors[0],
        data: res.data.map((r: AbsenceHourAnalysisResponse) => {
          return r.complementaryLeave;
        }),
      });

      // push vacation series
      data.series.push({
        name: translate("vacation"),
        color: seriesColors[1],
        data: res.data.map((r: AbsenceHourAnalysisResponse) => {
          return r.vacation;
        }),
      });

      // push leave of absence series
      data.series.push({
        name: translate("other_leave"),
        color: seriesColors[2],
        data: res.data.map((r: AbsenceHourAnalysisResponse) => {
          return r.otherLeave;
        }),
      });

      // push leave of absence series
      data.series.push({
        name: translate("leave_of_absence"),
        color: seriesColors[3],
        data: res.data.map((r: AbsenceHourAnalysisResponse) => {
          return r.leaveOfAbsence;
        }),
      });

      // push leave of absence series
      data.series.push({
        name: translate("parental_leave"),
        color: seriesColors[4],
        data: res.data.map((r: AbsenceHourAnalysisResponse) => {
          return r.parentalLeave;
        }),
      });

      // push sick leave series
      data.series.push({
        name: translate("sick_leave"),
        color: seriesColors[5],
        data: res.data.map((r: AbsenceHourAnalysisResponse) => {
          return r.sickLeave;
        }),
      });

      data.columnName = res.data.map((r: AbsenceHourAnalysisResponse) => {
        const month = getMonthMmm(new Date(r.theFirstOfMonth));

        if (month) {
          return translate(`month_${month}`);
        }

        return "";
      });

      setChartData(data);
    });
  };

  const handleChartTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedChartType(selectedOption);
  };

  const handleCompetenceDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedCompetenceShareOptions.length > 1) {
      setSelectedCompetenceShareOptions(() => {
        return selectedCompetenceShareOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        });
      });
    }
  };

  const handleCompetenceSelect = (option: MultiSelectOption) => {
    const selectedCompetence = [...selectedCompetenceShareOptions, option];
    setSelectedCompetenceShareOptions(selectedCompetence);
  };

  return (
    <div className="h-full w-full lg:h-[79vh] xl:h-[80vh]">
      {careProviderId !== null && (
        <>
          <div className="flex h-full w-full">
            <div className="h-full w-5/6">
              <HighchartsReact
                containerProps={{ style: { height: "100%" } }}
                highcharts={Highcharts}
                options={options}
              />
            </div>

            <div className="h-full w-1/6 px-1 pr-4 pt-6">
              <AbsenceChartFilter
                competenceShareOptions={competenceShareOptions}
                selectedCompetenceShareOptions={selectedCompetenceShareOptions}
                handleCompetenceDeselect={handleCompetenceDeselect}
                handleCompetenceSelect={handleCompetenceSelect}
                handleTypesOfInputSelect={handleChartTypesOfInputSelect}
                selectedInput={selectedChartType}
                typesOfInputs={chartTypes}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AbsencePlan;
