import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import OrgStructureTooltip from "../../../../components/org-structure-tooltip/org-structure-tooltip";
import { ScrollBarConstants } from "../../utils/constants";

interface OrganizationStructureItem {
  id: number;
  name: string;
  disabled: boolean;
}

interface OrgStructureItemProps {
  disabled?: boolean;
  editButtonAction: string;
  entityType: string;
  isArrowHidden?: boolean;
  isMaxLevel?: boolean;
  isExpanded: boolean;
  item: any;
  deleteOrganizationStructureEntity: (orgStructureItemId: number, entityType: string) => void;
  onClick: (id: any) => void;
  setSideBarOpen: Dispatch<SetStateAction<boolean>>;
  editOrganizationStructureEntity: (orgStructureItemId: number, entityType: string) => void;
  setSideBarVariant: Dispatch<SetStateAction<string | undefined>>;
  type: string;
  titleSingular: string;
}

const OrgStructureItem = ({
  disabled,
  deleteOrganizationStructureEntity,
  editButtonAction,
  entityType,
  isArrowHidden,
  isMaxLevel,
  isExpanded,
  item,
  onClick,
  setSideBarOpen,
  setSideBarVariant,
  type,
  titleSingular,
  editOrganizationStructureEntity,
}: OrgStructureItemProps) => {
  const isEditDisplayed = false;

  const { t: translate } = useTranslation();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [organizationItemType, setOrganizationItemType] = useState<string | undefined>(undefined);

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const onEditItemClick = () => {
    if (editButtonAction === ScrollBarConstants.UPDATE_CARE_UNIT) {
      editOrganizationStructureEntity(item.id, entityType);
    }
  };

  useEffect(() => {
    setOrganizationItemType(organizationItemType?.slice(0, -1));
  }, [type]);

  const handleOptionsIconClick = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipOutsideClick = () => {
    setIsTooltipOpen(false);
  };

  const handleOrgStructureItemDeleteClick = () => {
    deleteOrganizationStructureEntity(item.id, entityType);
  };

  return (
    <>
      <div
        className="org_structure_item__container relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`org_structure_item ${isHovered && !isExpanded && !disabled && "org_structure_item--hovered"}  ${
            isExpanded && "org_structure_item--expanded"
          } ${isMaxLevel && "org_structure_item--max-level"}`}
          onClick={() => onClick(item)}
        >
          <span className={`p-1-v-1 org_structure_item__division_name gap-3 justify-self-start leading-snug ${"px-3"}`}>
            <div className="flex items-center gap-0">
              <div className={`${(isExpanded || isHovered) && "flex h-6 shrink-0 justify-start"}`}>
                {isTooltipOpen && (
                  <>
                    <OutsideClickHandler onOutsideClick={handleTooltipOutsideClick}>
                      <OrgStructureTooltip
                        handleOrganizationStructureItemDeleteClick={handleOrgStructureItemDeleteClick}
                        handleOrganizationStructureItemEditClick={onEditItemClick}
                      />
                    </OutsideClickHandler>
                  </>
                )}
                {(isExpanded || isHovered) && (
                  <svg
                    onClick={handleOptionsIconClick}
                    className={`h-full  fill-white ${isHovered && !isExpanded && "!fill-[#00283C]"} ${
                      isHovered && !isExpanded && isMaxLevel && "!fill-[#739768]"
                    }`}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      className={`stroke-white ${isHovered && !isExpanded && "stroke-[#00283C]"} ${
                        isHovered && !isExpanded && isMaxLevel && "stroke-[#739768]"
                      }`}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                      className={`stroke-white ${isHovered && !isExpanded && "stroke-[#00283C]"} ${
                        isHovered && !isExpanded && isMaxLevel && "stroke-[#739768]"
                      }`}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                      className={`stroke-white ${isHovered && !isExpanded && "stroke-[#00283C]"} ${
                        isHovered && !isExpanded && isMaxLevel && "stroke-[#739768]"
                      }`}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>

              {(type = "Hospitals" && item.name)}
              {(type = "Divisions" && item.divisionName)}
              {(type = "Departments" && item.departmentName)}
              {(type = "Sections" && item.sectionName)}
              {(type = "Care units" && item.careUnitName)}
              {(type = "Physical Care units" && item.physicalCareUnitName)}
            </div>

            {(isHovered || isExpanded) && !isArrowHidden && !disabled && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-4 shrink-0 fill-white ${isHovered && !isExpanded && "fill-[#00283C]"} ${
                  isHovered && !isExpanded && isMaxLevel && "fill-[#739768]"
                }`}
                viewBox="0 0 20.243 13.501"
              >
                <path
                  id="Next_Arrow"
                  data-name="Next Arrow"
                  d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                  transform="translate(-7.875 -11.252)"
                />
              </svg>
            )}
          </span>
          {isExpanded && isEditDisplayed && (
            <div className="org_structure_item--expanded-content mr-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-2.5 fill-white" viewBox="0 0 14.56 14.56">
                <path
                  id="Edit"
                  d="M4.5,16.023v3.033H7.533l8.945-8.945L13.445,7.078ZM18.823,7.766a.805.805,0,0,0,0-1.14L16.931,4.733a.805.805,0,0,0-1.14,0l-1.48,1.48,3.033,3.033Z"
                  transform="translate(-4.5 -4.496)"
                />
              </svg>

              <span className="p-3-v-1" onClick={onEditItemClick}>
                {translate("edit")} {titleSingular}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrgStructureItem;
