import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import ReferralIntakeChart from "./DemandChart/DemandChart";
import { useTranslation } from "react-i18next";
import DemandSidePanel from "./DemandSidePanel/DemandSidePanel";
import {
  OutpatientPlanDataRequest,
  OutpatientPlanPayload,
  OutpatientPlanRequest,
} from "../../../../interfaces/productivity/outpatient-plan-data-props";
import { AxiosResponse } from "axios";
import { OutpatientPlanDataResponse } from "../../../../interfaces/responses";
import { OutpatientPlanService } from "../../../../services";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import NotificationBox from "../../../../components/NotificationBox/NotificationBox";
import { ToastContainer, toast } from "react-toastify";

export interface DemandProps {
  selectedTabIndex: number;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  nextAvailableTabIndex: number;
  setNextAvailableTabIndex: Dispatch<SetStateAction<number>>;
  isSaveButtonClicked: boolean;
  setIsSaveButtonClicked: Dispatch<SetStateAction<boolean>>;
  isOutpatientParametersModifying: boolean;
  setIsOutpatientParametersModifying: Dispatch<SetStateAction<boolean>>;
  isTabBarClicked: boolean;
  setIsTabBarClicked: Dispatch<SetStateAction<boolean>>;
  clickedTabIndex: number;
}

const Demand = ({
  selectedTabIndex,
  setSelectedTabIndex,
  nextAvailableTabIndex,
  setNextAvailableTabIndex,
  isSaveButtonClicked,
  setIsSaveButtonClicked,
  isOutpatientParametersModifying,
  setIsOutpatientParametersModifying,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
}: DemandProps) => {
  const { t: translate, i18n } = useTranslation();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const [outpatientRequest, setOutpatientRequest] = useState<OutpatientPlanPayload>({
    productionPlan: 0,
    queueCuttingPlan: 0,
    id: 0,
    queueCuttingReductionGoalDate: null,
    overloadPlanAverage: 0,
  });

  const [outpatientRequestBeforeEdit, setOutpatientRequestBeforeEdit] = useState<OutpatientPlanPayload>({
    productionPlan: 0,
    queueCuttingPlan: 0,
    id: 0,
    queueCuttingReductionGoalDate: null,
    overloadPlanAverage: 0,
  });

  const [isDefaultValue, setIsDefaultValue] = useState(false);
  const [productionPlanDefaultAverage, setProductionPlanDefaultAverage] = useState<number | null>(null);
  const [isSaveConfirmationModalBoxOpen, setIsSaveConfirmationModalBoxOpen] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const fetchOutpatientPlan = async (careUnitId: number) => {
    try {
      //setIsOutpatientPlanLoading(true);

      const res: AxiosResponse<OutpatientPlanDataResponse> = await OutpatientPlanService.getOutpatientPlan(
        careUnitId,
        year,
        scenario
      );

      if (res.data.id === 0) {
        setOutpatientRequest({
          productionPlan: 0,
          queueCuttingPlan: 0,
          id: 0,
          queueCuttingReductionGoalDate: null,
          overloadPlanAverage: res.data.overloadPlanAverage,
        });
      } else {
        //setIsDefaultValue(false);
        res.data.productionOutpatientPlan = res.data.productionOutpatientPlan
          ? res.data.productionOutpatientPlan * 100 || 0
          : 0;
        setOutpatientRequest(res.data);
        setOutpatientRequestBeforeEdit(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveOutpatientPlanData = async (): Promise<boolean> => {
    isSaveConfirmationModalBoxOpen ? setIsSaveConfirmationModalBoxOpen(false) : null;

    const toastId = toast(<NotificationFloat varaint="loading" content={translate("saving_outpatient_parameters")} />, {
      icon: false,
      className: "custom-toast",
      containerId: "notificationBox",
      autoClose: false,
    });

    setIsOutpatientParametersModifying(true);

    const chartData: OutpatientPlanDataRequest = {
      id: outpatientRequest.id,
      careUnitId: careUnitId || 0,
      queueCuttingPlan: outpatientRequest.queueCuttingPlan,
      productionPlan: outpatientRequest.productionPlan,
      queueCuttingReductionGoalDate: outpatientRequest.queueCuttingReductionGoalDate,
    };

    const data: OutpatientPlanRequest = {
      year: year,
      scenarioId: scenario,
      updateOutPatintPlanDto: chartData,
    };

    let response = true;
    await OutpatientPlanService.saveProductionPlan(data)
      .then(() => {
        setIsSaveButtonClicked(false);
        toast.update(toastId, {
          render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
          type: "success",
          autoClose: 3000,
        });

        setIsEdited(false);
        setIsOutpatientParametersModifying(false);
        response = true;
      })
      .catch(() => {
        setIsSaveButtonClicked(false);
        toast.update(toastId, {
          render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
          type: "error",
          autoClose: 3000,
        });

        setIsEdited(false);
        setIsOutpatientParametersModifying(false);
        response = false;
      });

    return response;
  };

  useEffect(() => {
    if (careUnitId !== null && careUnitId > 0 && year > 0 && scenario > 0) {
      fetchOutpatientPlan(careUnitId);
    }
  }, [careUnitId, year, scenario]);

  useEffect(() => {
    if (isSaveButtonClicked && selectedTabIndex === 0) {
      saveOutpatientPlanData();
    }
  }, [isSaveButtonClicked]);

  useEffect(() => {
    if (productionPlanDefaultAverage !== null || productionPlanDefaultAverage !== 0) {
      if (outpatientRequest.productionPlan === 0) {
        setIsDefaultValue(true);

        /** disable default value temporarily **/

        // setOutpatientRequest((prevState) => {
        //   return {
        //     ...prevState,
        //     productionPlan: productionPlanDefaultAverage
        //       ? parseInt(productionPlanDefaultAverage.toFixed(0).toString())
        //       : 0,
        //     overloadPlanAverage: 0,
        //   };
        // });
      }
    }
  }, [productionPlanDefaultAverage]);

  useEffect(() => {
    if (isTabBarClicked && selectedTabIndex === 0) {
      if (isEdited) {
        setIsSaveConfirmationModalBoxOpen(true);
      } else {
        setSelectedTabIndex(() => clickedTabIndex);
        setIsTabBarClicked(false);
      }
    }
  }, [isTabBarClicked]);

  const setLastWeekAverage = (average: number) => {
    if (outpatientRequest.productionPlan === 0 && !isNaN(average)) {
      setProductionPlanDefaultAverage(average);
    }
  };

  const handleNotificationBoxSaveButtonClick = async () => {
    if (selectedTabIndex === 0) {
      await saveOutpatientPlanData();

      if (isTabBarClicked) {
        setIsTabBarClicked(false);
        setSelectedTabIndex(() => clickedTabIndex);
      } else {
        setSelectedTabIndex(nextAvailableTabIndex);
      }
    }
  };

  const handleNotificationBoxDiscardSaveButtonClick = () => {
    setIsSaveConfirmationModalBoxOpen(false);
    setOutpatientRequest(outpatientRequestBeforeEdit);

    if (isTabBarClicked) {
      setSelectedTabIndex(() => clickedTabIndex);
    } else {
      setSelectedTabIndex(nextAvailableTabIndex);
    }
  };

  return (
    <>
      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />
      {isSaveConfirmationModalBoxOpen ? (
        <NotificationBox
          content={translate("do_you_want_to_save_changes")}
          title={translate("save_changes")}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxSaveButtonClick}
          onRightButtonClick={handleNotificationBoxDiscardSaveButtonClick}
          isLeftButtonDisabled={false}
          isRightButtonDisabled={false}
        />
      ) : null}
      <div className="h-full w-5/6">
        <ReferralIntakeChart sendLastWeekAverage={setLastWeekAverage} />
      </div>
      <div className="ml-5 w-1/6">
        <DemandSidePanel
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          outpatientRequest={outpatientRequest}
          setOutpatientRequest={setOutpatientRequest}
          outpatientRequestBeforeEdit={outpatientRequestBeforeEdit}
          setNextAvailableTabIndex={setNextAvailableTabIndex}
          setIsSaveConfirmationModalBoxOpen={setIsSaveConfirmationModalBoxOpen}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
          isOutpatientParametersModifying={isOutpatientParametersModifying}
        />
      </div>
    </>
  );
};

export default Demand;
