import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Select } from "../../../components";
import { ISelectOption } from "../../../interfaces";
import { RootState, setCareProviderId } from "../../../redux/store";

import OrgService from "../../../services/orgService";
import { OrgCareUnit } from "../../../interfaces/careunits/orgCareUnit";

const GlobalSettings = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [careProviders, setCareProviders] = useState<ISelectOption[]>([]);

  const careProvider = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const loginState = useSelector((state: RootState) => state.auth.isLoggedIn);

  const [selectedCareProvider, setSelectedCareProvider] = useState<ISelectOption | null>(null);

  const [selectedLanguage, setSelectedLanguage] = useState<ISelectOption | null>(null);

  const handleCareProviderSelect = (careProvider: ISelectOption) => {
    setSelectedCareProvider(careProvider);
    dispatch(setCareProviderId(Number(careProvider.value)));
  };

  const convertOrgTreeToCareProviders = (res: AxiosResponse) => {
    const careProviders = res.data.map((orgCareUnit: OrgCareUnit) => {
      return {
        label: orgCareUnit.careUnitName,
        value: orgCareUnit.careUnitId,
      };
    });

    if (careProvider !== null) {
      setDefaultCareProvider(careProviders);
    }

    setCareProviders(careProviders);
  };

  useEffect(() => {
    // get all care providers
    OrgService.getOrgCareProvidersAsync(year)
      .then((res: AxiosResponse) => {
        convertOrgTreeToCareProviders(res);
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  useEffect(() => {
    if (loginState === true && careProvider !== null && careProviders.length > 0) {
      setDefaultCareProvider(careProviders);
    }
  }, [careProvider, careProviders, loginState]);

  useEffect(() => {
    if (i18n.language === "en-US" || i18n.language === "en") {
      setSelectedLanguage(languages[0]);
    } else {
      setSelectedLanguage(languages[1]);
    }
  }, [i18n.language]);

  const languages: ISelectOption[] = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Swedish",
      value: "sv",
    },
  ];

  const setDefaultCareProvider = (careProviders: ISelectOption[]) => {
    const selectedcareProvider = careProviders.find((cp) => Number(careProvider) === Number(cp.value));

    if (selectedcareProvider) {
      const selectedOption: ISelectOption = {
        label: selectedcareProvider.label,
        value: selectedcareProvider.value,
      };

      setSelectedCareProvider(selectedOption);
    }
  };

  const handleLanguageClickChange = (selectedLanguage: ISelectOption) => {
    i18n.changeLanguage(selectedLanguage.value);
  };

  const careProviderChangeAccess = useSelector((state: RootState) => state.permission.globalSettingEnabled);

  return (
    <div>
      <div className="">
        {careProviderChangeAccess && (
          <div className="mt-10 flex w-1/4  items-center justify-center gap-4 px-8">
            <h3 className="p-1-v-1 mb-4 w-1/4">
              <p>Care Provider</p>
            </h3>

            <div className="flex-1">
              <Select
                options={careProviders}
                placeholder="Care Provider"
                onSelectOption={handleCareProviderSelect}
                onRemoveOption={() => {
                  ("");
                }}
                selectedOption={selectedCareProvider}
              />
            </div>
          </div>
        )}

        <div className="mt-10 flex w-1/4  items-center justify-center gap-4 px-8">
          <h3 className="p-1-v-1 mb-4 w-1/4">
            <p>Language</p>
          </h3>

          <div className="flex-1">
            <Select
              options={languages}
              placeholder="Language"
              onSelectOption={handleLanguageClickChange}
              onRemoveOption={() => {
                ("");
              }}
              selectedOption={selectedLanguage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
