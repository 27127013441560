import { useEffect, useState } from "react";
import WorkCompetence from "../../../interfaces/competence/WorkCompetence";
import { WorkCompetenceService } from "../../../services/workCompetenceService";
import BaseDataRetrieveHookReturn from "../../../interfaces/hooks/baseDataRetrieveHookReturn";

const useGetWorkCompetencesData = (
  careUnitId: number | null
): BaseDataRetrieveHookReturn<WorkCompetence[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [workCompetences, setWorkCompetences] = useState<WorkCompetence[]>([]);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    if (careUnitId) {
      WorkCompetenceService.getWorkComepetencesByCareUnit(careUnitId)
        .then((res) => {
          const responseData: WorkCompetence[] = res.data;

          setWorkCompetences(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setWorkCompetences([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId]);

  return {
    data: workCompetences,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetWorkCompetencesData;
