import React from "react";
import { IconNumericInput } from "../../../../components";

const WeekdayPhasingInput = ({
  name,
  handleLeftIconClick,
  handleRightIconClick,
  onChange,
  placeholder,
  value,
  disabled,
}: {
  weekday: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLeftIconClick?: (key: string) => void;
  handleRightIconClick?: (key: string) => void;
  placeholder: string;
  value: number;
  name: string;
  disabled?: boolean;
}) => {
  return (
    <div className="my-2 flex items-center justify-between">
      <div className="p-2-v-1 w-3/6">{placeholder}</div>

      <div>
        <IconNumericInput
          name={name}
          value={value}
          handleInputChange={onChange}
          handleLeftIconClick={handleLeftIconClick}
          handleRightIconClick={handleRightIconClick}
          type="number"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default WeekdayPhasingInput;
