import HttpCommonService from "./httpCommonService";

const InPatientProductionService = {
  getCareBedsForecastData: async (id: number | null, year: number | null) => {
    return await HttpCommonService.getAsync(`api/inpatientproduction/rollingforcast/${id}/${year}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default InPatientProductionService;
