import React, { useState } from "react";

interface CheckboxProps {
  checked: boolean;
  setChecked?: (checked: boolean) => void;
}

const Checkbox = ({ checked, setChecked }: CheckboxProps) => {
  const handleClick = () => {
    if (setChecked) {
      setChecked(!checked);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`checkbox ${!checked && "checkbox--hover"}  ${
        checked && "checkbox--checked"
      } `}
    >
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="checkbox__icon"
          viewBox="0 0 18.247 14.255"
        >
          <path
            id="Icon_metro-checkmark"
            data-name="Icon metro-checkmark"
            d="M17.966,5.784,9.413,14.337,5.422,10.346,2.571,13.2l6.843,6.843,11.4-11.4Z"
            transform="translate(-2.571 -5.784)"
          />
        </svg>
      )}
    </div>
  );
};

export default Checkbox;
