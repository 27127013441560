import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { MultiSelectDropdownProps } from "../../interfaces/props/multiSelectDropdownProps";
import Truncate from "react-truncate";
import Checkbox from "../Checkbox/Checkbox";
import { MultiSelectOption } from "../../interfaces";
import Icon from "../icons/Icons";
import { useTranslation } from "react-i18next";

const MultiSelectDropDown = ({
  label,
  options,
  initialSelectedOptions,
  onValueChange,
}: MultiSelectDropdownProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(initialSelectedOptions);

  const { t: translate } = useTranslation();

  useEffect(() => {
    setSelectedOptions(initialSelectedOptions);
  }, [initialSelectedOptions]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSelectedAny, setIsSelectedAny] = useState<boolean>(initialSelectedOptions.length > 0);

  const [isAllSelected, setIsAllSelected] = useState<boolean>(options.length == selectedOptions.length);

  useEffect(() => {
    setIsSelectedAny(selectedOptions.length > 0);

    if (onValueChange) {
      onValueChange(selectedOptions);
    }
  }, [selectedOptions]);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option: MultiSelectOption) => {
    const opt = selectedOptions.find((r) => r.value === option.id);

    if (opt) {
      const idToRemove = option.id;
      const newArray = selectedOptions.filter((item) => item.id !== idToRemove);
      setSelectedOptions(newArray);
      setIsAllSelected(newArray.length === options.length);
    } else {
      const newArray = [...selectedOptions, option];
      setSelectedOptions(newArray);
      setIsAllSelected(newArray.length === options.length);
    }
  };

  const handleAllOptionSelect = () => {
    const currentValue = isAllSelected;
    const newValue = !currentValue;

    setIsAllSelected(newValue);

    if (newValue) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions([]);
    }
  };

  const isOptionChecked = (option: MultiSelectOption) => {
    let found = false;
    const opt = selectedOptions.find((r) => r.id === option.id);

    if (opt) {
      found = true;
    }

    return found;
  };

  const getSelectionText = (): string => {
    if (isAllSelected) {
      return translate("all");
    }

    return selectedOptions.map((r) => r.label).join(", ");
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <div className=" flex w-full flex-col items-center">
        <div className="h-full w-full">
          <div className="relative flex flex-col items-center ">
            <div className={`w-full py-2`}>
              <div
                className="flex rounded-[0.438rem] border border-x border-y  border-[#ADADAD] hover:cursor-pointer hover:bg-[#EDEDED] lg:p-[0.20rem] lg:!py-0.5 lg:px-3 xl:!p-1.5  xl:!px-3"
                onClick={toggleSelect}
              >
                <div
                  className={`absolute pl-1 text-[#747474] ${
                    !isSelectedAny && !isAllSelected ? "p-1-v-1 top-1/2 -translate-y-1/2" : "p-3-v-1 top-3 "
                  }`}
                >
                  {label}
                </div>
                <div className="flex flex-auto flex-wrap">
                  <Truncate
                    lines={1}
                    className="multiselect-selected-options absolute ml-1 lg:top-[1.5rem] xl:top-[1.8rem]"
                  >
                    <div className="flex items-center justify-center">
                      <div className="p-2-v-1 max-w-full flex-initial text-xs font-normal leading-none">
                        {selectedOptions.findIndex((x) => x.value === "null") > -1 ? (
                          <>{selectedOptions[selectedOptions.findIndex((x) => x.value === "null")].label}</>
                        ) : (
                          <>{getSelectionText()}</>
                        )}
                      </div>
                    </div>
                  </Truncate>
                </div>
                <div className="-mr-1.5 flex w-8 items-center border-gray-200 py-1 pl-2 pr-1 text-gray-300">
                  <button className="h-6 w-6 cursor-pointer text-gray-600 outline-none focus:outline-none">
                    {isOpen ? <Icon variant="arrowdown" /> : <Icon variant="arrowup" />}
                  </button>
                </div>
              </div>
            </div>
            {isOpen && (
              <div className="mult-select-z-index max-h-select svelte-5uyqqj absolute left-0 top-full z-40 w-full overflow-y-auto rounded bg-white shadow">
                <div className="flex w-full flex-col">
                  <div
                    onClick={handleAllOptionSelect}
                    className={`w-full cursor-pointer rounded-t border-b border-gray-100 hover:bg-[#EDEDED] ${
                      options.length < 0 ? "rounded-b" : ""
                    }`}
                  >
                    <div className="relative flex w-full items-center p-2 pl-2 ">
                      <div className="flex w-full items-center">
                        <div className="mx-2 leading-6 ">{translate("all")}</div>
                      </div>

                      <div className="mx-2 h-4 w-4">
                        <Checkbox checked={isAllSelected} />
                      </div>
                    </div>
                  </div>
                  {options.map((option, i) => (
                    <div
                      onClick={() => handleOptionSelect(option)}
                      key={i}
                      className={`w-full cursor-pointer border-b border-gray-100 hover:bg-[#EDEDED] ${
                        i === 0 ? "rounded-t" : i === options.length - 1 && "rounded-b"
                      }`}
                    >
                      <div className="relative flex w-full items-center p-2 pl-2 ">
                        <div className="flex w-full items-center">
                          <div className="mx-2 leading-6 ">{option.label} </div>
                        </div>

                        <div className="mx-2 h-4 w-4">
                          <Checkbox checked={isOptionChecked(option)} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelectDropDown;
