import { useEffect, useState } from "react";
import { FormalCompetenceService } from "../../../../services/formalCompetenceService";
import { MultiSelectOption } from "../../../../interfaces";
import { v4 as uuid } from "uuid";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";

interface ReturnFormalCompetenceData<T> {
  data: T;
  fetch(): void;
}

const useGetFormalCompetenceDataByCareUnit = (
  careUnitId: number
): ReturnFormalCompetenceData<MultiSelectOption[]> => {
  const [formalCompetences, setFormalCompetences] = useState<
    MultiSelectOption[]
  >([]);

  const fetchData = async (): Promise<void> => {
    await FormalCompetenceService.getFormalCompetencesByCareUnit(careUnitId)
      .then((response) => {
        const data: MultiSelectOption[] = response.data.map(
          (competence: FormalCompetence) => {
            return {
              id: uuid(),
              value: competence.id.toString(),
              label: competence.shortName,
            };
          }
        );

        setFormalCompetences([...data]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId]);

  return { data: formalCompetences, fetch: fetchData };
};

export default useGetFormalCompetenceDataByCareUnit;
