import React, { useEffect, useState } from "react";
import { SidePanelProps } from "../../../../interfaces/props/org-structure-scrollbar";

import { ScrollBarConstants } from "../../utils/constants";

import {
  CareProvider,
  Department as DepartmentForm,
  Division as DivisionForm,
  CareUnitGroup as CareUnitGroupForm,
  Section as SectionForm,
  CareUnit,
  CareUnitForm,
} from "../forms";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import Drawer from "../Drawer/Drawer";
import ScrollLayout from "../../../../layouts/ScrollLayout/ScrollLayout";

const SidePanel = ({
  variant,
  isScrollBarOpen,
  selectedHospital,
  selectedDivision,
  selectedDepartment,
  selectedSection,
  selectedCareUnitGroup,
  setIsScrollBarOpen,
  setHospitals,
  setDivisions,
  setDepartments,
  setSections,
  setCareUnits,
  setCareUnitGroups,
  setSuccessNotification,
  selectedEditableCareUnit,
  medicalSpecialitiesProp,
  careLevelsProp,
  careUnitTypesProp,
  selectablePhysicianCareUnits,
}: SidePanelProps) => {
  // executes when close (x) icon clicks
  const handleCloseIconClick = () => {
    if (addNewCareUnitProperty != "") {
      setAddNewCareUnitProperty("");
    } else {
      setIsScrollBarOpen(false);
      setAddNewCareUnitProperty("");
      isAddHospitalFailed && setIsAddHospitalFailed(false);
      isAddDivisionFailed && setIsAddDivisionFailed(false);
      isAddDepartmentFailed && setIsAddDepartmentFailed(false);
      isAddSectionFailed && setIsAddSectionFailed(false);
      isAddCareUnitFailed && setIsAddCareUnitFailed(false);
      isAddCareUnitGroupFailed && setIsAddCareUnitGroupFailed(false);
      isAddSiteFailed && setIsAddSiteFailed(false);
      isAddSiteSuccess && setIsAddSiteSuccess(false);
    }
  };

  const [isAddSiteSuccess, setIsAddSiteSuccess] = useState<boolean>(false);
  const [isPhysicianCareUnit, setIsPhysicianCareUnit] = useState<boolean>(false);

  useEffect(() => {
    if (isAddSiteSuccess) {
      const timer = setTimeout(() => {
        setIsAddSiteSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isAddSiteSuccess]);

  const [isAddHospitalFailed, setIsAddHospitalFailed] = useState<boolean>(false);
  const [isAddDivisionFailed, setIsAddDivisionFailed] = useState<boolean>(false);
  const [isAddDepartmentFailed, setIsAddDepartmentFailed] = useState<boolean>(false);
  const [isAddSectionFailed, setIsAddSectionFailed] = useState<boolean>(false);
  const [isAddCareUnitFailed, setIsAddCareUnitFailed] = useState<boolean>(false);
  const [isAddCareUnitGroupFailed, setIsAddCareUnitGroupFailed] = useState<boolean>(false);
  const [isAddSiteFailed, setIsAddSiteFailed] = useState<boolean>(false);

  const [addNewCareUnitProperty, setAddNewCareUnitProperty] = useState<string>("");

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.CreateCareUnitWithParent);

  return (
    <ScrollLayout isOpen={isScrollBarOpen} onCloseIconClick={handleCloseIconClick} moreSpace={isPhysicianCareUnit}>
      {/* Add Hospital */}
      {(variant === ScrollBarConstants.ADD_HOSPITAL || variant === ScrollBarConstants.UPDATE_HOSPITAL) && (
        <CareProvider
          setCareProviders={setHospitals}
          setIsScrollBarOpen={setIsScrollBarOpen}
          variant={variant}
          setSuccessNotification={setSuccessNotification}
        />
      )}

      {(variant === ScrollBarConstants.ADD_DIVISION || variant === ScrollBarConstants.UPDATE_DIVISION) && (
        <DivisionForm
          setDivisions={setDivisions}
          setIsScrollBarOpen={setIsScrollBarOpen}
          variant={variant}
          setSuccessNotification={setSuccessNotification}
          selectedHospital={selectedHospital}
        />
      )}

      {(variant === ScrollBarConstants.ADD_DEPARTMENT || variant === ScrollBarConstants.UPDATE_DEPARTMENT) && (
        <DepartmentForm
          setDepartments={setDepartments}
          setIsScrollBarOpen={setIsScrollBarOpen}
          variant={variant}
          setSuccessNotification={setSuccessNotification}
          selectedDivision={selectedDivision}
        />
      )}

      {(variant === ScrollBarConstants.ADD_SECTION || variant === ScrollBarConstants.UPDATE_SECTION) && (
        <SectionForm
          setIsScrollBarOpen={setIsScrollBarOpen}
          variant={variant}
          setSuccessNotification={setSuccessNotification}
          selectedDepartment={selectedDepartment}
          setSections={setSections}
        />
      )}

      {(variant === ScrollBarConstants.ADD_CARE_UNIT || variant === ScrollBarConstants.UPDATE_CARE_UNIT) && (
        <>
          {isFeatureFlagEnabled && (
            <CareUnitForm
              addNewCareUnitProperty={addNewCareUnitProperty}
              setAddNewCareUnitProperty={setAddNewCareUnitProperty}
              variant={variant}
              selectedCareUnitGroup={selectedCareUnitGroup}
              setCareUnits={setCareUnits}
              setIsScrollBarOpen={setIsScrollBarOpen}
              selectedHospital={selectedHospital}
              setSuccessNotification={setSuccessNotification}
              selectedEditCareUnit={selectedEditableCareUnit}
              setIsPhysicianCareUnit={setIsPhysicianCareUnit}
              medicalSpecialitiesProp={medicalSpecialitiesProp}
              careLevelsProp={careLevelsProp}
              selectablePhysicianCareUnits={selectablePhysicianCareUnits}
              careUnitTypesProp={careUnitTypesProp}
              isScrollBarOpen={isScrollBarOpen}
            />
          )}
          {!isFeatureFlagEnabled && (
            <CareUnit
              addNewCareUnitProperty={addNewCareUnitProperty}
              setAddNewCareUnitProperty={setAddNewCareUnitProperty}
              variant={variant}
              selectedCareUnitGroup={selectedCareUnitGroup}
              setCareUnits={setCareUnits}
              setIsScrollBarOpen={setIsScrollBarOpen}
              selectedHospital={selectedHospital}
              setSuccessNotification={setSuccessNotification}
              selectedEditCareUnit={selectedEditableCareUnit}
              setIsPhysicianCareUnit={setIsPhysicianCareUnit}
              medicalSpecialitiesProp={medicalSpecialitiesProp}
              careLevelsProp={careLevelsProp}
              selectablePhysicianCareUnits={selectablePhysicianCareUnits}
              careUnitTypesProp={careUnitTypesProp}
            />
          )}
        </>
      )}

      {(variant === ScrollBarConstants.ADD_CARE_UNIT_GROUP ||
        variant === ScrollBarConstants.UPDATE_CARE_UNIT_GROUP) && (
        <>
          <CareUnitGroupForm
            setIsScrollBarOpen={setIsScrollBarOpen}
            variant={variant}
            setSuccessNotification={setSuccessNotification}
            selectedSection={selectedSection}
            setCareUnitGroups={setCareUnitGroups}
          />
        </>
      )}
    </ScrollLayout>
  );
};

export default SidePanel;
