import React from "react";
import { HandsOnTableColumn } from "../../../../../../interfaces/handsOnTable/handsOnTableColumn";
import Handsontable from "handsontable";
import { CellValue } from "hyperformula";
import { CellProperties } from "handsontable/settings";
import numbro from "numbro";
import { useFeatureFlagIsEnabled } from "../../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../../utils/constants/featureFlags";

const useOverviewColumnConfigs = () => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.InpatientOrgOverviewV2);

  const getColumns = (culture: string): HandsOnTableColumn[] => {
    const thousandSeparatorRenderer = (
      instance: Handsontable,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: string | number,
      value: CellValue,
      cp: CellProperties
    ) => {
      if (value !== null) {
        td.innerHTML = numbro(value).format({
          thousandSeparated: true,
          spaceSeparated: true,
          mantissa: 0,
        });
      }

      if (cp.className) {
        if (Array.isArray(cp.className)) {
          td.classList.add(...cp.className);
        } else {
          const classNames = cp.className.split(" ");
          td.classList.add(...classNames);
        }
      }
    };

    const cols: HandsOnTableColumn[] = [
      {
        meta: {
          data: "careUnitName",
          type: "text",
        },
        headerTitle: "CareUnitName",
        width: 250,
      },
      {
        meta: {
          data: "careLevel",
          type: "text",
        },
        headerTitle: "CareLevel",
        width: 120,
      },
      {
        meta: {
          data: "careBeds",
          type: "numeric",
        },
        headerTitle: "CareBeds",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "staffingPlan",
          type: "numeric",
        },
        headerTitle: "StaffingPlan",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "costModel",
          type: "numeric",
          renderer: thousandSeparatorRenderer,
        },
        headerTitle: "CostModel",
        width: 110,
        headerColor: "#AD1E23",
      },
      {
        meta: {
          data: "planVsTarget",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "PlanVsTarget",
        width: 110,
        headerColor: "#00283C",
      },
      {
        meta: {
          data: "productivityPlan",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
        headerTitle: "ProductivityPlan",
        width: 80,
        headerColor: "#00283C",
      },
      {
        meta: {
          data: "productivityTarget",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
        headerTitle: "ProductivityTarget",
        width: 80,
        headerColor: "#00283C",
      },
      {
        meta: {
          data: "staffingPotential",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
        headerTitle: "StaffingPotential",
        width: 110,
        headerColor: "#426FA2",
      },
      {
        meta: {
          data: "costPotential",
          type: "numeric",
          renderer: thousandSeparatorRenderer,
        },
        headerTitle: "CostPotential",
        width: 110,
        headerColor: "#426FA2",
      },
    ];

    const colsV2: HandsOnTableColumn[] = [
      {
        meta: {
          data: "careUnitName",
          type: "text",
        },
        headerTitle: "CareUnitName",
        width: 250,
      },
      {
        meta: {
          data: "careLevel",
          type: "text",
        },
        headerTitle: "CareLevel",
        width: 120,
      },
      {
        meta: {
          data: "careBeds",
          type: "numeric",
        },
        headerTitle: "CareBeds",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "careEpisodes",
          type: "numeric",
        },
        headerTitle: "CareEpisodes",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "los",
          type: "numeric",
        },
        headerTitle: "LOS",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "occupancy",
          type: "numeric",
        },
        headerTitle: "Occupancy",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "careBedsR12",
          type: "numeric",
        },
        headerTitle: "CareBedsR12",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "prodVsPlan",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "ProdVsPlan",
        width: 110,
        headerColor: "#416031",
      },
      {
        meta: {
          data: "staffingPlan",
          type: "numeric",
        },
        headerTitle: "StaffingPlan",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "workingHoursR12",
          type: "numeric",
        },
        headerTitle: "WorkingHoursR12",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "workingHoursClinicalShare",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "WorkingHoursClinicalShare",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "avgRentalCoverage",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "AvgRentalCoverage",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "avgOvertimeCoverage",
          type: "numeric",
        },
        headerTitle: "AvgOvertimeCoverage",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "staffingActualVsPlan",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "StaffingActualVsPlan",
        width: 110,
        headerColor: "#E5A32B",
      },
      {
        meta: {
          data: "costModel",
          type: "numeric",
          renderer: thousandSeparatorRenderer,
        },
        headerTitle: "CostModel",
        width: 110,
        headerColor: "#AD1E23",
      },
      {
        meta: {
          data: "actualCost",
          type: "numeric",
          renderer: thousandSeparatorRenderer,
        },
        headerTitle: "ActualCost",
        width: 110,
        headerColor: "#AD1E23",
      },
      {
        meta: {
          data: "actualBudget",
          type: "numeric",
          renderer: thousandSeparatorRenderer,
        },
        headerTitle: "ActualBudget",
        width: 110,
        headerColor: "#AD1E23",
      },
      {
        meta: {
          data: "costActualVsPlan",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "CostActualVsPlan",
        width: 110,
        headerColor: "#AD1E23",
      },

      {
        meta: {
          data: "productivityPlan",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
        headerTitle: "ProductivityPlan",
        width: 80,
        headerColor: "#00283C",
      },
      {
        meta: {
          data: "productivityTarget",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
        headerTitle: "ProductivityTarget",
        width: 80,
        headerColor: "#00283C",
      },
      {
        meta: {
          data: "planVsTarget",
          type: "numeric",
          numericFormat: {
            culture: culture,
            pattern: {
              output: "percent",
              mantissa: 0,
            },
          },
        },
        headerTitle: "PlanVsTarget",
        width: 110,
        headerColor: "#00283C",
      },
      {
        meta: {
          data: "staffingPotential",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
          renderer: (
            instance: Handsontable,
            td: HTMLTableCellElement,
            row: number,
            col: number,
            prop: string | number,
            value: CellValue,
            cp: CellProperties
          ) => {
            if (value !== null) {
              const mantissa = Number.isInteger(value) ? 0 : 1;
              td.innerHTML = numbro(value).format({
                mantissa: mantissa,
              });
            }

            if (cp.className) {
              if (Array.isArray(cp.className)) {
                td.classList.add(...cp.className);
              } else {
                const classNames = cp.className.split(" ");
                td.classList.add(...classNames);
              }
            }
          },
        },
        headerTitle: "StaffingPotential",
        width: 110,
        headerColor: "#426FA2",
      },
      {
        meta: {
          data: "costPotential",
          type: "numeric",
          renderer: thousandSeparatorRenderer,
        },
        headerTitle: "CostPotential",
        width: 110,
        headerColor: "#426FA2",
      },
    ];

    return isFeatureFlagEnabled ? colsV2 : cols;
  };

  return { getColumns };
};

export default useOverviewColumnConfigs;
