import React from "react";
import clsx from "clsx";

const Drawer = ({
  isOpen,
  onClose,
  children,
  moreSpace,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  moreSpace?: boolean;
}) => {
  return (
    <div
      className={clsx(
        "fixed right-0 top-0 z-10 flex h-screen flex-col gap-4 bg-white p-4 transition-all duration-1000",
        {
          "shadow-[-20px_0px_35px_rgba(0,0,0,0.16)]": isOpen,
          "translate-x-full": !isOpen,
          "w-8/12": moreSpace,
          "w-4/12": !moreSpace,
        }
      )}
    >
      <div className="self-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={"w-4 cursor-pointer"}
          viewBox="0 0 20.205 20.205"
          onClick={onClose}
        >
          <path
            id="Close"
            d="M27.729,9.544l-2.02-2.02-8.082,8.082L9.544,7.523l-2.02,2.02,8.082,8.082L7.523,25.708l2.02,2.02,8.082-8.082,8.082,8.082,2.02-2.02-8.082-8.082Z"
            transform="translate(-7.523 -7.523)"
          />
        </svg>
      </div>

      <div className="flex-1 overflow-y-auto">{children}</div>
    </div>
  );
};

export default Drawer;
