import React, { useRef } from "react";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import { useTranslation } from "react-i18next";
import useOutcomeVsPlanTableColumns from "./useOutcomeVsPlanTableColumns";
import { HotTableClass as HotTable } from "@handsontable/react";

const useOutcomeVsPlanGridConfigs = (): DataTableProps => {
  const culture = localStorage.getItem("i18nextLng");

  const cols = useOutcomeVsPlanTableColumns();

  const { t: translate } = useTranslation();

  const ref = useRef<HotTable | null>(null);

  const props: DataTableProps = {
    autoRowSize: true,
    language: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
    numericFormat: {
      culture: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
      pattern: {},
    },
    ref,
    columns: cols.map((r) => r.meta),
    colHeaders: cols.map((r) => r.headerTitle),
    columnHeaderHeight: 20,
    licenseKey: "non-commercial-and-evaluation",
    contextMenu: ["alignment", "copy"],
    fixedColumnsLeft: 1,
    rowHeights: 20,
    height: "100%",
    afterGetColHeader: (col: number, TH) => {
      const colConfig = cols[col];

      if (colConfig && colConfig.headerColor) {
        TH.style.backgroundColor = colConfig.headerColor;
        TH.style.color = "white";
      } else {
        TH.classList.add("dark-blue-column");
      }

      TH.classList.add("htMiddle");

      if (colConfig && !colConfig.isFilterable) {
        TH.classList.add("remove-filter");
      }
    },
    cells: (row, col, prop) => {
      type cp = {
        className?: string;
        readOnly?: boolean;
      };
      const cp: cp = {};

      cp.className = "htMiddle";

      if (col === 0) {
        cp.className = cp.className + " dark-blue-column";
      } else {
        cp.className = cp.className + " htRight";
      }

      return cp;
    },
    colWidths: cols.map((x) => x.width),
    readOnly: true,
    rowHeaders: false,
    rowHeaderWidth: 20,
    columnSorting: true,
    manualRowMove: true,
    dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
  };

  return props;
};

export default useOutcomeVsPlanGridConfigs;
