export const productionShares = [
  "ProductionShareEdVisit",
  "ProductionShareOutpatientVisit",
  "ProductionShareWard",
  "ProductionShareSurgery",
  "Total - Direct care",
  "ProductionShareConsultant",
  "ProductionShareStExternalTraining",
  "ProductionShareNonClinicalTime",
  "ProductionShareManagement",
  "Total",
];

export const productionSharesColors: any = {
  ProductionShareEdVisit: "#5B7852",
  ProductionShareOutpatientVisit: "#5B7852",
  ProductionShareWard: "#5B7852",
  ProductionShareSurgery: "#5B7852",
  "Total - Direct care": "#5B7852",
  ProductionShareConsultant: "#364355",
  ProductionShareResidentTraining: "#365B9D",
  ProductionShareNonClinicalTime: "#C2830F",
  ProductionShareManagement: "#992319",
  Total: "#375330",
};

export const competenceShares = [
  "ÖL",
  "BÖL",
  "SPEC",
  "SPEC+",
  "ST+",
  "ST",
  "RAND",
  "LEG",
  "LEG/RAND",
  "AT",
  "UL",
  "AT/UL",
  "ST/LEG/AT/UL",
  "SSK+/++",
  "SSK++",
  "SSK+",
  "SSK",
  "USK+",
  "USK",
  "Omvårdnad",
  "Läkare",
  "Övrigt",
  "SSK Natt",
  "USK Natt",
];

export const competenceSharesColors: any = {
  ÖL: "#AD1E23",
  BÖL: "#AD1E23",
  SPEC: "#AD1E23",
  "SPEC+": "#AD1E23",
  "ST+": "#AD1E23",
  ST: "#AD1E23",
  RAND: "#AD1E23",
  LEG: "#AD1E23",
  "LEG/RAND": "#AD1E23",
  AT: "#AD1E23",
  UL: "#AD1E23",
  "AT/UL": "#AD1E23",
  "ST/LEG/AT/UL": "#AD1E23",
  SSK: "#426FA2",
  "SSK+": "#426FA2",
  "SSK++": "#426FA2",
  "SSK+/++": "#426FA2",
  USK: "#416031",
  "USK+": "#416031",
  Läkare: "#AD1E23",
  Omvårdnad: "#00283C",
  Övrigt: "#E5A32B",
};
