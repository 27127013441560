import React, { useEffect, useState } from "react";
import ActivityAnalysisService from "../../../../services/activityAnalysisService";
import { ActivityAnalysisShiftSchema } from "../../../../interfaces/analytics/activity-analysis-shift-schema";
import { useTranslation } from "react-i18next";
import { ActivityAnalysisIsEditedRefType } from "../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";
import { ReturnGetActivityShiftsData } from "../../../../interfaces/analytics/activityAnalysisShiftDataReturnType";

const useGetActivityShiftsData = (
  careUnitId: number,
  scenarioId: number,
  year: number,
  isWithinShift: boolean,
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>
): ReturnGetActivityShiftsData<ActivityAnalysisShiftSchema[]> => {
  const { t: translate } = useTranslation();

  const [activityAnalysisShifts, setActivityAnalysisShifts] = useState<ActivityAnalysisShiftSchema[]>([]);

  const fetchData = async (): Promise<void> => {
    ActivityAnalysisService.getActivityAnalysisShift(careUnitId, scenarioId, year, isWithinShift).then((res) => {
      const responseData: ActivityAnalysisShiftSchema[] = res.data.map((x, i) => {
        const row = i + 1;
        return {
          ...x,
          id: Number(x.id),
          timeSpent: `=((C${row}/60)*D${row}*E${row}*F${row})/52`,
          fte: `=IF(H${row}=0,0,G${row}/H${row})`,
        };
      });

      const noOfRows = responseData.length;

      if (noOfRows > 0) {
        const newRow: ActivityAnalysisShiftSchema = {
          id: "total_row",
          activity: translate("total"),
          competenceGroupLvl2: "",
          timePerActivity: null,
          activitiesPerWeek: null,
          numberPresence: null,
          weeksPerYear: null,
          timeSpent: `=SUM(G1:G${noOfRows})`,
          weeklyWorkingHours: null,
          fte: `=SUM(I1:I${noOfRows})`,
        };

        responseData.push(newRow);
      }

      setActivityAnalysisShifts(responseData);

      if (isWithinShift) {
        isEditedRef.current = {
          ...isEditedRef.current,
          withinShift: false,
        };
      } else {
        isEditedRef.current = {
          ...isEditedRef.current,
          outsideShift: false,
        };
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId, scenarioId, year, isWithinShift]);

  return {
    data: activityAnalysisShifts,
    fetch: fetchData,
    setData: setActivityAnalysisShifts,
  };
};

export default useGetActivityShiftsData;
