import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import { OrgNodeResponse } from "../../interfaces/responses/org-structure-tree-response";
import { RootState, setFilterCareUnit, setGlobalFilterIsLoadingFalse } from "../../redux/store";
import OrgService from "../../services/orgService";
import { searchPredicate } from "../../utils/react-dropdown-tree";

import DropdownTreeSelect from "react-dropdown-tree-select";

import { convertOrgNodeToDropdownData, setExpandState } from "../../utils/react-dropdown-tree/convert-api-response";
import { DropdownOrgTreeNode } from "../../interfaces/components/dropdown-tree";
import { CareUnitTypeEnum } from "../../utils/constants/careUnitTypes";

interface ControllerValue {
  careUnit: number | null;
  careUnitType: CareUnitTypeEnum | null;
  careUnitLabel: string | null;
  parents: number[];
  children: number[];
  year: number;
}

const OrgTreeDropdown = () => {
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const location = useLocation();

  // Used to store the previous org structure data source in order to do the
  // hierarchy comparison based on year changes

  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const stateCareUnit = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const stateCareUnitLabel = useSelector((state: RootState) => state.globalFilter.filterCareUnitLabel);
  const stateParentCareUnits = useSelector((state: RootState) => state.globalFilter.parentCareUnits);
  const stateChildCareUnits = useSelector((state: RootState) => state.globalFilter.childCareUnits);
  const stateCareUnitType = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const [controllerValue, setControllerValue] = useState<ControllerValue>({
    careUnit: stateCareUnit,
    careUnitLabel: stateCareUnitLabel,
    parents: stateParentCareUnits,
    children: stateChildCareUnits,
    careUnitType: stateCareUnitType,
    year: year,
  });

  const [dropdownResponse, setDropdownReponse] = useState<OrgNodeResponse[]>([]);
  const [dropdownData, setDropdownData] = useState<DropdownOrgTreeNode[]>([]);

  const [isMultiSelectable, setIsMultiSelectable] = useState<boolean>(
    location.pathname === "/" || location.pathname === "/analytics/production-vs-plan"
  );

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/analytics/production-vs-plan") {
      setIsMultiSelectable(true);
    } else {
      setIsMultiSelectable(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    //  Retrieve new org structure when there is a change in login, year selection or care provider

    const getOrgStructureTree = () => {
      if (careProviderId && year) {
        OrgService.getOrgChildrenHierarchyAsync(careProviderId, year)
          .then((res) => {
            setDropdownReponse(res);
          })
          .catch((err) => {
            throw err;
          });
      }
    };

    if (careProviderId !== null && year !== null) {
      getOrgStructureTree();
    }
  }, [careProviderId, year]);

  useEffect(() => {
    // when there is a new ogr structure

    if (dropdownResponse && dropdownResponse.length > 0) {
      // Get the default dropdown data with expanded state
      const tempDropdownData = convertOrgNodeToDropdownData(
        dropdownResponse,
        controllerValue.parents,
        controllerValue.careUnit
      );

      if (controllerValue.careUnit) {
        const selectedValue = findNode(controllerValue.careUnit, tempDropdownData);

        if (selectedValue) {
          const newParents = getParents(tempDropdownData, Number(controllerValue.careUnit));
          const newChilds = getLeafNodes(selectedValue).map((r) => r.value);

          const currentParents = controllerValue.parents;
          const currentChilds = controllerValue.children;

          // Update the Redux state without checking the org structure is equal
          // to trigger the dashboard data fetch endpoints

          dispatch(
            setFilterCareUnit({
              childCareUnits: newChilds,
              filterCareUnit: controllerValue.careUnit,
              filterCareUnitLabel: controllerValue.careUnitLabel,
              filterCareUnits: newChilds,
              filterCareUnitType: controllerValue.careUnitType,
              parentCareUnits: newParents,
            })
          );

          // If the careunit's position has changed based on the year change, update the dropdown controllers
          // expanded state
          if (!isEqual(newParents, currentParents) || !isEqual(newChilds, currentChilds)) {
            const dropdownDataWithExpandedState = convertOrgNodeToDropdownData(
              dropdownResponse,
              newParents,
              controllerValue.careUnit
            );

            setControllerValue({
              careUnit: controllerValue.careUnit,
              careUnitLabel: controllerValue.careUnitLabel,
              careUnitType: controllerValue.careUnitType,
              children: newChilds,
              parents: newParents,
              year: year,
            });

            setDropdownData(dropdownDataWithExpandedState);
          } else {
            setDropdownData(tempDropdownData);
          }
        } else {
          // If the org strucutures are equal, update the dropdown expanded state
          const dropdownDataWithExpandedState = convertOrgNodeToDropdownData(dropdownResponse, [], null);

          setDropdownData(dropdownDataWithExpandedState);
        }
      } else {
        setDropdownData(tempDropdownData);
      }

      dispatch(setGlobalFilterIsLoadingFalse());
    } else {
      setDropdownData([]);
    }
  }, [dropdownResponse]);

  useEffect(() => {
    // update the controller values and the state based on the drop down options available
    if (controllerValue.careUnit && dropdownData.length > 0) {
      const selectedValue = findNode(controllerValue.careUnit, dropdownData);

      if (!selectedValue) {
        // reset controller value
        setControllerValue({
          careUnit: null,
          careUnitLabel: "",
          careUnitType: null,
          children: [],
          parents: [],
          year: year,
        });

        dispatch(
          setFilterCareUnit({
            childCareUnits: [],
            filterCareUnit: null,
            filterCareUnitLabel: null,
            filterCareUnits: [],
            filterCareUnitType: null,
            parentCareUnits: [],
          })
        );
      }
    }
  }, [dropdownData]);

  const getLeafNodes = (node: DropdownOrgTreeNode): DropdownOrgTreeNode[] => {
    if (node.children && node.children.length > 0) {
      return node.children.reduce((acc: DropdownOrgTreeNode[], child: DropdownOrgTreeNode) => {
        if (child.children && child.children.length > 0) {
          return [...acc, ...getLeafNodes(child)];
        } else {
          return [...acc, child];
        }
      }, []);
    } else {
      return [];
    }
  };

  const getParents = (node: DropdownOrgTreeNode[], nodeId: number): number[] => {
    const parents: number[] = [];

    const recursiveSearch = (currentNode: DropdownOrgTreeNode[], targetId: number): boolean => {
      for (const child of currentNode) {
        if (child.value === targetId) {
          return true;
        } else if (child.children && child.children.length > 0) {
          if (recursiveSearch(child.children, targetId)) {
            parents.push(child.value);
            return true;
          }
        }
      }

      return false;
    };

    recursiveSearch(node, nodeId);

    return parents.reverse();
  };

  const findNode = (id: number, dropDownResponse: DropdownOrgTreeNode[]): DropdownOrgTreeNode | null => {
    for (const child of dropDownResponse) {
      if (child.value === id) {
        return child;
      } else if (child.children && child.children.length > 0) {
        const result = findNode(id, child.children);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  const onFocus = () => {
    document.querySelector(".dropdown-content")?.classList.remove("hidden");
    document.querySelector(".dropdown-content")?.classList.add("visible");

    document.querySelector(".analytics-dropdown-tree .dropdown-trigger.arrow")?.classList.remove("bottom");
    document.querySelector(".analytics-dropdown-tree .dropdown-trigger.arrow")?.classList.add("top");
  };

  const onChange = (node: any) => {
    document.querySelector(".dropdown-content")?.classList.add("hidden");
    document.querySelector(".dropdown-trigger")?.classList.remove("top");
    document.querySelector(".dropdown-trigger")?.classList.add("bottom");

    const currentNode: DropdownOrgTreeNode = node as DropdownOrgTreeNode;

    const currentNodeDetail = findNode(currentNode.value as number, dropdownData);

    if (currentNodeDetail) {
      const parents = getParents(dropdownData, Number(currentNodeDetail.value));
      const children = getLeafNodes(currentNodeDetail).map((r) => r.value);

      // set controller values
      setControllerValue({
        careUnit: currentNodeDetail.value,
        careUnitLabel: currentNodeDetail.label,
        careUnitType: currentNodeDetail.careUnitTypeId,
        children: children,
        parents: parents,
        year: year,
      });

      const newDropdownData = setExpandState(dropdownData, parents, currentNodeDetail.value);

      // Refresh expanded state of the dropdownData state variable
      setDropdownData(newDropdownData);

      // set redux state
      dispatch(
        setFilterCareUnit({
          childCareUnits: children,
          filterCareUnit: currentNodeDetail.value,
          filterCareUnitLabel: currentNodeDetail.label,
          filterCareUnits: children,
          filterCareUnitType: currentNodeDetail.careUnitTypeId,
          parentCareUnits: parents,
        })
      );
    } else {
      // this scenario cannot occour
    }
  };

  return (
    <div className="max-w-full">
      <div className="relative">
        <DropdownTreeSelect
          data={dropdownData}
          onChange={onChange}
          showDropdown="default"
          className={`dropdown-tree ${
            isMultiSelectable ? "analytics-dropdown-tree dashboard-dropdown-tree" : "analytics-dropdown-tree"
          }`}
          keepTreeOnSearch={false}
          keepOpenOnSelect={true}
          clearSearchOnChange={true}
          disabled={Object.keys(dropdownData).length === 0}
          texts={{ placeholder: controllerValue.careUnitLabel ? controllerValue.careUnitLabel : "" }}
          onFocus={onFocus}
          searchPredicate={searchPredicate}
        />
        <p className="p-3-v-1 absolute left-2 top-1 px-[1.0rem] text-[#747474]">{translate("org_item")}</p>
      </div>
    </div>
  );
};

export default OrgTreeDropdown;
