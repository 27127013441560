import { RowObject } from "handsontable/common";
import numbro from "numbro";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import { StaffFormalCompetenceMappingStatus } from "../../../../interfaces/staff-availabilities/staffFormalCompetenceMappingStatus";

const useStaffGridValidators = () => {
  const workCompetenceMappingValidator = (
    data: RowObject[] | undefined,
    formalCompetences: FormalCompetence[]
  ): StaffFormalCompetenceMappingStatus => {
    let errorObject: StaffFormalCompetenceMappingStatus = {
      isValid: true,
    };

    data?.forEach((row, index) => {
      const shortName = row["formalCompetenceShortName"];
      const formalCompetence = formalCompetences.find((fc) => fc.shortName === shortName);

      if (formalCompetence) {
        let hasValue = false;
        let otherFormalCompContainsValue = false;
        let isValueAString = false;

        formalCompetence.workCompetences.forEach((workCompetence) => {
          if (workCompetence.id !== null) {
            const value = Number(row[workCompetence.id]);

            if (row[workCompetence.id] === "" || row[workCompetence.id] === null) {
              return false;
            }

            if (!isNaN(value)) {
              hasValue = true;
            } else {
              isValueAString = true;
              hasValue = true;
            }
          }
        });

        formalCompetences.forEach((competence) => {
          if (competence.shortName !== shortName) {
            competence.workCompetences.forEach((workCompetence) => {
              if (workCompetence) {
                const value = row[workCompetence.id as number];

                if (value && Number(value) !== 0) {
                  otherFormalCompContainsValue = true;
                }
              }
            });
          }
        });

        if (!hasValue) {
          errorObject = {
            isValid: false,
            message: `Atleast one work competence is required for ${shortName}`,
            row: index + 1,
          };

          return errorObject;
        }

        if (isValueAString) {
          errorObject = {
            isValid: false,
            message: `Values on work competences must be numbers`,
            row: index + 1,
          };

          return errorObject;
        }

        if (otherFormalCompContainsValue) {
          errorObject = {
            isValid: false,
            message: `Only one formal competence can be filled at a time`,
            row: index + 1,
          };

          return errorObject;
        }
      }
    });

    return errorObject;
  };

  const competenceAndProductionsSharesSumValidator = (
    data: RowObject[] | undefined,
    competencesSharesColumns: string[]
  ) => {
    const isValid = data?.every((col) => {
      let competenceShareColumnsTotal = numbro(0);

      Object.keys(col).forEach((key) => {
        if (competencesSharesColumns.includes(key)) {
          if (col[key] === null || col[key] === " " || col[key] === "") {
            col[key] = 0;
          }
          competenceShareColumnsTotal = competenceShareColumnsTotal.add(col[key]);
        }
      });

      if (competenceShareColumnsTotal.value() !== 100) {
        return false;
      }

      return true;
    });

    return isValid;
  };

  return {
    competenceAndProductionsSharesSumValidator,
    workCompetenceMappingValidator,
  };
};

export default useStaffGridValidators;
