import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import ScrollLayout from "../../../../../layouts/ScrollLayout/ScrollLayout";
import { Button, Input, LoadingWithColor } from "../../../../../components";
import { ScrollBarConstants } from "../../Utils";
import { ScenarioRead, ScenarioSaveRequest } from "../../../../../interfaces/parameters/scenario";
import ScenarioService from "../../../../../services/scenarioService";
import DatePickerInput from "../../../../../components/date-picker/date-picker";
import { useTimeFormats } from "../../../../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface ScrollBarProps {
  scenarioToEdit?: ScenarioRead;
  variant: string | undefined;
  isScrollBarOpen: boolean;
  setIsScrollBarOpen: Dispatch<SetStateAction<boolean>>;
  setScenario?: Dispatch<SetStateAction<ScenarioRead[]>>;
  setSuccessNotification?: Dispatch<SetStateAction<string>>;
  setSideBarVariant: Dispatch<SetStateAction<string | undefined>>;
  setScenarioToEdit: Dispatch<SetStateAction<ScenarioRead>>;
}

const SidePanel = ({
  scenarioToEdit,
  variant,
  isScrollBarOpen,
  setIsScrollBarOpen,
  setSideBarVariant,
  setSuccessNotification,
  setScenarioToEdit,
}: ScrollBarProps) => {
  const { t: translate } = useTranslation();
  const { toISOIgnoreTimezone } = useTimeFormats();
  const handleCloseIconClick = () => {
    clearStateValues();
  };

  const [scenarioAddError, setAddScenarioError] = useState<string>("");
  const [scenario, setScenario] = useState<ScenarioSaveRequest>({
    scenarioName: "",
    lockUntil: null,
  });

  const isGlobalAdminAccess = useSelector((state: RootState) => state.permission.globalSettingEnabled);

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value === "") {
      setAddScenarioError(translate("scenario_name_required"));
    } else {
      setAddScenarioError("");
    }
    setScenario((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const [isAddingEntity, setIsAddingEntity] = useState<boolean>(false);

  const [isAddUserFailed, setIsAddUserFailed] = useState<boolean>(false);

  const clearStateValues = () => {
    setScenario({
      scenarioName: "",
      lockUntil: null,
    });
    setIsScrollBarOpen(false);
    setSideBarVariant(undefined);
    setAddScenarioError("");
    resetEditScenarioState();
  };

  useEffect(() => {
    if (scenarioToEdit !== undefined && isScrollBarOpen) {
      setScenario(scenarioToEdit);
    }
  }, [scenarioToEdit !== undefined, isScrollBarOpen]);

  const handleAddUserSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (scenario.scenarioName !== "") {
      setIsAddingEntity(true);
      const scenarioRequest: ScenarioSaveRequest = {
        scenarioName: scenario.scenarioName,
        lockUntil: scenario.lockUntil ? scenario.lockUntil : null,
      };

      await ScenarioService.saveScenarios(scenarioRequest)
        .then(() => {
          clearStateValues();

          setIsAddUserFailed(false);
          setIsAddingEntity(false);
          setSuccessNotification && setSuccessNotification("Scenario");
        })
        .catch(() => {
          setIsAddingEntity(false);
          setIsAddUserFailed(true);
        });
    } else {
      setAddScenarioError(translate("scenario_name_required"));
    }
  };

  const resetEditScenarioState = () => {
    if (variant === ScrollBarConstants.UPDATE_SCENARIO) {
      setScenarioToEdit({
        id: 0,
        scenarioName: "",
        createdBy: "",
        dateCreated: new Date(),
        lastModifiedBy: "",
        lastModifiedDate: new Date(),
        lockUntil: null,
      });
    }
  };

  const handleEditScenario = async (e: React.FormEvent) => {
    e.preventDefault();

    if (scenario.scenarioName !== "") {
      setIsAddingEntity(true);
      const scenarioDto: ScenarioSaveRequest = {
        id: scenario.id,
        scenarioName: scenario.scenarioName,
        lockUntil: scenario.lockUntil,
      };
      await ScenarioService.updateScenarios(scenarioDto)
        .then(() => {
          clearStateValues();
          setIsAddUserFailed(false);
          setIsAddingEntity(false);
          setSuccessNotification && setSuccessNotification("Scenario");
        })
        .catch(() => {
          setIsAddingEntity(false);
          setIsAddUserFailed(true);
        });
    }
  };

  const handleDatePickerChange = (e: Date) => {
    const isoIgnoreFormat = toISOIgnoreTimezone(e);

    setScenario((prevState) => {
      return {
        ...prevState,
        lockUntil: new Date(isoIgnoreFormat),
      };
    });
  };

  const handleClearIconClick = () => {
    setScenario((prevState) => {
      return {
        ...prevState,
        lockUntil: null,
      };
    });
  };

  return (
    <ScrollLayout isOpen={isScrollBarOpen} onCloseIconClick={handleCloseIconClick}>
      {variant === ScrollBarConstants.ADD_SCENARIO && (
        <>
          {isAddUserFailed && (
            <div className="my-3 h-12">
              <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
            </div>
          )}
          {isAddingEntity ? (
            <div className="w-8/12">
              <LoadingWithColor sizeInPixel={50} additionalStyles="side-panel-loader" />
            </div>
          ) : (
            <>
              <div className="flex h-screen w-full flex-col justify-center">
                <form onSubmit={handleAddUserSubmit}>
                  <h3 className="heading-3"> {translate("add_scenario")} </h3>

                  <div>
                    <Input
                      name="scenarioName"
                      value={scenario.scenarioName}
                      placeholder={translate("Scenario")}
                      handleInputChange={handleUserInputChange}
                      error={scenarioAddError}
                      success=""
                      type="text"
                    />
                  </div>

                  {isGlobalAdminAccess && (
                    <div>
                      <DatePickerInput
                        handleInputChange={handleDatePickerChange}
                        id="lockUntil"
                        name="lockUntil"
                        placeholder={translate("locked_until")}
                        value={scenario.lockUntil ? toISOIgnoreTimezone(scenario.lockUntil) : null}
                        placement="bottom-start"
                        isClearable={true}
                        handleClearIconClick={handleClearIconClick}
                        error={null}
                      />
                    </div>
                  )}

                  <div className="row w-full text-right">
                    <Button additionalStyles=" w-44 h-10" variant="primary" text={translate("save")} />
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}

      {variant === ScrollBarConstants.UPDATE_SCENARIO && (
        <>
          {isAddUserFailed && (
            <div className="my-3 h-12">
              <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
            </div>
          )}
          {isAddingEntity ? (
            <div className="w-8/12">
              <LoadingWithColor sizeInPixel={50} additionalStyles="side-panel-loader" />
            </div>
          ) : (
            <>
              <div className="flex h-screen w-full flex-col justify-center">
                <form onSubmit={handleEditScenario}>
                  <h3 className="heading-3"> {translate("edit_scenario")} </h3>

                  <div className="flex w-full items-center gap-[0.75rem]">
                    <Input
                      name="scenarioName"
                      value={scenario.scenarioName}
                      placeholder={translate("Scenario")}
                      handleInputChange={handleUserInputChange}
                      error={scenarioAddError}
                      success=""
                      type="text"
                    />
                  </div>

                  {isGlobalAdminAccess && (
                    <div>
                      <DatePickerInput
                        handleInputChange={handleDatePickerChange}
                        id="lockUntil"
                        name="lockUntil"
                        placeholder={translate("locked_until")}
                        value={scenario.lockUntil ? toISOIgnoreTimezone(scenario.lockUntil) : null}
                        placement="auto"
                        isClearable={true}
                        handleClearIconClick={handleClearIconClick}
                        error={null}
                      />
                    </div>
                  )}

                  <div className="row w-full text-right">
                    <Button additionalStyles=" w-44 h-10" variant="primary" text={translate("save")} />
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </ScrollLayout>
  );
};

export default SidePanel;
