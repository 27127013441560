import HttpCommonService from "./httpCommonService";
import { FilterGroupPayload } from "../interfaces/filter-group/filter-group";

export const FilterGroupService = {
  saveFilterGroup: async (data: FilterGroupPayload) => {
    return await HttpCommonService.postAsync(`api/filter-group/save`, data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getFilterGroup: async (careUnitIds: number[]) => {
    const payload = {
      careUnitIds: careUnitIds,
    };
    return await HttpCommonService.postAsync(`api/filter-groups`, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getDimensionsForFilterGroup: async (filterGroupId: number) => {
    return await HttpCommonService.getAsync(`api/filter-groups/dimensions/${filterGroupId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  deleteFilterGroup: async (filterGroupId: number, careUnitId: number) => {
    return await HttpCommonService.deletAsync(`api/filter-group/delete/${careUnitId}/${filterGroupId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default FilterGroupService;
