import { RowObject } from "handsontable/common";
import HttpCommonService from "./httpCommonService";
import StaffModel from "../interfaces/staff-availabilities/staffModel";

const StaffAvailabilityService = {
  getStaffAvailabilites: async (careUnitId: number | null, scenario: number | null, competences?: string[] | null) => {
    return await HttpCommonService.postAsync(`api/getstaffavailability`, {
      careUnitId: careUnitId,
      scenarioId: scenario,
      competences: competences,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getGapAnalysisCompetences: async (careUnitId: number | null, scenarioId: number | null) => {
    return await HttpCommonService.getAsync(`api/results/staffinggapcompetences/${careUnitId}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getGapAnalysisResults: async (
    careUnitId: number | null,
    scenarioId: number | null,
    workCompetences: number[],
    employmentTypes: number[]
  ) => {
    return await HttpCommonService.postAsync(`api/results/staffinggapcompetences`, {
      careUnitId,
      scenarioId,
      employmentTypes,
      workCompetences,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getStaffGapsGroupedByWeeks: async (
    careUnitId: number | null,
    scenarioId: number | null,
    workCompetences: number[],
    employmentTypes: number[]
  ) => {
    return await HttpCommonService.postAsync(`api/staffs/gaps/weeks/`, {
      careUnitId,
      scenarioId,
      workCompetences,
      employmentTypes,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getStaffGapsGroupedByWorkCompetence: async (careUnitId: number, scenarioId: number) => {
    return await HttpCommonService.getAsync(
      `api/staffs/gaps/workcompetences/careunit/${careUnitId}/scenario/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveStaffAvailabilites: async (
    staff: RowObject[] | undefined,
    careUnitId: number | null,
    scenarioId: number | null
  ) => {
    return await HttpCommonService.postAsync(`api/staffavailability`, {
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      createStaffavailabilityList: {
        createStaffAvailabilities: staff,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  updateStaffAvailabilites: async (
    staff: RowObject[] | undefined,
    careUnitId: number | null,
    scenarioId: number | null
  ) => {
    return await HttpCommonService.putAsync(`api/staffavailability`, {
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      updateStaffAvailabilities: {
        updateStaffAvailabilities: staff,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getStaffWorkCompetenceAllocations: async (careUnitId: number | null, scenarioId: number | null) => {
    return await HttpCommonService.getAsync(
      `api/staffs/workCompetences/allocations/careUnit/${careUnitId}/scenario/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveStaffWorkCompetenceAllocations: async (careUnitId: number, scenarioId: number, staffs: StaffModel[]) => {
    return await HttpCommonService.postAsync(`api/staffs/workCompetences/allocations`, {
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      upsertStaffs: staffs,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default StaffAvailabilityService;
