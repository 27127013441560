import { useEffect, useState } from "react";
import FeatureFlagService from "../services/featureFlagService";
import { useDispatch } from "react-redux";
import { setFetaureFlags } from "../redux/slices/featureFlagSlice";

export const useFeatureFlags = (): { isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await FeatureFlagService.getFeatureFlags();
      dispatch(setFetaureFlags(data));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return { isLoading: isLoading };
};
