interface FuncNumberFormatting {
  // (nput: number, separator: string | null): string | null;
  thousandSeparate: (nput: number, separator: string | null) => string | null;
  inMillions: (nput: number) => number;
  round: (nput: number, decimalPoints: number) => number;
  localize: (nput: number, culture: string, separator?: string | null) => string;
}

export const useNumberFormat = (): FuncNumberFormatting => {
  const thousandSeparate = (input: number, separator: string | null): string | null => {
    if (input) {
      if (separator) {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
      } else {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    } else {
      return null;
    }
  };

  const inMillions = (number: number): number => {
    return number / 100000;
  };

  const round = (number: number, decimalPoints: number): number => {
    const str = number.toFixed(decimalPoints);

    return Number(str);
  };

  const localize = (number: number, culture: string, separator?: string | null): string => {
    if (culture === "sv") {
      culture = "sv-SE";
    } else if (culture === "en") {
      culture = "en-US";
    }

    const localizedValue = number.toLocaleString(culture);

    if (separator) {
      if (culture === "en-US") {
        return localizedValue.replace(/,/g, " ");
      }

      return localizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    return localizedValue;
  };

  return { thousandSeparate, inMillions, round, localize };
};
