import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import {
  nullStringStringLengthValidator,
  nullStringValidator,
} from "../../../../utils/handsontable-configuration/validators/validators";

export const useGetActivityShiftTableColumns = (
  formalCompetences: FormalCompetence[]
): HandsOnTableColumn[] => {
  const cols: HandsOnTableColumn[] = [
    {
      meta: {
        data: "formalCompetenceName",
        type: "dropdown",
        source: formalCompetences.map((r) => r.name) as string[],
        strict: true,
        validator: nullStringValidator,
      },
      headerTitle: "formal-competence",
      width: 250,
    },
    {
      meta: {
        data: "name",
        nullable: false,
        validator: nullStringValidator,
      },
      headerTitle: "work-competence-name",
      width: 400,
    },
    {
      meta: {
        data: "shortName",
        nullable: false,
        validator: nullStringStringLengthValidator,
      },
      headerTitle: "work-competence-short-name",
      width: 200,
    },
    {
      meta: {
        data: "id",
        type: "numeric",
        readOnly: true,
      },
      headerTitle: "id",
      width: 130,
    },
  ];
  return cols;
};

export default useGetActivityShiftTableColumns;
