import { MultiSelectOption } from "../../../../../interfaces";

const UseGetStaticDimensionIds = (
  dimensionList: MultiSelectOption[]
): number[] => {
  const filteredList = dimensionList.filter(
    (item) => item.value !== "all" && item.value !== "null"
  );
  const filteredIds: number[] = filteredList.map((item) =>
    parseInt(item.value as string, 10)
  );

  return filteredIds;
};

export default UseGetStaticDimensionIds;
