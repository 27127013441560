import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GridLayout } from "../../../layouts";
import StaffTabIndex from "../../../common/enums/staffTabIndex";
import { Button, DataTableAddButton } from "../../../components";
import { useBlocker } from "react-router";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useGetEmploymentTypes from "../../../common/hooks/data/useGetEmploymentTypes";
import { MultiSelectOption } from "../../../interfaces";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import WorkCompetence from "../../../interfaces/competence/WorkCompetence";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import useGetWorkCompetencesData from "../../../common/hooks/data/useGetWorkCompetencesData";
import { StaffAvailabilityService } from "../../../services";
import useStaffGridConfigurator from "./hooks/useStaffGridConfigurator";
import useStaffGridValidators from "./hooks/useStaffGridValidators";
import lodash from "lodash";
import { HotTableClass as HotTable } from "@handsontable/react";
import { DataTableProps } from "../../../interfaces/props/data-table-props";
import StaffGridDynamicRowType from "../../../interfaces/staff-availabilities/staffGridDynamicRowType";
import useGetFormalCompetencesByCareUnit from "../../../common/hooks/data/useGetFormalCompetencesByCareUnit";
import useGetStaffWorkCompetenceAllocations from "../../../common/hooks/data/useGetStaffWorkCompetenceAllocations";
import StaffAvailabilityAllocation from "../../../interfaces/staff-availabilities/staffAvailabilityAllocation";
import StaffModel from "../../../interfaces/staff-availabilities/staffModel";
import StaffGapByWeekChart from "../StaffGapByWeekChart/StaffGapByWeekChart";
import StaffGapByWorkCompetenceChart from "../StaffGapByWorkCompetenceChart/StaffGapByWorkCompetenceChart";
import SaveChangesConfirmation from "../../../components/SaveChangesConfirmation/SaveChangesConfirmation";
import MultiSelectDropDown from "../../../components/MultiselectDropdown/MultiSelectDropdown";
import { toast } from "react-toastify";
import clsx from "clsx";
import StaffWorkCompetenceAllocationModel from "../../../interfaces/staff-availabilities/staffWorkCompetenceAllocationModel";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";

const StaffHome = (): JSX.Element => {
  const { t: translate } = useTranslation();

  // Local state start

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(StaffTabIndex.Staff);

  const tabs = [translate("Staffing"), translate("people-tab-competence-allocation"), translate("GapAnalysis")];

  // Local state end

  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const handleTabbarClick = (clickedIndex: number): void => {
    setSelectedTabIndex(clickedIndex);
  };

  // staff states
  // Redux state start

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const employmentTypesDataHandler = useGetEmploymentTypes();

  // Redux state end

  // Local state start

  const [fetchStaffCompetenceChartData, setFetchStaffCompetenceChartData] = useState<boolean>(true);

  const [fetchStaffWeeklyChartData, setFetchStaffWeeklyChartData] = useState<boolean>(true);

  const [allFormalCompetenceOptions, setAllFormalCompetenceOptions] = useState<MultiSelectOption[]>([]);

  const [selectedFormalCompetenceOptions, setSelectedFormalCompetenceOptions] = useState<MultiSelectOption[]>([]);

  const [allEmploymentTypeOptions, setAllEmploymentTypeOptions] = useState<MultiSelectOption[]>([]);

  const [selectedEmploymentTypeOptions, setSelectedEmploymentTypeOptions] = useState<MultiSelectOption[]>([]);

  const [workCompetencesInStaffingGap, setWorkCompetencesInStaffingGap] = useState<WorkCompetence[]>([]);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.EmploymentTypeMultiSelect);

  // Local state end

  const workCompetenceDataHandler = useGetWorkCompetencesData(careUnitId);

  useEffect(() => {
    if (careUnitId && scenario) {
      StaffAvailabilityService.getGapAnalysisCompetences(careUnitId, scenario)
        .then((res: { data: WorkCompetence[] }) => {
          setWorkCompetencesInStaffingGap(res.data);
        })
        .catch((err: string) => {
          throw err;
        });

      if (selectedTabIndex === StaffTabIndex.StaffCompetenceAllocation) {
        setFetchStaffCompetenceChartData(true);
      } else if (selectedTabIndex === StaffTabIndex.GapAnalysis) {
        setFetchStaffWeeklyChartData(true);
      }
    }
  }, [careUnitId, scenario]);

  useEffect(() => {
    if (selectedTabIndex === StaffTabIndex.StaffCompetenceAllocation) {
      setFetchStaffCompetenceChartData(true);
    } else if (selectedTabIndex === StaffTabIndex.GapAnalysis) {
      setFetchStaffWeeklyChartData(true);
    }

    if (selectedTabIndex !== StaffTabIndex.GapAnalysis) {
      setSelectedFormalCompetenceOptions(selectedFormalCompetenceOptions);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    const multiSelectOptions = populateFormalCompetenceMultiSelectOptions();

    const selectedOptions: MultiSelectOption[] = [];
    if (workCompetencesInStaffingGap.length > 0) {
      workCompetencesInStaffingGap.forEach((element) => {
        const competenceObject = multiSelectOptions.find((e) => element.id && e.value === element.id.toString());
        if (competenceObject) {
          selectedOptions.push(competenceObject);
        }
      });

      setSelectedFormalCompetenceOptions(selectedOptions);
    } else {
      setSelectedFormalCompetenceOptions(multiSelectOptions);
    }

    setAllFormalCompetenceOptions(multiSelectOptions);
    setSelectedFormalCompetenceOptions(multiSelectOptions);
  }, [workCompetenceDataHandler.data]);

  const populateFormalCompetenceMultiSelectOptions = (): MultiSelectOption[] => {
    const multiSelectOptions: MultiSelectOption[] = [];

    for (const item of workCompetenceDataHandler.data) {
      multiSelectOptions.push({
        id: item.id ? item.id.toString() : "",
        label:
          item.shortName && item.formalCompetenceShortName
            ? `${item.formalCompetenceShortName} - ${item.shortName}`
            : "",
        value: item.id ? item.id.toString() : "",
      });
    }

    return multiSelectOptions;
  };

  useEffect(() => {
    if (!employmentTypesDataHandler.isLoading) {
      const multiSelectOptions = populateEmploymentTypeMultiSelectOptions();

      const selectedOptions: MultiSelectOption[] = [];
      if (workCompetencesInStaffingGap.length > 0) {
        workCompetencesInStaffingGap.forEach((element) => {
          const competenceObject = multiSelectOptions.find((e) => element.id && e.value === element.id.toString());
          if (competenceObject) {
            selectedOptions.push(competenceObject);
          }
        });

        setSelectedEmploymentTypeOptions(selectedOptions);
      } else {
        setSelectedEmploymentTypeOptions(multiSelectOptions);
      }

      setAllEmploymentTypeOptions(multiSelectOptions);
      setSelectedEmploymentTypeOptions(multiSelectOptions);
    }
  }, [employmentTypesDataHandler.data]);

  const populateEmploymentTypeMultiSelectOptions = (): MultiSelectOption[] => {
    const multiSelectOptions: MultiSelectOption[] = [];

    for (const item of employmentTypesDataHandler.data) {
      multiSelectOptions.push({
        id: item.id ? item.id.toString() : "",
        label: item.name ? `${item.name}` : "",
        value: item.id ? item.id.toString() : "",
      });
    }

    return multiSelectOptions;
  };

  const getGridHeightClass = (): string => {
    return selectedTabIndex === StaffTabIndex.GapAnalysis ||
      selectedTabIndex === StaffTabIndex.StaffCompetenceAllocation
      ? "h-[50%]"
      : "h-full";
  };

  const getPaddingTopClass = (): string => {
    return selectedTabIndex === StaffTabIndex.GapAnalysis ||
      selectedTabIndex === StaffTabIndex.StaffCompetenceAllocation
      ? ""
      : "pt-4";
  };

  const getGridWidthClass = (): string => {
    return selectedTabIndex === StaffTabIndex.GapAnalysis ? "w-[85%]" : "w-[100%]";
  };

  const onFormalCompetenceDropdownValueChange = (values: MultiSelectOption[]): void => {
    setSelectedFormalCompetenceOptions(values);
    setFetchStaffWeeklyChartData(true);
  };

  const onEmploymentTypeDropdownValueChange = (values: MultiSelectOption[]): void => {
    setSelectedEmploymentTypeOptions(values);
    setFetchStaffWeeklyChartData(true);
  };

  const setFetchChartData = () => {
    setFetchStaffCompetenceChartData(true);
    setFetchStaffWeeklyChartData(true);
  };

  // Staff grid states

  const table = useRef<HotTable>(null);

  const [tableProps, setTableProps] = useState<DataTableProps>({
    ref: table,
  });

  const [tableData, setTableData] = useState<StaffGridDynamicRowType[]>([]);

  const [isReferenceDataLoading, setIsReferenceDataLoading] = useState<boolean>(true);

  const [isTableDataEdited, setIsTableDataEdited] = useState<boolean>(false);

  const [isSaveChangesNotificationOpen, setIsSaveChangesNotificationOpen] = useState(false);

  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const formalCompetenceDataHandler = useGetFormalCompetencesByCareUnit(careUnitId);

  const staffAllocationsDataHandler = useGetStaffWorkCompetenceAllocations(careUnitId, scenarioId);

  const staffAvailabilityHiddenColumnIndexes = lodash.range(8, 60);
  const staffHiddenColumnIndexes = lodash.range(3, 8);
  const staffHiddenColumnIndexes2 = lodash.range(2, 7);

  const [workCompetenceHiddenColumns, setWorkCompetenceHiddenColumns] = useState<number[]>([]);

  const [hiddenColumns, setHiddenColumns] = React.useState<number[]>(staffAvailabilityHiddenColumnIndexes);

  const hiddenColumnsPlugin = table.current?.__hotInstance?.getPlugin("hiddenColumns");

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isTableDataEdited && currentLocation.pathname !== nextLocation.pathname
  );

  const authorizedColumns = [0, 1, 7];

  useEffect(() => {
    if (blocker.state === "blocked") {
      setIsSaveChangesNotificationOpen(true);
    }
  }, [blocker.state]);

  useEffect(() => {
    let tmpHiddenColumns: number[] = [];
    if (selectedTabIndex === StaffTabIndex.Staff) {
      tmpHiddenColumns = [...staffAvailabilityHiddenColumnIndexes, ...workCompetenceHiddenColumns];
    } else if (selectedTabIndex === StaffTabIndex.StaffCompetenceAllocation) {
      tmpHiddenColumns = [...staffHiddenColumnIndexes, ...staffAvailabilityHiddenColumnIndexes];
    } else if (selectedTabIndex === StaffTabIndex.GapAnalysis) {
      tmpHiddenColumns = [...staffHiddenColumnIndexes2, ...workCompetenceHiddenColumns];
    } else {
      tmpHiddenColumns = [...staffAvailabilityHiddenColumnIndexes, ...workCompetenceHiddenColumns];
    }

    if (isCareUnitReadOnly) {
      tmpHiddenColumns = tmpHiddenColumns.concat(authorizedColumns);
    }

    hiddenColumnsPlugin?.hideColumns(tmpHiddenColumns);

    setHiddenColumns(tmpHiddenColumns);
  }, [selectedTabIndex, workCompetenceHiddenColumns]);

  const tableConfigurator = useStaffGridConfigurator(table);

  const validators = useStaffGridValidators();

  const saveStaffAvailabilities = async () => {
    const isLoadingToast = toast.loading(
      <NotificationFloat varaint="loading" content={translate("saving_and_running_calculations")} />,
      {
        icon: false,
        className: "custom-toast",
      }
    );

    const showToast = (variant: string, content: string) => {
      toast.update(isLoadingToast, {
        render: <NotificationFloat varaint={variant} content={content} />,
      });
    };

    const dismissToast = () => {
      setTimeout(() => {
        toast.dismiss(isLoadingToast);
      }, 3000);
    };

    const workCompetences: string[] = [];
    workCompetenceDataHandler.data.map((x) => {
      x.id ? workCompetences.push(x.id.toString()) : "";
    });

    const result = validators.workCompetenceMappingValidator(tableData, formalCompetenceDataHandler.data);

    if (result.isValid === false) {
      showToast("error", result.message as string);
      dismissToast();
    } else {
      const isValidData = validators.competenceAndProductionsSharesSumValidator(tableData, workCompetences);

      if (isValidData === false) {
        showToast("error", "Competence shares sum must be equal to 100%");
        dismissToast();
        return;
      }

      table.current?.hotInstance?.validateCells(async (valid) => {
        if (valid && isValidData) {
          const data = convertTableDataToPayload();

          staffAllocationsDataHandler
            .submit(careUnitId as number, scenarioId, data)
            .then(() => {
              showToast("success", translate("data_saved"));
              setFetchStaffWeeklyChartData(true);
            })
            .catch(() => {
              showToast("error", "Something went wrong");
            })
            .finally(() => {
              dismissToast();
            });
        } else {
          showToast("error", translate("staffing_data_not_valid"));

          dismissToast();
        }
      });
    }

    resetTableEditedStates();
  };

  const resetTableEditedStates = () => {
    if (isSaveChangesNotificationOpen) {
      setIsSaveChangesNotificationOpen(false);
    }
    setIsTableDataEdited(false);
  };

  useEffect(() => {
    if (
      !formalCompetenceDataHandler.isLoading &&
      !workCompetenceDataHandler.isLoading &&
      !employmentTypesDataHandler.isLoading
    ) {
      setIsReferenceDataLoading(false);

      const numbOfWorkCom = formalCompetenceDataHandler.data.reduce(
        (count, obj) => count + obj.workCompetences.length,
        0
      );

      if (numbOfWorkCom > 0) {
        const columnsToHide = lodash.range(60, 60 + numbOfWorkCom);

        setWorkCompetenceHiddenColumns(columnsToHide);
      } else {
        setWorkCompetenceHiddenColumns([]);
      }
    } else {
      setIsReferenceDataLoading(true);
    }
  }, [formalCompetenceDataHandler.data, workCompetenceDataHandler.data, employmentTypesDataHandler.data]);

  useEffect(() => {
    if (!isReferenceDataLoading) {
      const row = getDefaultSchema();

      const tableConfigs = tableConfigurator.getConfigs(
        formalCompetenceDataHandler.data,
        employmentTypesDataHandler.data,
        row,
        setIsTableDataEdited
      );

      setTableProps(tableConfigs);

      staffAllocationsDataHandler.fetch();
    }
  }, [isReferenceDataLoading, scenarioId, formalCompetenceDataHandler.data, employmentTypesDataHandler.data]);

  useEffect(() => {
    if (!isReferenceDataLoading && !staffAllocationsDataHandler.isLoading) {
      const data = convertToTableData();

      setTableData(data);
    }
  }, [staffAllocationsDataHandler.data]);

  useEffect(() => {
    const filterPlugin = table.current?.__hotInstance?.getPlugin("filters");

    if (filterPlugin && filterPlugin.enabled) {
      filterPlugin?.clearConditions();
    }

    if (
      selectedFormalCompetenceOptions.length > 0 &&
      selectedEmploymentTypeOptions.length > 0 &&
      selectedTabIndex == StaffTabIndex.GapAnalysis
    ) {
      const filteredFormalCompetences: string[] = [];
      const filteredEmploymentTypes: string[] = [];

      selectedFormalCompetenceOptions
        .map((e) => parseInt(e.id))
        .forEach((selectedWorkCompetence) => {
          const workCompetence = workCompetenceDataHandler.data.find((r) => r.id === selectedWorkCompetence);

          if (workCompetence && workCompetence.formalCompetenceShortName) {
            filteredFormalCompetences.push(workCompetence.formalCompetenceShortName);
          }
        });

      selectedEmploymentTypeOptions
        .map((op) => parseInt(op.id))
        .forEach((selectedEmploymentType) => {
          const employmentType = employmentTypesDataHandler.data.find((r) => r.id === selectedEmploymentType);

          if (employmentType) {
            filteredEmploymentTypes.push(employmentType.name);
          }
        });

      if (filterPlugin && filterPlugin.enabled) {
        filterPlugin?.addCondition(2, "by_value", [filteredFormalCompetences]);
        filterPlugin?.addCondition(3, "by_value", [filteredEmploymentTypes]);
      }
    }

    if (filterPlugin && filterPlugin.enabled) {
      filterPlugin?.filter();
    }
  }, [selectedFormalCompetenceOptions, selectedEmploymentTypeOptions]);

  const convertToTableData = (): StaffGridDynamicRowType[] => {
    const rows: StaffGridDynamicRowType[] = [];

    staffAllocationsDataHandler.data.forEach((item: StaffAvailabilityAllocation) => {
      const propertyNames: string[] = [
        ...workCompetenceDataHandler.data.map((item) => (item.id ? item.id.toString() : "")),
      ];

      const tableRow = createDynamicType(propertyNames, item);
      rows.push(tableRow);
    });

    return rows;
  };

  const convertTableDataToPayload = (): StaffModel[] => {
    const tableRecords = table.current?.hotInstance?.getSourceData();

    const payLoad: StaffModel[] = [];

    tableRecords?.forEach((row: StaffGridDynamicRowType) => {
      const formalCompetence = formalCompetenceDataHandler.data.find(
        (i) => i.shortName == row["formalCompetenceShortName"]
      );

      const staff = {} as StaffModel;

      staff.id = row["id"] ? parseInt(row["id"]?.toString()) : 0;
      staff.staffFirstName = row["staffFirstName"] ? row["staffFirstName"].toString() : "";

      staff.staffLastName = row["staffLastName"] ? row["staffLastName"].toString() : null;

      const employmentType = employmentTypesDataHandler.data.find((i) => i.name == row["employmentTypeName"]);

      if (employmentType) {
        staff.employmentTypeId = employmentType.id;
      }

      staff.workingHoursPerWeek = Number(row["workingHoursPerWeek"]);
      staff.employmentRate = Number(row["employmentRate"]);
      staff.weeksendsPerWeek = Number(row["weeksendsPerWeek"]);
      staff.comment = row["comment"] ? row["comment"].toString() : null;

      populateStaffAvailabilities(staff, row);

      if (formalCompetence) {
        staff.formalCompetenceId = formalCompetence.id;

        const allocations: StaffWorkCompetenceAllocationModel[] = [];

        formalCompetence.workCompetences.forEach((workCompetence) => {
          if (workCompetence.id) {
            if (row[`${workCompetence.id}`] && row[`${workCompetence.id}`] !== "") {
              const rowValue = row[`${workCompetence.id}`];
              let parsedValue: number = rowValue ? parseFloat(rowValue.toString()) : 0;

              if (Number.isNaN(parsedValue)) {
                parsedValue = 0;
              }

              allocations.push({
                staffAvailabilityId: row["id"] ? parseInt(row["id"]?.toString()) : 0,
                distribution: parsedValue,
                workCompetenceId: workCompetence.id,
              });
            }
          }
        });

        staff.staffWorkCompetenceAllocations = allocations;
      }

      payLoad.push(staff);
    });

    return payLoad;
  };

  const populateStaffAvailabilities = (staff: StaffModel, row: StaffGridDynamicRowType) => {
    staff.staffAvailability1 = Number(row["staffAvailability1"]);
    staff.staffAvailability2 = Number(row["staffAvailability2"]);
    staff.staffAvailability3 = Number(row["staffAvailability3"]);
    staff.staffAvailability4 = Number(row["staffAvailability4"]);
    staff.staffAvailability5 = Number(row["staffAvailability5"]);
    staff.staffAvailability6 = Number(row["staffAvailability6"]);
    staff.staffAvailability7 = Number(row["staffAvailability7"]);
    staff.staffAvailability8 = Number(row["staffAvailability8"]);
    staff.staffAvailability9 = Number(row["staffAvailability9"]);
    staff.staffAvailability10 = Number(row["staffAvailability10"]);
    staff.staffAvailability11 = Number(row["staffAvailability11"]);
    staff.staffAvailability12 = Number(row["staffAvailability12"]);
    staff.staffAvailability13 = Number(row["staffAvailability13"]);
    staff.staffAvailability14 = Number(row["staffAvailability14"]);
    staff.staffAvailability15 = Number(row["staffAvailability15"]);
    staff.staffAvailability16 = Number(row["staffAvailability16"]);
    staff.staffAvailability17 = Number(row["staffAvailability17"]);
    staff.staffAvailability18 = Number(row["staffAvailability18"]);
    staff.staffAvailability19 = Number(row["staffAvailability19"]);
    staff.staffAvailability20 = Number(row["staffAvailability20"]);
    staff.staffAvailability21 = Number(row["staffAvailability21"]);
    staff.staffAvailability22 = Number(row["staffAvailability22"]);
    staff.staffAvailability23 = Number(row["staffAvailability23"]);
    staff.staffAvailability24 = Number(row["staffAvailability24"]);
    staff.staffAvailability25 = Number(row["staffAvailability25"]);
    staff.staffAvailability26 = Number(row["staffAvailability26"]);
    staff.staffAvailability27 = Number(row["staffAvailability27"]);
    staff.staffAvailability28 = Number(row["staffAvailability28"]);
    staff.staffAvailability29 = Number(row["staffAvailability29"]);
    staff.staffAvailability30 = Number(row["staffAvailability30"]);
    staff.staffAvailability31 = Number(row["staffAvailability31"]);
    staff.staffAvailability32 = Number(row["staffAvailability32"]);
    staff.staffAvailability33 = Number(row["staffAvailability33"]);
    staff.staffAvailability34 = Number(row["staffAvailability34"]);
    staff.staffAvailability35 = Number(row["staffAvailability35"]);
    staff.staffAvailability36 = Number(row["staffAvailability36"]);
    staff.staffAvailability37 = Number(row["staffAvailability37"]);
    staff.staffAvailability38 = Number(row["staffAvailability38"]);
    staff.staffAvailability39 = Number(row["staffAvailability39"]);
    staff.staffAvailability40 = Number(row["staffAvailability40"]);
    staff.staffAvailability41 = Number(row["staffAvailability41"]);
    staff.staffAvailability42 = Number(row["staffAvailability42"]);
    staff.staffAvailability43 = Number(row["staffAvailability43"]);
    staff.staffAvailability44 = Number(row["staffAvailability44"]);
    staff.staffAvailability45 = Number(row["staffAvailability45"]);
    staff.staffAvailability46 = Number(row["staffAvailability46"]);
    staff.staffAvailability47 = Number(row["staffAvailability47"]);
    staff.staffAvailability48 = Number(row["staffAvailability48"]);
    staff.staffAvailability49 = Number(row["staffAvailability49"]);
    staff.staffAvailability50 = Number(row["staffAvailability50"]);
    staff.staffAvailability51 = Number(row["staffAvailability51"]);
    staff.staffAvailability52 = Number(row["staffAvailability52"]);
  };

  const createDynamicType = <T extends string[]>(
    propertyNames: T,
    staff: StaffAvailabilityAllocation
  ): StaffGridDynamicRowType => {
    const dynamicObject = {} as StaffGridDynamicRowType;

    dynamicObject["id"] = staff.id.toString();
    dynamicObject["staffFirstName"] = staff.staffFirstName;
    dynamicObject["staffLastName"] = staff.staffLastName ? staff.staffLastName : "";
    dynamicObject["formalCompetenceName"] = staff.formalCompetenceName;
    dynamicObject["formalCompetenceShortName"] = staff.formalCompetenceShortName;

    dynamicObject["employmentTypeName"] = staff.employmentTypeName ? staff.employmentTypeName : "";

    dynamicObject["workingHoursPerWeek"] = staff.workingHoursPerWeek != null ? staff.workingHoursPerWeek : null;

    dynamicObject["employmentRate"] = staff.employmentRate != null ? staff.employmentRate : null;

    dynamicObject["weeksendsPerWeek"] = staff.weeksendsPerWeek != null ? staff.weeksendsPerWeek : null;

    dynamicObject["comment"] = staff.comment;
    dynamicObject["employmentTypeId"] = staff.employmentTypeId;

    setStaffAvailability(dynamicObject, staff);

    propertyNames.forEach((propertyName) => {
      const allocation = staff.staffWorkCompetenceAllocations.find(
        (r) => r.workCompetenceId.toString() == propertyName
      );

      dynamicObject[propertyName] = allocation ? allocation.distribution : null;
    });

    return dynamicObject;
  };

  const setStaffAvailability = (dynamicObject: StaffGridDynamicRowType, staff: StaffAvailabilityAllocation) => {
    for (let i = 1; i <= 52; i++) {
      const value = staff[`staffAvailability${i}` as keyof typeof staff] as number;

      dynamicObject[`staffAvailability${i}`] = value != null ? value : null;
    }
  };

  const getDefaultSchema = (): StaffGridDynamicRowType => {
    return {
      id: 0,
      staffFirstName: "",
      staffLastName: null,
      formalCompetenceName: "",
      employmentTypeName: "",
      workingHoursPerWeek: 0,
      employmentRate: 0,
      weeksendsPerWeek: 0,
      comment: null,
      staffAvailability1: 1,
      staffAvailability2: 1,
      staffAvailability3: 1,
      staffAvailability4: 1,
      staffAvailability5: 1,
      staffAvailability6: 1,
      staffAvailability7: 1,
      staffAvailability8: 1,
      staffAvailability9: 1,
      staffAvailability10: 1,
      staffAvailability11: 1,
      staffAvailability12: 1,
      staffAvailability13: 1,
      staffAvailability14: 1,
      staffAvailability15: 1,
      staffAvailability16: 1,
      staffAvailability17: 1,
      staffAvailability18: 1,
      staffAvailability19: 1,
      staffAvailability20: 1,
      staffAvailability21: 1,
      staffAvailability22: 1,
      staffAvailability23: 1,
      staffAvailability24: 1,
      staffAvailability25: 1,
      staffAvailability26: 1,
      staffAvailability27: 1,
      staffAvailability28: 1,
      staffAvailability29: 1,
      staffAvailability30: 1,
      staffAvailability31: 1,
      staffAvailability32: 1,
      staffAvailability33: 1,
      staffAvailability34: 1,
      staffAvailability35: 1,
      staffAvailability36: 1,
      staffAvailability37: 1,
      staffAvailability38: 1,
      staffAvailability39: 1,
      staffAvailability40: 1,
      staffAvailability41: 1,
      staffAvailability42: 1,
      staffAvailability43: 1,
      staffAvailability44: 1,
      staffAvailability45: 1,
      staffAvailability46: 1,
      staffAvailability47: 1,
      staffAvailability48: 1,
      staffAvailability49: 1,
      staffAvailability50: 1,
      staffAvailability51: 1,
      staffAvailability52: 1,
    };
  };

  return (
    <>
      <ToastContainer style={{ width: "400px" }} position="top-right" hideProgressBar={true} closeButton={false} />
      <GridLayout additionalStyles="mt-0 px-0 min-h-full">
        <div className="col-span-12 flex flex-col">
          <div className="flex items-center justify-between">
            <div className="inline-block">
              <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} />
            </div>
            <div>
              {((!isCareUnitReadOnly && selectedTabIndex === StaffTabIndex.Staff) ||
                (!isCareUnitReadOnly && selectedTabIndex === StaffTabIndex.GapAnalysis) ||
                (!isCareUnitReadOnly && selectedTabIndex === StaffTabIndex.StaffCompetenceAllocation)) && (
                <Button
                  onClick={saveStaffAvailabilities}
                  text={translate("save_changes")}
                  disabled={!isTableDataEdited}
                  variant={"primary"}
                  additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                />
              )}
            </div>
          </div>

          <div className="mt-0 flex-1 rounded-b rounded-r border-2 bg-[#F8F8F8]">
            <div className="flex h-full flex-row">
              <div className={clsx("h-full ", getGridWidthClass())}>
                {(() => {
                  switch (selectedTabIndex) {
                    case StaffTabIndex.GapAnalysis:
                      if (careUnitId) {
                        return (
                          <div className={clsx(getGridHeightClass(), "p-4")}>
                            <StaffGapByWeekChart
                              careUnitId={careUnitId}
                              scenarioId={scenario}
                              fetchData={fetchStaffWeeklyChartData}
                              setFetchData={setFetchStaffWeeklyChartData}
                              workCompetences={selectedFormalCompetenceOptions.map((e) => parseInt(e.id))}
                              employmentTypes={selectedEmploymentTypeOptions.map((e) => parseInt(e.id))}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }

                    case StaffTabIndex.StaffCompetenceAllocation:
                      if (careUnitId) {
                        return (
                          <div className={clsx(getGridHeightClass(), "p-4")}>
                            <StaffGapByWorkCompetenceChart
                              careUnitId={careUnitId}
                              scenarioId={scenario}
                              fetchData={fetchStaffCompetenceChartData}
                              setFetchData={setFetchStaffCompetenceChartData}
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    default:
                      return null;
                  }
                })()}
                <div className={clsx("p-4", getGridHeightClass(), getPaddingTopClass())}>
                  {careUnitId ? (
                    <div className="relative h-full">
                      <SaveChangesConfirmation
                        isSaveChangesNotificationOpen={isSaveChangesNotificationOpen}
                        onLeftButtonClick={() => {
                          saveStaffAvailabilities();
                          blocker.reset && blocker.reset();
                        }}
                        onRightButtonClick={() => {
                          blocker.reset && blocker.proceed();
                        }}
                      />

                      <HotTable
                        language={
                          localStorage.getItem("i18nextLng") === "en" || localStorage.getItem("i18nextLng") === "en-US"
                            ? "en-US"
                            : "sv-SE"
                        }
                        {...tableProps}
                        data={tableData}
                        hiddenColumns={{
                          columns: hiddenColumns,
                          indicators: false,
                        }}
                        licenseKey="non-commercial-and-evaluation"
                      />
                      <DataTableAddButton dataTable={table} />
                    </div>
                  ) : null}
                </div>
              </div>
              {selectedTabIndex === StaffTabIndex.GapAnalysis ? (
                <div className="h-full w-[15%] px-4 pt-12">
                  <div className="w-full">
                    <MultiSelectDropDown
                      initialSelectedOptions={selectedFormalCompetenceOptions}
                      label={translate("competence")}
                      options={allFormalCompetenceOptions}
                      onValueChange={onFormalCompetenceDropdownValueChange}
                    />
                  </div>
                  {isFeatureFlagEnabled && (
                    <div className="w-full">
                      <MultiSelectDropDown
                        initialSelectedOptions={selectedEmploymentTypeOptions}
                        label={translate("EmploymentType")}
                        options={allEmploymentTypeOptions}
                        onValueChange={onEmploymentTypeDropdownValueChange}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </GridLayout>
    </>
  );
};

export default StaffHome;
