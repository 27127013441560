import { ChangeSource } from "handsontable/common";
import React from "react";
import { HotTableClass as HotTable } from "@handsontable/react";

import { ActivityAnalysisShiftSchema } from "../../../../interfaces/analytics/activity-analysis-shift-schema";
import { useTranslation } from "react-i18next";
import { useAfterCreateRowReturntype } from "../../../../interfaces/analytics/handsontable/use-aftercreate-row-return-type";

const useAfterCreateRow = (
  data: ActivityAnalysisShiftSchema[],
  dataTable: React.RefObject<HotTable | null>,
  setData: React.Dispatch<React.SetStateAction<ActivityAnalysisShiftSchema[]>>
): useAfterCreateRowReturntype => {
  const { t: translate } = useTranslation();

  const afterCreateRow = (
    index: number,
    amount: number,
    source: ChangeSource | undefined
  ) => {
    const row = index + 1;

    const timeSpent = `=((C${row}/60)*D${row}*E${row}*F${row})/52`;
    const fte = `=IF(H${row}=0,0,G${row}/H${row})`;

    const rowData = data[index];

    if (rowData.id !== "total_row") {
      dataTable.current?.hotInstance?.setSourceDataAtCell(index, 6, timeSpent);
      dataTable.current?.hotInstance?.setSourceDataAtCell(index, 8, fte);
      dataTable.current?.hotInstance?.setDataAtCell(index, 7, 40);
    }

    if (source === "ContextMenu.rowBelow") {
      const isTotalRowExists = data.find((row) => row.id === "total_row");

      const noOfRows = data?.length;

      if (!isTotalRowExists) {
        const newRow = {
          id: "total_row",
          activity: translate("total"),
          competenceGroupLvl2: "",
          timePerActivity: null,
          activitiesPerWeek: null,
          numberPresence: null,
          weeksPerYear: null,
          timeSpent: `=SUM(G1:G${noOfRows})`,
          weeklyWorkingHours: null,
          fte: `=SUM(I1:I${noOfRows})`,
        } as ActivityAnalysisShiftSchema;

        setData([...data, newRow]);
      }
    }
  };

  return { afterCreateRow };
};

export default useAfterCreateRow;
