import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import TabbarSecondary from "../../../components/tab-bar/tabbar-secondary/TabbarSecondary";
import { GridLayout } from "../../../layouts";
import useTabNavigation from "../../../common/hooks/navigation/useTabNavigation";
import { Outlet, useLocation } from "react-router-dom";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";

const AnalyticsHome = () => {
  const { t: translate } = useTranslation();
  const location = useLocation();

  const isActivityAnalysisIsEnabled = useFeatureFlagIsEnabled(FeatureFlags.ActivityAnalysis);

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.InpatientProductivityAnalysis);

  const isICDAnalysisIsEnabled = useFeatureFlagIsEnabled(FeatureFlags.ICDAnalysis);

  const [tabs, setTabs] = useState<string[]>([
    translate("ProductionVsPlan"),
    translate("no_of_care_beds"),
    translate("StaffingCostsVsBudget"),
    translate("DynamicReports"),
  ]);

  const [paths, setPaths] = useState<string[]>([
    "/analytics/production-vs-plan",
    "/analytics/carebeds-plan",
    "/analytics/staffing-costs-vs-budget",
    "/analytics/dynamic-reports",
  ]);

  useEffect(() => {
    let tabTitles = [
      translate("ProductionVsPlan"),
      translate("no_of_care_beds"),
      translate("StaffingCostsVsBudget"),
      translate("DynamicReports"),
    ];

    let tabPaths = [
      "/analytics/production-vs-plan",
      "/analytics/carebeds-plan",
      "/analytics/staffing-costs-vs-budget",
      "/analytics/dynamic-reports",
    ];

    if (isActivityAnalysisIsEnabled) {
      tabTitles.push(translate("activity_analysis"));
      tabPaths.push("/analytics/activity-analysis");
    }

    if (isICDAnalysisIsEnabled) {
      tabTitles.push(translate("icd_analysis"));
      tabPaths.push("/analytics/icd-analysis");
    }

    if (isFeatureFlagEnabled) {
      tabTitles = tabTitles.filter((tab) => tab !== translate("organizational_overview"));
      tabPaths = tabPaths.filter((path) => path !== "/analytics/organization-performance-review");

      const index = 3;
      tabTitles.splice(index, 0, translate("organizational_overview"));
      tabPaths.splice(index, 0, "/analytics/organization-performance-review");
    }

    setTabs(tabTitles);
    setPaths(tabPaths);
  }, [isFeatureFlagEnabled, isICDAnalysisIsEnabled, isActivityAnalysisIsEnabled]);

  const { selectedTabIndex, navigateToTab } = useTabNavigation(paths);

  const handleTabBarClick = (clickedIndex: number) => {
    navigateToTab(clickedIndex);
  };

  return (
    <GridLayout additionalStyles="mt-0">
      <div className="col-start-1 col-end-13 mt-0">
        <div className="flex items-center justify-between">
          <TabbarSecondary tabs={tabs} onClick={handleTabBarClick} selectedTabIndex={selectedTabIndex} />
        </div>

        <div
          className={clsx("mt-0 rounded-b rounded-r border-2 border-solid", {
            "min-h-[86vh]": location.pathname === "/analytics/icd-analysis",
            "h-[86vh]": location.pathname !== "/analytics/icd-analysis",
          })}
        >
          <Outlet />
        </div>
      </div>
    </GridLayout>
  );
};

export default AnalyticsHome;
