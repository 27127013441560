import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "../../../../components";
import { ISelectOption } from "../../../../interfaces";

interface MultiSelectListWithSearchProps {
  list: ISelectOption[];
  error: boolean;
  onItemsSelect?: (careunits: ISelectOption[]) => void;
  setSelectedItem?: (careunit: ISelectOption) => void;
  variant: "CARE_UNIT_USER" | "CARE_PROVIDER_READER";
  isMulti: boolean;
  title: string;
}

function MultiSelectListWithSearch({
  list,
  error,
  onItemsSelect,
  setSelectedItem,
  variant,
  isMulti,
  title,
}: MultiSelectListWithSearchProps) {
  const { t: translate } = useTranslation();
  const [items, setItems] = useState<ISelectOption[]>([]);
  const [filteredItems, setFilteredItems] = useState<ISelectOption[]>([]);

  useEffect(() => {
    setItems(list);
  }, [list]);

  useEffect(() => {
    setFilteredItems(list);
  }, [list]);

  const filterCareUnits = (filter: string) => {
    if (filter.length > 0) {
      const oldArr = items;

      const filteredArr = oldArr.filter((unit) => unit.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
      setFilteredItems(filteredArr);
    } else {
      setFilteredItems([]);
    }
  };

  const handleCareUnitSelection = (unitId: string) => {
    if (isMulti) {
      const newItemsState = items.map((item) => {
        return item.value === unitId ? { ...item, isSelected: item.isSelected ? false : true } : item;
      });

      const newFilteredItemsState = filteredItems.map((item) => {
        return item.value === unitId ? { ...item, isSelected: item.isSelected ? false : true } : item;
      });

      const selectedEntitites = newItemsState.filter((item) => {
        return item.isSelected === true;
      });

      onItemsSelect && onItemsSelect(selectedEntitites);
      setFilteredItems(newFilteredItemsState);
      setItems(newItemsState);
    } else {
      const selectedUnit = items.find((unit) => unit.value === unitId);

      if (selectedUnit) {
        setSelectedItem && setSelectedItem(selectedUnit);
      }
    }
  };

  // const searchLabel = variant === "CARE_UNIT_USER" ? translate("search_care_unit") : translate("search_care_provider");

  const errorMessage =
    variant === "CARE_UNIT_USER"
      ? translate("select_atleast_one_care_unit")
      : translate("select_atleast_one_care_provider");

  const displayedItems = filteredItems?.length > 0 ? filteredItems : items;

  return (
    <div className="ml-5 h-full w-auto">
      <Search name="search" placeholder={title} handleSearchValueChange={filterCareUnits} />
      <div className="mb-3">
        <h3>{title}</h3>
        {error && <span className="mb-5 text-paragraph-xl-3 text-red-600">{errorMessage}</span>}
      </div>
      <div className="h-full overflow-y-scroll">
        {displayedItems?.map((unit) => {
          return (
            <div className="mb-4 ml-2 mr-12 flex items-center" key={unit.value}>
              {variant === "CARE_UNIT_USER" ? (
                <input
                  onChange={() => handleCareUnitSelection(unit.value)}
                  checked={unit.isSelected ? true : false}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
              ) : (
                <>
                  <input
                    onChange={() => handleCareUnitSelection(unit.value)}
                    checked={unit.isSelected ? true : false}
                    type="radio"
                    className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  />
                </>
              )}
              <label className="ml-2 w-auto text-sm font-medium">{unit.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MultiSelectListWithSearch;
