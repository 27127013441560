import HttpCommonService from "./httpCommonService";

const EmploymentTypeService = {
  getEmploymentTypes: async () => {
    return await HttpCommonService.getAsync(`api/employmenttype`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default EmploymentTypeService;
