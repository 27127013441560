import React from "react";
import { HotTableClass as HotTable } from "@handsontable/react";
import { CellValue } from "handsontable/common";
import { UseBeforeValidateReturnType } from "../../../../interfaces/analytics/handsontable/use-beforevalidate-return-type";

const useBeforeValidate = (
  dataTable: React.RefObject<HotTable | null>
): UseBeforeValidateReturnType => {
  const beforeValidate = (
    value: CellValue,
    row: number
  ): string | number | undefined => {
    const noOfRows =
      dataTable.current?.hotInstance &&
      dataTable.current?.hotInstance?.countRows();

    if (noOfRows) {
      const lastRow = noOfRows - 1;

      if (row === lastRow) {
        return 0;
      }
    }
  };

  return { beforeValidate };
};

export default useBeforeValidate;
