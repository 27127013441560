import React, { useState } from "react";
import StaffAvailabilityService from "../../../../services/staffAvailabilityService";
import WeekStaffGap from "../../../../interfaces/competence/weekStaffGap";

interface ReturnType {
  data: WeekStaffGap[];
  fetch: (workCompetences: number[], employmentTypes: number[]) => Promise<void>;
  isLoading: boolean;
}

const useWeeksWithStaffingGaps = (careUnitId: number | null, scenario: number | null): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<WeekStaffGap[]>([]);

  const fetchData = async (workCompetences: number[], employmentTypes: number[]): Promise<void> => {
    if (careUnitId && scenario) {
      setIsLoading(true);

      await StaffAvailabilityService.getStaffGapsGroupedByWeeks(careUnitId, scenario, workCompetences, employmentTypes)
        .then((res) => {
          const responseData: WeekStaffGap[] = res.data;
          setData(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return {
    data,
    fetch: fetchData,
    isLoading,
  };
};

export default useWeeksWithStaffingGaps;
