import React from "react";

import { GridLayout } from "../../../../layouts";
import { AuthFormProps } from "../../interfaces/Props";

const AuthForm = ({ children, title, alignment }: AuthFormProps) => {
  return (
    <GridLayout additionalStyles="authform">
      <div className="authform__container">
        <article className="authform__layout">
          <div className={`${alignment === "center" && "authform__center"}`}>
            <div>
              {/* Width: 190px */}
              <img
                src="/images/logo/Evidence_Logo.svg"
                alt="Careopti Logo"
                className={`authform__logo ${
                  alignment === "center" && "authform__center"
                }`}
              />
            </div>
            <h1 className="authform__headline heading-2">{title}</h1>
            {children}
          </div>
        </article>
      </div>
    </GridLayout>
  );
};

export default AuthForm;
