import { AxiosResponse } from "axios";
import WorkCompetence from "../interfaces/competence/WorkCompetence";
import HttpCommonService from "./httpCommonService";

export const WorkCompetenceService = {
  getWorkComepetencesByCareUnit: async (careUnitId: number): Promise<AxiosResponse<WorkCompetence[]>> => {
    return await HttpCommonService.getAsync(`api/WorkCompetences/CareUnit/${careUnitId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  postWorkCompetences: async (careUnitId: number, data: WorkCompetence[]) => {
    const payload = {
      careUnitId: careUnitId,
      upsertWorkCompetences: data.map((r) => {
        return {
          name: r.name,
          shortName: r.shortName,
          formalCompetenceId: r.formalCompetenceId,
          id: r.id,
        };
      }),
    };

    return await HttpCommonService.postAsync("api/WorkCompetences/CareUnit", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};
