import { useEffect, useState } from "react";
import { OutPatientMultiSelectRequest } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { OutpatientPlanResultRequest } from "../../../../interfaces/production-plan/outpatient/outpatientPlanResultRequest";
import queueCuttingPlanService from "../../../../services/queueCuttingPlanService";

export const useGetQueueCuttingPlan = (
  careUnitId: number | null,
  scenarioId: number | null,
  productionYear: number | null,
  outpatientMultiSelectFilterRequest: OutPatientMultiSelectRequest | null
) => {
  const [queueCuttingPlanByFilters, setQueueCuttingPlanByFilters] = useState<number>(0);

  const fetchData = async (): Promise<void> => {
    if (
      outpatientMultiSelectFilterRequest &&
      outpatientMultiSelectFilterRequest.medicalSpecialtyIds.length > 0 &&
      careUnitId !== null &&
      careUnitId > 0 &&
      scenarioId !== null &&
      scenarioId > 0 &&
      productionYear !== null
    ) {
      const outpatientResultRequest: OutpatientPlanResultRequest = {
        careUnitId: careUnitId,
        productionYear: productionYear,
        scenarioId: scenarioId,
        professionIds:
          outpatientMultiSelectFilterRequest?.professionIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.professionIds,
        contactTypeIds:
          outpatientMultiSelectFilterRequest?.contactTypeIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.contactTypeIds,
        contactReasonGroupingIds:
          outpatientMultiSelectFilterRequest?.contactReasonGroupingIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.contactReasonGroupingIds,
        typesOfVisit:
          outpatientMultiSelectFilterRequest?.typesOfVisit[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.typesOfVisit,
        careTypeIds:
          outpatientMultiSelectFilterRequest?.careTypeIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.careTypeIds,
        typesOfEmergency:
          outpatientMultiSelectFilterRequest?.typesOfEmergency[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.typesOfEmergency,
        medicalSpecialtyIds:
          outpatientMultiSelectFilterRequest?.medicalSpecialtyIds[0] === -1
            ? []
            : outpatientMultiSelectFilterRequest?.medicalSpecialtyIds,
      };
      queueCuttingPlanService.getQueueCuttingPlan(outpatientResultRequest).then((res) => {
        setQueueCuttingPlanByFilters(res);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId, scenarioId, productionYear, outpatientMultiSelectFilterRequest]);

  return [queueCuttingPlanByFilters] as const;
};
