import React from "react";
import { Select } from "../../../../components";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "../../../../interfaces";
import { ProductionFilterProps } from "../../../../interfaces/productivity";

const ProductionFilters = ({
  typesOfInputs,
  selectedInput,
  handleTypesOfInputSelect,
}: ProductionFilterProps) => {
  const { t: translate, i18n } = useTranslation();
  return (
    <div>
      <Select
        placeholder={translate("type_of_input")}
        options={typesOfInputs}
        onSelectOption={handleTypesOfInputSelect}
        selectedOption={selectedInput}
      />
    </div>
  );
};

export default ProductionFilters;
