import React from "react";
import { CareBedsPlan } from "../features/analytics";
import DynamicReports from "../features/analytics/DynamicReports/DynamicReports";
import OrganizationalOverviewHome from "../features/analytics/OrganizationalOverview/OrganizationalOverviewHome";
import StaffCostsVsBudget from "../features/analytics/StaffCostsVsBudget/StaffCostsVsBudget";
import ICDAnalysisHome from "../features/analytics/ICDAnalysis/ICDAnalysisHome";
import ActivityAnalysisHome from "../features/analytics/ActivityAnalysis/ActivityAnalysisHome";
import ProductionVsPlanV2 from "../features/analytics/ProductionVsPlan/ProductionVsPlanV2";
import GlobalFilterReadyGuard from "./guards/GlobalFilterReadyGuard";

const AnalyticsRoutes = [
  {
    path: "production-vs-plan",
    element: (
      <GlobalFilterReadyGuard>
        <ProductionVsPlanV2 />
      </GlobalFilterReadyGuard>
    ),
  },
  {
    path: "carebeds-plan",
    element: <CareBedsPlan />,
  },
  {
    path: "staffing-costs-vs-budget",
    element: <StaffCostsVsBudget />,
  },
  {
    path: "organization-performance-review",
    element: <OrganizationalOverviewHome />,
  },
  {
    path: "dynamic-reports",
    element: <DynamicReports />,
  },
  {
    path: "activity-analysis",
    element: <ActivityAnalysisHome />,
  },
  {
    path: "icd-analysis",
    element: <ICDAnalysisHome />,
  },
];

export default AnalyticsRoutes;
