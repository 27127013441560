import React from "react";
import { useTranslation } from "react-i18next";

import { AuthForm } from "../../layouts";
import AuthService from "../../../../services/authService";
import { useNavigate, useParams } from "react-router-dom";

const ForgotPasswordConfirmation = () => {
  const nagivate = useNavigate();
  const { email } = useParams();
  const { t: translate } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const locale = localStorage.getItem("i18nextLng") || "";

    if (email)
      await AuthService.forgotPasswordAsync(email, locale)
        .then(() => {
          nagivate(`/auth/forgot-password/email-sent/${email}`);
        })
        .catch((err) => {
          throw err;
        });
  };

  return (
    <AuthForm title={translate("reset_password_link_sent")} alignment="center">
      <p className="p-1-v-1 mt-10">
        {translate("didn`t_receive_yet")}
        <span className="forgotpwconfirm-forgotpw__text link" onClick={handleSubmit}>
          {translate("resend_link")}
        </span>
      </p>
    </AuthForm>
  );
};

export default ForgotPasswordConfirmation;
