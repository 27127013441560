import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ChartData,
  DetailPlanProps,
} from "../../../../../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import { useTranslation } from "react-i18next";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";

const DetailPlanChart = ({ data, dateRange, isLoading }: DetailPlanProps) => {
  const { t: translate } = useTranslation();
  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    title: {
      text: undefined,
    },

    xAxis: {
      categories: dateRange,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
      labelFormatter: function (this: Highcharts.AxisLabelsFormatterCallbackFunction) {
        const maxDigits = 40;

        if (this.name.length <= maxDigits) {
          return this.name;
        }

        return `${this.name.substring(0, 40)}...`;
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (this.series.name === translate("moving_average_52_weeks_chart")) {
          const point = this.point as Highcharts.Point;

          const info = `${translate("moving_average_52_weeks")}: ${Math.round(point.y || 0)} <br /> ${translate(
            "resulting_annual_rate"
          )}: ${Math.round(Number(((point.y || 0) * 365) / 7))}`;

          return info;
        } else if (this.series.name === translate("carried_out_visits")) {
          const point = this.point as Highcharts.Point;

          const info = `${translate("carriedout_visits_52_weeks")}: ${Math.round(point.y || 0)} <br /> ${translate(
            "resulting_annual_rate"
          )}: ${Math.round(Number(((point.y || 0) * 365) / 7))}`;

          return info;
        } else {
          const point = this.point as Highcharts.Point;
          const series = this.series as Highcharts.Series;
          const tooltipInfo = `${series.name} <br /> ${Math.round(point.y as number)}`;
          return tooltipInfo;
        }
      },
    },

    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        pointPlacement: "between",
        stickyTracking: false,
        marker: {
          enabled: false,
        },
      },
      column: {
        stacking: "normal",
        minPointLength: 2,
      },
    },

    series: data.map((series: ChartData) => ({
      name: series.name,
      visible: true,
      data: series.data,
      color: series.color,
      type: series.type,
    })),
  };

  return isLoading ? (
    <div className="flex h-full items-center justify-center">
      <LoadingWithGrey sizeInPixel={40} />
    </div>
  ) : (
    <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
  );
};

export default DetailPlanChart;
