import { useEffect, useState } from "react";
import { WaitingListKPIs } from "../../../../interfaces/dashboard/waitinglist-kpi";
import { DashboardService } from "../../../../services";
import { WaitingListProps } from "../../../../interfaces/props/wating-list";

export const useFetchKPIs = ({ careUnitList }: WaitingListProps) => {
  const [waitingListData, setWatingListData] = useState<WaitingListKPIs>();

  const fetchData = async (): Promise<void> => {
    DashboardService.getWatingListKpis(careUnitList)
      .then((res: WaitingListKPIs) => {
        setWatingListData({
          above90: res.above90 ?? 0,
          returnVisitAfterGoalDate: res.returnVisitAfterGoalDate ?? 0,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [careUnitList]);

  return [waitingListData] as const;
};
