import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { TabbarProps } from "../../../interfaces/props";
import TabSecondary from "./tab-secondary/TabSecondary";

const TabbarSecondary = ({ tabs, onClick, selectedTabIndex }: TabbarProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleTabClick = (id: number) => {
    setSelectedIndex(id);
    onClick(id);
  };

  useEffect(() => {
    if (selectedTabIndex !== undefined) {
      setSelectedIndex(selectedTabIndex);
    }
  }, [selectedTabIndex]);

  return (
    <div className="tabbar-secondary">
      {tabs.map((tab, i) => (
        <TabSecondary
          key={uuid()}
          id={i}
          onClick={handleTabClick}
          title={tab}
          isSelected={false}
          selectedIndex={selectedIndex}
          isLastElement={tabs.length - 1 === i}
        />
      ))}
    </div>
  );
};

export default TabbarSecondary;
