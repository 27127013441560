import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TabbarSecondary from "../../../components/tab-bar/tabbar-secondary/TabbarSecondary";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import { CARE_UNIT_TYPES } from "../../../utils/constants/careUnitTypes";
import { Outlet } from "react-router-dom";
import useTabNavigation from "../../../common/hooks/navigation/useTabNavigation";

const ProductionHome = () => {
  const { t: translate } = useTranslation();
  const tabs = [
    translate("OutpatientPlan"),
    // translate("SurgeryPlan"),
    translate("InpatientPlan"),
  ];

  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const paths = [
    "/production/outpatient",
    // "/production/surgery",
    "/production/inpatient",
  ];

  const { navigateToTab, selectedTabIndex } = useTabNavigation(paths);

  useEffect(() => {
    if (CARE_UNIT_TYPES.INPATIENT === careUnitTypeId) {
      navigateToTab(1);
    } else if (CARE_UNIT_TYPES.OUTPATIENT === careUnitTypeId) {
      navigateToTab(0);
    } else {
      navigateToTab(0);
    }
  }, [careUnitTypeId]);

  const handleTabbarClick = (clickedIndex: number) => {
    navigateToTab(clickedIndex);
  };

  return (
    <GridLayout additionalStyles="mt-0">
      <div className="col-start-1 col-end-13 mt-0">
        <div className="flex items-center justify-between">
          <TabbarSecondary selectedTabIndex={selectedTabIndex} tabs={tabs} onClick={handleTabbarClick} />
        </div>
        <div className="mt-0 h-[86vh] rounded-b rounded-r border-2 border-solid">
          <Outlet />
        </div>
      </div>
    </GridLayout>
  );
};

export default ProductionHome;
