import React from "react";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

import { AxisLabelObject } from "../../../../interfaces/highcharts/AxisLabelObject";
import { ProductionOutcomeReponse, ProductionPlanPhasedResponse } from "../../../../interfaces/responses";
import { useMediaQuery } from "../../../../hooks";
import { SCREEN_SIZES } from "../../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../../utils/highcharts/common";
import { RecoveryPlanReponse } from "../../../../interfaces/responses/productionvsanalyticsresponse";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";
import { ISelectOption } from "../../../../interfaces";

interface ProductionVsPlanProps {
  selectedTypeOfPlan?: ISelectOption;
  chartTitle?: string;
  isPlacedOnDashboard?: boolean;
  areaChartData: ProductionPlanPhasedResponse;
  recoveryPlan: RecoveryPlanReponse[] | undefined;
  columnChartData: ProductionOutcomeReponse | undefined;
}

const ProductionVsPlanChart = ({
  selectedTypeOfPlan,
  chartTitle,
  isPlacedOnDashboard,
  areaChartData,
  columnChartData,
  recoveryPlan,
}: ProductionVsPlanProps) => {
  const { t: translate } = useTranslation();

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);

  const chartFontSize = getChartFontSize(isPlacedOnDashboard ? true : false, isLargeScreen);

  const generateLegendIcon = (name: string, isSeriesVisible: boolean) => {
    let imagePath = "";

    if (name === translate("plan")) {
      imagePath = "/images/circle.svg";
    } else if (name === translate("outcome")) {
      imagePath = "/images/circle-svg-colored.svg";
    } else if (name === translate("recovery_plan")) {
      imagePath = "/images/circle-svg-yellow.svg";
    }

    const imageDimension =
      name === translate("outcome") ? "width = '48px' height = '40px'>" : "width = '16px' height = '16px'>";

    const img: string =
      `<div class="highcharts-custom-legend ${isPlacedOnDashboard && "dashboard"} ${
        !isSeriesVisible && "highcharts-custom-selected"
      }"> <img src =` +
      imagePath +
      " " +
      imageDimension +
      "  " +
      name +
      "</div>";

    return img;
  };

  const areaChartOptions = {
    chart: {
      backgroundColor: "white",
      className: "product_vs_plan_chart",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: chartFontSize,
      },
    },

    title: {
      text: chartTitle,
      style: {
        fontSize: "20px",
      },
    },

    xAxis: {
      allowDecimals: false,
      max: 52,
      min: 1,
      startOnTick: false,
      tickInterval: 2,
      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },
    yAxis: {
      title: {
        text: Number(selectedTypeOfPlan?.value) === 1 ? translate("no_of_visits") : translate("no_of_care_episodes"),
        style: {
          fontSize: chartFontSize,
        },
      },

      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },

    legend: {
      useHTML: true,
      verticalAlign: "top",
      symbolWidth: 0,
      margin: 30,
      itemStyle: {
        fontSize: chartFontSize,
      },

      style: {
        fontSize: chartFontSize,
      },

      labelFormatter: function (this: AxisLabelObject): string | undefined {
        const name = this.name;

        const chart = this.chart;

        let isPlanSeriesVisible = false;
        let isOutcomeSeriesVisible = false;
        let isRecoveryPlanSeriesVisible = false;

        chart.series.forEach((s: Highcharts.Series) => {
          if (s.name === translate("plan") && s.visible) {
            isPlanSeriesVisible = true;
          } else if (s.name === translate("outcome") && s.visible) {
            isOutcomeSeriesVisible = true;
          } else if (s.name === translate("recovery_plan") && s.visible) {
            isRecoveryPlanSeriesVisible = true;
          }
        });

        if (name === translate("plan")) {
          return generateLegendIcon(name, isPlanSeriesVisible);
        } else if (name === translate("outcome")) {
          return generateLegendIcon(name, isOutcomeSeriesVisible);
        } else if (name === translate("recovery_plan")) {
          return generateLegendIcon(name, isRecoveryPlanSeriesVisible);
        }
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        let tooltipInfo = "";
        const point = this.point as Highcharts.Point;
        const series = this.series as Highcharts.Series;
        const otherSeriesData = series.chart.series
          .filter((s) => s.name !== series.name)
          .map((s) => s.data.filter((d) => d.x === point.x));

        if (this.series.name === translate("outcome") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo = `${translate("week")} ${point.x} <br /> ${translate("plan")} ${
            otherSeriesData[0][0].y
          } <br /> ${translate("outcome")} ${point.y}`;
        } else if (this.series.name === translate("recovery_plan") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo = `${translate("week")} ${point.x} <br /> ${translate("plan")} ${
            otherSeriesData[0][0].y
          } <br /> ${translate("recovery_plan")} ${point.y}`;
        }
        return tooltipInfo;
      },
    },

    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      column: {
        borderWidth: 0,
      },
    },

    series: [
      {
        type: "area",
        name: translate("plan"),
        data: areaChartData,
        color: "#BFBFBF",
        enableMouseTracking: false,
      },
      {
        type: "column",
        name: translate("outcome"),
        data: columnChartData?.outcome.map((outcome, index) => {
          return {
            y: outcome,
            color: columnChartData.isWithinLimit[index] ? "#4A6541" : "#BE2A23",
          };
        }),
        color: "#4A6541",
      },
      {
        type: "line",
        name: translate("recovery_plan"),
        data: recoveryPlan?.map((rp) => {
          return {
            y: rp.recoveryPlan,
            x: rp.weekId,
          };
        }),
        color: "#E5A32B",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="!h-full max-h-full">
      <HighchartsReact
        containerProps={{ style: { height: "100%" } }}
        highcharts={Highcharts}
        options={areaChartOptions}
      />
    </div>
  );
};

export default ProductionVsPlanChart;
