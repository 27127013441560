import { HotTableClass as HotTable } from "@handsontable/react";
import React from "react";
import { UseCellsReturnType } from "../../../../interfaces/analytics/handsontable/use-cells-return-type";

const useCells = (
  dataTable: React.RefObject<HotTable | null>
): UseCellsReturnType => {
  const cells = (row: number) => {
    const noOfRows = dataTable.current?.hotInstance?.countRows() || 0;
    const isLastRow = row === noOfRows - 1;

    const cellProperties = {
      readOnly: isLastRow,
      className: isLastRow
        ? "htRight activity-result-sub-total htMiddle"
        : "htMiddle",
    };

    return cellProperties;
  };

  return { cells };
};

export default useCells;
