import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import GroupCategories from "highcharts-grouped-categories";
GroupCategories(Highcharts);
HighchartsHeatmap(Highcharts);

import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import { AppInsightService, CapacityService } from "../../../services";
import {
  HeatMapData,
  PlannedCompetenceDataItem,
  PlannedCompPerHourHeatMapResponse,
  PlannedCompPerHourResponse,
} from "../../../interfaces/capacity";
import { AxiosResponse } from "axios";
import { MultiSelect } from "../../../components";
import { MultiSelectOption } from "../../../interfaces";
import {
  competence_shares,
  competence_shares_colours,
  hoursOfTheDay,
} from "../../../utils/highcharts/capacity/constants";
import { useTranslation } from "react-i18next";
import { HeatMap2Response, HeatMap3Response } from "../../../interfaces/capacity/heat-map-data-response";
import { HeatMap2Data } from "../../../interfaces/capacity/heatmap-data";
import { useFeatureFlagIsEnabled } from "../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../utils/constants/featureFlags";
import useGetFormalCompetenceData from "./hooks/useGetFormalCompetenceByCareUnit";

const Capacity = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "Capacity" });
  }, []);
  const { t: translate, i18n } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [heatMap1Data, setHeatMap1Data] = useState<HeatMapData>({
    name: undefined,
    data: [],
  });
  const [heatMap2Data, setHeatMap2Data] = useState<HeatMap2Data>({
    data: [],
  });
  const [heatMap3Data, setHeatMap3Data] = useState<HeatMap2Data>({
    data: [],
  });
  const [isHeatMapLoading, setIsHeatMapLoading] = useState<boolean>(false);
  const [isHeatMap2Loading, setIsHeatMap2Loading] = useState<boolean>(false);
  const [isHeatMap3Loading, setIsHeatMap3Loading] = useState<boolean>(false);
  const [isBarChartLoading, setIsBarChartLoading] = useState<boolean>(false);
  const [competenceBarChartSeries, setCompetenceBarChartSeries] = useState([]);

  const weekDays: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("Mon"),
      value: 1,
    },

    {
      id: uuid(),
      label: translate("Tue"),
      value: 2,
    },

    {
      id: uuid(),
      label: translate("Wed"),
      value: 3,
    },

    {
      id: uuid(),
      label: translate("Thu"),
      value: 4,
    },

    {
      id: uuid(),
      label: translate("Fri"),
      value: 5,
    },

    {
      id: uuid(),
      label: translate("Sat"),
      value: 6,
    },

    {
      id: uuid(),
      label: translate("Sun"),
      value: 7,
    },
  ];

  const competenceOptions: MultiSelectOption[] = [];

  const competence_share_options: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("ÖL"),
      value: "ÖL",
    },

    {
      id: uuid(),
      label: translate("BÖL"),
      value: "BÖL",
    },

    {
      id: uuid(),
      label: translate("SPEC"),
      value: "SPEC",
    },

    {
      id: uuid(),
      label: translate("ST+"),
      value: "ST+",
    },

    {
      id: uuid(),
      label: translate("ST"),
      value: "ST",
    },

    {
      id: uuid(),
      label: translate("RAND"),
      value: "RAND",
    },

    {
      id: uuid(),
      label: translate("LEG"),
      value: "LEG",
    },

    {
      id: uuid(),
      label: translate("AT"),
      value: "AT",
    },

    {
      id: uuid(),
      label: translate("UL"),
      value: "UL",
    },

    {
      id: uuid(),
      label: translate("SSK++"),
      value: "SSK++",
    },

    {
      id: uuid(),
      label: translate("SSK+"),
      value: "SSK+",
    },

    {
      id: uuid(),
      label: translate("SSK"),
      value: "SSK",
    },

    {
      id: uuid(),
      label: translate("SSK Natt"),
      value: "SSK Natt",
    },

    {
      id: uuid(),
      label: translate("USK+"),
      value: "USK+",
    },

    {
      id: uuid(),
      label: translate("USK"),
      value: "USK",
    },

    {
      id: uuid(),
      label: translate("USK Natt"),
      value: "USK Natt",
    },

    {
      id: uuid(),
      label: translate("Övrigt"),
      value: "Övrigt",
    },
  ];

  const competenceBarChartCategories = [
    {
      name: translate("Mon"),
      categories: hoursOfTheDay,
    },
    {
      name: translate("Tue"),
      categories: hoursOfTheDay,
    },
    {
      name: translate("Wed"),
      categories: hoursOfTheDay,
    },
    {
      name: translate("Thu"),
      categories: hoursOfTheDay,
    },
    {
      name: translate("Fri"),
      categories: hoursOfTheDay,
    },
    {
      name: translate("Sat"),
      categories: hoursOfTheDay,
    },
    {
      name: translate("Sun"),
      categories: hoursOfTheDay,
    },
  ];

  const daysOfWeek = [
    translate("Mon"),
    translate("Tue"),
    translate("Wed"),
    translate("Thu"),
    translate("Fri"),
    translate("Sat"),
    translate("Sun"),
  ];

  // Weekdays filter capacity
  const [weekdaySelectOptions] = useState<MultiSelectOption[]>(weekDays);
  const [selectedWeekDaySelectedOptions, setSelectedWeekDaySelectedOptions] = useState<MultiSelectOption[]>(weekDays);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  // Competence filter for heatmap
  const formalCompetences = useGetFormalCompetenceData(careUnitId !== null ? careUnitId : 0).data;

  const [competenceShareOptions, setCompetenceShareOptions] = useState<MultiSelectOption[]>(competenceOptions);

  const [selectedCompetenceShareOptions, setSelectedCompetenceShareOptions] =
    useState<MultiSelectOption[]>(competenceOptions);

  const tabs = [translate("Heatmap"), translate("PlannedCompetencePerHour")];

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.Heatmaps);

  const handleTabbarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  // Capacity Stackebarchart options
  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    title: {
      text: undefined,
    },

    xAxis: {
      categories: selectedWeekDaySelectedOptions.map((selection: any) => {
        return competenceBarChartCategories[selection.value - 1];
      }),

      labels: {
        // step: 1, // - Show all the labels in the x axis,
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      // Primary yAxis
      reversedStacks: false,
      title: {
        text: undefined,
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
      },
    },

    series: competenceBarChartSeries,
  };

  // Heatmap options
  const heatmap1Options = {
    chart: {
      type: "heatmap",
      marginTop: 80,
      marginBottom: 60,
      plotBorderWidth: 1,
      backgroundColor: "transparent",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: 16,
      },
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;

        const tooltipInfo = `
        ${daysOfWeek[point.x]} <br />
        ${point.y} <br /> 
        ${point.value}%`;
        return tooltipInfo;
      },
    },

    title: {
      text: translate("heatmap_1_title"),
      style: {
        fontSize: 16,
      },
    },

    xAxis: {
      categories: [
        translate("Mon"),
        translate("Tue"),
        translate("Wed"),
        translate("Thu"),
        translate("Fri"),
        translate("Sat"),
        translate("Sun"),
      ],
      opposite: true,
      labels: {
        style: {
          fontSize: 16,
        },
        rotation: 0,
      },
    },

    yAxis: {
      categories: hoursOfTheDay,
      title: null,
      reversed: true,
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    accessibility: {
      point: {},
    },

    colorAxis: {
      stops: [
        [0, "#7EA4CF"],
        [0.5, "#426FA2"],
        [1, "#00283C"],
      ],
      labels: {
        format: "{value}%",
      },
    },

    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
      style: {
        fontSize: 16,
      },
    },

    series: [
      {
        ...heatMap1Data,
        borderWidth: 1,
        borderColor: "#ADADAD",
        dataLabels: {
          enabled: true,
          color: "white",
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            return this.point.value + "%";
          },
          style: {
            textOutline: 0,
            fontWeight: "normal",
            fontSize: 12,
          },
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {},
            },
          },
        },
      ],
    },
  };

  const heatmap2Options = {
    chart: {
      type: "heatmap",
      marginTop: 80,
      marginBottom: 60,
      plotBorderWidth: 1,
      backgroundColor: "transparent",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: 16,
      },
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;

        const tooltipInfo = `
        ${daysOfWeek[point.x]} <br />
        ${point.y} <br /> 
        ${point.value}%`;
        return tooltipInfo;
      },
    },

    title: {
      text: translate("heatmap_2_title"),
      style: {
        fontSize: 16,
      },
    },

    xAxis: {
      categories: [
        translate("Mon"),
        translate("Tue"),
        translate("Wed"),
        translate("Thu"),
        translate("Fri"),
        translate("Sat"),
        translate("Sun"),
      ],
      opposite: true,
      labels: {
        style: {
          fontSize: 16,
        },
        rotation: 0,
      },
    },

    yAxis: {
      categories: hoursOfTheDay,
      title: null,
      reversed: true,
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    accessibility: {
      point: {},
    },

    colorAxis: {
      stops: [
        [0, "#7EA4CF"],
        [0.5, "#426FA2"],
        [1, "#00283C"],
      ],
      labels: {
        format: "{value}%",
      },
    },

    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
      style: {
        fontSize: 16,
      },
    },

    series: [
      {
        ...heatMap2Data,
        borderWidth: 1,
        borderColor: "#ADADAD",
        dataLabels: {
          enabled: true,
          color: "white",
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            return this.point.value + "%";
          },
          style: {
            textOutline: 0,
            fontWeight: "normal",
            fontSize: 12,
          },
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {},
            },
          },
        },
      ],
    },
  };

  const heatmap3Options = {
    chart: {
      type: "heatmap",
      marginTop: 80,
      marginBottom: 60,
      plotBorderWidth: 1,
      backgroundColor: "transparent",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: 16,
      },
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;

        const tooltipInfo = `
        ${daysOfWeek[point.x]} <br />
        ${point.y} <br /> 
        ${point.value}%`;
        return tooltipInfo;
      },
    },

    title: {
      text: translate("heatmap_3_title"),
      style: {
        fontSize: 16,
      },
    },

    xAxis: {
      categories: [
        translate("Mon"),
        translate("Tue"),
        translate("Wed"),
        translate("Thu"),
        translate("Fri"),
        translate("Sat"),
        translate("Sun"),
      ],
      opposite: true,
      labels: {
        style: {
          fontSize: 16,
        },
        rotation: 0,
      },
    },

    yAxis: {
      categories: hoursOfTheDay,
      title: null,
      reversed: true,
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    accessibility: {
      point: {},
    },

    //Do not remove comments inside stops
    colorAxis: {
      min: 0,
      max: 200,
      minColor: "#AD1E23",
      maxColor: "#00283C",
      stops: [
        [0.25, "#AD1E23"], // 50% Below mid point
        [0.425, "#FFF"], // 15% Below mid point
        [0.5, "#FFF"], // mid point == 100%
        [0.575, "#FFF"], // 15% Above mid point
        [0.75, "#426FA2"], // 50% Above mid point
        [1, "#00283C"],
      ],
      labels: {
        format: "{value}%",
      },
    },

    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
      style: {
        fontSize: 16,
      },
    },

    series: [
      {
        ...heatMap3Data,
        borderWidth: 1,
        borderColor: "#ADADAD",
        dataLabels: {
          enabled: true,
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            return this.point.value + "%";
          },
          style: {
            textOutline: 0,
            fontWeight: "normal",
            fontSize: 12,
          },
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {},
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    if (careUnitId !== null && selectedWeekDaySelectedOptions.length !== 0) {
      setIsBarChartLoading(true);

      const selectedWeekDays = selectedWeekDaySelectedOptions.map((day) => {
        return Number(day.value);
      });

      CapacityService.getPlannedCompPerHour(selectedWeekDays, careUnitId, scenario).then((res) => {
        const data = res.data.map((competence: PlannedCompPerHourResponse, i: number) => {
          return {
            name: competence.competenceName,
            data: competence.weeklyWorkingHours,
            color: competence_shares_colours[competence.competenceName],
          };
        });

        // Sort the array based on competence
        const sortedData = data.sort(
          (a: PlannedCompetenceDataItem, b: PlannedCompetenceDataItem) =>
            competence_shares.indexOf(a.name) - competence_shares.indexOf(b.name)
        );
        setCompetenceBarChartSeries(sortedData);
      });
      setIsBarChartLoading(false);
    }
  }, [careUnitId, selectedWeekDaySelectedOptions]);

  //heatmap 1 data loader
  useEffect(() => {
    if (careUnitId !== null && selectedCompetenceShareOptions.length > 0) {
      setIsHeatMapLoading(true);

      const competences = selectedCompetenceShareOptions.map((share) => {
        return String(share.value);
      });

      CapacityService.getWeeklyWorkingHoursHeatMapData(competences, careUnitId, scenario).then(
        (res: AxiosResponse<PlannedCompPerHourHeatMapResponse>) => {
          setHeatMap1Data(() => ({
            name: competences.toString(),
            data: res.data.weeklyWorkingHours,
          }));
        }
      );
      setIsHeatMapLoading(false);
    }
  }, [careUnitId, selectedCompetenceShareOptions]);

  useEffect(() => {
    setCompetenceShareOptions(formalCompetences);
    setSelectedCompetenceShareOptions(formalCompetences);
  }, [formalCompetences]);

  //heatmap 2 data loader
  useEffect(() => {
    if (careUnitId !== null && isFeatureFlagEnabled) {
      setIsHeatMap2Loading(true);

      CapacityService.getWeeklyWorkingHoursHeatMap2Data(careUnitId).then((res: AxiosResponse<HeatMap2Response>) => {
        setHeatMap2Data(() => ({
          data: res.data.workloadValues,
        }));
        if (res.data.workloadValues.length !== 0) {
          setIsHeatMap2Loading(false);
        }
      });
    }
  }, [careUnitId, isFeatureFlagEnabled]);

  //heatmap 3 data loader
  useEffect(() => {
    if (careUnitId !== null && selectedCompetenceShareOptions.length > 0 && isFeatureFlagEnabled) {
      setIsHeatMap3Loading(true);

      const competences = selectedCompetenceShareOptions.map((share) => {
        return String(share.value);
      });

      CapacityService.getWeeklyWorkingHoursOverWeelyLoadHeatMapData(competences, careUnitId, scenario).then(
        (res: AxiosResponse<HeatMap3Response>) => {
          setHeatMap3Data(() => ({
            data: res.data.heatMapThreeResults,
          }));
          if (res.data.heatMapThreeResults.length !== 0) {
            setIsHeatMap3Loading(false);
          }
        }
      );
    }
  }, [careUnitId, selectedCompetenceShareOptions, scenario, isFeatureFlagEnabled]);

  const sortWeekDays = (weekdaysToSort: MultiSelectOption[]) => {
    weekdaysToSort.sort((a: MultiSelectOption, b: MultiSelectOption) => {
      return weekDays.indexOf(a) - weekDays.indexOf(b);
    });

    return weekdaysToSort;
  };

  const sortCompetences = (competencesToSort: MultiSelectOption[]) => {
    competencesToSort.sort((a: MultiSelectOption, b: MultiSelectOption) => {
      return competence_share_options.indexOf(a) - competence_share_options.indexOf(b);
    });

    return competencesToSort;
  };

  const handleWeekDaySelect = (option: MultiSelectOption) => {
    const selectedWeekdays = [...selectedWeekDaySelectedOptions, option];
    setSelectedWeekDaySelectedOptions(sortWeekDays(selectedWeekdays));
  };

  const handleWeekDayDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedWeekDaySelectedOptions.length > 1) {
      setSelectedWeekDaySelectedOptions(() => {
        return selectedWeekDaySelectedOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        });
      });
    }
  };

  const handleHeatMapCompetenceSelect = (option: MultiSelectOption) => {
    const selectedCompetences = [...selectedCompetenceShareOptions, option];
    setSelectedCompetenceShareOptions(sortCompetences(selectedCompetences));
  };

  const handleHeatMapCompetenceDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedCompetenceShareOptions.length > 1) {
      setSelectedCompetenceShareOptions(
        selectedCompetenceShareOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        })
      );
    }
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} />
          {selectedTabIndex === 0 && (
            <div className="w-80">
              <MultiSelect
                label={translate("competence")}
                selectedOptions={selectedCompetenceShareOptions}
                options={competenceShareOptions}
                onSelectItem={handleHeatMapCompetenceSelect}
                onRemoveSelectItem={handleHeatMapCompetenceDeselect}
              />
            </div>
          )}

          {selectedTabIndex === 1 && (
            <div className="w-80">
              <MultiSelect
                label={translate("weekday")}
                selectedOptions={selectedWeekDaySelectedOptions}
                options={weekdaySelectOptions}
                onSelectItem={handleWeekDaySelect}
                onRemoveSelectItem={handleWeekDayDeselect}
              />
            </div>
          )}
        </div>
        {selectedTabIndex === 0 && (
          <div className="mt-0 w-full bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
            <div className="flex h-full">
              {!isHeatMapLoading && (
                <div className="w-1/3">
                  <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={heatmap1Options}
                  />
                </div>
              )}
              {!isHeatMap2Loading && isFeatureFlagEnabled ? (
                <div className="w-1/3">
                  <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={heatmap2Options}
                  />
                </div>
              ) : null}
              {!isHeatMap3Loading && isFeatureFlagEnabled ? (
                <div className="w-1/3">
                  <HighchartsReact
                    containerProps={{ style: { height: "100%" } }}
                    highcharts={Highcharts}
                    options={heatmap3Options}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
        {selectedTabIndex === 1 && !isBarChartLoading && (
          <div className="mt-0 bg-[#F8F8F8] px-2 py-2 lg:h-[79vh] xl:h-[80vh]">
            <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
          </div>
        )}
      </div>
    </GridLayout>
  );
};

export default Capacity;
