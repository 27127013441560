import { AxiosResponse } from "axios";
import FormalCompetence from "../interfaces/competence/FormalCompetence";
import HttpCommonService from "./httpCommonService";

export const FormalCompetenceService = {
  getFormalCompetences: async (): Promise<AxiosResponse<FormalCompetence[]>> => {
    return await HttpCommonService.getAsync(`api/FormalCompetences`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getFormalCompetencesByCareUnit: async (careUnitId: number): Promise<AxiosResponse<FormalCompetence[]>> => {
    return await HttpCommonService.getAsync(`api/FormalCompetences/${careUnitId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};
