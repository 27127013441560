import { Middleware } from "redux";
import { RootState, setCareUnitReadOnly } from "../store";

export const careUnitPermissionMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (action.type === "globalFilter/setFilterCareUnit" || action.type === "globalFilter/setCareProviderId") {
    const state: RootState = storeAPI.getState();

    const role = state.auth.role;
    const careUnitId = state.globalFilter.filterCareUnit;
    const userOrgs = state.auth.orgIds;

    if (role === "Care Unit User" && careUnitId) {
      const isCUWriteAccess = userOrgs && userOrgs.includes(careUnitId);
      storeAPI.dispatch(setCareUnitReadOnly(!isCUWriteAccess));
    } else if (role === "Global Admin") {
      storeAPI.dispatch(setCareUnitReadOnly(false));
    }
  }

  return result;
};
