import React from "react";
import { useTranslation } from "react-i18next";

const useStaffGapByWeekChartConfigurator = (
  isEmploymentTypeEnabled: boolean,
  isNoOfWeekendShiftsEnabled: boolean
): {
  getOptions: (
    weeks: string[],
    available: number[],
    target: number[],
    fteOhh: number[],
    noOfWeekendShifts: number[]
  ) => any;
} => {
  const { t: translate } = useTranslation();

  const getOptions = (
    weeks: string[],
    available: number[],
    target: number[],
    fteOhh: number[],
    noOfWeekendShifts: number[]
  ) => {
    const options = {
      chart: {
        backgroundColor: "transparent",
        style: {
          fontFamily: "Open Sans",
          textOutline: 0,
          fontSize: 14,
        },
      },
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: translate("week"),
        },
        categories: weeks,
        labels: {
          style: {
            fontSize: 14,
          },
        },
      },
      yAxis: {
        title: {
          text: translate("FTE"),
        },

        labels: {
          style: {
            fontSize: 14,
          },
        },
      },

      legend: {
        verticalAlign: "top",
        itemStyle: {
          fontSize: 14,
        },
        style: {
          fontSize: 14,
        },
      },

      tooltip: {
        shared: false,
        formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
          const point = this.point as Highcharts.Point;
          const series = this.series as Highcharts.Series;

          const otherSeriesData = series.chart.series
            .filter((s) => s.name !== series.name)
            .map((s) => s.data.filter((d) => d.x === point.x));

          const tooltipInfo = `<b>${translate("week")} ${point.x + 1}</b>
            <br /> 
              ${isEmploymentTypeEnabled ? translate("out_of_hours_compensation") + " : " : ""} 
              ${otherSeriesData[0][0] !== undefined ? otherSeriesData[0][0].y : ""}
            <br /> 
              ${translate("competence_need")} : 
              ${otherSeriesData[1][0] !== undefined ? otherSeriesData[1][0].y : ""} 
            <br /> 
              ${translate("competence_available") + " : "} ${point.y}
            <br />
              ${
                isNoOfWeekendShiftsEnabled ? translate("weekend_shifts_need") + " : " + noOfWeekendShifts[point.x] : ""
              }`;

          return tooltipInfo;
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick(this: Highcharts.Series, e: Highcharts.ChartClickEventObject) {
              e.preventDefault();
              const chart = this.chart;

              chart.series.forEach((s: Highcharts.Series) => {
                if (s !== this && s.visible) {
                  s.hide();
                } else {
                  s.show();
                }
              });
            },
          },
        },
        area: {
          stacking: "normal",
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        column: {
          borderWidth: 0,
        },
      },
      series: [
        {
          type: "area",
          name: isEmploymentTypeEnabled ? translate("out_of_hours_compensation") : "",
          data: isEmploymentTypeEnabled ? fteOhh : [],
          color: isEmploymentTypeEnabled ? "#BFBFBF" : "#FFFFFF",
          enableMouseTracking: false,
        },
        {
          type: "area",
          name: translate("competence_need"),
          data: target,
          color: "#747474",
          enableMouseTracking: false,
        },
        {
          type: "column",
          name: translate("competence_available"),
          data: available,
          color: "#2f4f74",
        },
      ],
    };

    return options;
  };

  return { getOptions };
};

export default useStaffGapByWeekChartConfigurator;
