import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActivityAnalysisResultsType } from "../../../../../interfaces/analytics/activity-analysis-results";
import ActivityAnalysisService from "../../../../../services/activityAnalysisService";

interface ReturnGetActivityResultsData<T> {
  data: T;
  fetch(): void;
}

const useGetActivityResultsData = (
  careUnitId: number,
  scenarioId: number,
  year: number
): ReturnGetActivityResultsData<ActivityAnalysisResultsType[]> => {
  const [activityAnalysisShifts, setActivityAnalysisResults] = useState<ActivityAnalysisResultsType[]>([]);
  const { t: translate, i18n } = useTranslation();

  const fetchData = async (): Promise<void> => {
    ActivityAnalysisService.getActivityAnalysisResults(careUnitId, year, scenarioId)
      .then((response) => {
        const data: ActivityAnalysisResultsType[] = response.data.map((production: ActivityAnalysisResultsType) => {
          return {
            type: production.type,
            workCompetenceId: production.workCompetenceId,
            workCompetenceName: production.workCompetenceName,
            fte: production.fte,
            hours: production.hours,
            productionType: production.productionType,
          };
        });

        setActivityAnalysisResults([...data]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId, scenarioId, year]);

  return { data: activityAnalysisShifts, fetch: fetchData };
};

export default useGetActivityResultsData;
