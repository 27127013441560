import * as Highcharts from "highcharts";
import HighChartExport from "highcharts/modules/exporting";
import HighChartExportData from "highcharts/modules/export-data";

import { setNumbroLocale } from "../utils";

import { useFeatureFlags } from "./useFeatureFlags";

export const useGlobalConfig = (): { isLoading: boolean } => {
  const { isLoading: isFeatureFlagsLoading } = useFeatureFlags();

  setNumbroLocale("sv-SE");

  // useEffect(() => {
  //   if (!isFeatureFlagEnabled && location.pathname !== "/" && selectedCareUnits.length > 0) {
  //     dispatch(setSelectedCareUnits([]));
  //   }
  // }, [location.pathname, isFeatureFlagEnabled]);

  // useEffect(() => {
  //   if (loginState === true) {
  //     const storageCareUnitId = localStorage.getItem(ACTIVE_ORG_CARE_UNIT_ID);
  //     const storagedCareUnitLabel = localStorage.getItem(ACTIVE_ORG_CARE_UNIT_LABEL);
  //     const storageCareUnitTypeId = localStorage.getItem(ACTIVE_ORG_CARE_UNIT_TYPE_ID);

  //     const storagedDashboardCareUnitsList = localStorage.getItem(ACTIVE_DASHBOARD_CARE_UNITS_LIST);
  //     const storagedParentIds = localStorage.getItem(ACTIVE_ORG_PARENT_CARE_UNIT_IDS);
  //     const storagedChildIds = localStorage.getItem(ACTIVE_ORG_PARENT_CARE_UNIT_IDS);

  //     if (storageCareUnitId && Number(storageCareUnitId) > 0) {
  //       dispatch(
  //         setCareUnit({
  //           careUnitId: Number(storageCareUnitId),
  //           careUnitLabel: storagedCareUnitLabel,
  //           careUnitTypeId: Number(storageCareUnitTypeId),
  //           parentCareUnitIds: storagedParentIds ? JSON.parse(storagedParentIds) : [],
  //           childCareUnitIds: storagedChildIds ? JSON.parse(storagedChildIds) : [],
  //         })
  //       );
  //     }

  //     if (storagedDashboardCareUnitsList) {
  //       dispatch(setSelectedCareUnits(JSON.parse(storagedDashboardCareUnitsList)));
  //     }
  //   }
  // }, [isFeatureFlagEnabled, loginState]);

  HighChartExport(Highcharts);
  HighChartExportData(Highcharts);

  Highcharts.setOptions({
    xAxis: {
      lineColor: "#e6e6e6",
      tickColor: "#e6e6e6",
    },
    legend: {
      itemStyle: {
        fontWeight: "bold",
      },
      itemHiddenStyle: {
        "text-decoration": "none",
      },
    },
  });

  let highchartsTheme = Highcharts.theme;

  highchartsTheme = {
    ...highchartsTheme,
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };

  Highcharts.setOptions(highchartsTheme);

  return { isLoading: isFeatureFlagsLoading };
};

export default useGlobalConfig;
