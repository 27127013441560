import React from "react";
import { IconButtonProps } from "../../interfaces/props";
import "./save-icon-button.css";
import SaveIcon from "../icons/save-icon/save-icon";

const SaveIconButton = ({ onClick, disabled }: IconButtonProps) => {
  return (
    <button
      className={disabled ? "save-icon-button--disabled" : "save-icon-button"}
      onClick={onClick}
      disabled={disabled}
    >
      <SaveIcon width={16} height={16} />
    </button>
  );
};

export default SaveIconButton;
