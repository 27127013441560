import React, { useEffect, useState } from "react";
import { ISelectOption } from "../../../../interfaces";
import MultiSelectListWithSearch from "../../../user-management/components/care-unit-search/care-unit-search";
import Drawer from "../Drawer/Drawer";
import { CareUnitDataSourceDrawerProps } from "../../../../interfaces/props/careUnitDataSourceDrawerProps";

const CareUnitDataSourceDrawer = ({
  isDataSourceDrawerOpen,
  setIsDataSourceDrawerOpen,
  onDataSourcesSelect,
  dataSources,
}: CareUnitDataSourceDrawerProps) => {
  const [selectableDataSources, setSelectableDataSources] = useState<ISelectOption[]>([]);
  const [selectedDataSources, setSelectedDataSources] = useState<ISelectOption[]>([]);

  const setSelectedCareUnitsForSubmit = (option: ISelectOption[]) => {
    setSelectedDataSources(option);
    onDataSourcesSelect(option);
  };

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    dataSources.sort((a, b) => {
      if (a.isAssign === b.isAssign) {
        return 0;
      }
      return a.isAssign ? -1 : 1;
    });

    const options: ISelectOption[] = dataSources.map((dataSource) => {
      return {
        label: `${dataSource.uniqueIdentifier} - ${dataSource.name}`,
        value: String(dataSource.id),
        isSelected: dataSource.isAssign,
      };
    });

    const selectedDatasources: ISelectOption[] = dataSources
      .filter((datasource) => datasource.isAssign === true)
      .map((dataSource) => {
        return {
          label: `${dataSource.uniqueIdentifier} - ${dataSource.name}`,
          value: String(dataSource.id),
          isSelected: dataSource.isAssign,
        };
      });

    setSelectedDataSources(selectedDatasources);
    onDataSourcesSelect(selectedDatasources);
    setSelectableDataSources(options);
  }, [dataSources]);

  return (
    <Drawer
      onClose={() => {
        setIsDataSourceDrawerOpen(false);
      }}
      isOpen={isDataSourceDrawerOpen}
    >
      <MultiSelectListWithSearch
        variant="CARE_UNIT_USER"
        error={error}
        list={selectableDataSources}
        onItemsSelect={setSelectedCareUnitsForSubmit}
        isMulti={true}
        title="Select Data Sources"
      />
    </Drawer>
  );
};

export default CareUnitDataSourceDrawer;
