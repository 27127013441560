import HttpCommonService from "./httpCommonService";

const WaterfallKpiService = {
  getWaterfallKPIs: async (id: number | null, careUnitTypeId: number | null, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/waterfallkpi/${id}/${careUnitTypeId}/${year}/${scenario}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getWaterfallOutPatientKPIs: async (careUnitIds: number[] | null, year: number, scenarioId: number) => {
    return await HttpCommonService.postAsync(`api/waterfallkpi/outpatient`, {
      careUnitIds,
      year,
      scenarioId,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getWaterfallInPatientKPIs: async (careUnitIds: number[] | null, year: number, scenarioId: number) => {
    return await HttpCommonService.postAsync(`api/waterfallkpi/inpatient`, {
      careUnitIds,
      year,
      scenarioId,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default WaterfallKpiService;
