import { ColumnResizeConfigReturntype } from "../../../../interfaces/analytics/handsontable/use-modify-col-width-return-type";

const useModifyColumnWidth = (
  tableName: string
): ColumnResizeConfigReturntype => {
  const modifyColWidth = (width: number, col: number) => {
    const newColumnsWidths: number[] = localStorage.getItem(tableName)
      ? JSON.parse(localStorage.getItem(tableName) as string)
      : {};

    if (newColumnsWidths[col]) {
      return newColumnsWidths[col];
    }

    return width;
  };

  const afterColumnResize = (newSize: number, col: number): void => {
    let newColumnsWidths: number[] = localStorage.getItem(tableName)
      ? JSON.parse(localStorage.getItem(tableName) as string)
      : {};
    newColumnsWidths = {
      ...newColumnsWidths,
      [col]: newSize,
    };

    localStorage.setItem(tableName, JSON.stringify(newColumnsWidths));
  };

  return { afterColumnResize, modifyColWidth };
};

export default useModifyColumnWidth;
