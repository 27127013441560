import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button, Input, LoadingWithColor } from "../../../components";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";
import { ChangePassword, CreatePassword, ResetPassword } from "../../../interfaces/user-management";
import { RootState } from "../../../redux/store";
import UserManagementService from "../../../services/userManagementService";
import { ChangePasswordForm } from "../layouts";
import { PasswordOperation, PasswordSetStatus } from "../utils/change-password";

interface PasswordProps {
  type: PasswordOperation;
}

const ChangePasswordHome = ({ type }: PasswordProps) => {
  const { t: translate } = useTranslation();
  const [successNotification, setSuccessNotification] = useState("");
  const [errorNotification, setErrorNotification] = useState("");

  //used for the loading icon status
  const [isAddingEntity, setIsAddingEntity] = useState<boolean>(false);

  const [isInitialOldPasswordState, setIsInitialOldPasswordState] = useState<boolean>(true);
  const [isInitialPasswordState, setIsInitialPasswordState] = useState<boolean>(true);
  const [isInitialPasswordConfirmState, setIsInitialPasswordConfirmState] = useState<boolean>(true);
  const [isPasswordSetStatus, setIsPasswordSetStatus] = useState<PasswordSetStatus>(PasswordSetStatus.Undefined);

  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [oldPasswordErrors, setOldPasswordErrors] = useState<string[]>([]);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [passwordConfirmErrors, setPasswordConfirmErrors] = useState<string[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const loginState = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [token, settoken] = useState<string>("");
  const [code, setCode] = useState<string>("");

  useEffect(() => {
    const pathnames = window.location.pathname.split("/");
    const userId = pathnames.at(-1) || "";
    const token = searchParams.get("code") || "";

    setUserId(userId);
    settoken(token);

    if (type === PasswordOperation.reset) {
      const code = window.location.href.split("?code=")[1];
      setCode(code);
    }
  }, []);

  useEffect(() => {
    if (successNotification !== "") {
      const timer = setTimeout(() => {
        setSuccessNotification("");
        if (isPasswordSetStatus === PasswordSetStatus.Success) {
          navigate("/auth/login");
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successNotification]);

  useEffect(() => {
    if (errorNotification !== "") {
      const timer = setTimeout(() => {
        setErrorNotification("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorNotification]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsAddingEntity(true);

    if (type === PasswordOperation.set) {
      await createUserPassword();
    } else if (type === PasswordOperation.change) {
      await changeUserPassword();
    } else if (type === PasswordOperation.reset) {
      await ResetPassword();
    }
  };

  const ResetPassword = async () => {
    const passwordPayload: ResetPassword = {
      userId: userId,
      token: token,
      newPassword: password,
    };

    await UserManagementService.ResetPasswordAsync(passwordPayload)
      .then((res) => {
        setIsAddingEntity(false);
        setIsPasswordSetStatus(PasswordSetStatus.Success);
        setSuccessNotification && setSuccessNotification("password");
      })
      .catch((err) => {
        setIsAddingEntity(false);
        setIsPasswordSetStatus(PasswordSetStatus.Failed);
        setErrorNotification && setErrorNotification("password");
        throw err;
      });
  };

  const createUserPassword = async () => {
    const passwordPayload: CreatePassword = {
      userId: userId,
      token: token,
      password,
    };

    await UserManagementService.createPasswordAsync(passwordPayload)
      .then((res) => {
        setIsAddingEntity(false);
        setIsPasswordSetStatus(PasswordSetStatus.Success);
        setSuccessNotification && setSuccessNotification("password");
      })
      .catch((err) => {
        setIsAddingEntity(false);
        setIsPasswordSetStatus(PasswordSetStatus.Failed);
        setErrorNotification && setErrorNotification("password");
        throw err;
      });
  };

  const changeUserPassword = async () => {
    if (loginState.user) {
      const passwordPayload: ChangePassword = {
        userId: loginState.user.id,
        oldPassword,
        newPassword: password,
      };

      await UserManagementService.changePasswordAsync(passwordPayload)
        .then((res) => {
          if (res === true) {
            setIsAddingEntity(false);
            setIsPasswordSetStatus(PasswordSetStatus.Success);
            setSuccessNotification && setSuccessNotification("password");
          } else {
            setIsAddingEntity(false);
            setIsPasswordSetStatus(PasswordSetStatus.Failed);
            setErrorNotification && setErrorNotification("password");
          }
        })
        .catch((err) => {
          setIsAddingEntity(false);
          setIsPasswordSetStatus(PasswordSetStatus.Failed);
          setErrorNotification && setErrorNotification("password");
          throw err;
        });
    }
  };

  const onChangeOldPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInitialOldPasswordState) {
      setIsInitialOldPasswordState(false);
    }
    const { value } = e.target;
    // oldPasswordValidator(value);

    setOldPassword(value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInitialPasswordState) {
      setIsInitialPasswordState(false);
    }
    const { value } = e.target;
    passwordValidator(value);

    setPassword(value);
  };

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInitialPasswordConfirmState) {
      setIsInitialPasswordConfirmState(false);
    }
    const { value } = e.target;
    passwordConfirmValidator(value);

    setConfirmPassword(value);
  };

  const oldPasswordValidator = (value: string) => {
    const errors = validatePassword(value);
    setOldPasswordErrors(errors);
  };

  const passwordValidator = (value: string) => {
    const errors = validatePassword(value);
    setPasswordErrors(errors);
  };

  const validatePassword = (value: string) => {
    const errors = [];

    if (!new RegExp("(?=.*[A-Z])").test(value)) {
      errors.push(translate("uppercase_letter"));
    }

    if (!new RegExp("(?=.*[a-z])").test(value)) {
      errors.push(translate("lowercase_letter"));
    }

    if (!new RegExp("(?=.*\\d)").test(value)) {
      errors.push(translate("a_number"));
    }
    //this reges should be changed
    if (!new RegExp("([^A-Za-z0-9])").test(value)) {
      errors.push(translate("special_character"));
    }

    if (!(value.length >= 8 || value === "")) {
      errors.push(translate("8_characters"));
    }

    return errors;
  };

  const passwordConfirmValidator = (value: string) => {
    const errors = [];
    if (value !== password) {
      errors.push(translate("password_mismatch"));
    }
    setPasswordConfirmErrors(errors);
  };

  const getTitle = (operationType: PasswordOperation) => {
    if (operationType === PasswordOperation.set) {
      return translate("");
    } else if (operationType === PasswordOperation.reset) {
      return translate("reset_password");
    } else {
      return translate("change_your_password");
    }
  };

  const getSubmitButtonText = (operationType: PasswordOperation) => {
    const title =
      operationType === PasswordOperation.set
        ? translate("complete")
        : operationType === PasswordOperation.change
        ? translate("change_password")
        : translate("reset_password");

    return title;
  };

  const getSubmitButtonDisableStatus = (operationType: PasswordOperation) => {
    if (operationType === PasswordOperation.set || operationType === PasswordOperation.reset) {
      if (
        isInitialPasswordState ||
        isInitialPasswordConfirmState ||
        isAddingEntity ||
        passwordErrors.length > 0 ||
        passwordConfirmErrors.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        isInitialOldPasswordState ||
        isInitialPasswordState ||
        isInitialPasswordConfirmState ||
        isAddingEntity ||
        passwordErrors.length > 0 ||
        passwordConfirmErrors.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <ChangePasswordForm operationType={type} title={getTitle(type)}>
      {isAddingEntity ? (
        <>
          <div className="empty-container" />
          <LoadingWithColor sizeInPixel={50} additionalStyles="change-password-loader" />
        </>
      ) : isPasswordSetStatus === PasswordSetStatus.Success ? (
        <div className="redirect-message-container">
          <NotificationFloat
            varaint="success"
            content={
              type === PasswordOperation.set || type === PasswordOperation.reset
                ? `${successNotification} Changed. Redirecting to the login`
                : `${successNotification} Changed. Redirecting to home`
            }
          />
        </div>
      ) : (
        <>
          {errorNotification !== "" && (
            <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
          )}

          <form onSubmit={handleSubmit}>
            {type === PasswordOperation.set ? (
              <>
                <p className="complete-profile-header mb-3">{translate("complete_your_profile")}</p>
                <hr />
                {/* <br /> */}
                <p className="add-password-header mb-3 ml-3">{translate("add_your_password")}</p>
              </>
            ) : null}

            {type === PasswordOperation.change ? (
              <>
                <Input
                  name="oldPassword"
                  value={oldPassword}
                  handleInputChange={onChangeOldPassword}
                  placeholder={translate("old_password")}
                  type="password"
                />
                {/* {oldPasswordErrors.length > 0 ? (
                  <span className="text-input-error">
                    <p>Your password should contain</p>
                    <ul>
                      {oldPasswordErrors.map((error) => {
                        return <li key={error}>- {error}</li>;
                      })}
                    </ul>
                  </span>
                ) : null} */}

                <hr className="mb-10 mt-10" />
              </>
            ) : null}

            <Input
              name="password"
              value={password}
              handleInputChange={onChangePassword}
              placeholder={translate("new_password")}
              type="password"
            />
            {passwordErrors.length > 0 ? (
              <span className="text-input-error">
                <p>Your password should contain</p>
                <ul>
                  {passwordErrors.map((error) => {
                    return <li key={error}>- {error}</li>;
                  })}
                </ul>
              </span>
            ) : null}

            <Input
              name="confirmPassword"
              value={confirmPassword}
              handleInputChange={onChangeConfirmPassword}
              placeholder={translate("retype_new_password")}
              type="password"
            />
            {passwordConfirmErrors.length > 0 ? (
              <span className="text-input-error">
                <ul>
                  {passwordConfirmErrors.map((error) => {
                    return <li key={error}>{error}</li>;
                  })}
                </ul>
              </span>
            ) : null}

            <Button
              variant="primary"
              text={getSubmitButtonText(type)}
              type="submit"
              disabled={getSubmitButtonDisableStatus(type)}
              additionalStyles="authform__button"
            />
          </form>
        </>
      )}
    </ChangePasswordForm>
  );
};

export default ChangePasswordHome;
