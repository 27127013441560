import { configureStore, Middleware } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth.slice";
import { gapAnalysisReducer } from "./slices/gap-analysis-slice";
import { permissionReducer } from "./slices/permission.slice";
import { featureFlagsReducer } from "./slices/featureFlagSlice";
import { globalFilterReducer, resetFilterCareUnit, setFilterCareUnit } from "./slices/globalFilterSlice";
import { globalFilterLocalStorageMiddleware } from "./middlewares/globalFilterLocalStorageMiddleware";
import { careUnitPermissionMiddleware } from "./middlewares/careUnitPermissionMiddleware";

export const logoutMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  if (action.type === "auth/logout") {
    storeAPI.dispatch(resetFilterCareUnit());
  }

  return result;
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    permission: permissionReducer,
    gapAnalysis: gapAnalysisReducer,
    featureFlagsState: featureFlagsReducer,
    globalFilter: globalFilterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(globalFilterLocalStorageMiddleware, careUnitPermissionMiddleware, logoutMiddleware),
});

export * from "./slices/auth.slice";
export * from "./slices/permission.slice";
export * from "./slices/gap-analysis-slice";
export * from "./slices/featureFlagSlice";
export * from "./slices/globalFilterSlice";
export type RootState = ReturnType<typeof store.getState>;
export default store;
