import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input, LoadingWithColor, Select as SelectComponent } from "../../../../components";
import { ISelectOption } from "../../../../interfaces";
import ScrollLayout from "../../../../layouts/ScrollLayout/ScrollLayout";
import { ScrollBarConstants, UserRoleNames, UserTypes } from "../../utils";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import UserManagementService from "../../../../services/userManagementService";
import UserRoleService from "../../../../services/userRoleService";
import { USER_ROLE_ENDPOINT } from "../../../../utils/apiendpoints";
import { EditUserDto, User, UserDto } from "../../../../interfaces/user-management";
import { UserRole } from "../../../../interfaces/user-role";

//CSS
import "./side-panal.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import MultiSelectListWithSearch from "../care-unit-search/care-unit-search";
import CareProviderSearch from "../care-unit-search/care-unit-search";
import { CareProvidersService } from "../../../../services";
import { AxiosResponse } from "axios";
import { Hospital } from "../../../../interfaces/orgstructure";
import { UserReadDto } from "../../../../interfaces/user-management/User";
import OrgService from "../../../../services/orgService";
import { ScrollBarProps } from "../../../../interfaces/user-management/scrollBarProps";
import { OrgNodeResponse } from "../../../../interfaces/responses/org-structure-tree-response";
import { OrgCareUnit } from "../../../../interfaces/careunits/orgCareUnit";

const SidePanel = ({
  userToEdit,
  variant,
  isScrollBarOpen,
  setIsScrollBarOpen,
  setSideBarVariant,
  setUsers,
  setSuccessNotification,
  setUserToEdit,
}: ScrollBarProps) => {
  const { t: translate } = useTranslation();
  const handleCloseIconClick = () => {
    clearStateValues();
  };

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const activeCareProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const [user, setUser] = useState<User | UserReadDto>({
    userRoleId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    careProviderId: null,
    permission: "",
    hospital: "",
    email: "",
  });

  const [userType, setUserType] = useState<string>("Global");

  const [selectedCareUnit, setSelectedCareUnit] = useState<ISelectOption[]>([]);

  const [isCareUnitUser, setIsCareUnitUser] = useState<boolean>(false);

  const [isCareProviderReader, setIsCareProviderReader] = useState<boolean>(false);

  const [userRolesForSelect, setUserRolesForSelect] = useState<ISelectOption[]>([]);

  const [careUnitsForSelect, setCareUnitsForSelect] = useState<ISelectOption[]>([]);

  const [isCareUnitSearchLoaded, setIsCareUnitSearchLoaded] = useState<boolean>(false);

  const [isCareProviderSearchLoaded, setIsCareProviderSearchLoaded] = useState<boolean>(false);

  const [careProviders, setCareProviders] = useState<ISelectOption[]>([]);

  const [checklistError, setChecklistError] = useState<boolean>(false);

  const [initialLoadingForCareUnitList, setInitialLoadingForCareUnitList] = useState<boolean>(true);

  const [initialLoadingForCareProviders, setInitialLoadingForCareProviders] = useState<boolean>(true);

  const [selectedCareProvider, setSelectedCareProvider] = useState<ISelectOption | undefined>();

  const [careProviderChecklistError, setCareProviderChecklistError] = useState<boolean>(false);

  const [isAddingEntity, setIsAddingEntity] = useState<boolean>(false);

  const [isAddUserFailed, setIsAddUserFailed] = useState<boolean>(false);

  const handleSelectedCareProvider = (selectedCareProvider: ISelectOption) => {
    setInitialLoadingForCareProviders(false);
    setSelectedCareProvider(selectedCareProvider);

    const filterSelectedCareProviders: ISelectOption[] = careProviders.map((provider) => {
      if (provider.value === selectedCareProvider.value) {
        provider.isSelected = true;
      } else {
        provider.isSelected = false;
      }

      return provider;
    });

    setCareProviders([...filterSelectedCareProviders]);
  };

  const getUserRoles = async () => {
    const selectListList: ISelectOption[] = [];

    const userListArray: UserRole[] = await UserRoleService.getUserRolesAsync(USER_ROLE_ENDPOINT);
    userListArray.forEach((role) => {
      const newSelectOption: ISelectOption = {
        value: role.id,
        label: role.name,
      };
      selectListList.push(newSelectOption);
    });

    setUserRolesForSelect(() => selectListList);

    if (variant === ScrollBarConstants.ADD_USER) {
      const globalRole = selectListList.find((obj) => obj.label === "Global Admin");
      if (globalRole) {
        setUser({ ...user, userRoleId: globalRole.value });
        setSelectedUserType(globalRole.label);
      }
    } else {
      const userToEditRole = userRolesForSelect.find((role) => role.value === user.userRoleId);
      if (userToEditRole) {
        setSelectedUserType(userToEditRole.label);
      }
    }
  };

  const getCareUnitsByCareProviderIds = async (): Promise<ISelectOption[]> => {
    const careUnits: ISelectOption[] = [];

    if (activeCareProviderId) {
      const careUnitList: OrgNodeResponse[] = await OrgService.getCareUnitsByParentAsync(activeCareProviderId, year);

      careUnitList.forEach((unit: any) => {
        const newSelectOption: ISelectOption = {
          value: unit.careUnitId,
          label: unit.careUnitName,
          isSelected: false,
        };
        careUnits.push(newSelectOption);
      });
    }

    return careUnits;
  };

  const fetchUserRolesAndCareUnits = async () => {
    await getUserRoles();

    const careUnits = await getCareUnitsByCareProviderIds();

    if (variant === ScrollBarConstants.ADD_USER) {
      setCareUnitsForSelect(careUnits);
      setUser({
        userRoleId: userRolesForSelect[0]?.value,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        careProviderId: null,
        permission: "",
        hospital: "",
        email: "",
      });
      setIsCareUnitUser(false);
    } else if (
      variant === ScrollBarConstants.EDIT_USER &&
      userToEdit !== undefined &&
      user.permission === "Care Unit User"
    ) {
      setUser((prevState) => {
        return {
          ...userToEdit,
          permission: prevState.permission,
          userRoleId: prevState.userRoleId,
        };
      });

      const selectedUnitsWithinAllUnits: ISelectOption[] = [];
      const selectedUnits: ISelectOption[] = [];

      careUnits.forEach((unit: ISelectOption) => {
        if (userToEdit.assignedCareUnits?.includes(parseInt(unit.value))) {
          const newSelectOption: ISelectOption = {
            value: unit.value,
            label: unit.label,
            isSelected: true,
          };
          selectedUnitsWithinAllUnits.push(newSelectOption);
          selectedUnits.push(newSelectOption);
        } else {
          const newSelectOption: ISelectOption = {
            value: unit.value,
            label: unit.label,
            isSelected: false,
          };
          selectedUnitsWithinAllUnits.push(newSelectOption);
        }
      });
      setCareUnitsForSelect(selectedUnitsWithinAllUnits);
      setSelectedCareUnit(selectedUnits);
    }
    setIsCareUnitSearchLoaded(true);
  };

  const fetchAndProcessCareUnits = async () => {
    const careUnits = await getCareUnitsByCareProviderIds();

    if (variant === ScrollBarConstants.ADD_USER) {
      setCareUnitsForSelect(careUnits);

      setIsCareUnitUser(true);
    }
    setIsCareUnitSearchLoaded(true);
  };

  useEffect(() => {
    if (userToEdit?.permission && isScrollBarOpen) {
      if (userToEdit.permission === "Care Unit User") {
        setIsCareUnitUser(true);
        setUserType(UserTypes.CareUnit);
        setUser(userToEdit);
      } else if (userToEdit.permission === "Care Provider Reader") {
        setIsCareProviderReader(true);
        setUserType(UserTypes.Hospital);
        getUserRoles();

        setUser(userToEdit);
      } else {
        getUserRoles();
        setUser(userToEdit);
      }
    }
  }, [userToEdit?.permission, isScrollBarOpen]);

  useEffect(() => {
    if (isScrollBarOpen && isCareUnitUser && variant === ScrollBarConstants.EDIT_USER) {
      fetchUserRolesAndCareUnits();
    } else if (isScrollBarOpen && variant === ScrollBarConstants.ADD_USER && userRolesForSelect.length === 0) {
      getUserRoles();
    }

    if (isScrollBarOpen && isCareUnitUser && variant === ScrollBarConstants.ADD_USER) {
      fetchAndProcessCareUnits();
    }
  }, [variant, isCareUnitUser, isScrollBarOpen]);

  useEffect(() => {
    if (isCareProviderReader) {
      OrgService.getOrgCareProvidersAsync(year)
        .then((res: AxiosResponse<OrgCareUnit[]>) => {
          if (variant === ScrollBarConstants.ADD_USER) {
            const careProviders = res.data.map((hospital) => {
              return {
                label: hospital.careUnitName,
                value: String(hospital.careUnitId),
              };
            });

            setCareProviders([...careProviders]);
            setIsCareProviderSearchLoaded(() => true);
          } else if (variant === ScrollBarConstants.EDIT_USER) {
            const careProviders = res.data.map((hospital) => {
              let userCareProviderId;

              userToEdit?.userRoles.forEach((role) => {
                if (role.role === "Care Provider Reader") {
                  userCareProviderId = role.parentCareUnitId;
                }
              });

              if (userCareProviderId === Number(hospital.careUnitId)) {
                setSelectedCareProvider({
                  label: hospital.careUnitName,
                  value: String(hospital.careUnitId),
                  isSelected: true,
                });

                return {
                  label: hospital.careUnitName,
                  value: String(hospital.careUnitId),
                  isSelected: true,
                };
              } else {
                return {
                  label: hospital.careUnitName,
                  value: String(hospital.careUnitId),
                  isSelected: false,
                };
              }
            });

            setCareProviders([...careProviders]);
            setIsCareProviderSearchLoaded(() => true);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [isCareProviderReader]);

  //handle user adding notifications
  useEffect(() => {
    if (isAddUserFailed) {
      const timer = setTimeout(() => {
        setIsAddUserFailed(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isAddUserFailed]);

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const resetStates = (label: string) => {
    if (label === UserRoleNames.care_unit_user) {
      setIsCareProviderReader(() => false);
      setSelectedCareProvider(undefined);
    } else if (label === UserRoleNames.care_provider_reader) {
      setIsCareUnitUser(() => false);
      setSelectedCareUnit([]);
    }
  };

  const resetErrorHandlingStates = () => {
    setCareProviderChecklistError(false);
    setChecklistError(false);
  };

  const handleUserRoleSelectChange = (role: any) => {
    const { name, value, label } = role;

    if (label === UserRoleNames.care_unit_user) {
      setIsCareUnitUser(() => true);
      resetStates(label);
    } else if (label === UserRoleNames.care_provider_reader) {
      setIsCareProviderReader(() => true);
      resetStates(label);
    } else {
      setIsCareUnitUser(() => false);
      setIsCareProviderReader(() => false);
    }

    setSelectedUserType(label);
    resetErrorHandlingStates();

    setUser((prevState) => {
      return {
        ...prevState,
        permission: label,
        [name]: value,
      };
    });
  };

  const setSelectedUserType = (type: string) => {
    if (type === UserRoleNames.care_provider_reader) {
      setUserType(UserTypes.Hospital);
    } else if (type === UserRoleNames.global_admin) {
      setUserType(UserTypes.Global);
    } else if (type === UserRoleNames.care_provider_admin) {
      setUserType(UserTypes.Hospital);
    } else if (type === UserRoleNames.global_reader) {
      setUserType(UserTypes.Global);
    } else if (type === UserRoleNames.care_unit_user) {
      setUserType(UserTypes.CareUnit);
    } else {
      setUserType(UserTypes.Department);
    }
  };

  const setSelectedCareUnitsForSubmit = (options: ISelectOption[]) => {
    setInitialLoadingForCareUnitList(false);

    setSelectedCareUnit(options);
  };

  useEffect(() => {
    if (selectedCareUnit.length === 0 && !initialLoadingForCareUnitList) {
      setChecklistError(true);
    } else {
      setChecklistError(false);
    }
  }, [selectedCareUnit]);

  useEffect(() => {
    if (selectedCareProvider === undefined && !initialLoadingForCareProviders) {
      setCareProviderChecklistError(true);
    } else {
      setCareProviderChecklistError(false);
    }
  }, [selectedCareProvider]);

  const handleSelectToggle = (value: string) => {
    setCareUnitsForSelect((current) =>
      current.map((obj) => {
        if (obj.value === value) {
          return {
            ...obj,
            label: obj.label,
            value: obj.value,
            isSelected: !obj.isSelected,
          };
        }

        return obj;
      })
    );
  };

  const getCareUnitErrorStatus = () => {
    if (userType === "CareUnit") {
      if (selectedCareUnit.length === 0) {
        setChecklistError(true);
        return true;
      } else {
        setChecklistError(false);
        return false;
      }
    } else {
      return false;
    }
  };

  const getCareProviderErrors = () => {
    if (userType === "Hospital") {
      if (selectedCareProvider === undefined) {
        setCareProviderChecklistError(true);
        return true;
      } else {
        setCareProviderChecklistError(false);
        return false;
      }
    } else {
      return false;
    }
  };

  const handleAddUserSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const locale = localStorage.getItem("i18nextLng") || "";

    if (!getCareUnitErrorStatus() && !getCareProviderErrors()) {
      setIsAddingEntity(true);
      const userDto: UserDto = {
        userDto: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          hospital: user.hospital,
          userRoleId: user.userRoleId,
          careUnitId: isCareUnitUser
            ? selectedCareUnit?.map((care) => {
                return parseInt(care.value);
              })
            : undefined,
          parentCareUnitId: isCareUnitUser
            ? activeCareProviderId
            : isCareProviderReader
            ? Number(selectedCareProvider?.value)
            : null,
          careProviderId: null,
          type: userType,
          locale: locale,
        },
      };

      await UserManagementService.addUserAsync(userDto)
        .then((res) => {
          clearStateValues();

          setIsAddUserFailed(false);
          setIsAddingEntity(false);
          setSuccessNotification && setSuccessNotification("User");
        })
        .catch((err) => {
          setIsAddingEntity(false);
          setIsAddUserFailed(true);
          throw err;
        });
    }
  };

  const handleEditUser = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!getCareUnitErrorStatus() && !getCareProviderErrors()) {
      setIsAddingEntity(true);
      const userDto: EditUserDto = {
        id: user.id || "",
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        hospital: user.hospital,
        userRoleId: user.userRoleId,
        careUnitId: isCareUnitUser
          ? selectedCareUnit?.map((care) => {
              return parseInt(care.value);
            })
          : undefined,
        parentCareUnitId: isCareUnitUser
          ? activeCareProviderId
          : isCareProviderReader
          ? Number(selectedCareProvider?.value)
          : null,
        careProviderId: null,
        type: userType,
      };

      await UserManagementService.editUserAsync(userDto)
        .then(() => {
          clearStateValues();

          setIsAddUserFailed(false);
          setIsAddingEntity(false);
          setSuccessNotification && setSuccessNotification("User");
        })
        .catch((err) => {
          setIsAddingEntity(false);
          setIsAddUserFailed(true);
          throw err;
        });
    }
  };

  const clearStateValues = () => {
    setUser({
      userRoleId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      permission: "",
      careProviderId: null,
      hospital: "",
      email: "",
      userRoles: [],
    });
    setUserType("Global");
    setSelectedCareUnit([]);
    setIsCareUnitUser(false);
    setUserRolesForSelect([]);
    setCareUnitsForSelect([]);
    setIsCareUnitSearchLoaded(false);
    setIsScrollBarOpen(false);
    setSideBarVariant(undefined);
    setIsCareProviderReader(false);
    setIsCareProviderSearchLoaded(false);
    setSelectedCareProvider(undefined);
    setCareProviders([]);
    setChecklistError(false);
    setInitialLoadingForCareUnitList(true);
    setInitialLoadingForCareProviders(true);
    resetEditUserState();
  };

  const resetEditUserState = () => {
    if (variant === ScrollBarConstants.EDIT_USER) {
      setUserToEdit({
        email: "",
        firstName: "",
        hospital: "",
        lastName: "",
        phoneNumber: "",
        permission: "",
        userRoleId: "",
        careProviderId: null,
        assignedCareUnits: [],
        userRoles: [],
      });
    }
  };

  return (
    <ScrollLayout
      isOpen={isScrollBarOpen}
      moreSpace={isCareUnitUser || isCareProviderReader}
      onCloseIconClick={handleCloseIconClick}
    >
      {variant === ScrollBarConstants.ADD_USER && (
        <>
          {isAddUserFailed && (
            <div className={`${isCareUnitUser || isCareProviderReader ? "w-1/2" : ""} my-3 h-12`}>
              <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
            </div>
          )}
          {isAddingEntity ? (
            <div className="w-8/12">
              <LoadingWithColor sizeInPixel={50} additionalStyles="side-panel-loader" />
            </div>
          ) : (
            <>
              <div className="flex h-screen w-auto flex-row">
                <div
                  className={`${
                    isCareUnitUser || isCareProviderReader ? "w-1/2" : "w-full"
                  } flex h-full flex-row place-items-center`}
                >
                  <form onSubmit={handleAddUserSubmit}>
                    <h3 className="heading-3"> {translate("add_new_user")} </h3>

                    <div>
                      <Input
                        name="email"
                        value={user.email}
                        placeholder={translate("email")}
                        handleInputChange={handleUserInputChange}
                        error=""
                        success=""
                        type="email"
                      />
                    </div>

                    <span className="p-3-v-1 text-paragraph-lg-2 text-gray-500">
                      {translate("send_user_invitation_message")}
                    </span>

                    <div className="flex w-full items-center gap-[0.75rem]">
                      <Input
                        name="firstName"
                        value={user.firstName}
                        placeholder={translate("first_name")}
                        handleInputChange={handleUserInputChange}
                        error=""
                        success=""
                        type="text"
                      />

                      <Input
                        name="lastName"
                        value={user.lastName}
                        placeholder={translate("last_name")}
                        handleInputChange={handleUserInputChange}
                        error=""
                        success=""
                        type="text"
                      />
                    </div>

                    <hr className="mt-[1.25rem] border border-[#dfe1e4]" />

                    <div>
                      <span className="p-3-v-2 mb-[1rem] mt-[1.5rem] block text-paragraph-lg-1">
                        {translate("permission")}
                      </span>
                      <div className="my-2 h-11">
                        <SelectComponent
                          selectedOption={userRolesForSelect.find((obj) => obj.label === "Global Admin")}
                          placeholder={translate("permission")}
                          options={userRolesForSelect}
                          onSelectOption={(option) => {
                            const dep = {
                              name: "userRoleId",
                              value: option.value,
                              label: option.label,
                            };
                            handleUserRoleSelectChange(dep);
                          }}
                        />
                      </div>
                    </div>

                    <hr className="my-[1.5rem] border border-[#dfe1e4]" />

                    <div className="row w-full text-right">
                      <Button additionalStyles=" w-44 h-10" variant="primary" text={translate("send_invitation")} />
                    </div>
                  </form>
                </div>

                {isCareUnitSearchLoaded && isCareUnitUser && (
                  <div className="mb-10 ml-10 mt-10 w-1/2 border-l-2 pr-12">
                    <MultiSelectListWithSearch
                      variant="CARE_UNIT_USER"
                      error={checklistError}
                      list={careUnitsForSelect}
                      onItemsSelect={setSelectedCareUnitsForSubmit}
                      isMulti={true}
                      title={translate("search_care_unit")}
                    />
                  </div>
                )}

                {isCareProviderSearchLoaded && isCareProviderReader && (
                  <div className="mb-10 ml-10 mt-10 w-1/2 border-l-2 pr-12">
                    <CareProviderSearch
                      variant="CARE_PROVIDER_READER"
                      error={careProviderChecklistError}
                      list={careProviders}
                      isMulti={false}
                      setSelectedItem={handleSelectedCareProvider}
                      title={translate("search_care_provider")}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      {variant === ScrollBarConstants.EDIT_USER && (
        <>
          {isAddUserFailed && (
            <div className={`${isCareUnitUser || isCareProviderReader ? "w-1/2" : ""} my-3 h-12`}>
              <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
            </div>
          )}
          {isAddingEntity ? (
            <div className="w-8/12">
              <LoadingWithColor sizeInPixel={50} additionalStyles="side-panel-loader" />
            </div>
          ) : (
            <>
              <div className="flex h-screen w-auto flex-row">
                <div
                  className={`${
                    isCareUnitUser || isCareProviderReader ? "w-1/2" : "w-full"
                  } flex h-full flex-row place-items-center`}
                >
                  <form onSubmit={handleEditUser}>
                    <h3 className="heading-3"> {translate("edit_user")} </h3>

                    <div>
                      <Input
                        name="email"
                        value={user.email}
                        placeholder={translate("email")}
                        handleInputChange={handleUserInputChange}
                        error=""
                        success=""
                        type="email"
                        disabled
                      />
                    </div>

                    <span className="p-3-v-1 text-paragraph-lg-2 text-gray-500">
                      {translate("send_user_invitation_message")}
                    </span>

                    <div className="flex w-full items-center gap-[0.75rem]">
                      <Input
                        name="firstName"
                        value={user.firstName}
                        placeholder={translate("first_name")}
                        handleInputChange={handleUserInputChange}
                        error=""
                        success=""
                        type="text"
                      />

                      <Input
                        name="lastName"
                        value={user.lastName}
                        placeholder={translate("last_name")}
                        handleInputChange={handleUserInputChange}
                        error=""
                        success=""
                        type="text"
                      />
                    </div>

                    <hr className="mt-[1.25rem] border border-[#dfe1e4]" />

                    <div>
                      <span className="p-3-v-2 mb-[1rem] mt-[1.5rem] block text-paragraph-lg-1">
                        {translate("permission")}
                      </span>
                      <div className="my-2 h-11">
                        <SelectComponent
                          selectedOption={userRolesForSelect.find((role) => role.value === user.userRoleId)}
                          placeholder={translate("permission")}
                          options={userRolesForSelect}
                          onSelectOption={(option) => {
                            const dep = {
                              name: "userRoleId",
                              value: option.value,
                              label: option.label,
                            };
                            handleUserRoleSelectChange(dep);
                          }}
                        />
                      </div>
                    </div>

                    <hr className="my-[1.5rem] border border-[#dfe1e4]" />

                    <div className="row w-full text-right">
                      <Button additionalStyles=" w-44 h-10" variant="primary" text={translate("save")} />
                    </div>
                  </form>
                </div>

                {isCareUnitSearchLoaded && isCareUnitUser && (
                  <div className="mb-10 ml-10 mt-10 w-1/2 border-l-2 pr-12">
                    <MultiSelectListWithSearch
                      variant="CARE_UNIT_USER"
                      error={checklistError}
                      list={careUnitsForSelect}
                      onItemsSelect={setSelectedCareUnitsForSubmit}
                      isMulti={true}
                      title={translate("search_care_unit")}
                    />
                  </div>
                )}

                {isCareProviderSearchLoaded && isCareProviderReader && (
                  <div className="mb-10 ml-10 mt-10 w-1/2 border-l-2 pr-12">
                    <CareProviderSearch
                      variant="CARE_PROVIDER_READER"
                      error={careProviderChecklistError}
                      list={careProviders}
                      isMulti={false}
                      setSelectedItem={handleSelectedCareProvider}
                      title={translate("search_care_provider")}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </ScrollLayout>
  );
};

export default SidePanel;
