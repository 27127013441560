import { useState } from "react";
import BaseDataRetrieveHookReturn from "../../../interfaces/hooks/baseDataRetrieveHookReturn";
import StaffAvailabilityAllocation from "../../../interfaces/staff-availabilities/staffAvailabilityAllocation";
import { StaffAvailabilityService } from "../../../services";
import { AxiosResponse } from "axios";
import StaffModel from "../../../interfaces/staff-availabilities/staffModel";

interface StaffWorkCompetenceAllocationReturn<T>
  extends BaseDataRetrieveHookReturn<T> {
  submit(
    careUnitId: number,
    scenario: number,
    data: StaffModel[]
  ): Promise<AxiosResponse<any, any> | undefined>;
}

const useGetStaffWorkCompetenceAllocations = (
  careUnitId: number | null,
  scenario: number | null
): StaffWorkCompetenceAllocationReturn<StaffAvailabilityAllocation[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [staffAvailabilityAllocations, setStaffAvailabilityAllocations] =
    useState<StaffAvailabilityAllocation[]>([]);

  const fetchData = async (): Promise<void> => {
    if (careUnitId && scenario) {
      setIsLoading(true);

      StaffAvailabilityService.getStaffWorkCompetenceAllocations(
        careUnitId,
        scenario
      )
        .then((res) => {
          const responseData: StaffAvailabilityAllocation[] = res.data;

          setStaffAvailabilityAllocations(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const submitData = async (
    careUnitId: number,
    scenario: number,
    allocations: StaffModel[]
  ): Promise<AxiosResponse<any, any> | undefined> => {
    if (careUnitId && scenario) {
      setIsLoading(true);

      const response =
        StaffAvailabilityService.saveStaffWorkCompetenceAllocations(
          careUnitId,
          scenario,
          allocations
        ).finally(() => {
          fetchData();
        });

      return response;
    }

    return undefined;
  };

  return {
    data: staffAvailabilityAllocations,
    fetch: fetchData,
    isLoading,
    submit: submitData,
  };
};

export default useGetStaffWorkCompetenceAllocations;
