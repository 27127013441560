import { ActivityAnalysisProductionSchema } from "../../../../../interfaces/analytics/activity-analysis-production-schema";
import { ActivityAnalysisProductionWithFilterGroupSchema } from "../../../../../interfaces/analytics/activity-analysis-production-schema-with-filter-group";
import { FilterGroupSchema } from "../../../../../interfaces/filter-group/filter-group-schema";

const UseActivityAnalysisModifiedProductionTable = (
  data: ActivityAnalysisProductionSchema[],
  filterGroups: FilterGroupSchema[]
): ActivityAnalysisProductionWithFilterGroupSchema[] => {
  const modifiedDataSet: ActivityAnalysisProductionWithFilterGroupSchema[] = [];

  const filterGroupMap: { [key: string]: number } = {};
  filterGroups.forEach((f) => {
    filterGroupMap[f.filterGroupName] = f.id;
  });

  data?.forEach((element) => {
    const filterGroupId = element.filterGroupId as number;

    modifiedDataSet.push({
      workCompetenceId: element.workCompetenceId,
      filterGroupId,
      fteProductionPlan: element.fteProductionPlan,
      id: element.id,
      productionPlan: Number(element.productionPlan),
      timePerContact: element.timePerContact,
      timeSpent: element.timeSpent,
      isProductionPlanReloaded: element.isProductionPlanReloaded,
      weeklyWorkingHours: Number(element.weeklyWorkingHours),
    });
  });

  return modifiedDataSet;
};

export default UseActivityAnalysisModifiedProductionTable;
