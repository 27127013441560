import React from "react";
import "./status-icon-modal.css";
import { StatusIconModalProps } from "../../interfaces/props";
import SuccessIcon from "../icons/success-icon/success-icon";
import ErrorIcon from "../icons/error-icon/error-icon";

const StatusIconModal = ({ title, status }: StatusIconModalProps) => {
  return (
    <div className="status-box">
      <div
        className={`status-title ${
          status === 1 ? "title--success" : "title--error"
        }`}
      >
        <span className="p-1-v-2">{title}</span>
      </div>
      <div
        className={`status-icon ${
          status === 1 ? "icon--success" : "icon--error"
        }`}
      >
        {status === 1 && <SuccessIcon width={48} height={48} />}
        {status === 2 && <ErrorIcon width={48} height={48} />}
      </div>
    </div>
  );
};

export default StatusIconModal;
