import React, { useEffect, useState } from "react";
import { TabbarProps } from "../../../interfaces/props";
import TabTeritary from "./tab-teritary/TabTeritary";
import { v4 as uuid } from "uuid";

const TabbarTeritary = ({ tabs, index, onClick }: TabbarProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedIndex(index || 0);
  }, [index]);

  const handleTabClick = (id: number) => {
    setSelectedIndex(id);
    onClick(id);
  };

  return (
    <div className="tabbar-teritary">
      {tabs.map((tab, i) => (
        <TabTeritary
          key={uuid()}
          id={i}
          onClick={handleTabClick}
          title={tab}
          isSelected={false}
          selectedIndex={selectedIndex}
          isLastElement={tabs.length - 1 === i}
        />
      ))}
    </div>
  );
};

export default TabbarTeritary;
