import clsx from "clsx";
import React from "react";

interface TextAreaProps {
  id: string;
  name: string;
  defaultRows?: number;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
}

const TextArea = ({ id, name, defaultRows, className, placeholder, value, onChange }: TextAreaProps) => {
  return (
    <div className="relative">
      {placeholder ? (
        <label
          className={clsx(
            "absolute top-3 -translate-y-1/2 text-[#747474] transition-all group-hover:text-black lg:left-4 xl:left-6",
            {
              "p-2-v-1 top-7": (value?.length ?? 0) === 0,
              "p-3-v-1": (value?.length ?? 0) > 0,
            }
          )}
          htmlFor={id}
        >
          {placeholder}
        </label>
      ) : null}
      <textarea
        id={id}
        name={name}
        className={clsx(
          "w-full rounded-[0.5rem] border-x border-y border-[#ADADAD] bg-[#F8F8F8] pt-4 lg:px-4 lg:py-[0.2rem] xl:px-6 xl:py-4",
          className
        )}
        value={value}
        rows={defaultRows}
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export default TextArea;
