export enum PasswordSetStatus {
  Undefined,
  Ongoing,
  Success,
  Failed,
}

export enum PasswordOperation {
  change,
  set,
  reset
}
