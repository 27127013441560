import { TreeNode } from "react-dropdown-tree-select";

export function searchPredicate(node: TreeNode, searchTerm: string): boolean {
  return (
    node.label
      .replace(/\s/g, "")
      .toLowerCase()
      .indexOf(searchTerm.replace(/\s/g, "").toLowerCase()) >= 0
  );
}
