import { ChangeSource } from "handsontable/common";
import React from "react";
import { ActivityAnalysisProductionSchema } from "../../../../../interfaces/analytics/activity-analysis-production-schema";
import { HotTableClass as HotTable } from "@handsontable/react";
import { useTranslation } from "react-i18next";
import { useAfterCreateRowReturntype } from "../../../../../interfaces/analytics/handsontable/use-aftercreate-row-return-type";

const useAfterCreateRow = (
  data: ActivityAnalysisProductionSchema[],
  dataTable: React.RefObject<HotTable | null>,
  setData: React.Dispatch<
    React.SetStateAction<ActivityAnalysisProductionSchema[]>
  >
): useAfterCreateRowReturntype => {
  const { t: translate } = useTranslation();

  const afterCreateRow = (
    index: number,
    amount: number,
    source: ChangeSource | undefined
  ): void => {
    const row = index + 1;
    dataTable.current?.hotInstance?.setSourceDataAtCell(
      index,
      4,
      `=((C${row}/60)*D${row})/52`
    );
    dataTable.current?.hotInstance?.setSourceDataAtCell(
      index,
      6,
      `=IF(F${row}=0,0,E${row}/F${row})`
    );

    dataTable.current?.hotInstance?.setDataAtCell(index, 5, 40);

    if (source === "ContextMenu.rowBelow") {
      const isTotalRowExists = data.find((row) => row.id === "total_row");

      const noOfRows = data?.length;

      if (!isTotalRowExists) {
        const newRow = {
          id: "total_row",
          filterGroupId: translate("total"),
          workCompetenceId: 0,
          timePerContact: "",
          fteProductionPlan: `=SUM(G1:G${noOfRows})`,
          productionPlan: "",
          timeSpent: `=SUM(E1:E${noOfRows})`,
          weeklyWorkingHours: "",
        } as ActivityAnalysisProductionSchema;

        setData([...data, newRow]);
      }
    }
  };

  return { afterCreateRow };
};

export default useAfterCreateRow;
