import React, { useState } from "react";
import { NumberIconInputProps } from "../../interfaces/props";
import minus from "../../assets/svg/minus.svg";
import plus from "../../assets/svg/plus.svg";

const IconNumericInput = ({
  id,
  name,
  placeholder,
  error,
  success,
  handleInputChange,
  handleLeftIconClick,
  handleRightIconClick,
  onFocus,
  type,
  disabled,
  value,
}: NumberIconInputProps) => {
  /* Id props: To give a unique id to input element and assign it to it's label */

  const [hover, setHover] = useState<boolean>(false);

  const handleOnInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setHover(false);

    if (onFocus !== undefined) {
      onFocus(e);
    }
  };

  const onLeftIconClick = () => {
    if (handleLeftIconClick !== undefined) {
      handleLeftIconClick(name);
    }
  };

  const onRightIconClick = () => {
    if (handleRightIconClick !== undefined) {
      handleRightIconClick(name);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <div className="shrink-0">
        <img
          className="cursor-pointer"
          onClick={onLeftIconClick}
          src={minus}
          alt="minus"
        />
      </div>

      <div
        className="input__container input-container-small"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div
          className={`input input-small group ${
            error
              ? "input--error"
              : success && success?.length > 0
              ? "input--success"
              : disabled
              ? "input--disabled"
              : hover && "input--hover"
          } `}
        >
          <input
            id={id}
            className={`input__element p-2-v-1 ${hover && "input--hover"}`}
            placeholder={placeholder}
            onChange={handleInputChange}
            type={type}
            value={value}
            name={name}
            disabled={disabled}
            onFocus={handleOnInputFocus}
            min="0"
            max="100"
          />
          <div className="input__icon p-2-v-1">%</div>
        </div>
      </div>

      <div className="shrink-0">
        <img
          className="cursor-pointer"
          onClick={onRightIconClick}
          src={plus}
          alt="plus"
        />
      </div>
    </div>
  );
};

export default IconNumericInput;
