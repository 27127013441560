const useHHMMFormat = () => {
  const tohhmmFormat = (value: number): string => {
      const hours = Math.floor(value);
      const minutes = Math.floor((value - hours) * 60);
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    };

 return { tohhmmFormat };
};

export default useHHMMFormat;