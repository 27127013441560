import React from "react";
import Button from "../Button/Button";

interface NotificationBoxProps {
  title: string;
  content: string;
  leftButtonLabel: string;
  rightButtonLabel: string;
  middleButtonLabel?: string;
  variant: "primary" | "secondary";
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
  onMiddleButtonClick?: () => void;
  isLeftButtonDisabled?: boolean;
  isRightButtonDisabled?: boolean;
}

const NotificationBox = ({
  title,
  content,
  leftButtonLabel,
  rightButtonLabel,
  middleButtonLabel,
  variant,
  onLeftButtonClick,
  onRightButtonClick,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  onMiddleButtonClick,
}: NotificationBoxProps) => {
  return (
    <div className="notification-box">
      <div>
        <span className="p-1-v-2">{title}</span>
      </div>

      <div className="notification-box__content p-2-v-1">
        <span>{content}</span>
      </div>

      {variant === "primary" && (
        <div className="notification-box__buttons--primary">
          <Button
            additionalStyles="notification-box__buttons__left"
            variant="secondary"
            text={leftButtonLabel}
            onClick={onLeftButtonClick}
            disabled={isLeftButtonDisabled}
          />

          {onMiddleButtonClick && (
            <Button
              additionalStyles="notification-box__buttons__left"
              variant="territary"
              text={middleButtonLabel || ""}
              onClick={onMiddleButtonClick}
            />
          )}

          <Button
            additionalStyles="notification-box__buttons__right"
            variant="territary"
            text={rightButtonLabel}
            onClick={onRightButtonClick}
            disabled={isRightButtonDisabled}
          />
        </div>
      )}

      {variant === "secondary" && (
        <div className="notification-box__buttons__container flex justify-end">
          <div className="notification-box__buttons--secondary flex w-3/4 lg:!gap-[0.75rem] xl:!gap-[1rem]">
            <Button
              additionalStyles="notification-box__buttons__button"
              variant="secondary"
              text={rightButtonLabel}
              onClick={onLeftButtonClick}
              disabled={isLeftButtonDisabled}
            />

            <Button
              additionalStyles="notification-box__buttons__button"
              variant="danger"
              text={leftButtonLabel}
              onClick={onRightButtonClick}
              disabled={isRightButtonDisabled}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationBox;
