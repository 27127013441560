import { HotTableClass as HotTable } from "@handsontable/react";
import { ChangeSource } from "handsontable/common";
import React from "react";
import { UseBeforeCreateRowReturnType } from "../../../../../interfaces/analytics/handsontable/use-beforecreaterow-return-type";

const useBeforeCreateRow = (
  dataTable: React.RefObject<HotTable | null>
): UseBeforeCreateRowReturnType => {
  const beforeCreateRow = (
    index: number,
    amount: number,
    source: ChangeSource | undefined
  ) => {
    const rowCount = dataTable.current?.__hotInstance?.countRows();

    if (
      rowCount &&
      source === "ContextMenu.rowBelow" &&
      index === rowCount - 1
    ) {
      // remove last row
      dataTable.current?.__hotInstance?.alter("remove_row", rowCount - 1);
    }
  };

  return { beforeCreateRow };
};

export default useBeforeCreateRow;
