import React from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Select } from "../../../../components";
import { ISelectOption, MultiSelectOption } from "../../../../interfaces";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";

interface InpatientFilterProps {
  typesOfInputs: ISelectOption[];
  medicalSpecialityList: ISelectOption[];
  careLevelsList: ISelectOption[];
  selectedProductionTypes: MultiSelectOption[];
  productionTypes: MultiSelectOption[];
  handleTypesOfInputSelect: (selectedOption: ISelectOption) => void;
  handleMedicalSpecSelectChange: (selectedOption: ISelectOption) => void;
  handleCareLevelSelectChange: (selectedOption: ISelectOption) => void;
  handleBenchmarkProductionTypeSelect: (option: MultiSelectOption) => void;
  handleBenchmarkProductionTypeDeSelect: (optionToDeselect: MultiSelectOption) => void;
  selectedInput: ISelectOption;
}

const InpatientFilters = ({
  typesOfInputs,
  medicalSpecialityList,
  careLevelsList,
  selectedInput,
  selectedProductionTypes,
  handleBenchmarkProductionTypeDeSelect,
  handleBenchmarkProductionTypeSelect,
  handleMedicalSpecSelectChange,
  handleCareLevelSelectChange,
  handleTypesOfInputSelect,
  productionTypes,
}: InpatientFilterProps) => {
  const { t: translate } = useTranslation();

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.BenchmarkCareLevelFilter);

  return (
    <>
      <div className="mb-4">
        <Select
          placeholder={translate("type_of_input")}
          options={typesOfInputs}
          onSelectOption={handleTypesOfInputSelect}
          selectedOption={selectedInput}
        />
      </div>

      <div className="mb-4">
        <Select
          placeholder={translate("medical_speciality")}
          options={medicalSpecialityList}
          onSelectOption={handleMedicalSpecSelectChange}
          selectedOption={medicalSpecialityList[0]}
        />
      </div>

      {isFeatureFlagEnabled ? (
        <div className="mb-4">
          <Select
            placeholder={translate("care_level")}
            options={careLevelsList}
            onSelectOption={handleCareLevelSelectChange}
            selectedOption={careLevelsList[0]}
          />
        </div>
      ) : null}

      <div className="custom-select mb-4">
        <MultiSelect
          label={translate("production_type")}
          selectedOptions={selectedProductionTypes}
          options={productionTypes}
          onSelectItem={handleBenchmarkProductionTypeSelect}
          onRemoveSelectItem={handleBenchmarkProductionTypeDeSelect}
        />
      </div>
    </>
  );
};

export default InpatientFilters;
