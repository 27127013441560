import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../redux/store";

const AuthenticatedRoutes = () => {
  const loginState = useSelector((state: RootState) => state.auth.isLoggedIn);

  return loginState ? <Outlet /> : <Navigate to="/auth/login" replace />;
};

export default AuthenticatedRoutes;
