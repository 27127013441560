import { FeatureFlag } from "../interfaces/featureFlags/featureFlag";
import HttpCommonService from "./httpCommonService";

const FeatureFlagService = {
  getFeatureFlagEvaluation: async (featureName: string): Promise<boolean> => {
    const { REACT_APP_FEATURE_FLAG_ENV } = process.env;

    if (!REACT_APP_FEATURE_FLAG_ENV || REACT_APP_FEATURE_FLAG_ENV === "") {
      throw new Error("REACT_APP_FEATURE_FLAG_ENV variable is not defined. Check the build pipeline");
    }

    const url = `api/featureflags/evaulate/${featureName}/${REACT_APP_FEATURE_FLAG_ENV}`;

    return await HttpCommonService.getAsync(url)
      .then((response) => {
        return response.data as boolean;
      })
      .catch((error) => {
        throw error;
      });
  },
  getFeatureFlags: async (): Promise<FeatureFlag[]> => {
    const { REACT_APP_FEATURE_FLAG_ENV } = process.env;

    if (!REACT_APP_FEATURE_FLAG_ENV || REACT_APP_FEATURE_FLAG_ENV === "") {
      throw new Error("REACT_APP_FEATURE_FLAG_ENV variable is not defined. Check the build pipeline");
    }

    const url = `api/featureflags/${REACT_APP_FEATURE_FLAG_ENV}`;

    return await HttpCommonService.getAsync(url)
      .then((response) => {
        return response.data as FeatureFlag[];
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default FeatureFlagService;
