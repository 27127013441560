import _ from "lodash";

export const adjustAgreementColumnHeaderColour = (
  col: number,
  TH: HTMLElement
) => {
  const darkBlueHeaderIndexes = _.range(0, 3);
  const lightBlueHeaderIndexes = _.range(3, 8);
  const columnsWithFilters = [0];

  const lightBlueHeaders = [...lightBlueHeaderIndexes];

  const darkBlueHeaders = [...darkBlueHeaderIndexes];

  if (darkBlueHeaders.includes(col)) {
    TH.classList.add("dark-blue-column");
  }

  if (lightBlueHeaders.includes(col)) {
    TH.classList.add("light-blue-column");
  }

  if (!columnsWithFilters.includes(col)) {
    TH.classList.add("remove-filter");
  }

  TH.classList.add("htMiddle");
};
