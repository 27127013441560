import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashboardProductionVsPlanProps } from "../../../interfaces/props";
import { RootState } from "../../../redux/store";
import { KpiResponse } from "../../../interfaces/dashboard/kpis";
import { DashboardService } from "../../../services";
import ProductionVsPlanKpis from "./production-vs-plan-kpis";
import { useTranslation } from "react-i18next";
import "../styles/common-chart-styles.css";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import ProductionVsPlanDashboardChart from "../../analytics/ProductionVsPlan/Chart/ProductionVsPlanDashboardChart";

const ProductionVsPlanComponent = ({ careUnitList, selectedCareUnitType }: DashboardProductionVsPlanProps) => {
  const { t: translate } = useTranslation();

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const [kpis, setKpis] = useState<KpiResponse>({
    actualR12: 0,
    actualVsPlan: 0,
    plannedTotal: 0,
    productionPercentage: 0,
    recoveryPlan: 0,
  });
  const [isKpisLoading, setIsKpisLoading] = useState<boolean>(false);

  const selectedTypeOfPlan = {
    value: selectedCareUnitType.value,
    label: selectedCareUnitType.label,
  };

  useEffect(() => {
    const getDashboardProductionKpis = async () => {
      setIsKpisLoading(true);
      await DashboardService.getProductionKpis(careUnitList, year, scenario, Number(selectedTypeOfPlan.value))
        .then((res: KpiResponse) => {
          setKpis(res);
          setIsKpisLoading;
        })
        .catch((err) => {
          console.error(err);
        });

      setIsKpisLoading(false);
    };

    if (careUnitList !== null && careUnitList.length > 0 && careUnitList[0] !== 0 && year > 0) {
      getDashboardProductionKpis();
    }
  }, [careUnitList, scenario, selectedTypeOfPlan.value]);

  const selectedYear = useSelector((state: RootState) => state.globalFilter.filterYear);
  const navigate = useNavigate();

  const handleChartClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLElement).closest(".highcharts-legend-item") ||
      (event.target as HTMLElement).closest(".highcharts-legend")
    ) {
      return;
    }
    navigate("/analytics/production-vs-plan");
  };

  // chart loading state
  const [isChartDataLoading, setIsChartDataLoading] = useState<boolean>(false);
  const isLoading = isChartDataLoading || isKpisLoading;

  return (
    <>
      {isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}
      <div
        className={`${isLoading ? "hidden" : "flex"} !h-full !max-h-full w-full cursor-pointer`}
        onClick={handleChartClick}
      >
        <div className="!h-full !max-h-full w-full flex-col">
          <div className="!h-full max-h-full">
            <div className="flex !h-full max-h-full flex-col">
              <div className="chart-title">
                <span className="">{translate("production")}</span>
              </div>
              <div className="flex h-full w-full">
                <div className="flex h-full w-full">
                  <div className="chart-bg !h-full max-h-full basis-5/6">
                    <ProductionVsPlanDashboardChart
                      year={selectedYear}
                      selectedTypeOfPlan={selectedTypeOfPlan}
                      careUnitList={careUnitList}
                      chartTitle=""
                      isPlacedOnDashboard={true}
                      onChartDataLoading={(isChartDataLoading: boolean) => {
                        setIsChartDataLoading(() => isChartDataLoading);
                      }}
                    />
                  </div>

                  <div className="chart-kpi-root basis-1/6">
                    {!isKpisLoading ? (
                      <ProductionVsPlanKpis
                        actualR12={kpis.actualR12}
                        plannedTotal={kpis.plannedTotal}
                        actualVsPlan={kpis.actualVsPlan}
                        productionPercentage={kpis.productionPercentage}
                        recoveryPlan={kpis.recoveryPlan}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductionVsPlanComponent;
