import axios from "axios";
import { userCredentials } from "../interfaces/UserCredentials";
import HttpCommonService from "./httpCommonService";

const AuthService = {
  loginAsync: async (credentials: userCredentials) => {
    return await HttpCommonService.postAsync("api/account/login", {
      email: credentials.email,
      password: credentials.password,
      IsPersistent: credentials.isPersistent,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  forgotPasswordAsync: async (userEmail: string, locale: string) => {
    return await HttpCommonService.postAsync(`api/account/forgot-password?email=${userEmail}&locale=${locale}`, {
      email: userEmail,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default AuthService;
