import { useEffect, useState } from "react";
import { FormalCompetenceService } from "../../../services/formalCompetenceService";
import FormalCompetence from "../../../interfaces/competence/FormalCompetence";
import BaseDataRetrieveHookReturn from "../../../interfaces/hooks/baseDataRetrieveHookReturn";

const useGetFormalCompetencesByCareUnit = (
  careUnitId: number | null
): BaseDataRetrieveHookReturn<FormalCompetence[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formalCompetences, setFormalCompetences] = useState<
    FormalCompetence[]
  >([]);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    if (careUnitId) {
      FormalCompetenceService.getFormalCompetencesByCareUnit(careUnitId)
        .then((res) => {
          const responseData: FormalCompetence[] = res.data;
          setFormalCompetences(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setFormalCompetences([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId]);

  return {
    data: formalCompetences,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetFormalCompetencesByCareUnit;
