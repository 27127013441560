import { AddCardPayload } from "../interfaces/kanban/payloads/addCardPayload";
import { UpdateCardPayload } from "../interfaces/kanban/payloads/updateCardPayload";
import HttpCommonService from "./httpCommonService";

const KanbanService = {
  getKanbanStatuses: async () => {
    return await HttpCommonService.getAsync(`api/kanban/statuses`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getKanbaTasksByStatuses: async (careUnitId: number) => {
    return await HttpCommonService.getAsync(`api/kanban/tasks/${careUnitId}/group/status`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  addKanbanCard: async (card: AddCardPayload) => {
    return await HttpCommonService.postAsync(`api/kanban/tasks`, card)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  updateKanbanCard: async (card: UpdateCardPayload) => {
    return await HttpCommonService.putAsync(`api/kanban/tasks/${card.id}`, card)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  deleteKanbanCard: async (cardId: number) => {
    return await HttpCommonService.deletAsync(`api/kanban/tasks/${cardId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default KanbanService;
