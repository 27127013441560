import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input, Select } from "../../../../../components";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import { ISelectOption } from "../../../../../interfaces";
import { Hospital } from "../../../../../interfaces/orgstructure";
import { HospitalDto } from "../../../../../interfaces/orgstructure/dtos";
import { CareProviderFormProps } from "../../../../../interfaces/props/org-structure-scrollbar";
import OrgService from "../../../../../services/orgService";
import { ScrollBarConstants } from "../../../utils/constants";

const countries: ISelectOption[] = [
  {
    label: "Sweden",
    value: "sweden",
  },
];

const CareProvider = ({
  variant,
  setCareProviders,
  setIsScrollBarOpen,
  setSuccessNotification,
}: CareProviderFormProps) => {
  const { t: translate } = useTranslation();

  const [hospital, setHospital] = useState<Hospital>({
    id: 0,
    name: "",
    region: "",
    country: "",
  });

  const [selectedHospitalCountry, setSelectedHospitalCountry] = useState<ISelectOption>({
    value: countries[0].value,
    label: countries[0].label,
  });

  const [isAddHospitalFailed, setIsAddHospitalFailed] = useState<boolean>(false);

  const handleHospitalInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setHospital((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleCountrySelectChange = (option: ISelectOption) => {
    setSelectedHospitalCountry({
      value: option.value,
      label: option.label,
    });
  };

  const handleAddHospitalSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const hospitalDto: HospitalDto = {
      hospitalDto: {
        name: hospital.name,
        region: hospital.region,
        country: selectedHospitalCountry.value,
      },
    };

    await OrgService.createHospitalAsync(hospitalDto)
      .then((res) => {
        if (setCareProviders)
          setCareProviders((prevArray) => [
            ...prevArray,
            {
              id: res.id,
              name: hospital.name,
              region: hospital.region,
              country: hospital.country,
            },
          ]);
        setHospital({ id: 0, name: "", region: "", country: "" });
        setIsAddHospitalFailed(false);
        setIsScrollBarOpen(false);
        setSuccessNotification && setSuccessNotification("Care Provider");
      })
      .catch((err) => {
        setIsAddHospitalFailed(true);
        throw err;
      });
  };

  return (
    <>
      {isAddHospitalFailed && (
        <div className="my-3 h-12">
          <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
        </div>
      )}

      <div className="flex h-screen w-full flex-col justify-center">
        <h3 className="heading-3">
          {variant === ScrollBarConstants.ADD_HOSPITAL ? translate("add_a_new") + " " : translate("edit") + " "}
          {translate("care_provider")}
        </h3>
        <form onSubmit={handleAddHospitalSubmit}>
          <div>
            <Input
              name="name"
              value={hospital.name}
              placeholder={translate("name")}
              handleInputChange={handleHospitalInputChange}
              error=""
              success=""
              type="text"
            />
          </div>

          <div>
            <Input
              name="region"
              value={hospital.region}
              placeholder={translate("region")}
              handleInputChange={handleHospitalInputChange}
              error=""
              success=""
              type="text"
            />
          </div>

          <div>
            <div className="my-2 h-[2.813rem]">
              <Select
                placeholder={translate("country")}
                options={countries}
                onSelectOption={handleCountrySelectChange}
                selectedOption={countries[0]}
              />
            </div>
          </div>

          <Button
            additionalStyles="w-1/2 h-11"
            variant="primary"
            text={ScrollBarConstants.ADD_HOSPITAL ? translate("save_care_provider") : translate("edit_hospital")}
          />
        </form>
      </div>
    </>
  );
};

export default CareProvider;
