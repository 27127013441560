import { useEffect, useState } from "react";
import WorkCompetence from "../../../../interfaces/competence/WorkCompetence";
import { WorkCompetenceService } from "../../../../services/workCompetenceService";

const useGetWorkCompetencesForCareUnit = (careUnitId: number) => {
  const [workComps, setWorkComps] = useState<WorkCompetence[]>([]);

  const getWorkCompetences = async () => {
    const res = await WorkCompetenceService.getWorkComepetencesByCareUnit(
      careUnitId
    );

    setWorkComps(res.data);
  };

  useEffect(() => {
    getWorkCompetences();
  }, [careUnitId]);

  return workComps;
};

export default useGetWorkCompetencesForCareUnit;
