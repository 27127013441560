import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import TabbarSecondary from "../../../components/tab-bar/tabbar-secondary/TabbarSecondary";
import { GridLayout } from "../../../layouts";
import useTabNavigation from "../../../common/hooks/navigation/useTabNavigation";

const ProductivityHome = () => {
  const { t: translate } = useTranslation();

  const tabs = [
    translate("WorkShifts"),
    translate("productivity"),
    translate("Benchmarking"),
    translate("Capacity"),
    translate("Budget"),
  ];

  const paths = [
    "/productivity/shifts",
    "/productivity/productivity",
    "/productivity/benchmarking",
    "/productivity/capacity",
    "/productivity/budget",
  ];

  const { selectedTabIndex, navigateToTab } = useTabNavigation(paths);

  const handleTabbarClick = (clickedIndex: number) => {
    navigateToTab(clickedIndex);
  };

  return (
    <GridLayout additionalStyles="mt-0">
      <div className="col-start-1 col-end-13 mt-0">
        <div className="flex items-center justify-between">
          <TabbarSecondary
            selectedTabIndex={selectedTabIndex}
            tabs={tabs}
            onClick={handleTabbarClick}
          />
        </div>
        <div className="mt-0 h-[86vh] rounded-b rounded-r border-2 border-solid">
          <Outlet />
        </div>
      </div>
    </GridLayout>
  );
};

export default ProductivityHome;
