import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "../../interfaces/userstate";

const createInitialState = (): UserState => {
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  return {
    user: userData,
    error: null,
    isLoggedIn: !!userData.id,
    roleType: localStorage.getItem("user") && userData.roles[0].type,
    role: localStorage.getItem("user") && userData.roles[0].role,
    orgIds:
      localStorage.getItem("user") &&
      userData.roles.map((role: any) => {
        return role.careUnitId;
      }),
  };
};

const name = "auth";
const initialState = createInitialState();

const slice = createSlice({
  name,
  initialState,
  reducers: {
    login: (state: UserState, action: any) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.roleType = action.payload.roles[0].type;
      state.role = action.payload.roles[0].role;

      state.orgIds =
        action.payload.roles[0].type === "Hospital"
          ? action.payload.roles.map((role: any) => {
              return role.hospitalId;
            })
          : action.payload.roles[0].type === "Department"
          ? action.payload.roles.map((role: any) => {
              return role.departmentId;
            })
          : action.payload.roles[0].type === "CareUnit"
          ? action.payload.roles.map((role: any) => {
              return role.careUnitId;
            })
          : action.payload.roles.map(() => {
              return null;
            });
    },
    logout: (state) => {
      localStorage.removeItem("user");
      state.user = null;
      state.isLoggedIn = false;
      state.roleType = null;
      state.role = null;
      state.orgIds = null;
    },
  },
});

export const { login, logout } = slice.actions;
export const authReducer = slice.reducer;
