import { HotTableClass as HotTable } from "@handsontable/react";
import { CellChange, ChangeSource } from "handsontable/common";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, UploadButton } from "../../../components";
import { Agreement } from "../../../interfaces/parameters/Agreement";
import { UploadParamterProps } from "../../../interfaces/parameters/ParameterProps";
import { GridLayout } from "../../../layouts";
import ParameterService from "../../../services/parameterService";
import { AGREEMENT_ENDPOINT } from "../../../utils/apiendpoints";
import ExportExcel from "../../../utils/ExportExcel";
import { adjustAgreementColumnHeaderColour } from "../../../utils/handsontable-configuration";
import { OohCompType } from "../../../interfaces/parameters/OohCompType";
import useGetAgreementColumns from "./hooks/useGetAgreementColumns";

const AgreementHome = ({
  setFileAddSuccessNotification,
  setFileAddFailNotification,
  setLoading,
  loadingStat,
  readOnly,
}: UploadParamterProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<Agreement[]>([]);
  const { t: translate } = useTranslation();
  const [updatedIndexes, setUpdatedIndexes] = useState<number[]>([]);
  const [saveStatus, setSaveStatus] = useState<boolean>(false);
  const [saveFailedStatus, setSaveFailedStatus] = useState<boolean>(false);
  const [oohCompTypes, setOohCompTypes] = useState<OohCompType[]>([]);

  const agreements_data_schema = {
    agreementName: "",
    weeklyWorkingHours: 0,
    oohCompTypeId: 1,
    poAdder: 0,
    workingDays: 0,
    sickLeaveComp: 0,
    oohTimeComp: 0,
    oohMoneyComp: 0,
  };

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      await ParameterService.uploadExcelAsync(file[0], AGREEMENT_ENDPOINT)
        .then(() => {
          if (setFileAddSuccessNotification) setFileAddSuccessNotification("File Saved Successfully");
        })
        .catch((err) => {
          if (setFileAddFailNotification && err.response.data)
            setFileAddFailNotification(err.response.data.ErrorMessage);
        });
      e.target.value = "";
    } else setFileAddFailNotification && setFileAddFailNotification("File is not selected");
  };

  const uploadAgreementExcel = () => {
    inputRef.current?.click();
  };

  const dataTable = useRef<HotTable>(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  dataTable.current?.__hotInstance?.updateSettings({
    readOnly: readOnly,
  });

  const handleOnSaveClick = () => {
    setIsSaveButtonDisabled(true);
    setLoading && setLoading("Saving Parameter Data");
    const uniqueUpdatedIndexes = updatedIndexes.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    ParameterService.saveAgreementsAsync(data, uniqueUpdatedIndexes)
      .then(() => {
        setFileAddSuccessNotification && setFileAddSuccessNotification("Agreement Data Saved Successfully");
        setLoading && setLoading("");
        setSaveStatus(true);
        setUpdatedIndexes([]);
      })
      .catch((err) => {
        setFileAddFailNotification && setFileAddFailNotification("Something went wrong");
        setLoading && setLoading("");
        setSaveFailedStatus(true);
        throw err;
      });
  };

  //handle the updated row indexes
  const handleUpdatedIndexes = (changes: CellChange[]) => {
    if (changes !== null) {
      changes.forEach(([row]) => {
        const physicalRowIndex = dataTable.current?.__hotInstance?.toPhysicalRow(row);
        if (physicalRowIndex !== undefined) {
          setUpdatedIndexes((prevArray) => [...prevArray, physicalRowIndex + 1]);
        }
      });
    }
  };

  const getParameterData = async () => {
    if (setLoading) {
      setLoading("Loading Parameter Data");
      await ParameterService.getParametersAsync(AGREEMENT_ENDPOINT)
        .then((res) => {
          if (res.length > 0) setData(res);
          setLoading("");
        })
        .catch((err) => {
          setLoading("");
          throw err;
        });
    }
  };

  const getOohCompTypes = async () => {
    await ParameterService.getOohCompTypes()
      .then((res) => {
        const responseData: OohCompType[] = res;
        if (responseData.length > 0) setOohCompTypes(responseData);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getOohCompTypes();
  }, []);

  useEffect(() => {
    getParameterData();
    if (saveStatus === true) setSaveStatus(false);
    if (saveFailedStatus === true) setSaveFailedStatus(false);
  }, [saveStatus, saveFailedStatus]);

  const cols = useGetAgreementColumns(localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE", oohCompTypes);

  return (
    <GridLayout additionalStyles="mt-0 border-2 rounded-r rounded-b h-[86vh] px-0 bg-[#F8F8F8]">
      <div className="col-start-1 col-end-13">
        <div className="flex items-center justify-between bg-white px-2 py-2">
          <div className="col-start-1 col-end-7 items-center justify-between gap-3"></div>
          <div className="col-start-7 col-end-13 mt-0 flex pr-0">
            <div className="mr-4 items-center justify-between gap-3">
              <ExportExcel apiData={data} fileName={"Agreement"} disabled={loadingStat !== ""} />
            </div>
            {!readOnly && (
              <>
                {/* temporarily disabled excel upload of agreements
                <div className="mx-4 items-center justify-between gap-3">
                  <UploadButton text={translate("UploadData")} onClick={uploadAgreementExcel} />
                  <input hidden ref={inputRef} type="file" onInput={onFileInputChange} />
                </div> */}
                <div className="items-center justify-between gap-3">
                  <Button
                    disabled={isSaveButtonDisabled}
                    onClick={handleOnSaveClick}
                    text={"Save Changes"}
                    variant={"primary"}
                    additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className=" rounded px-2">
          <HotTable
            language={localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE"}
            numericFormat={{
              culture: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
              pattern: {},
            }}
            data={data}
            dataSchema={agreements_data_schema}
            columns={cols.map((c) => c.meta)}
            ref={dataTable}
            licenseKey="non-commercial-and-evaluation"
            contextMenu={
              readOnly === false
                ? ["row_above", "row_below", "remove_row", "alignment", "copy", "cut"]
                : ["alignment", "copy"]
            }
            rowHeights={35}
            height="79vh"
            fixedColumnsLeft={3}
            afterGetColHeader={adjustAgreementColumnHeaderColour}
            cells={() => {
              return {
                className: "htMiddle",
              };
            }}
            colHeaders={cols.map((c) => translate(c.headerTitle).replace("\n", "<br />"))}
            colWidths={cols.map((c) => c.width)}
            rowHeaders={true}
            rowHeaderWidth={30}
            columnSorting={true}
            manualRowMove={true}
            filters={true}
            dropdownMenu={["filter_by_condition", "filter_action_bar", "filter_by_value"]}
            afterChange={(changes: CellChange[] | null, source: ChangeSource) => {
              if (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste") {
                dataTable.current?.hotInstance?.validateCells((valid) => {
                  if (valid) {
                    setIsSaveButtonDisabled(false);
                    changes && handleUpdatedIndexes(changes);
                  } else {
                    setIsSaveButtonDisabled(true);
                  }
                });
              }
            }}
            afterCreateRow={() => {
              setIsSaveButtonDisabled(false);
            }}
            afterRemoveRow={() => {
              setIsSaveButtonDisabled(false);
            }}
            afterRowMove={() => {
              setIsSaveButtonDisabled(false);
            }}
          />
        </div>
      </div>
    </GridLayout>
  );
};

export default AgreementHome;
