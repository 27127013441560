import { createSlice } from "@reduxjs/toolkit";
import { GapAnalysisState } from "../../interfaces/gap-analysis-state";

const createInitialState = (): GapAnalysisState => {
  return {
    competences: [],
  };
};

const name = "gapAnalysis";
const initialState = createInitialState();

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectedCompetences: (state, action) => {
      state.competences = action.payload;
    },
  },
});

export const { setSelectedCompetences } = slice.actions;
export const gapAnalysisReducer = slice.reducer;
