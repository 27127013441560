import React from "react";
import { QueueCuttingPlanRequest } from "../interfaces/production-plan/outpatient/queue/queueCuttingPlan";
import HttpCommonService from "./httpCommonService";
import { OutpatientPlanResultRequest } from "../interfaces/production-plan/outpatient/outpatientPlanResultRequest";

const queueCuttingPlanService = {
  saveQueueCuttingPlan: async (data: QueueCuttingPlanRequest) => {
    return await HttpCommonService.putAsync("api/queue/plan/update", data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  resetQueueCuttingPlan: async (careUnitId: number, scenarioId: number, year: number) => {
    const payload = {
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      year: year,
    };

    return await HttpCommonService.putAsync("api/queue/plan/reset", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getQueueCuttingPlan: async (request: OutpatientPlanResultRequest) => {
    return await HttpCommonService.postAsync(`api/queue/plan/kpis`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default queueCuttingPlanService;
