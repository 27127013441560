import { ScenarioRead, ScenarioSaveRequest } from "../interfaces/parameters/scenario";
import HttpCommonService from "./httpCommonService";

const ScenarioService = {
  getScenario: async (): Promise<ScenarioRead[]> => {
    return await HttpCommonService.getAsync(`api/scenarios`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  saveScenarios: async (scenario: ScenarioSaveRequest) => {
    const createScenarioDto = {
      createScenarioDto: {
        scenarioName: scenario.scenarioName,
        lockUntil: scenario.lockUntil,
      },
    };

    return await HttpCommonService.postAsync("api/scenarios", createScenarioDto)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  updateScenarios: async (scenario: ScenarioSaveRequest) => {
    const updateScenarioDto = {
      updateScenarioDto: {
        id: scenario.id,
        scenarioName: scenario.scenarioName,
        lockUntil: scenario.lockUntil,
      },
    };

    return await HttpCommonService.putAsync("api/scenarios", updateScenarioDto)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ScenarioService;
