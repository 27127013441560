import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import WaterfallKpiService from "../../../../services/kpiService";
import { WaterfallKpi } from "../../../../interfaces/productivity";
import useHHMMFormat from "../../../../hooks/use-number-hhmm-formatting";
import { useNumberFormat } from "../../../../hooks/use-number-format";

const WaterfallKpis = () => {
  const { t: translate, i18n } = useTranslation();
  const { tohhmmFormat: tohhmmFormat } = useHHMMFormat();
  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const { localize } = useNumberFormat();

  const locale = localStorage.getItem("i18nextLng") === "sv" ? "sv-SE" : "en";

  const [kpis, setKpis] = useState<WaterfallKpi[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  useEffect(() => {
    if (scenario) {
      WaterfallKpiService.getWaterfallKPIs(careUnitId, careUnitTypeId, year, scenario).then((res) =>
        setKpis(() => res)
      );
      setDataLoaded(true);
    }
  }, [careUnitId, year, scenario]);

  const formatNumberWithSpaceSeparator = (number: number) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const options = {
    useGrouping: true,
    groupingSeparator: " ",
  };

  if (dataLoaded) {
    return (
      <div className="px-4">
        {dataLoaded && kpis.length > 0 && (
          <>
            {careUnitTypeId === 1 && (
              <>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate("working_hours_per_care_visit")}</h1>
                  <p className="sidearea__text--value-v2">
                    {kpis[2]?.kpiValue ? localize(kpis[2]?.kpiValue, i18n.language) : kpis[2]?.kpiValue}
                  </p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate("staffing_cost_per_care_visit")}</h1>
                  <p className="sidearea__text--value-v2">{formatNumberWithSpaceSeparator(kpis[3]?.kpiValue)} kr</p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate("daily_visits_per_rn")}</h1>
                  <p className="sidearea__text--value-v2">
                    {kpis[4]?.kpiValue ? localize(kpis[4]?.kpiValue, i18n.language) : kpis[4]?.kpiValue}
                  </p>
                </div>
              </>
            )}

            {careUnitTypeId === 2 && (
              <>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate("working_hours_per_care_day")}</h1>
                  <p className="sidearea__text--value-v2">{kpis[2]?.kpiValue}</p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate("staffing_cost_per_care_day")}</h1>
                  <p className="sidearea__text--value-v2">{formatNumberWithSpaceSeparator(kpis[3]?.kpiValue)} kr</p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate("patients_per_rn")}</h1>
                  <p className="sidearea__text--value-v2">{kpis[4]?.kpiValue}</p>
                </div>
              </>
            )}

            {careUnitTypeId === 3 && (
              <>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate(kpis[0].kpiName)}</h1>
                  <p className="sidearea__text--value-v2">
                    {Number(kpis[0]?.kpiValue.toFixed(1)).toLocaleString(locale)}
                  </p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate(kpis[1].kpiName)}</h1>
                  <p className="sidearea__text--value-v2">
                    {Number(kpis[1]?.kpiValue.toFixed(0)).toLocaleString(locale) + " kr"}
                  </p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate(kpis[2].kpiName)}</h1>
                  <p className="sidearea__text--value-v2">{tohhmmFormat(kpis[2]?.kpiValue)}</p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate(kpis[3].kpiName)}</h1>
                  <p className="sidearea__text--value-v2">
                    {Number(kpis[3]?.kpiValue.toFixed(0)).toLocaleString(locale) + " kr"}
                  </p>
                </div>
                <div className="sidearea__text--container-v2">
                  <h1 className="sidearea__text--title-v2">{translate(kpis[4].kpiName)}</h1>
                  <p className="sidearea__text--value-v2">{tohhmmFormat(kpis[4]?.kpiValue)}</p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default WaterfallKpis;
