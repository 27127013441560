import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import {
  Button,
  HideToggle,
  Input,
  MultiSelect,
  SaveIconButton,
  DeleteIconButton,
  Select,
  TextBoxModal,
  StatusIconModal,
} from "../../../../../components";
import { useTranslation } from "react-i18next";
import { ISelectOption, MultiSelectOption } from "../../../../../interfaces";
import { DetailedPlanSidePanelProps } from "../../../../../interfaces/production-plan";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { DetailPlanFilterOptionResponse } from "../../../../../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import ControlIcon from "../../../Components/ControlIcon/ControlIcon";
import { ControlIconTypes } from "../../../Components/ControlIcon/IconTypes";
import { v4 as uuid } from "uuid";
import startCase from "lodash";
import FilterGroupService from "../../../../../services/filterGroupService";
import { AxiosResponse } from "axios";
import { FilterGroupSchema } from "../../../../../interfaces/filter-group/filter-group-schema";
import { FilterGroupDimensions } from "../../../../../interfaces/filter-group/filter-group-dimension";
import { FilterGroupPayload } from "../../../../../interfaces/filter-group/filter-group";
import { Status } from "../../../../../utils/types/status-enum";
import NotificationBox from "../../../../../components/NotificationBox/NotificationBox";
import UseGetStaticDimensionIds from "../../../../analytics/ActivityAnalysis/FilterGroup/hooks/useGetStaticDimensionIds";
import useGetOutpatientDimensions from "../../Hooks/useGetOutpatientDimensions";

const nextIcon = <ControlIcon type={ControlIconTypes.next} />;
const prevIcon = <ControlIcon type={ControlIconTypes.perv} />;

const DetailPlanSidePanel = ({
  detailedPlanRequest,
  setDetailedPlanRequest,
  annualDetailedPlan,
  outpatientDetailedPlan,
  setOutpatientDetailedPlan,
  setIsDetailedPlanEdited,
  handleDetailedPlanNextAndPreviousClick,
  selectedTabIndex,
  isOutpatientParametersModifying,
}: DetailedPlanSidePanelProps) => {
  const { t: translate } = useTranslation();
  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  // available options
  const type_of_visits: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("follow_up_visit"),
      value: "0",
    },
    { id: uuid(), label: translate("new_visit"), value: "1" },
  ];
  const type_of_emergency: MultiSelectOption[] = [
    {
      id: uuid(),
      label: translate("is_planned"),
      value: "0",
    },
    {
      id: uuid(),
      label: translate("is_emergency"),
      value: "1",
    },
  ];
  const [typesOfVisits, setTypesOfVisit] = useState<MultiSelectOption[]>([]);
  const [typesOfEmergency, setTypesOfEmergency] = useState<MultiSelectOption[]>([]);
  const [contactTypes, setContactTypes] = useState<MultiSelectOption[]>([]);
  const [professions, setProfessions] = useState<MultiSelectOption[]>([]);
  const [contactReasons, setContactReasons] = useState<MultiSelectOption[]>([]);
  const [careTypes, setCareTypes] = useState<MultiSelectOption[]>([]);
  const [medicalSpecialties, setMedicalSpecialties] = useState<MultiSelectOption[]>([]);

  const [filterGroupDropDownList, setFilterGroupDropDownList] = useState<ISelectOption[]>([]);
  const [filterGroupList, setFilterGroupList] = useState<FilterGroupSchema[]>([]);
  const [isDeleteFilterGroupButtonsDisabled, setIsDeleteFilterGroupButtonsDisabled] = useState(true);

  const [isFilterGroupSaveModalVisible, setIsFilterGroupSaveModalVisible] = useState<boolean>(false);
  const [filterGroupNameInputValue, setFilterGroupNameInputValue] = useState<string>("");
  const [filterGroupNameModalError, setFilterGroupNameModalError] = useState<string>();
  const [filterGroupSaveStatus, setfilterGroupSaveStatus] = useState<Status | null>(null);
  const [filterGroupDeleteStatus, setfilterGroupDeleteStatus] = useState<Status | null>(null);

  const outpatientDimensions = useGetOutpatientDimensions(careUnitId, careUnitTypeId);

  // selected options
  const [selectedVisit, setSelectedVisit] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedTypeOfEmergency, setSelectedTypeOfEmergency] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedContactType, setSelectedContactType] = useState<MultiSelectOption[]>([
    { id: uuid(), value: "null", label: translate("all") },
  ]);

  const [selectedProfession, setSelectedProfession] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedContactReason, setSelectedContactReason] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedCareType, setSelectedCareType] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedMedicalSpecialties, setSelectedMedicalSpecialties] = useState<MultiSelectOption[]>([
    {
      id: uuid(),
      value: "null",
      label: translate("all"),
    },
  ]);

  const [selectedFilterGroup, setSelectedFilterGroup] = useState<ISelectOption>({
    value: "null",
    label: translate("all"),
  });

  const [notificationBoxState, setNotificationBoxState] = useState<{
    isOpen: boolean;
    entityId: string | undefined;
    entityName: string | undefined;
  }>({
    isOpen: false,
    entityId: undefined,
    entityName: undefined,
  });

  useEffect(() => {
    const mapSelectedValues = (selectedValues: MultiSelectOption[]) =>
      selectedValues.filter((x) => x.value !== "null").map((x) => parseInt(x.value.toString(), 10));

    if (
      selectedVisit.length === typesOfVisits.length - 1 ||
      selectedTypeOfEmergency.length === typesOfEmergency.length - 1 ||
      selectedProfession.length === professions.length - 1 ||
      selectedContactReason.length === contactReasons.length - 1 ||
      selectedCareType.length === careTypes.length - 1 ||
      selectedContactType.length === contactTypes.length - 1 ||
      selectedMedicalSpecialties.length === medicalSpecialties.length - 1
    ) {
      selectedVisit.length === typesOfVisits.length - 1 ? setSelectedVisit(typesOfVisits) : selectedVisit;
      selectedTypeOfEmergency.length === typesOfEmergency.length - 1
        ? setSelectedTypeOfEmergency(typesOfEmergency)
        : selectedTypeOfEmergency;
      selectedProfession.length === professions.length - 1 ? setSelectedProfession(professions) : selectedProfession;
      selectedContactReason.length === contactReasons.length - 1
        ? setSelectedContactReason(contactReasons)
        : selectedContactReason;
      selectedCareType.length === careTypes.length - 1 ? setSelectedCareType(careTypes) : selectedCareType;
      selectedContactType.length === contactTypes.length - 1
        ? setSelectedContactType(contactTypes)
        : selectedContactType;
      selectedMedicalSpecialties.length === medicalSpecialties.length - 1
        ? setSelectedMedicalSpecialties(medicalSpecialties)
        : selectedMedicalSpecialties;
    } else {
      if (
        selectedVisit.length > 0 &&
        selectedTypeOfEmergency.length > 0 &&
        selectedProfession.length > 0 &&
        selectedContactReason.length > 0 &&
        selectedCareType.length > 0 &&
        selectedContactType.length > 0 &&
        selectedMedicalSpecialties.length > 0
      ) {
        setDetailedPlanRequest({
          contactTypeIds:
            isEqual(selectedContactType, contactTypes) || selectedContactType.length === 0
              ? [-1]
              : mapSelectedValues(selectedContactType),
          typesOfVisit:
            isEqual(selectedVisit, typesOfVisits) || selectedVisit.length === 0
              ? [-1]
              : mapSelectedValues(selectedVisit),
          typesOfEmergency:
            isEqual(selectedTypeOfEmergency, typesOfEmergency) || selectedTypeOfEmergency.length === 0
              ? [-1]
              : mapSelectedValues(selectedTypeOfEmergency),
          professionIds:
            isEqual(selectedProfession, professions) || selectedProfession.length === 0
              ? [-1]
              : mapSelectedValues(selectedProfession),
          contactReasonGroupingIds:
            isEqual(selectedContactReason, contactReasons) || selectedContactReason.length === 0
              ? [-1]
              : mapSelectedValues(selectedContactReason),
          careTypeIds:
            isEqual(selectedCareType, careTypes) || selectedCareType.length === 0
              ? [-1]
              : mapSelectedValues(selectedCareType),
          medicalSpecialtyIds:
            isEqual(selectedMedicalSpecialties, medicalSpecialties) || selectedMedicalSpecialties.length === 0
              ? [-1]
              : mapSelectedValues(selectedMedicalSpecialties),
          contactReasonGroupingEnabled: selectedContactReasonFilterVisibility,
          careTypeEnabled: selectedCareTypeFilterVisibility,
          professionEnabled: selectedProfessionFilterVisibility,
          contactTypeEnabled: selectedContactTypeFilterVisibility,
          newVisitEnabled: selectedVisitFilterVisibility,
          typeOfEmergencyEnabled: selectedTypeOfEmergencyFilterVisibility,
          medicalSpecialtyEnabled: selectedMedicalSpecialtyFilterVisibility,
        });
      }
    }
  }, [
    selectedVisit,
    selectedTypeOfEmergency,
    selectedProfession,
    selectedContactReason,
    selectedCareType,
    selectedContactType,
    selectedMedicalSpecialties,
  ]);

  const [selectedVisitFilterVisibility, setSelectedVisitFilterVisibility] = useState<boolean>(true);
  const [selectedContactTypeFilterVisibility, setSelectedContactTypeFilterVisibility] = useState<boolean>(false);
  const [selectedProfessionFilterVisibility, setSelectedProfessionFilterVisibility] = useState<boolean>(false);
  const [selectedContactReasonFilterVisibility, setSelectedContactReasonFilterVisibility] = useState<boolean>(false);
  const [selectedCareTypeFilterVisibility, setSelectedCareTypeFilterVisibility] = useState<boolean>(false);
  const [selectedTypeOfEmergencyFilterVisibility, setSelectedTypeOfEmergencyFilterVisibility] =
    useState<boolean>(false);
  const [selectedMedicalSpecialtyFilterVisibility, setSelectedMedicalSpecialtyFilterVisibility] =
    useState<boolean>(false);

  const [enabledFilters, setEnabledFilters] = useState<number>(1);

  const getDimensionsForDetailPlan = () => {
    const defaultFirstOption: MultiSelectOption = {
      id: uuid(),
      value: "null",
      label: translate("all"),
    };

    const professions: MultiSelectOption[] = [];
    const contactTypes: MultiSelectOption[] = [];
    const contactReasons: MultiSelectOption[] = [];
    const careTypes: MultiSelectOption[] = [];
    const medicalSpecs: MultiSelectOption[] = [];
    const typesOfVisit: MultiSelectOption[] = [];
    const typesOfEmergency: MultiSelectOption[] = [];

    professions.length === 0 && professions.push(defaultFirstOption);
    contactTypes.length === 0 && contactTypes.push(defaultFirstOption);
    contactReasons.length === 0 && contactReasons.push(defaultFirstOption);
    careTypes.length === 0 && careTypes.push(defaultFirstOption);
    medicalSpecs.length === 0 && medicalSpecs.push(defaultFirstOption);
    typesOfVisit.length === 0 && typesOfVisit.push(defaultFirstOption);
    typesOfEmergency.length === 0 && typesOfEmergency.push(defaultFirstOption);

    outpatientDimensions.data.contactTypes.map((obj: DetailPlanFilterOptionResponse) => {
      contactTypes.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.professions.map((obj: DetailPlanFilterOptionResponse) => {
      professions.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.contactReasonGroupings.map((obj: DetailPlanFilterOptionResponse) => {
      contactReasons.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.careTypes.map((obj: DetailPlanFilterOptionResponse) => {
      careTypes.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    outpatientDimensions.data.medicalSpecialties.map((obj: DetailPlanFilterOptionResponse) => {
      medicalSpecs.push({
        id: uuid(),
        value: obj.id.toString(),
        label: obj.name,
      });
    });

    type_of_visits.map((obj: MultiSelectOption) => {
      typesOfVisit.push({
        id: obj.id,
        value: obj.value,
        label: obj.label,
      });
    });

    type_of_emergency.map((obj: MultiSelectOption) => {
      typesOfEmergency.push({
        id: obj.id,
        value: obj.value,
        label: obj.label,
      });
    });

    setProfessions(professions);
    setContactTypes(contactTypes);
    setContactReasons(contactReasons);
    setCareTypes(careTypes);
    setMedicalSpecialties(medicalSpecs);
    setTypesOfVisit(typesOfVisit);
    setTypesOfEmergency(typesOfEmergency);

    setSelectedContactType(contactTypes);
    setSelectedContactReason(contactReasons);
    setSelectedProfession(professions);
    setSelectedCareType(careTypes);
    setSelectedMedicalSpecialties(medicalSpecs);
    setSelectedVisit(typesOfVisit);
    setSelectedTypeOfEmergency(typesOfEmergency);
  };

  const getFilterGroups = async () => {
    if (careUnitId) {
      await FilterGroupService.getFilterGroup([careUnitId]).then((res: AxiosResponse<FilterGroupSchema[]>) => {
        const response = res.data as FilterGroupSchema[];
        setFilterGroupList(response);
      });
    }
  };

  useEffect(() => {
    const defaultFirstOption: ISelectOption = {
      value: "null",
      label: translate("all"),
    };

    const filterGroups: ISelectOption[] = [];
    filterGroups.push(defaultFirstOption);

    filterGroupList.map((obj: FilterGroupSchema) => {
      filterGroups.push({
        value: obj.id.toString(),
        label: translate(obj.filterGroupName),
      });
    });

    setFilterGroupDropDownList(filterGroups);
  }, [filterGroupList]);

  useEffect(() => {
    if (careUnitId !== null && careUnitTypeId != null && careUnitTypeId == 1) {
      getFilterGroups();
    }
  }, [careUnitId]);

  useEffect(() => {
    if (careUnitId !== null && careUnitTypeId != null && careUnitTypeId == 1) {
      getDimensionsForDetailPlan();
    }
  }, [outpatientDimensions.data]);

  const handleDetailPlanInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDetailedPlanEdited(true);
    const { value } = e.target;

    if (value === "") {
      setOutpatientDetailedPlan(0);
    } else {
      setOutpatientDetailedPlan(parseInt(value));
    }
  };

  const handleTypeOfVisitSelect = (option: MultiSelectOption) => {
    let selectedTypeOfVisistsFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedVisit(typesOfVisits);
    } else {
      const isSelectedOptionExists = selectedVisit.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedTypeOfVisistsFromSelect = selectedVisit.filter((x) => x.value !== "null");
        selectedTypeOfVisistsFromSelect = [...selectedTypeOfVisistsFromSelect, option];
        setSelectedVisit(selectedTypeOfVisistsFromSelect);
      }
    }
  };

  const handleTypeOfVisitDeselect = (option: MultiSelectOption) => {
    if (selectedVisit.length <= typesOfVisits.length && selectedVisit.length > 1) {
      if (option.value === "null") {
        setSelectedVisit([]);
      } else {
        const isDeselectedOptionExists =
          selectedVisit.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedVisit(() => {
            return selectedVisit.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleTypeOfEmergencySelect = (option: MultiSelectOption) => {
    let selectedTypesOfEmergencyFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedTypeOfEmergency(typesOfEmergency);
    } else {
      const isSelectedOptionExists =
        selectedTypeOfEmergency.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedTypesOfEmergencyFromSelect = selectedTypeOfEmergency.filter((x) => x.value !== "null");
        selectedTypesOfEmergencyFromSelect = [...selectedTypesOfEmergencyFromSelect, option];
        setSelectedTypeOfEmergency(selectedTypesOfEmergencyFromSelect);
      }
    }
  };

  const handleTypeOfEmergencyDeselect = (option: MultiSelectOption) => {
    if (selectedTypeOfEmergency.length <= typesOfEmergency.length && selectedTypeOfEmergency.length > 1) {
      if (option.value === "null") {
        setSelectedTypeOfEmergency([]);
      } else {
        const isDeselectedOptionExists =
          selectedTypeOfEmergency.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedTypeOfEmergency(() => {
            return selectedTypeOfEmergency.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleContactTypeSelect = (option: MultiSelectOption) => {
    let selectedcontactTypeFromSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedContactType(contactTypes);
    } else {
      const isSelectedOptionExists =
        selectedContactType.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedcontactTypeFromSelect = selectedContactType.filter((x) => x.value !== "null");
        selectedcontactTypeFromSelect = [...selectedcontactTypeFromSelect, option];
        setSelectedContactType(selectedcontactTypeFromSelect);
      }
    }
  };

  const handleContactTypeDeselect = (option: MultiSelectOption) => {
    if (selectedContactType.length <= contactTypes.length && selectedContactType.length > 1) {
      if (option.value === "null") {
        setSelectedContactType([]);
      } else {
        const isDeselectedOptionExists =
          selectedContactType.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedContactType(() => {
            return selectedContactType.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleProfessionSelect = (option: MultiSelectOption) => {
    let selectedProfessionsSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedProfession(professions);
    } else {
      const isSelectedOptionExists =
        selectedProfession.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedProfessionsSelect = selectedProfession.filter((x) => x.value !== "null");
        selectedProfessionsSelect = [...selectedProfessionsSelect, option];
        setSelectedProfession(selectedProfessionsSelect);
      }
    }
  };

  const handleProfessionDeselect = (option: MultiSelectOption) => {
    if (selectedProfession.length <= professions.length && selectedProfession.length > 1) {
      if (option.value === "null") {
        setSelectedProfession([]);
      } else {
        const isDeselectedOptionExists =
          selectedProfession.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedProfession(() => {
            return selectedProfession.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleContactReasonSelect = (option: MultiSelectOption) => {
    let selectedContactReasonSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedContactReason(contactReasons);
    } else {
      const isSelectedOptionExists =
        selectedContactReason.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedContactReasonSelect = selectedContactReason.filter((x) => x.value !== "null");
        selectedContactReasonSelect = [...selectedContactReasonSelect, option];
        setSelectedContactReason(selectedContactReasonSelect);
      }
    }
  };

  const handleContactReasonDeselect = (option: MultiSelectOption) => {
    if (selectedContactReason.length <= contactReasons.length && selectedContactReason.length > 1) {
      if (option.value === "null") {
        setSelectedContactReason([]);
      } else {
        const isDeselectedOptionExists =
          selectedContactReason.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedContactReason(() => {
            return selectedContactReason.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleCareTypeSelect = (option: MultiSelectOption) => {
    let selectedCareTypeSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedCareType(careTypes);
    } else {
      const isSelectedOptionExists = selectedCareType.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedCareTypeSelect = selectedCareType.filter((x) => x.value !== "null");
        selectedCareTypeSelect = [...selectedCareTypeSelect, option];
        setSelectedCareType(selectedCareTypeSelect);
      }
    }
  };

  const handleCareTypeDeselect = (option: MultiSelectOption) => {
    if (selectedCareType.length <= careTypes.length && selectedCareType.length > 1) {
      if (option.value === "null") {
        setSelectedCareType([]);
      } else {
        const isDeselectedOptionExists =
          selectedCareType.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedCareType(() => {
            return selectedCareType.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const handleMedicalSpecialtiesSelect = (option: MultiSelectOption) => {
    let selectedMedicalSpecialtiesSelect: MultiSelectOption[] = [];

    if (option.value == "null") {
      setSelectedMedicalSpecialties(medicalSpecialties);
    } else {
      const isSelectedOptionExists =
        selectedMedicalSpecialties.filter((x) => x.value === option.value).length > 0 ? true : false;
      if (!isSelectedOptionExists) {
        selectedMedicalSpecialtiesSelect = selectedMedicalSpecialties.filter((x) => x.value !== "null");
        selectedMedicalSpecialtiesSelect = [...selectedMedicalSpecialtiesSelect, option];
        setSelectedMedicalSpecialties(selectedMedicalSpecialtiesSelect);
      }
    }
  };

  const handleMedicalSpecialtiesDeselect = (option: MultiSelectOption) => {
    if (selectedMedicalSpecialties.length <= medicalSpecialties.length && selectedMedicalSpecialties.length > 1) {
      if (option.value === "null") {
        setSelectedMedicalSpecialties([]);
      } else {
        const isDeselectedOptionExists =
          selectedMedicalSpecialties.filter((x) => x.value === option.value).length > 0 ? true : false;
        if (isDeselectedOptionExists) {
          setSelectedMedicalSpecialties(() => {
            return selectedMedicalSpecialties.filter((x) => {
              return x.value !== option.value && x.value !== "null";
            });
          });
        }
      }
    }
  };

  const getFilterGroupDimensions = async (selectedFilterGroupId: number) => {
    await FilterGroupService.getDimensionsForFilterGroup(selectedFilterGroupId).then(
      (res: AxiosResponse<FilterGroupDimensions>) => {
        const response = res.data as FilterGroupDimensions;

        const professionIds = professions.filter((r) => response.professionIds.includes(Number(r.value.toString())));
        setSelectedProfession(professionIds);

        const careTypeIds = careTypes.filter((r) => response.careTypeIds.includes(Number(r.value.toString())));
        setSelectedCareType(careTypeIds);

        const contactTypeIds = contactTypes.filter((r) => response.contactTypeIds.includes(Number(r.value.toString())));
        setSelectedContactType(contactTypeIds);

        const contactReasonGroupingIds = contactReasons.filter((r) =>
          response.contactReasonGroupingIds.includes(Number(r.value.toString()))
        );
        setSelectedContactReason(contactReasonGroupingIds);

        const isNewVisits = typesOfVisits.filter((r) => response.isNewVisits.includes(Number(r.value.toString())));
        setSelectedVisit(isNewVisits);

        const isEmergencies = typesOfEmergency.filter((r) =>
          response.isEmergencies.includes(Number(r.value.toString()))
        );
        setSelectedTypeOfEmergency(isEmergencies);

        const medicalSpecialtyIds = medicalSpecialties.filter((r) =>
          response.medicalSpecialtyIds.includes(Number(r.value.toString()))
        );
        setSelectedMedicalSpecialties(medicalSpecialtyIds);
      }
    );
  };

  const handleFilterGroupSelect = (selectedFilterGroup: ISelectOption) => {
    setSelectedFilterGroup(selectedFilterGroup);
    const filterGroup = filterGroupList.filter((filterGroup) => {
      return filterGroup.id === Number(selectedFilterGroup.value);
    });
    if (filterGroup.length > 0) {
      if (filterGroup[0].isDefaultFilterGroup) {
        setIsDeleteFilterGroupButtonsDisabled(true);
      } else {
        setIsDeleteFilterGroupButtonsDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (selectedFilterGroup.value !== "null") {
      const selectedFilterGroupId = Number(selectedFilterGroup.value);
      getFilterGroupDimensions(selectedFilterGroupId);
    } else {
      setSelectedProfession(professions);
      setSelectedCareType(careTypes);
      setSelectedContactType(contactTypes);
      setSelectedContactReason(contactReasons);
      setSelectedVisit(typesOfVisits);
      setSelectedTypeOfEmergency(typesOfEmergency);
      setSelectedMedicalSpecialties(medicalSpecialties);
      setIsDeleteFilterGroupButtonsDisabled(true);
    }
  }, [selectedFilterGroup]);

  const toogleTypeOfVisitFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedVisitFilterVisibility;
    setSelectedVisitFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest && setDetailedPlanRequest({ ...detailedPlanRequest, newVisitEnabled: !visibility });
  };

  const toogleContactTypeFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedContactTypeFilterVisibility;
    setSelectedContactTypeFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest && setDetailedPlanRequest({ ...detailedPlanRequest, contactTypeEnabled: !visibility });
  };

  const toogleProfessionFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedProfessionFilterVisibility;
    setSelectedProfessionFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest && setDetailedPlanRequest({ ...detailedPlanRequest, professionEnabled: !visibility });
  };

  const toogleContactReasonFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedContactReasonFilterVisibility;
    setSelectedContactReasonFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest &&
      setDetailedPlanRequest({ ...detailedPlanRequest, contactReasonGroupingEnabled: !visibility });
  };

  const toogleCareTypeFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedCareTypeFilterVisibility;
    setSelectedCareTypeFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest && setDetailedPlanRequest({ ...detailedPlanRequest, careTypeEnabled: !visibility });
  };

  const toogleTypeOfEmergencyFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedTypeOfEmergencyFilterVisibility;
    setSelectedTypeOfEmergencyFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest && setDetailedPlanRequest({ ...detailedPlanRequest, typeOfEmergencyEnabled: !visibility });
  };

  const toogleMedicalSpecialtyFilterVisibilityAndFetch = () => {
    const visibility: boolean = selectedMedicalSpecialtyFilterVisibility;
    setSelectedMedicalSpecialtyFilterVisibility(!visibility);
    setEnableFilterCount(!visibility);
    detailedPlanRequest && setDetailedPlanRequest({ ...detailedPlanRequest, medicalSpecialtyEnabled: !visibility });
  };

  const setEnableFilterCount = (visibility: boolean) => {
    const currentFilters = enabledFilters;
    if (visibility) {
      setEnabledFilters(currentFilters + 1);
    } else {
      setEnabledFilters(currentFilters - 1);
    }
  };

  const handleFilterGroupNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFilterGroupNameInputValue(value);
  };

  useEffect(() => {
    const isFilterGroupNameExist = filterGroupDropDownList.some(
      (x) => x.label.toLowerCase() === filterGroupNameInputValue.toLowerCase()
    );

    if (filterGroupNameInputValue === "") {
      setFilterGroupNameModalError(translate("patient_flow_name_empty"));
    } else if (isFilterGroupNameExist) {
      setFilterGroupNameModalError(translate("patient_flow_name_exists"));
    } else {
      setFilterGroupNameModalError(undefined);
    }
  }, [filterGroupNameInputValue]);

  const HandleSaveFilterGroup = async () => {
    if (careUnitId && filterGroupNameInputValue !== "") {
      const filterGroupPayload: FilterGroupPayload = {
        careUnitId: careUnitId,
        filterGroupName: filterGroupNameInputValue,
        isNewVisit: UseGetStaticDimensionIds(selectedVisit),
        professionId: UseGetStaticDimensionIds(selectedProfession),
        contactTypeId: UseGetStaticDimensionIds(selectedContactType),
        contactReasonGroupingId: UseGetStaticDimensionIds(selectedContactReason),
        careTypeId: UseGetStaticDimensionIds(selectedCareType),
        isEmergency: UseGetStaticDimensionIds(selectedTypeOfEmergency),
        medicalSpecialtyId: UseGetStaticDimensionIds(selectedMedicalSpecialties),
      };

      await FilterGroupService.saveFilterGroup(filterGroupPayload)
        .then(() => {
          setIsFilterGroupSaveModalVisible(false);
          setfilterGroupSaveStatus(1);
          getFilterGroups();
          setFilterGroupNameInputValue("");
        })
        .catch(() => {
          setIsFilterGroupSaveModalVisible(false);
          setfilterGroupSaveStatus(2);
        });
    }
  };

  const HandleCancelButtonClick = () => {
    setIsFilterGroupSaveModalVisible(false);
    setFilterGroupNameInputValue("");
  };

  useEffect(() => {
    if (filterGroupSaveStatus) {
      const timer = setTimeout(() => {
        setfilterGroupSaveStatus(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [filterGroupSaveStatus]);

  const handleNotificationBoxLeftButtonClick = async () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityId: undefined,
      isOpen: false,
    }));

    await FilterGroupService.deleteFilterGroup(parseInt(selectedFilterGroup.value), careUnitId || 0)
      .then(() => {
        setfilterGroupDeleteStatus(1);
        getFilterGroups();
      })
      .catch(() => {
        setIsFilterGroupSaveModalVisible(false);
        setfilterGroupDeleteStatus(2);
      });
  };

  const handleNotificationBoxRightButtonClick = () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityId: undefined,
      isOpen: false,
    }));
  };

  const deleteFilterGroupPrompt = async (selectedFilterGroup: ISelectOption) => {
    setNotificationBoxState(() => ({
      entityName: selectedFilterGroup.label,
      entityId: selectedFilterGroup.value,
      isOpen: true,
    }));
  };

  useEffect(() => {
    if (filterGroupDeleteStatus) {
      const timer = setTimeout(() => {
        setfilterGroupDeleteStatus(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [filterGroupDeleteStatus]);

  return (
    <>
      {notificationBoxState.isOpen && (
        <NotificationBox
          content={`${translate("delete_confirmation_for_patient_flow")} ${selectedFilterGroup.label}`}
          title={`${translate("delete")} ${startCase(notificationBoxState.entityName)}`}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxLeftButtonClick}
          onRightButtonClick={handleNotificationBoxRightButtonClick}
        />
      )}
      <div className="mr-5">
        <span className="graph-type-selector p-1-v-2 mb-4 mt-3 block">{translate("detail_plan")}</span>

        <div className="flex items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("type_of_visit")}
              selectedOptions={selectedVisit}
              options={typesOfVisits}
              onSelectItem={handleTypeOfVisitSelect}
              onRemoveSelectItem={handleTypeOfVisitDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedVisitFilterVisibility && enabledFilters >= 3}
              isVisible={selectedVisitFilterVisibility}
              onToggle={toogleTypeOfVisitFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("contact_type")}
              selectedOptions={selectedContactType}
              options={contactTypes}
              onSelectItem={handleContactTypeSelect}
              onRemoveSelectItem={handleContactTypeDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedContactTypeFilterVisibility && enabledFilters >= 3}
              isVisible={selectedContactTypeFilterVisibility}
              onToggle={toogleContactTypeFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("profession")}
              selectedOptions={selectedProfession}
              options={professions}
              onSelectItem={handleProfessionSelect}
              onRemoveSelectItem={handleProfessionDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedProfessionFilterVisibility && enabledFilters >= 3}
              isVisible={selectedProfessionFilterVisibility}
              onToggle={toogleProfessionFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-5/6">
            <MultiSelect
              label={translate("contact_reason")}
              selectedOptions={selectedContactReason}
              options={contactReasons}
              onSelectItem={handleContactReasonSelect}
              onRemoveSelectItem={handleContactReasonDeselect}
            />
          </div>
          <div className="w-1/6 pl-1 text-center">
            <HideToggle
              disabled={!selectedContactReasonFilterVisibility && enabledFilters >= 3}
              isVisible={selectedContactReasonFilterVisibility}
              onToggle={toogleContactReasonFilterVisibilityAndFetch}
              className="xl:mt-1"
            />
          </div>
        </div>

        <>
          <div className="flex items-center">
            <div className="w-5/6">
              <MultiSelect
                label={translate("care_type")}
                selectedOptions={selectedCareType}
                options={careTypes}
                onSelectItem={handleCareTypeSelect}
                onRemoveSelectItem={handleCareTypeDeselect}
              />
            </div>

            <div className="w-1/6 pl-1 text-center">
              <HideToggle
                disabled={!selectedCareTypeFilterVisibility && enabledFilters >= 3}
                isVisible={selectedCareTypeFilterVisibility}
                onToggle={toogleCareTypeFilterVisibilityAndFetch}
                className="xl:mt-1"
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-5/6">
              <MultiSelect
                label={translate("type_of_emergency")}
                selectedOptions={selectedTypeOfEmergency}
                options={typesOfEmergency}
                onSelectItem={handleTypeOfEmergencySelect}
                onRemoveSelectItem={handleTypeOfEmergencyDeselect}
              />
            </div>
            <div className="w-1/6 pl-1 text-center">
              <HideToggle
                disabled={!selectedTypeOfEmergencyFilterVisibility && enabledFilters >= 3}
                isVisible={selectedTypeOfEmergencyFilterVisibility}
                onToggle={toogleTypeOfEmergencyFilterVisibilityAndFetch}
                className="xl:mt-1"
              />
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-5/6">
              <MultiSelect
                label={translate("medical_speciality")}
                selectedOptions={selectedMedicalSpecialties}
                options={medicalSpecialties}
                onSelectItem={handleMedicalSpecialtiesSelect}
                onRemoveSelectItem={handleMedicalSpecialtiesDeselect}
              />
            </div>
            <div className="w-1/6 pl-1 text-center">
              <HideToggle
                disabled={!selectedMedicalSpecialtyFilterVisibility && enabledFilters >= 3}
                isVisible={selectedMedicalSpecialtyFilterVisibility}
                onToggle={toogleMedicalSpecialtyFilterVisibilityAndFetch}
                className="xl:mt-1"
              />
            </div>
          </div>

          <div className="mt-2 flex items-center">
            <div className="w-9/12">
              <Select
                placeholder={translate("patient_flow")}
                options={filterGroupDropDownList}
                onSelectOption={handleFilterGroupSelect}
                selectedOption={selectedFilterGroup}
              />
            </div>
            {isFilterGroupSaveModalVisible && (
              <TextBoxModal
                leftButtonLabel={translate("save")}
                rightButtonLabel={translate("cancel")}
                onPrimaryClick={HandleSaveFilterGroup}
                onSecondryClick={HandleCancelButtonClick}
                handleInputChange={handleFilterGroupNameInputChange}
                inputValue={filterGroupNameInputValue}
                inputPlaceholder={translate("patient_flow")}
                IsinputDisabled={false}
                errorLabel={filterGroupNameModalError}
              />
            )}
            {filterGroupSaveStatus && (
              <StatusIconModal
                title={
                  filterGroupSaveStatus === 1 ? translate("saved_successfully") : translate("something_went_wrong")
                }
                status={filterGroupSaveStatus}
              />
            )}
            {filterGroupDeleteStatus && (
              <StatusIconModal
                title={
                  filterGroupDeleteStatus === 1 ? translate("deleted_successfully") : translate("something_went_wrong")
                }
                status={filterGroupDeleteStatus}
              />
            )}
            <div className="mx-auto flex w-1/12 items-center justify-center">
              <SaveIconButton
                onClick={() => {
                  setIsFilterGroupSaveModalVisible(true);
                }}
              />
            </div>
            <div className="mx-auto flex w-1/12 items-center justify-center">
              <DeleteIconButton
                onClick={() => {
                  deleteFilterGroupPrompt(selectedFilterGroup);
                }}
                disabled={isDeleteFilterGroupButtonsDisabled}
              />
            </div>
          </div>
        </>

        <div>
          <div className="selection__text--container">
            <h1 className="selection__text--title">{translate("examinations_according_to_selection")}</h1>
            <p className="selection__text--value">{annualDetailedPlan}</p>
          </div>
        </div>
        <div>
          <Input
            name="detailPlan"
            value={outpatientDetailedPlan?.toString()}
            handleInputChange={handleDetailPlanInputChange}
            placeholder={translate("number_of_visits_plan")}
            disabled={isCareUnitReadOnly || isOutpatientParametersModifying ? true : false}
          />
        </div>
        <div className="flex items-center gap-4">
          <Button
            onClick={() => handleDetailedPlanNextAndPreviousClick(selectedTabIndex - 1)}
            text={translate("prev")}
            disabled={isOutpatientParametersModifying}
            variant={"button--secondary-optional"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
            icon={prevIcon}
          />

          <Button
            onClick={() => handleDetailedPlanNextAndPreviousClick(selectedTabIndex + 1)}
            text={translate("next")}
            disabled={isOutpatientParametersModifying}
            variant={"button--secondary-optional-with-border"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
            icon={nextIcon}
          />
        </div>
      </div>
    </>
  );
};

export default DetailPlanSidePanel;
