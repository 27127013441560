import React from "react";
import { KpiResponse } from "../../../interfaces/dashboard/kpis";
import { useTranslation } from "react-i18next";

import "./production-vs-plan-kpi-styles.css";

const ProductionVsPlanKpis = ({
  actualR12,
  actualVsPlan,
  plannedTotal,
  productionPercentage,
  recoveryPlan,
}: KpiResponse) => {
  const { t: translate } = useTranslation();
  return (
    <div>
      <div>
        <h1 className="sidearea__text--title-v3">{translate("plan")}</h1>
        <p className="sidearea__text--value-v3">{plannedTotal}</p>
      </div>

      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("actual_r12")}</h1>
        <p className="sidearea__text--value-v3">{actualR12}</p>
      </div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("actual_vs_plan")}</h1>
        <p className={`sidearea__text--value ${actualVsPlan < 1 ? "danger-v3" : actualVsPlan >= 1 && "success-v3"}`}>
          {Math.round(Number(parseFloat(actualVsPlan.toString()) * 100))} %
        </p>
      </div>
      <div className="sidearea__text--container-v3">
        <h1 className="sidearea__text--title-v3">{translate("production_percentage")}</h1>
        <p
          className={`sidearea__text--value ${
            productionPercentage === 0
              ? "neutral-v3"
              : productionPercentage < 0
              ? "danger-v3"
              : productionPercentage > 0 && "success-v3"
          }`}
        >
          {productionPercentage} %
        </p>
      </div>
      <div>
        <h1 className="sidearea__text--title-v3">{translate("recovery_plan_kpi")}</h1>
        <p className="sidearea__text--value-v3">{Math.round(Number(parseFloat(recoveryPlan.toString()) * 100))} %</p>
      </div>
    </div>
  );
};

export default ProductionVsPlanKpis;
