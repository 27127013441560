import React from "react";
import { ButtonProps } from "../../interfaces/props";

const Button = ({
  text,
  additionalStyles,
  type,
  disabled,
  variant,
  onClick,
  icon,
}: ButtonProps) => {
  return (
    <button
      className={`button ${
        variant === "primary"
          ? "button--primary"
          : variant === "secondary"
          ? "button--secondary"
          : variant === "territary"
          ? "button--territary"
          : variant === "button--secondary-optional"
          ? "button--secondary-optional"
          : variant === "button--secondary-optional-with-border"
          ? "button--secondary-optional-with-border"
          : variant === "danger" && "button--danger"
      } ${additionalStyles} lg:p-1-v-2`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
      {icon}
    </button>
  );
};

export default Button;
