import React from "react";
import OverviewGrid from "./OverviewGrid/OverviewGrid";
import CareLevelTargetGrid from "../CareLevelTargetGrid/CareLevelTargetGrid";
import OverviewKPI from "./OverviewKPI/OverviewKPI";
import useGetInpatientProdAnalysisData from "../hooks/useGetInpatientProdAnalysisData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const InpatientOverview = () => {
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const inpatientProductivityAnalysisDataHandler = useGetInpatientProdAnalysisData(careProviderId, scenario, year);

  return (
    <div className="flex h-full w-full">
      <div className="h-dvh mx-4 my-4 w-5/6">
        <OverviewGrid data={inpatientProductivityAnalysisDataHandler.data} />
      </div>
      <div className="h-dvh mx-4 my-4  flex w-1/6 flex-col justify-normal">
        <div>
          <CareLevelTargetGrid />
        </div>
        <div className="mx-8 mt-16">
          <OverviewKPI data={inpatientProductivityAnalysisDataHandler.data} />
        </div>
      </div>
    </div>
  );
};

export default InpatientOverview;
