import { TabProps } from "../../../../interfaces/props";
import React, { useEffect, useState } from "react";

const TabTeritary = ({
    title,
    id,
    onClick,
    isLastElement,
    selectedIndex,
  }: TabProps) => {
    const [isSelected, setIsSelected] = useState(false);
  
    useEffect(() => {
      if (selectedIndex === id) {
        setIsSelected(true);
      }
    }, []);
  
    return (
      <div
        onClick={() => onClick(id)}
        className={`tab-teritary ${isSelected && "tab-teritary--selected"}   ${
          id % 2 === 1 && "tab-teritary--middle"
        } ${id === selectedIndex + 1 && "tab-teritary--selected-after"} ${
          id === selectedIndex - 1 && "tab-teritary--selected-before"
        } ${id === 0 && "tab-teritary--first"} ${isLastElement && "tab-teritary--last"}`}
      >
        <span className="p-1-v-2">{title}</span>
      </div>
    );
  };
  
  export default TabTeritary;