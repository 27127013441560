import React from "react";
import "./comment-button.css";
import { CommentButtonProps } from "../../interfaces/props/comment-modal-button";

const CommentButton = ({
  onClick,
  icon,
  hasNewComment,
}: CommentButtonProps) => {
  return (
    <button className="comment-button" onClick={onClick}>
      {hasNewComment && (
        <span className="comment-button-indicator">
          <span className="comment-button-indicator-inner-layer"></span>
        </span>
      )}
      {icon}
    </button>
  );
};

export default CommentButton;
