import Handsontable from "handsontable";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const C = Handsontable.languages.dictionaryKeys;

export const registerSvLang = () => {
  Handsontable.languages.registerLanguageDictionary({
    languageCode: "sv-SE",
    [C.CONTEXTMENU_ITEMS_ROW_ABOVE]: "Infoga rad ovanför",
    [C.CONTEXTMENU_ITEMS_ROW_BELOW]: "Infoga rad under",
    [C.CONTEXTMENU_ITEMS_INSERT_LEFT]: "Infoga kolumn vänster",
    [C.CONTEXTMENU_ITEMS_INSERT_RIGHT]: "Infoga kolumn höger",
    [C.CONTEXTMENU_ITEMS_REMOVE_ROW]: "Ta bort rad",
    [C.CONTEXTMENU_ITEMS_REMOVE_COLUMN]: "Ta bort kolumn",
    [C.CONTEXTMENU_ITEMS_UNDO]: "Ångra",
    [C.CONTEXTMENU_ITEMS_REDO]: "Upprepa",
    [C.CONTEXTMENU_ITEMS_READ_ONLY]: "Skrivskyddad",
    [C.CONTEXTMENU_ITEMS_CLEAR_COLUMN]: "clearcol",
    [C.CONTEXTMENU_ITEMS_COPY]: "Kopiera",
    [C.CONTEXTMENU_ITEMS_CUT]: "Klipp ut",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT]: "Justering",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_LEFT]: "Vänster",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_CENTER]: "Centrera",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_RIGHT]: "Höger",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_JUSTIFY]: "Justera",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_TOP]: "Överkant",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_MIDDLE]: "Mitten",
    [C.CONTEXTMENU_ITEMS_ALIGNMENT_BOTTOM]: "Nederkant",
    [C.FILTERS_BUTTONS_CLEAR]: "Rensa",
    [C.FILTERS_BUTTONS_OK]: "Ok",
    [C.FILTERS_BUTTONS_CANCEL]: "Avbryt",
    [C.FILTERS_BUTTONS_SELECT_ALL]: "Markera allt",
    [C.FILTERS_DIVS_FILTER_BY_VALUE]: "Filtrera efter värde",
    [C.FILTERS_BUTTONS_PLACEHOLDER_SEARCH]: "Sök",
    [C.FILTERS_DIVS_FILTER_BY_CONDITION]: "Filtrera efter tillstånd",
    [C.FILTERS_NAMESPACE]: "Filtrera efter tillstånd",
    [C.FILTERS_CONDITIONS_NAMESPACE]: "Filtrera efter tillstånd",
    [C.FILTERS_CONDITIONS_NONE]: "Inget",
    [C.FILTERS_CONDITIONS_EMPTY]: "Är tom",
    [C.FILTERS_CONDITIONS_NOT_EMPTY]: "Är inte tom",
    [C.FILTERS_CONDITIONS_EQUAL]: "Lika med",
    [C.FILTERS_CONDITIONS_NOT_EQUAL]: "Inte lika med",
    [C.FILTERS_CONDITIONS_BEGINS_WITH]: "Börjar med",
    [C.FILTERS_CONDITIONS_ENDS_WITH]: "Slutar med",
    [C.FILTERS_CONDITIONS_CONTAINS]: "Innehåller",
    [C.FILTERS_CONDITIONS_NOT_CONTAIN]: "Innehåller inte",
    [C.FILTERS_CONDITIONS_BY_VALUE]: "",
    [C.FILTERS_CONDITIONS_GREATER_THAN]: "",
    [C.FILTERS_CONDITIONS_GREATER_THAN_OR_EQUAL]: "",
    [C.FILTERS_CONDITIONS_LESS_THAN]: "",
    [C.FILTERS_CONDITIONS_LESS_THAN_OR_EQUAL]: "",
    [C.FILTERS_CONDITIONS_BETWEEN]: "",
    [C.FILTERS_CONDITIONS_NOT_BETWEEN]: "",
    [C.FILTERS_CONDITIONS_AFTER]: "",
    [C.FILTERS_CONDITIONS_BEFORE]: "",
    [C.FILTERS_CONDITIONS_TODAY]: "",
    [C.FILTERS_CONDITIONS_TOMORROW]: "",
    [C.FILTERS_CONDITIONS_YESTERDAY]: "",
    [C.FILTERS_BUTTONS_PLACEHOLDER_VALUE]: "",
    [C.FILTERS_BUTTONS_PLACEHOLDER_SECOND_VALUE]: "",
    [C.CONTEXTMENU_ITEMS_FREEZE_COLUMN]: "",
    [C.CONTEXTMENU_ITEMS_UNFREEZE_COLUMN]: "",
    [C.CONTEXTMENU_ITEMS_MERGE_CELLS]: "",
    [C.CONTEXTMENU_ITEMS_UNMERGE_CELLS]: "",
    [C.CONTEXTMENU_ITEMS_ADD_COMMENT]: "",
    [C.CONTEXTMENU_ITEMS_EDIT_COMMENT]: "",
    [C.CONTEXTMENU_ITEMS_REMOVE_COMMENT]: "",
    [C.CONTEXTMENU_ITEMS_READ_ONLY_COMMENT]: "",
    [C.CONTEXTMENU_ITEMS_BORDERS]: "",
    [C.CONTEXTMENU_ITEMS_BORDERS_TOP]: "",
    [C.CONTEXTMENU_ITEMS_BORDERS_RIGHT]: "",
    [C.CONTEXTMENU_ITEMS_BORDERS_BOTTOM]: "",
    [C.CONTEXTMENU_ITEMS_BORDERS_LEFT]: "",
    [C.CONTEXTMENU_ITEMS_REMOVE_BORDERS]: "",
    [C.CONTEXTMENU_ITEMS_NESTED_ROWS_INSERT_CHILD]: "",
    [C.CONTEXTMENU_ITEMS_NESTED_ROWS_DETACH_CHILD]: "",
    [C.CONTEXTMENU_ITEMS_HIDE_COLUMN]: "",
    [C.CONTEXTMENU_ITEMS_SHOW_COLUMN]: "",
    [C.CONTEXTMENU_ITEMS_HIDE_ROW]: "",
    [C.CONTEXTMENU_ITEMS_SHOW_ROW]: "",
  });
};
