import React from "react";
import OrgTreeDropdown from "../OrgTreeDropdown/OrgTreeDropdown";

const CareUnitSelector = (): JSX.Element => {
  return (
    <>
      <OrgTreeDropdown />
    </>
  );
};

export default CareUnitSelector;
