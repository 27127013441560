import React from "react";
import { useTranslation } from "react-i18next";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";

const ActivityAnalysisNotification = ({
  isSubmitting,
  submitStatus,
}: {
  isSubmitting: boolean;
  submitStatus: number;
}): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
      {isSubmitting && (
        <NotificationFloat
          varaint="loading"
          content={translate("data_is_saving")}
        />
      )}
      {submitStatus === 1 && (
        <NotificationFloat
          varaint="success"
          content={translate("general_successful_message")}
        />
      )}
      {submitStatus === 2 && (
        <NotificationFloat
          varaint="error"
          content={translate("something_went_wrong")}
        />
      )}
    </div>
  );
};

export default ActivityAnalysisNotification;
