import { DetailedPlanRequest } from "../interfaces/production-plan";
import {
  DetailedAnnualPlanRequest,
  OutpatientDetailedPlanRequest,
} from "../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";
import { OutpatientPlanResultRequest } from "../interfaces/production-plan/outpatient/outpatientPlanResultRequest";
import HttpCommonService from "./httpCommonService";

const OutpatientPlanService = {
  getDetailedPlan: async (request: DetailedPlanRequest) => {
    return await HttpCommonService.postAsync(`api/outpatientproduction/detailed-plan`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getDimensionsForFilters: async (careUnitIds: number[]) => {
    const payload = {
      careUnitIds: careUnitIds,
    };

    return await HttpCommonService.postAsync(`api/outpatientdetailplans/dimensions`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAnnualDetailedPlan: async (request: DetailedAnnualPlanRequest) => {
    return await HttpCommonService.postAsync(`api/outpatientdetailplans`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getRefferelForecastPrediction: async (careUnitId: number, year: number) => {
    return await HttpCommonService.getAsync(`api/outpatientproduction/referral-forecast/${careUnitId}/${year}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getOutpatientPlan: async (id: number | null, year: number | null, scenario: number) => {
    let requestYear = year;
    requestYear === 0 ? (requestYear = new Date().getFullYear()) : (requestYear = year);

    return await HttpCommonService.getAsync(`api/outpatientplans/${id}/${requestYear}/${scenario}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getOutPatientResults: async (id: number | null, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/outpatientplans/numberofoutpatientvisits/${id}/${year}/${scenario}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getOutPatientResultsWithMultiSelect: async (data: OutpatientPlanResultRequest) => {
    return await HttpCommonService.postAsync(`api/outpatientplans/numberofoutpatientvisits`, data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveProductionPlan: async (data: any) => {
    return await HttpCommonService.postAsync("api/outpatientplans", data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveDetailedPlan: async (data: OutpatientDetailedPlanRequest) => {
    return await HttpCommonService.postAsync("api/updateoutpatientdetailplans", data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getOutpatientPlanKpis: async (request: OutpatientPlanResultRequest) => {
    return await HttpCommonService.postAsync(`api/outpatientplans/kpis`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  resetOutpatientPlan: async (careUnitId: number, scenarioId: number, year: number) => {
    const payload = {
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      year: year,
    };

    return await HttpCommonService.putAsync(`api/outpatientplans/reset`, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default OutpatientPlanService;
