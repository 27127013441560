import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "../../../../redux/store";
import AnalyticsService from "../../../../services/analyticsService";
import { NumberOfBeds } from "../../../../interfaces/analytics/number-of-beds";
import { ActualDataChartType, CareBedsChartData } from "../../../../interfaces/production-plan";
import { AppInsightService } from "../../../../services";
import InpatientPlanService from "../../../../services/inpatientPlanService";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";

const InpatientPlanResult = ({ selectedWeekdayIds }: { selectedWeekdayIds: number[] }) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "InpatientPlanResult" });
  }, []);
  const { t: translate } = useTranslation();

  const isInpatientWeekdayResultFeatureFlagEnabled = useFeatureFlagIsEnabled(
    FeatureFlags.inpatientResultWeekdayDropdown
  );

  const [numberofCareBedsChartSeries, setNumberofCareBedsChartSeries] = useState<CareBedsChartData[]>([]);
  const [actualDataForTheYearOne, setActualDataForTheYearOne] = useState<ActualDataChartType>();
  const [actualDataForTheYearTwo, setActualDataForTheYearTwo] = useState<ActualDataChartType>();
  const [actualDataForTheYearThree, setActualDataForTheYearThree] = useState<ActualDataChartType>();
  const [actualDataForTheYearFour, setActualDataForTheYearFour] = useState<ActualDataChartType>();
  const [agreedCareBeds, setAgreedCareBeds] = useState<number[]>([]);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const isGlobalAdminAccess = useSelector((state: RootState) => state.permission.globalSettingEnabled);

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    colors: ["#416031", "#739768", "#426fa2", "#7ea4cf", "#ad1e23", "#adadad", "#e5a32b"],

    title: {
      text: undefined,
    },

    xAxis: {
      categories: _.range(1, 53),
      title: {
        text: translate("year") + " " + year + ", " + translate("week"),
        style: {
          fontSize: 16,
        },
      },
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: translate("no_of_care_beds_per_week"),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;
        const series = this.series as Highcharts.Series;
        const tooltipInfo = `${series.name} <br/> ${translate("week")} ${point.x + 1} <br /> ${translate(
          "no_of_care_beds"
        )} ${point.y}`;
        return tooltipInfo;
      },
    },

    plotOptions: {
      series: {
        stacking: null,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 15,
      },
      column: {
        stacking: null,
        minPointLength: 2,
      },
      line: {
        stacking: null,
      },
    },
    series: [
      {
        //data for the columns
        data: numberofCareBedsChartSeries[0]?.data,
        color: "#416031",
        type: "column",
        name: translate("production_plan"),
      },
      {
        //data for the line
        data: actualDataForTheYearOne?.data,
        type: "line",
        color: "#ed7d31",
        name: actualDataForTheYearOne?.year,
        tooltip: {
          valueDecimals: 1,
        },
        marker: {
          enabled: false,
        },
      },
      {
        //data for the line
        data: actualDataForTheYearTwo?.data,
        type: "line",
        color: "#a5a5a5",
        name: actualDataForTheYearTwo?.year,
        tooltip: {
          valueDecimals: 1,
        },
        marker: {
          enabled: false,
        },
        visible: false,
      },
      {
        //data for the line
        data: actualDataForTheYearThree?.data,
        type: "line",
        color: "#ffc000",
        name: actualDataForTheYearThree?.year,
        tooltip: {
          valueDecimals: 1,
        },
        marker: {
          enabled: false,
        },
        visible: false,
      },
      {
        //data for the line
        data: actualDataForTheYearFour?.data,
        type: "line",
        color: "#ac9170",
        name: actualDataForTheYearFour?.year,
        tooltip: {
          valueDecimals: 1,
        },
        marker: {
          enabled: false,
        },
        visible: false,
      },
      {
        //data for the line
        data: agreedCareBeds,
        type: "line",
        color: "#93b6dd",
        name: translate("agreed_carebeds"),
        tooltip: {
          valueDecimals: 1,
        },
        marker: {
          enabled: false,
        },
        visible: isGlobalAdminAccess,
        showInLegend: isGlobalAdminAccess,
      },
    ],
  };

  useEffect(() => {
    if (!isInpatientWeekdayResultFeatureFlagEnabled) {
      AnalyticsService.getNumberOfCareBedsV2(careUnitId, year, scenario).then((res) => {
        const data = res.data.map((bed: NumberOfBeds) => {
          return {
            name: bed.careUnit,
            data: bed.numberOfBeds,
          };
        });

        setNumberofCareBedsChartSeries(data);
      });

      if (careUnitId !== null) {
        AnalyticsService.getLastThreeYearActualResults(careUnitId, scenario, year).then((res) => {
          setActualDataForTheYearOne(() => res?.year1);
          setActualDataForTheYearTwo(() => res?.year2);
          setActualDataForTheYearThree(() => res?.year3);
          setActualDataForTheYearFour(() => res?.year4);
        });
      }

      if (isGlobalAdminAccess) {
        if (careUnitId !== null) {
          InpatientPlanService.getAgreedCareBeds(careUnitId, year, scenario).then((res) => {
            const careBeds: number[] = res?.agreedCareBeds;
            setAgreedCareBeds(careBeds);
          });
        }
      }
    }
  }, [careUnitId, year, scenario, isGlobalAdminAccess, isInpatientWeekdayResultFeatureFlagEnabled]);

  useEffect(() => {
    if (isInpatientWeekdayResultFeatureFlagEnabled) {
      if (selectedWeekdayIds.length > 0) {
        AnalyticsService.getNumberOfCareBedsV2ByWeekday(careUnitId, year, scenario, selectedWeekdayIds).then((res) => {
          const data = res.data.map((bed: NumberOfBeds) => {
            return {
              name: bed.careUnit,
              data: bed.numberOfBeds,
            };
          });

          setNumberofCareBedsChartSeries(data);
        });

        if (careUnitId !== null) {
          AnalyticsService.getLastThreeYearActualResultsByWeekday(careUnitId, scenario, year, selectedWeekdayIds).then(
            (res) => {
              setActualDataForTheYearOne(() => res?.year1);
              setActualDataForTheYearTwo(() => res?.year2);
              setActualDataForTheYearThree(() => res?.year3);
              setActualDataForTheYearFour(() => res?.year4);
            }
          );
        }

        if (isGlobalAdminAccess) {
          if (careUnitId !== null) {
            InpatientPlanService.getAgreedCareBedsByWeekday(careUnitId, year, scenario, selectedWeekdayIds).then(
              (res) => {
                const careBeds: number[] = res?.agreedCareBeds;
                setAgreedCareBeds(careBeds);
              }
            );
          }
        }
      }
    }
  }, [careUnitId, year, scenario, isGlobalAdminAccess, isInpatientWeekdayResultFeatureFlagEnabled, selectedWeekdayIds]);

  return (
    <div className="h-full w-full lg:h-[79vh] xl:h-[80vh]">
      <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
    </div>
  );
};

export default InpatientPlanResult;
