import React, { useRef, useState } from "react";
import { InputProps } from "../../interfaces/props";
import clsx from "clsx";

const Input = ({
  id,
  name,
  placeholder,
  error,
  className,
  success,
  warning,
  handleInputChange,
  onFocus,
  type,
  disabled,
  icon,
  value,
}: InputProps) => {
  /* Id props: To give a unique id to input element and assign it to it's label */

  const [hover, setHover] = useState<boolean>(false);
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handlePasswordIconClick = () => {
    if (inputRef.current?.type === "password") {
      inputRef.current.type = "text";
      setIsPasswordShow(true);
    } else if (inputRef.current?.type === "text") {
      inputRef.current.type = "password";
      setIsPasswordShow(false);
    }
  };

  const handleOnInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setHover(false);

    if (onFocus !== undefined) {
      onFocus(e);
    }
  };

  return (
    <div
      className="input__container"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div
        className={`input small group ${
          error
            ? "input--error"
            : success && success?.length > 0
            ? "input--success"
            : disabled
            ? "input--disabled"
            : hover && "input--hover"
        } `}
      >
        <input
          id={id}
          className={clsx("input__element p-2-v-1 peer", className, {
            "input--typed": value && value.length > 0,
            "input--hover": hover,
          })}
          placeholder={placeholder}
          onChange={handleInputChange}
          type={type}
          value={value}
          name={name}
          ref={inputRef}
          disabled={disabled}
          onFocus={handleOnInputFocus}
        />
        <div className="input__icon">
          {/* Password Icon Area */}
          {type === "password" && isPasswordShow && (
            <svg
              className="input__icon--password__svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 26.755 21.403"
              onClick={handlePasswordIconClick}
            >
              <path
                id="Hide"
                d="M13.377,16.721A6,6,0,0,1,7.4,11.157L3.018,7.768a13.932,13.932,0,0,0-1.535,2.324,1.352,1.352,0,0,0,0,1.22,13.408,13.408,0,0,0,11.894,7.416,12.985,12.985,0,0,0,3.256-.437l-2.169-1.678a6.026,6.026,0,0,1-1.087.109ZM26.5,19.15l-4.621-3.572a13.847,13.847,0,0,0,3.4-4.267,1.352,1.352,0,0,0,0-1.22A13.408,13.408,0,0,0,13.377,2.675,12.882,12.882,0,0,0,7.219,4.251L1.9.141A.669.669,0,0,0,.962.258L.141,1.315a.669.669,0,0,0,.117.938l24.6,19.009a.669.669,0,0,0,.939-.117l.821-1.056A.669.669,0,0,0,26.5,19.15Zm-7.68-5.936-1.643-1.27a3.961,3.961,0,0,0-4.854-5.1,1.992,1.992,0,0,1,.389,1.179,1.95,1.95,0,0,1-.064.418L9.567,6.066a5.949,5.949,0,0,1,3.81-1.384A6.016,6.016,0,0,1,19.4,10.7a5.877,5.877,0,0,1-.581,2.513Z"
                transform="translate(0 0)"
                fill="#747474"
              />
            </svg>
          )}

          {icon && (
            <svg className="h-4 w-4 fill-[#000000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.25 18.25">
              <path
                id="Search"
                d="M17.5,15.971h-.832l-.312-.261a6.9,6.9,0,0,0,1.612-4.432,6.733,6.733,0,1,0-6.707,6.778,6.968,6.968,0,0,0,4.419-1.616l.312.261v.834l5.2,5.214,1.56-1.564Zm-6.239,0a4.693,4.693,0,1,1,4.679-4.693A4.667,4.667,0,0,1,11.259,15.971Z"
                transform="translate(-4.5 -4.5)"
              />
            </svg>
          )}

          {/* Password Icon Area */}
          {type === "password" && !isPasswordShow && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="input__icon--password__svg"
              viewBox="0 0 24.274 16.182"
              onClick={handlePasswordIconClick}
              fill="#747474"
            >
              <path
                id="Show"
                d="M24.128,11.976A13.517,13.517,0,0,0,12.137,4.5,13.519,13.519,0,0,0,.147,11.976a1.363,1.363,0,0,0,0,1.23,13.517,13.517,0,0,0,11.991,7.476,13.519,13.519,0,0,0,11.991-7.476A1.363,1.363,0,0,0,24.128,11.976ZM12.137,18.66a6.068,6.068,0,1,1,6.069-6.068A6.068,6.068,0,0,1,12.137,18.66Zm0-10.114a4.017,4.017,0,0,0-1.067.16,2.017,2.017,0,0,1-2.819,2.819,4.037,4.037,0,1,0,3.886-2.979Z"
                transform="translate(0 -4.5)"
                fill="#747474"
              />
            </svg>
          )}

          {/* Success SVG */}
          {success && success?.length > 0 && (
            <svg className="input__icon--success__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.473 22.472">
              <g id="Success" transform="translate(0 0)">
                <path
                  id="Subtraction_1"
                  data-name="Subtraction 1"
                  d="M11.237,22.472A11.237,11.237,0,0,1,3.291,3.29,11.237,11.237,0,0,1,19.182,19.182,11.163,11.163,0,0,1,11.237,22.472Zm0-20.058a8.822,8.822,0,1,0,8.822,8.822A8.832,8.832,0,0,0,11.237,2.414Z"
                  transform="translate(0 0)"
                />
                <path
                  id="Icon_metro-checkmark"
                  data-name="Icon metro-checkmark"
                  d="M11.885,5.784,6.71,10.959,4.3,8.544,2.571,10.269l4.14,4.14,6.9-6.9Z"
                  transform="translate(3.146 1.139)"
                />
              </g>
            </svg>
          )}

          {error && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.471 22.471" className="input__icon--error__svg">
              <path
                id="Warning"
                d="M13.112,17.606h2.247v2.247H13.112Zm0-8.988h2.247v6.741H13.112ZM14.224,3A11.235,11.235,0,1,0,25.471,14.235,11.23,11.23,0,0,0,14.224,3Zm.011,20.224a8.988,8.988,0,1,1,8.988-8.988A8.986,8.986,0,0,1,14.236,23.224Z"
                transform="translate(-3 -3)"
              />
            </svg>
          )}
        </div>

        <label
          htmlFor={id}
          className="input__label p-3-v-1 peer-placeholder-shown:p-1-v-1 peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-base"
        >
          {placeholder}
        </label>
      </div>

      {/* Refactor this? */}
      {/* Notification Area */}
      {error && error?.length > 0 && (
        <small className="input__notification p-2-v-2  input__notification--error">
          <b> {error} </b>
        </small>
      )}

      {warning && warning?.length > 0 && (
        <small className="input__notification p-2-v-2 input__notification--warning">
          <b> {warning} </b>
        </small>
      )}

      {success && success?.length > 0 && (
        <small className="input__notification p-2-v-2 input__notification--success">
          <b> {success} </b>
        </small>
      )}
    </div>
  );
};

export default Input;
