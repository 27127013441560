import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DownloadButton } from "../components";
import { useTranslation } from "react-i18next";

interface ExcelProps {
  apiData: any;
  fileName: string;
  disabled?: boolean;
}

const ExportExcel = ({ apiData, fileName, disabled }: ExcelProps) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const { t: translate, i18n } = useTranslation();

  const exportToCSV = (apiData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <DownloadButton
      text={translate("DownloadData")}
      onClick={() => !disabled && exportToCSV(apiData, fileName)}
      disabled={disabled}
    />
  );
};

export default ExportExcel;
