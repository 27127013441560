import React, { useEffect, useState } from "react";
import { TabProps } from "../../../../interfaces/props";

const TabSecondary = ({
  title,
  id,
  onClick,
  isLastElement,
  selectedIndex,
}: TabProps) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (selectedIndex === id) {
      setIsSelected(true);
    }
  }, []);

  return (
    <div
      onClick={() => onClick(id)}
      className={`tab-secondary ${isSelected && "tab-secondary--selected"}   ${
        id % 2 === 1 && "tab-secondary--middle"
      } ${id === selectedIndex + 1 && "tab-secondary--selected-after"} ${
        id === selectedIndex - 1 && "tab-secondary--selected-before"
      } ${id === 0 && "tab-secondary--first"} ${
        isLastElement && "tab-secondary--last"
      }`}
    >
      <span className="p-1-v-2">{title}</span>
    </div>
  );
};

export default TabSecondary;
