import { createSlice } from "@reduxjs/toolkit";
import { Permission } from "../../interfaces/Permission";

const createInitialState = (): Permission => {
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const userrole = localStorage.getItem("user") && userData.roles[0].role;

  return {
    careUnitReadOnly: null,
    parameterReadOnly: (userrole && userrole === "Care Provider Admin") || userrole === "Global Admin" ? false : true,
    userManagmentEnabled:
      (userrole && userrole === "Global Admin") || userrole === "Care Provider Admin" ? true : false,
    globalSettingEnabled: (userrole && userrole === "Global Admin") || userrole === "Global Reader" ? true : false,
  };
};

const name = "permission";
const initialState = createInitialState();

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setCareUnitReadOnly: (state, action) => {
      state.careUnitReadOnly = action.payload;
    },
    setParameterReadOnly: (state, action) => {
      state.parameterReadOnly = action.payload;
    },
    setUserManagmentEnabled: (state, action) => {
      state.userManagmentEnabled = action.payload;
    },
    setGlobalSettingEnabled: (state, action) => {
      state.globalSettingEnabled = action.payload;
    },
    resetPermission: (state) => {
      state.parameterReadOnly = null;
      state.careUnitReadOnly = null;
      state.userManagmentEnabled = null;
      state.globalSettingEnabled = null;
    },
  },
});

export const {
  setCareUnitReadOnly,
  setParameterReadOnly,
  setUserManagmentEnabled,
  setGlobalSettingEnabled,
  resetPermission,
} = slice.actions;
export const permissionReducer = slice.reducer;
