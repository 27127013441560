import HttpCommonService from "./httpCommonService";

const WorkAnalysisService = {
  getWorkingHoursAnalysis: async (
    careUnitId: number,
    year: number,
    scenario: number,
    typeId: number,
    formalCompetences: number[]
  ) => {
    const payload = {
      careUnitId: careUnitId,
      year: year,
      scenarioId: scenario,
      typeId: typeId,
      formalCompetences: formalCompetences,
    };

    return await HttpCommonService.postAsync(`api/work/workinghoursanalysis`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default WorkAnalysisService;
