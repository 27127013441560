import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SumOfProductionSumForMedicalSpecialityResponse } from "../../../../interfaces/responses";
import { RootState } from "../../../../redux/store";
import InpatientPlanService from "../../../../services/inpatientPlanService";
import ResultService from "../../../../services/resultService";
import {
  InPatientProductionByMediacalSpecialty,
  InPatientChartSeries,
  InPatientWeekData,
  InPatinetDateRangeWeek,
  InPatinetDateRangeYear,
  OutComeForMedicalSpecialities,
  MedicalSpecialtyGroupWeekValue,
} from "./careEpisodeTypes";
import InPatientProductionService from "../../../../services/inpatientProductionService";
import { AppInsightService, OutpatientHistoricalDataService } from "../../../../services";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";
import { ChartDataYearFormat } from "../../../../interfaces/production-plan/outpatient/referral-intake-chart-data";

const CareEpisodePlan = ({
  sendLastWeekCareEpisodeAverage,
  isNeededToGroupedByMedicalSpeciality,
}: {
  sendLastWeekCareEpisodeAverage: (average: number) => void;
  isNeededToGroupedByMedicalSpeciality: boolean;
}) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "CareEpisodePlan" });
  }, []);
  const { t: translate } = useTranslation();
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [inPatientProductionPlanRollingAvgChartData, setInPatientProductionPlanRollingAvgChartData] =
    useState<(number | null)[]>();

  const [lastWeekCareEpisodeAverage, setLastWeekCareEpisodeAverage] = useState<number>(0);

  const [inPatientProductionForecastData, setInPatientProductionForecastData] = useState<(number | null)[]>([]);
  const [predictionAvgData, setPredictionAvgData] = useState<(number | null)[]>([]);

  const [
    medicalSpecialitiesValuesForAverageProductionOutcome,
    setMedicalSpecialitiesValuesForAverageProductionOutcome,
  ] = useState<MedicalSpecialtyGroupWeekValue[]>([]);

  const [weekRange, setWeekRange] = useState<InPatinetDateRangeWeek[]>([]);
  const [chartDateRange, setChartDateRange] = useState<ChartDataYearFormat[]>([]);

  const fetchProductionOutComeSum = async (careUnitId: number, year: number) => {
    await ResultService.getSumOfInpatientProductionOutcomeChartData(careUnitId, year).then((res) => {
      const data: InPatientChartSeries[] = res.data;

      const chartData: InPatientWeekData<number>[] = [];

      data.forEach((year) => {
        year.weeks.forEach((week) => {
          chartData.push({
            theYearISOWeek: week.theYearISOWeek,
            value: week.value,
            week: week.week,
          });
        });
      });

      setChartDataSeries([
        {
          data: chartData.map((r) => r.value),
          type: "column",
          color: "#7b976d",
          name: translate("care_episodes"),
          tooltip: {
            valueDecimals: 0,
          },
          visible: true,
        },
      ]);
    });
  };

  const fetchProductionOutComePredictionForecast = async (careUnitId: number, year: number) => {
    await InpatientPlanService.getCareBedsForecastData(careUnitId, year).then((res) => {
      const data: InPatientChartSeries[] = res;

      const lengthHistoricalData = weekRange.filter((r) => r.isHistoricWeek == true).length;

      const forecastChartData: (InPatientWeekData<number> | null)[] = Array(lengthHistoricalData).fill(null);

      data.forEach((year) => {
        year.weeks.forEach((week) => {
          forecastChartData.push({
            theYearISOWeek: week.theYearISOWeek,
            value: week.value,
            week: week.week,
          });
        });
      });

      setInPatientProductionForecastData(forecastChartData.map((r) => (r ? r.value : null)));
    });
  };

  const fetchProductionOutComePredictionForecastAvg = async (careUnitId: number, year: number) => {
    await InPatientProductionService.getCareBedsForecastData(careUnitId, year).then((res) => {
      const data: InPatientChartSeries[] = res;

      const lengthHistoricalData = weekRange.filter((r) => r.isHistoricWeek == true).length;

      const forecastChartData: (InPatientWeekData<number> | null)[] = Array(lengthHistoricalData).fill(null);

      data.forEach((year) => {
        year.weeks.forEach((week) => {
          forecastChartData.push({
            theYearISOWeek: week.theYearISOWeek,
            value: week.value,
            week: week.week,
          });
        });
      });

      setPredictionAvgData(forecastChartData.map((r) => (r ? r.value : null)));
    });
  };

  const fetchProductionOutcomeAvgData = async (
    careUnitId: number,
    year: number,
    isNeededToGroupedByMedicalSpeciality: boolean
  ) => {
    await ResultService.getAverageOfInpatientProductionOutcomeChartData(careUnitId, year).then((res) => {
      const data: InPatientChartSeries[] = res.data;

      const historicalProductionPlanRollingAvgData: InPatientWeekData<number>[] = [];

      data.forEach((year) => {
        year.weeks.forEach((week) => {
          historicalProductionPlanRollingAvgData.push({
            theYearISOWeek: week.theYearISOWeek,
            value: week.value,
            week: week.week,
          });
        });
      });

      const historicalProductionPlanRollingAvg = historicalProductionPlanRollingAvgData.map((r) =>
        r ? r.value : null
      );

      setInPatientProductionPlanRollingAvgChartData(historicalProductionPlanRollingAvg);

      if (!isNeededToGroupedByMedicalSpeciality) {
        setMedicalSpecialitiesValuesForAverageProductionOutcome([]);
      }

      const lastWeekValue = historicalProductionPlanRollingAvg[historicalProductionPlanRollingAvg.length - 1];

      const lastWeekValueAverage = lastWeekValue ? (lastWeekValue * 365) / 7 : 0;

      setLastWeekCareEpisodeAverage(lastWeekValueAverage);
    });
  };

  const fetchProductionOutcomeAvgDataByMedicalSpeciality = async (careUnitId: number, year: number) => {
    await ResultService.getAverageOfInpatientProductionOutcomeByMedicalSpecialitiesChartData(careUnitId, year).then(
      (res) => {
        const data: InPatientProductionByMediacalSpecialty[] = res.data;

        let productionByMedicalSpec: MedicalSpecialtyGroupWeekValue[] = [];

        data.forEach((year) => {
          productionByMedicalSpec = productionByMedicalSpec.concat(year.weeks);
        });

        setMedicalSpecialitiesValuesForAverageProductionOutcome(productionByMedicalSpec);
      }
    );
  };

  const fecthAndProcessDataForColumnChart = async (careUnitId: number, year: number) => {
    await fetchProductionOutComePredictionForecast(careUnitId, year);

    if (!isNeededToGroupedByMedicalSpeciality) {
      await fetchProductionOutComeSum(careUnitId, year);
    } else {
      await ResultService.getSumOfInpatientProductionOutcomeForMedicalSpecialityChartData(careUnitId, year).then(
        (res) => {
          const data: SumOfProductionSumForMedicalSpecialityResponse[] = res.data;

          let medicalSpecialties = [];

          medicalSpecialties = data.map((specialty) => {
            return {
              name: specialty.medicalSpeciality,
              data: specialty.weeks.map((r) => r.value),
              type: "column",
            };
          });

          setChartDataSeries(medicalSpecialties);
        }
      );
    }
  };

  const fetchDataForLineChart = async (careUnitId: number, year: number) => {
    await fetchProductionOutcomeAvgData(careUnitId, year, isNeededToGroupedByMedicalSpeciality);

    if (isNeededToGroupedByMedicalSpeciality) {
      await fetchProductionOutcomeAvgDataByMedicalSpeciality(careUnitId, year);
    }

    await fetchProductionOutComePredictionForecastAvg(careUnitId, year);
  };

  useEffect(() => {
    const getXAxisData = async () => {
      if (year !== null) {
        await OutpatientHistoricalDataService.getOutPatientDataDateRange(year).then((res) => {
          const years: InPatinetDateRangeYear[] = res.data;

          const chartXAxisData: ChartDataYearFormat[] = years.map((date) => {
            const convertedDate: ChartDataYearFormat = {
              name: date.id,
              categories: date.weeks.map((week) => {
                return week.theISOWeek;
              }),
            };

            return convertedDate;
          });

          const populatingWeekRange: InPatinetDateRangeWeek[] = [];

          years.forEach((year) => {
            year.weeks.forEach((week) => {
              populatingWeekRange.push(week);
            });
          });

          setChartDateRange(chartXAxisData);
          setWeekRange(populatingWeekRange);
        });
      }
    };

    getXAxisData();
  }, [careUnitId, year, isNeededToGroupedByMedicalSpeciality]);

  useEffect(() => {
    const fetchAsync = async () => {
      if (careUnitId && year && weekRange && weekRange.length > 0) {
        setIsDataLoading(true);

        await fecthAndProcessDataForColumnChart(careUnitId, year);
        await fetchDataForLineChart(careUnitId, year);

        setIsDataLoading(false);
      }
    };

    fetchAsync();
  }, [weekRange]);

  useEffect(() => {
    sendLastWeekCareEpisodeAverage(lastWeekCareEpisodeAverage);
  }, [lastWeekCareEpisodeAverage]);

  const [chartDataSeries, setChartDataSeries] = useState<any>([]);

  const careEpisodeOption = {
    chart: {
      animation: false,
    },

    title: {
      text: "",
    },

    colors: ["#416031", "#7EA4CF", "#739768", "#426FA2", "#ADADAD", "#416031", "#747474"],

    xAxis: {
      categories: chartDateRange,
    },
    yAxis: {
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },

    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        pointPlacement: "between",
        stickyTracking: false,
        // dragDrop: {
        //   draggableY: true,
        // },

        marker: {
          enabled: false,
        },
      },
      column: {
        stacking: "normal",
        minPointLength: 2,
      },
      line: {
        // cursor: "ns-resize",
      },
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (this.series.name === translate("moving_average_52_weeks_inpatient")) {
          const point = this.point as Highcharts.Point;

          const info = `${translate("moving_average_52_weeks_inpatient_tooltip")}: ${point.y?.toFixed(
            1
          )} <br /> ${translate("resulting_annual_rate")}: ${Number(((point.y || 0) * 365) / 7).toFixed(1)}`;

          if (isNeededToGroupedByMedicalSpeciality) {
            const medicalSpecialitiesForPoint = medicalSpecialitiesValuesForAverageProductionOutcome[this.point.index];

            const medicalSpecialitiesTotal = medicalSpecialitiesForPoint.weeklyMedialSpecialties.reduce(
              (acc, item) => Number((acc + (item.value * 365) / 7).toFixed(1)),
              0
            );

            if (medicalSpecialitiesForPoint) {
              const info = `${translate("moving_average_52_weeks_inpatient_tooltip")}: ${point.y?.toFixed(
                1
              )} <br /> ${translate("resulting_annual_rate")}: ${medicalSpecialitiesTotal.toFixed(1)}`;

              return (
                info +
                "<br />" +
                `${medicalSpecialitiesForPoint.weeklyMedialSpecialties.reduce(
                  (acc, item: OutComeForMedicalSpecialities, index, array) => {
                    if (item.medialSpecialtyName !== "") {
                      const line = `<br /> ${item.medialSpecialtyName}: ${((item.value * 365) / 7).toFixed(1)}`;
                      if (index < array.length - 1) {
                        return acc + line + ", ";
                      } else {
                        return acc + line;
                      }
                    } else {
                      return acc;
                    }
                  },
                  ""
                )}`
              );
            } else {
              return info;
            }
          } else {
            return info;
          }
        } else if (this.series.name === translate("moving_average_52_weeks_forecast_inpatient")) {
          const point = this.point as Highcharts.Point;

          const info = `${translate("moving_average_52_weeks_forecast_inpatient_tooltip")}: ${
            point.y
          } <br /> ${translate("resulting_annual_rate")}: ${Number(((point.y || 0) * 365) / 7).toFixed(1)}`;

          return info;
        } else if (this.series.name === translate("care_episodes")) {
          return `${translate("care_episodes")}: ${this.y}`;
        } else {
          return `${this.series.name}: ${this.y}`;
        }
      },
    },

    series: [
      ...chartDataSeries,
      {
        //data for the columns
        data: inPatientProductionForecastData,
        type: "column",
        color: "#A9C9A0",
        visible: false,
        name: translate("prediction_inpatient"),
        tooltip: {
          valueDecimals: 0,
        },
      },
      {
        //data for the line
        data: inPatientProductionPlanRollingAvgChartData,
        color: "#00283C",
        type: "line",
        name: translate("moving_average_52_weeks_inpatient"),
        tooltip: {
          valueDecimals: 1,
        },
      },
      {
        //data for the line
        data: predictionAvgData,
        color: "#426FA2",
        type: "line",
        name: translate("moving_average_52_weeks_forecast_inpatient"),
        tooltip: {
          valueDecimals: 1,
        },
      },
    ],
  };

  return !isDataLoading ? (
    <HighchartsReact
      highcharts={Highcharts}
      options={careEpisodeOption}
      containerProps={{ style: { height: "100%" } }}
    />
  ) : (
    <div className="flex h-full items-center justify-center">
      <LoadingWithGrey sizeInPixel={40} />
    </div>
  );

  // return (
  //   <HighchartsReact
  //     highcharts={Highcharts}
  //     options={careEpisodeOption}
  //     containerProps={{ style: { height: "100%" } }}
  //   />
  // );
};

export default CareEpisodePlan;
