export const compensations_data_schema = {
  competenceName: "",
  formalCompetenceName: "",
  agreementName: "",
  careUnitName: "",
  salary: 0,
  sickLeave: 0,
  sickLeavePaidShare: 0,
  oohReceivedAsTime: 0,
  vacationDays: 0,
  overtimeCoverage: 0,
  overtimeHourlyRate: 0,
  rentalCoverage: 0,
  rentalHourlyRate: 0,
};
