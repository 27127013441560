export const productionShareColumns = [
  "productionShareEdVisit",
  "productionShareOutpatientVisit",
  "productionShareWard",
  "productionShareSurgery",
  "productionShareConsultant",
  "productionShareStExternalTraining",
  "productionShareManagement",
  "productionShareNonClinicalTime",
];
