import React from "react";
import { useTranslation } from "react-i18next";
import { WaterfallKpi } from "../../../interfaces/productivity";
import "./productivity-kpis.css";
import { inpatientProductivityKps } from "../../../interfaces/productivity/waterfall-kpi";
import _ from "lodash";
import { useNumberFormat } from "../../../hooks/use-number-format";

interface IProps {
  outpatientKpis: WaterfallKpi[];
  inpatientKpis: inpatientProductivityKps;
  careUnitType: number;
}

const ProductivityKpis: React.FC<IProps> = ({ outpatientKpis, inpatientKpis, careUnitType }) => {
  const { t: translate, i18n } = useTranslation();
  const { localize } = useNumberFormat();

  return (
    <>
      {careUnitType === 1
        ? outpatientKpis &&
          outpatientKpis.length > 0 && (
            <div className="flex h-full w-full justify-around text-center">
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate(outpatientKpis[0].kpiName)}</h1>
                <p className={`bottomarea__text--value`}>
                  {localize(_.round(outpatientKpis[0].kpiValue, 1), i18n.language)}
                </p>
              </div>

              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate(outpatientKpis[1].kpiName)}</h1>
                <p className={`bottomarea__text--value`}>
                  {localize(_.round(outpatientKpis[1].kpiValue, 1), i18n.language)}
                </p>
              </div>
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate(outpatientKpis[2].kpiName)}</h1>
                <p className={`bottomarea__text--value`}>{Math.round(outpatientKpis[2].kpiValue * 100)}%</p>
              </div>
            </div>
          )
        : inpatientKpis && (
            <div className="flex h-full w-full justify-around text-center">
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate("hours_per_care_day")}</h1>
                <p className={`bottomarea__text--value`}>{localize(inpatientKpis.hoursPerCareDay, i18n.language)}</p>
              </div>

              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate("patient_per_rn")}</h1>
                <p className={`bottomarea__text--value`}>{localize(inpatientKpis.patientsPerRn, i18n.language)}</p>
              </div>
              <div className="bottomarea__text--container">
                <h1 className="bottomarea__text--title">{translate("cost_per_care_day")}</h1>
                <p className={`bottomarea__text--value`}>{inpatientKpis.costPerCareDay}</p>
              </div>
            </div>
          )}
      {}
    </>
  );
};

export default ProductivityKpis;
