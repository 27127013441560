import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "../../../../../hooks/use-number-format";
import { AxiosResponse } from "axios";
import { OutpatientPlanDataResponse } from "../../../../../interfaces/responses";
import { OutpatientPlanPayload } from "../../../../../interfaces/productivity/outpatient-plan-data-props";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { OutpatientPlanService } from "../../../../../services";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { OutPatientMultiSelectRequest } from "../../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { OutpatientPlanResultRequest } from "../../../../../interfaces/production-plan/outpatient/outpatientPlanResultRequest";

const OutPatientResultPanel = ({
  outpatientMultiSelectFilterRequest,
}: {
  outpatientMultiSelectFilterRequest: OutPatientMultiSelectRequest | null;
}) => {
  const { t: translate } = useTranslation();
  const { round } = useNumberFormat();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.OutpatientResultMultiSelect);
  const [outpatientRequest, setOutpatientRequest] = useState<OutpatientPlanPayload>({
    productionPlan: 0,
    queueCuttingPlan: 0,
    id: 0,
    queueCuttingReductionGoalDate: null,
    overloadPlanAverage: 0,
  });

  useEffect(() => {
    if (careUnitId && scenarioId && year) {
      if (
        isFeatureFlagEnabled &&
        outpatientMultiSelectFilterRequest &&
        outpatientMultiSelectFilterRequest.medicalSpecialtyIds.length > 0
      ) {
        fetchOutpatientPlanData(outpatientMultiSelectFilterRequest);
      } else if (!isFeatureFlagEnabled) {
        fetchOutpatientPlan(careUnitId, year, scenarioId);
      }
    }
  }, [careUnitId, scenarioId, year, outpatientMultiSelectFilterRequest]);

  const fetchOutpatientPlan = async (careUnitId: number, year: number, scenario: number) => {
    try {
      const res: AxiosResponse<OutpatientPlanDataResponse> = await OutpatientPlanService.getOutpatientPlan(
        careUnitId,
        year,
        scenario
      );

      if (res.data.id === 0) {
        setOutpatientRequest({
          productionPlan: 0,
          queueCuttingPlan: 0,
          id: 0,
          queueCuttingReductionGoalDate: null,
          overloadPlanAverage: res.data.overloadPlanAverage,
        });
      } else {
        res.data.productionOutpatientPlan = res.data.productionOutpatientPlan
          ? res.data.productionOutpatientPlan * 100 || 0
          : 0;
        setOutpatientRequest(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOutpatientPlanData = async (outPatientMultiSelectParams: OutPatientMultiSelectRequest | null) => {
    if (
      careUnitId &&
      careUnitId > 0 &&
      outPatientMultiSelectParams &&
      outPatientMultiSelectParams.professionIds.length > 0
    ) {
      const outpatientResultRequest: OutpatientPlanResultRequest = {
        careUnitId: careUnitId,
        productionYear: year,
        professionIds:
          outPatientMultiSelectParams?.professionIds[0] === -1 ? [] : outPatientMultiSelectParams?.professionIds,
        contactTypeIds:
          outPatientMultiSelectParams?.contactTypeIds[0] === -1 ? [] : outPatientMultiSelectParams?.contactTypeIds,
        contactReasonGroupingIds:
          outPatientMultiSelectParams?.contactReasonGroupingIds[0] === -1
            ? []
            : outPatientMultiSelectParams?.contactReasonGroupingIds,
        typesOfVisit:
          outPatientMultiSelectParams?.typesOfVisit[0] === -1 ? [] : outPatientMultiSelectParams?.typesOfVisit,
        careTypeIds: outPatientMultiSelectParams?.careTypeIds[0] === -1 ? [] : outPatientMultiSelectParams?.careTypeIds,
        typesOfEmergency:
          outPatientMultiSelectParams?.typesOfEmergency[0] === -1 ? [] : outPatientMultiSelectParams?.typesOfEmergency,
        medicalSpecialtyIds:
          outPatientMultiSelectParams?.medicalSpecialtyIds[0] === -1
            ? []
            : outPatientMultiSelectParams?.medicalSpecialtyIds,
        scenarioId: scenarioId,
      };

      await OutpatientPlanService.getOutpatientPlanKpis(outpatientResultRequest).then((res) => {
        const data = res;
        setOutpatientRequest(data);
      });
    }
  };

  return (
    <div className="px-4">
      <div className="sidearea__text--container-v2">
        <div>
          <h1 className="sidearea__text--title-v2">{translate("totalnumber_of_visits")}</h1>
          <p className="sidearea__text--value-v2">
            {(outpatientRequest.productionPlan + outpatientRequest.queueCuttingPlan).toString()}
          </p>
        </div>
        <div>
          <h1 className="sidearea__text--title-v2">{translate("outpatient_result_plan_vs_actual_r12")}</h1>
          <p className="sidearea__text--value-v2">{round(outpatientRequest.overloadPlanAverage, 0)}</p>
        </div>
      </div>
    </div>
  );
};

export default OutPatientResultPanel;
