import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "../../../../components";
import { INewAccount, INewPasswordError } from "../../interfaces";
import { AuthForm } from "../../layouts";

const CompleteProfile = () => {
  const { t: translate } = useTranslation();
  const [userAccount, setUserAccount] = useState<INewAccount>({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState<INewPasswordError>({
    passwordError: "",
    confirmPasswordError: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>();

  const handleSubmit = (e: React.FormEvent) => {
    console.log(e);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUserAccount({
      ...userAccount,
      [name]: value,
    });
  };

  return (
    <AuthForm title={translate("complete_profile_inviter")}>
      <Input
        name="password"
        value={userAccount.password}
        handleInputChange={onChange}
        placeholder={translate("email")}
        disabled={true}
        type="password"
      />

      <hr className="complete-profile__divider" />

      <h5 className="p-2-v-1 complete-profile__addpassword">
        {translate("add_password")}
      </h5>

      <form onSubmit={handleSubmit}>
        <Input
          name="password"
          value={userAccount.password}
          handleInputChange={onChange}
          placeholder={translate("new_password")}
          error={passwordError.passwordError}
          type="password"
        />

        <Input
          name="confirmPassword"
          value={userAccount.confirmPassword}
          handleInputChange={onChange}
          placeholder={translate("retype_new_password")}
          error={passwordError.confirmPasswordError}
          type="password"
        />

        <Button
          variant="primary"
          text="Change Password"
          type="submit"
          disabled={isLoading}
          additionalStyles="authform__button"
        />
      </form>
    </AuthForm>
  );
};

export default CompleteProfile;
