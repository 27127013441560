import React, { useState } from "react";
import BaseDataRetrieveHookReturn from "../../../../interfaces/hooks/baseDataRetrieveHookReturn";
import WorkCompetenceStaffGap from "../../../../interfaces/competence/WorkCompetenceStaffGap";
import StaffAvailabilityService from "../../../../services/staffAvailabilityService";

const useWorkCompetencesWithStaffingGaps = (
  careUnitId: number | null,
  scenario: number | null
): BaseDataRetrieveHookReturn<WorkCompetenceStaffGap[]> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<WorkCompetenceStaffGap[]>([]);

  const fetchData = async (): Promise<void> => {
    if (careUnitId && scenario) {
      setIsLoading(true);

      StaffAvailabilityService.getStaffGapsGroupedByWorkCompetence(careUnitId, scenario)
        .then((res) => {
          const responseData: WorkCompetenceStaffGap[] = res.data;
          responseData.sort((a, b) => a.formalCompetenceSortOrder - b.formalCompetenceSortOrder);
          setData(responseData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return {
    data,
    fetch: fetchData,
    isLoading,
  };
};

export default useWorkCompetencesWithStaffingGaps;
