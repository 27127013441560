import React from "react";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import {
  numberBetWeenOneAndTwoValidator,
  numberBetWeenZeroAndOneValidator,
  numberBetWeenZeroAndPointZeroOneValidator,
  numberBetweenZeroand366Validator,
  numberBetweenZeroandSixtyValidator,
} from "../../../../utils/handsontable-configuration";
import { useTranslation } from "react-i18next";
import { OohCompType } from "../../../../interfaces/parameters/OohCompType";

const useGetAgreementColumns = (culture: string, oohCompTypeList: OohCompType[]) => {
  const { t: translate } = useTranslation();

  const cols: HandsOnTableColumn[] = [
    { meta: { data: "agreementName" }, headerTitle: "AgreementName", width: 200 },
    {
      meta: {
        data: "weeklyWorkingHours",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 2,
          },
        },
        validator: numberBetweenZeroandSixtyValidator,
      },
      headerTitle: "WeeklyWorkingHours",
      width: 180,
    },
    {
      meta: {
        data: "oohCompTypeId",
        type: "keyValueDropdown",
        source:
          oohCompTypeList.length > 0
            ? (oohCompTypeList.map((oohCompType) => {
                return {
                  _id: oohCompType.id,
                  label: translate(oohCompType.oohCompTypeName),
                };
              }) as any)
            : [],
        editorParams: { width: 150 },
      },
      headerTitle: "OohCompType",
      width: 150,
    },
    {
      meta: {
        data: "poAdder",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 3,
          },
        },
        validator: numberBetWeenOneAndTwoValidator,
      },
      headerTitle: "PoAdder",
      width: 100,
    },
    {
      meta: {
        data: "workingDays",
        type: "numeric",

        validator: numberBetweenZeroand366Validator,
      },
      headerTitle: "WorkingDays",
      width: 120,
    },
    {
      meta: {
        data: "sickLeaveComp",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 2,
          },
        },
        validator: numberBetWeenZeroAndOneValidator,
      },
      headerTitle: "SickLeaveComp",
      width: 120,
    },
    {
      meta: {
        data: "oohTimeComp",
        type: "numeric",
        numericFormat: {
          culture: culture,
          pattern: {
            mantissa: 8,
          },
        },
        validator: numberBetWeenZeroAndPointZeroOneValidator,
      },
      headerTitle: "OohTimeComp",
      width: 120,
    },
    {
      meta: {
        data: "oohMoneyComp",
        type: "numeric",
        validator: numberBetWeenZeroAndPointZeroOneValidator,
      },
      headerTitle: "OohMoneyComp",
      width: 130,
    },
  ];

  return cols;
};

export default useGetAgreementColumns;
