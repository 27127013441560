import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useTabNavigation = (paths: string[]) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    const tabIndex = paths.indexOf(location.pathname);
    if (tabIndex !== -1) {
      setSelectedTabIndex(tabIndex);
    }
  }, [location.pathname, paths]);

  const navigateToTab = (index: number) => {
    if (paths[index]) {
      navigate(paths[index]);
    }
  };

  return { selectedTabIndex, navigateToTab };
};

export default useTabNavigation;
