import { ISelectOption, MultiSelectOption } from "../../../../interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect, Select } from "../../../../components";

interface WorkHourFilterProps {
  typesOfInputs: ISelectOption[];
  competenceShareOptions: MultiSelectOption[];
  selectedCompetenceShareOptions: MultiSelectOption[];
  handleTypesOfInputSelect: (selectedOption: ISelectOption) => void;
  handleCompetenceSelect: (option: MultiSelectOption) => void;
  handleCompetenceDeselect: (optionToDeselect: MultiSelectOption) => void;
  selectedInput: ISelectOption;
}

const WorksHourFilter = ({
  typesOfInputs,
  selectedCompetenceShareOptions,
  selectedInput,
  competenceShareOptions,
  handleTypesOfInputSelect,
  handleCompetenceSelect,
  handleCompetenceDeselect,
}: WorkHourFilterProps) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <div className="mb-4">
        <Select
          placeholder={translate("type_of_input")}
          options={typesOfInputs}
          onSelectOption={handleTypesOfInputSelect}
          selectedOption={selectedInput}
        />
      </div>

      <div className="mb-4">
        <MultiSelect
          label={translate("competence")}
          selectedOptions={selectedCompetenceShareOptions}
          options={competenceShareOptions}
          onSelectItem={handleCompetenceSelect}
          onRemoveSelectItem={handleCompetenceDeselect}
        />
      </div>
    </>
  );
};

export default WorksHourFilter;
