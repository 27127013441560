import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { v4 as uuid } from "uuid";
import Truncate from "react-truncate";
import clsx from "clsx";

import { MultiSelectOption } from "../../interfaces";
import { MultiSelectProps } from "../../interfaces/props";
import Checkbox from "../Checkbox/Checkbox";

const MultiSelect = ({
  label,
  options,
  selectedOptions,
  onSelectItem,
  onRemoveSelectItem,
  maxHeight,
  isSmallFont,
}: MultiSelectProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const handleOptionSelect = (option: MultiSelectOption) => {
    if (selectedOptions.includes(option)) {
      deselectItem(option);
    } else {
      selectItem(option);
    }
  };

  const selectItem = (option: MultiSelectOption) => {
    onSelectItem(option);
  };

  const deselectItem = (optionToDeselect: MultiSelectOption) => {
    onRemoveSelectItem(optionToDeselect);
  };

  const toggleSelect = () => {
    if (isSelectOpen) {
      setIsSelectOpen(false);
    } else {
      setIsSelectOpen(true);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsSelectOpen(false);
      }}
    >
      <div className=" flex w-full flex-col items-center">
        <div className="h-full w-full">
          <div className="relative flex flex-col items-center ">
            <div className={`w-full py-2`}>
              <div
                className={`flex rounded-[0.438rem] border border-x border-y  border-[#ADADAD] hover:cursor-pointer hover:bg-[#EDEDED] lg:p-[0.20rem] lg:!py-0.5 lg:px-4 lg:!pl-4  xl:!p-1.5 xl:!px-3 xl:!pl-4 ${
                  selectedOptions.length >= 0 && "border-[#ADADAD] "
                }`}
                onClick={toggleSelect}
              >
                <div
                  className={`absolute pl-1 text-[#747474] ${
                    selectedOptions.length === 0 ? "p-1-v-1 top-1/2 -translate-y-1/2" : "p-3-v-1 top-3 "
                  }`}
                >
                  {label}
                </div>
                <div className="flex flex-auto flex-wrap">
                  <Truncate
                    lines={1}
                    className="multiselect-selected-options absolute ml-1 lg:top-[1.5rem] xl:top-[1.8rem]"
                  >
                    <div className="flex items-center justify-center">
                      {selectedOptions.findIndex((x) => x.value === "null") > -1 ? (
                        <div key={uuid()} className="p-2-v-1 max-w-full flex-initial text-xs font-normal leading-none">
                          {selectedOptions[selectedOptions.findIndex((x) => x.value === "null")].label}
                        </div>
                      ) : (
                        selectedOptions.map((option, i) => (
                          <div
                            key={uuid()}
                            className="p-2-v-1 max-w-full flex-initial text-xs font-normal leading-none"
                          >
                            {option.label} {selectedOptions.length !== i + 1 && ", "}
                          </div>
                        ))
                      )}
                    </div>
                  </Truncate>
                </div>
                <div className="-mr-1.5 flex w-8 items-center border-gray-200 py-1 pl-2 pr-1 text-gray-300">
                  <button className="h-6 w-6 cursor-pointer text-gray-600 outline-none focus:outline-none">
                    {isSelectOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={3}
                        stroke="#747474"
                        className="h-5 w-5"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={3}
                        stroke="#747474"
                        className="h-5 w-5"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/*  */}
            {isSelectOpen && (
              <div className="mult-select-z-index max-h-select svelte-5uyqqj absolute left-0 top-full z-40 w-full overflow-y-auto rounded bg-white shadow">
                <div
                  className={clsx("flex w-full flex-col", {
                    [`overscroll-y-auto ${maxHeight}`]: maxHeight,
                  })}
                >
                  {options.map((option, i) => (
                    <div
                      onClick={() => handleOptionSelect(option)}
                      key={i}
                      className={`w-full cursor-pointer border-b border-gray-100 hover:bg-[#EDEDED] ${
                        i === 0 ? "rounded-t" : i === options.length - 1 && "rounded-b"
                      }`}
                    >
                      <div className="relative flex w-full items-center border-l-2 border-transparent p-2 pl-2 ">
                        <div className="flex w-full items-center">
                          <div
                            className={clsx("mx-2", {
                              "text-sm": isSmallFont,
                            })}
                          >
                            {option.label}{" "}
                          </div>
                        </div>

                        <div className="mx-2 h-4 w-4">
                          <Checkbox checked={selectedOptions.includes(option)} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelect;
