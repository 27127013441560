import { ICDSidePanelState } from "../../../../../interfaces/analytics/icdAnalysis/icdSidePanelState";

export const icdAnalysisSidePanelInitialState: ICDSidePanelState = {
  ageOptions: [],
  chapterOptions: [],
  regionOptions: [],
  referenceRegionOptions: [],
  yearOptions: [],
  genderOptions: [],
  selectedTopOption: {
    label: "30",
    value: "30",
  },
  selectedAges: [],
  selectedChapters: [],
  selectedRegion: { value: "all", label: "All" },
  selectedReferenceRegions: [],
  selectedYears: [],
  selectedGenders: [],
  topOptions: [
    {
      label: "10",
      value: "10",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "40",
      value: "40",
    },
    {
      label: "50",
      value: "50",
    },
  ],
};
