import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.css";
import { OrgNodeResponse } from "../../interfaces/responses/org-structure-tree-response";
import { RootState } from "../../redux/store";
import { searchPredicate } from "../../utils/react-dropdown-tree";

import DropdownTreeSelect from "react-dropdown-tree-select";
import { convertOrgNodeToDropdownData } from "../../utils/react-dropdown-tree/convert-api-response";
import { DropdownOrgTreeNode } from "../../interfaces/components/dropdown-tree";
import clsx from "clsx";
import OrgService from "../../services/orgService";

interface IProp {
  value: number | null;
  onValueChange: (obj: { parents: number[]; name: string | null; value: number | null }) => void;
  placeholder?: string;
  error?: boolean;
}

interface ControllerValueState {
  value: number | null;
  label: string | null;
  type: number | null;
  parentIds: number[];
}

const OrgTreeDropdownController = ({ value, onValueChange, placeholder, error }: IProp): JSX.Element => {
  const { t: translate } = useTranslation();

  const location = useLocation();

  const [isMultiSelectable, setIsMultiSelectable] = useState<boolean>(
    location.pathname === "/" || location.pathname === "/analytics/production-vs-plan"
  );

  const [dropdownData, setDropdownData] = useState({});

  const [dropDownResponse, setDropDownReponse] = useState<OrgNodeResponse[]>([]);

  const [controllerState, setControllerState] = useState<ControllerValueState>({
    label: "",
    parentIds: [],
    type: null,
    value: null,
  });

  // Used to store the previous org structure data source in order to do the
  // hierarchy comparison based on year changes
  // const [previousOrgStructureDataSource, setPreviousOrgStructureDataSource] = useState<OrgNodeResponse[]>([]);

  // const [selelctedEntityLabel, setSelectedEntityLabel] = useState<string>("");

  const loginState = useSelector((state: RootState) => state.auth.isLoggedIn);

  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/analytics/production-vs-plan") {
      setIsMultiSelectable(true);
    } else {
      setIsMultiSelectable(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const getOrgStructureTree = () => {
      if (careProviderId) {
        OrgService.getOrgChildrenHierarchyAsync(careProviderId, year)
          .then((res) => {
            setDropDownReponse(res);
          })
          .catch((err) => {
            throw err;
          });
      }
    };

    if (loginState === true && careProviderId !== null) {
      getOrgStructureTree();
    }
  }, [loginState, careProviderId, year]);

  useEffect(() => {
    if (dropDownResponse.length > 0) {
      const activeCareUnit = value || -1;

      if (checkIfCareUnitExists(Number(activeCareUnit), dropDownResponse)) {
        compareHierarchyAndFindThePosition();
      }
    }

    if (value === null) {
      setControllerState({
        value: null,
        label: "",
        type: null,
        parentIds: [],
      });
    }
  }, [dropDownResponse, value]);

  const compareHierarchyAndFindThePosition = () => {
    if (value !== null) {
      const node = getNode(value, dropDownResponse);
      const parents = getParents(dropDownResponse, value || -1);

      if (node !== null) {
        updateControllerState({
          value: node.careUnitId,
          label: node.careUnitName,
          careUnitTypeId: node.careUnitTypeId,
          parentsIds: parents,
        });
      }
    }
  };

  useEffect(() => {
    const getHierarchyAsync = async (dropDownResponse: OrgNodeResponse[]) => {
      if (dropDownResponse && dropDownResponse.length > 0) {
        // setSelectedEntityLabel(controllerState.label ? controllerState.label : "");
      }
    };

    if (dropDownResponse !== undefined) {
      getHierarchyAsync(dropDownResponse);
    }
  }, [dropDownResponse, controllerState.label]);

  useEffect(() => {
    if (dropDownResponse && dropDownResponse.length > 0) {
      const convertedDropdownData = convertOrgNodeToDropdownData(
        dropDownResponse,
        controllerState.parentIds,
        controllerState.value || -1
      );

      if (convertedDropdownData && convertedDropdownData.length > 0) {
        setDropdownData(convertedDropdownData[0]);
      }
    }
  }, [dropDownResponse, controllerState.value, controllerState.parentIds, value]);

  const handleDropdownSelectFocus = () => {
    document.querySelector(".dropdown-content")?.classList.remove("hidden");
    document.querySelector(".dropdown-content")?.classList.add("visible");

    document.querySelector(".analytics-dropdown-tree .dropdown-trigger.arrow")?.classList.remove("bottom");
    document.querySelector(".analytics-dropdown-tree .dropdown-trigger.arrow")?.classList.add("top");
  };

  const getLeafNodes = (node: OrgNodeResponse): OrgNodeResponse[] => {
    if (node.children && node.children.length > 0) {
      return node.children.reduce((acc: OrgNodeResponse[], child: OrgNodeResponse) => {
        if (child.children && child.children.length > 0) {
          return [...acc, ...getLeafNodes(child)];
        } else {
          return [...acc, child];
        }
      }, []);
    } else {
      return [];
    }
  };

  const getParents = (nodes: OrgNodeResponse[], nodeId: number): number[] => {
    const parents: number[] = [];

    const recursiveSearch = (currentNode: OrgNodeResponse[], targetId: number): boolean => {
      for (const child of currentNode) {
        if (child.careUnitId === targetId) {
          return true;
        } else if (child.children && child.children.length > 0) {
          if (recursiveSearch(child.children, targetId)) {
            parents.push(child.careUnitId);
            return true;
          }
        }
      }

      return false;
    };

    recursiveSearch(nodes, nodeId);

    return parents.reverse();
  };

  const getNode = (nodeId: number, nodes: OrgNodeResponse[]): OrgNodeResponse | null => {
    for (const node of nodes) {
      if (node.careUnitId === nodeId) {
        return node;
      } else if (node.children && node.children.length > 0) {
        const foundNode = getNode(nodeId, node.children);

        if (foundNode) {
          return foundNode;
        }
      }
    }

    return null;
  };

  const findChild = (
    currentNode: DropdownOrgTreeNode,
    dropDownResponse: OrgNodeResponse[]
  ): OrgNodeResponse | null => {
    for (const child of dropDownResponse) {
      if (child.careUnitId === Number(currentNode.value)) {
        return child;
      } else if (child.children && child.children.length > 0) {
        const result = findChild(currentNode, child.children);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const findChildUsingId = (id: number, dropDownResponse: OrgNodeResponse[]): OrgNodeResponse | null => {
    for (const child of dropDownResponse) {
      if (child.careUnitId === id) {
        return child;
      } else if (child.children && child.children.length > 0) {
        const result = findChildUsingId(id, child.children);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const checkIfCareUnitExists = (careUnitId: number, dropDownResponse: OrgNodeResponse[]): boolean => {
    for (const child of dropDownResponse) {
      if (child.careUnitId === careUnitId) {
        return true;
      } else if (child.children && child.children.length > 0) {
        const result = checkIfCareUnitExists(careUnitId, child.children);
        if (result) {
          return true;
        }
      }
    }
    return false;
  };

  const onChange = (node: any) => {
    const currentNode: DropdownOrgTreeNode = node as DropdownOrgTreeNode;

    const parentsIds = getParents(dropDownResponse, Number(currentNode.value));

    document.querySelector(".dropdown-content")?.classList.add("hidden");
    document.querySelector(".dropdown-trigger")?.classList.remove("top");
    document.querySelector(".dropdown-trigger")?.classList.add("bottom");

    updateControllerState({
      value: currentNode.value,
      label: currentNode.label,
      careUnitTypeId: currentNode.careUnitTypeId,
      parentsIds: parentsIds,
    });
  };

  const updateControllerState = (selectedUnit: {
    value: number;
    label: string;
    careUnitTypeId: number;
    parentsIds: number[];
  }) => {
    setControllerState({
      value: selectedUnit.value,
      label: selectedUnit.label,
      type: selectedUnit.careUnitTypeId,
      parentIds: selectedUnit.parentsIds,
    });

    onValueChange({
      value: selectedUnit.value,
      name: selectedUnit.label,
      parents: selectedUnit.parentsIds,
    });
  };

  return (
    <div className="max-w-full">
      <div className="relative">
        <DropdownTreeSelect
          data={dropdownData}
          onChange={onChange}
          showDropdown="default"
          className={clsx(
            "dropdown-tree",
            `${isMultiSelectable ? "analytics-dropdown-tree dashboard-dropdown-tree" : "analytics-dropdown-tree"}`,
            `${error ? "controller--error" : ""}`
          )}
          keepTreeOnSearch={false}
          keepOpenOnSelect={true}
          clearSearchOnChange={true}
          disabled={Object.keys(dropdownData).length === 0}
          texts={{ placeholder: controllerState.label ? controllerState.label : "" }}
          onFocus={handleDropdownSelectFocus}
          searchPredicate={searchPredicate}
        />
        <p className="p-3-v-1 absolute left-[6px] top-1 px-[1.0rem] text-[#747474]">
          {placeholder ? placeholder : translate("org_item")}
        </p>
      </div>
    </div>
  );
};

export default OrgTreeDropdownController;
