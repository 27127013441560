import React from "react";
import { useTranslation } from "react-i18next";
import { BudgetKpis as IBudgetKpis } from "../../../interfaces/dashboard/budget-kpis";

interface BudgetKpiProps {
  chartKpis: IBudgetKpis;
}

const BudgetKpis = ({ chartKpis }: BudgetKpiProps) => {
  const { t: translate } = useTranslation();

  const actualBudget = Math.round(chartKpis.actualVsBudget);
  const actualModel = Math.round(chartKpis.actualVsModel);

  return (
    <div className="flex h-full w-full justify-around text-center">
      <div className="bottomarea__text--container">
        <h1 className="bottomarea__text--title">
          {translate("actual_vs_budget")}
        </h1>
        <p
          className={`bottomarea__text--value ${
            chartKpis.actualVsBudget > 0 && "danger"
          }`}
        >
          {actualBudget > 0 ? `+${actualBudget}` : actualBudget}M
        </p>
      </div>

      <div className="bottomarea__text--container">
        <h1 className="bottomarea__text--title">
          {translate("actual_vs_model")}
        </h1>
        <p
          className={`bottomarea__text--value ${
            chartKpis.actualVsModel > 0 && "danger"
          }`}
        >
          {actualModel > 0 ? `+${actualModel}` : actualModel}M
        </p>
      </div>
    </div>
  );
};

export default BudgetKpis;
