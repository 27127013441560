import React, { useState } from "react";
import { AccordianProps } from "../../interfaces/props";

const Accordian = ({ children, title }: AccordianProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden border border-x-0 border-y-[#ADADAD] bg-transparent">
      <div
        onClick={() => setOpen(!open)}
        className="flex w-full items-center justify-between rounded bg-transparent p-4"
      >
        <div className="flex items-center gap-2">
          <h4 className="text-sm p-2-v-1 font-medium">{title}</h4>
        </div>
        <div className="select__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`select__icon__svg ${
              open && "select__icon__svg--expanded"
            }`}
            viewBox="0 0 17.274 10.667"
          >
            <path
              id="Dropdown_Arrow"
              data-name="Dropdown Arrow"
              d="M11.03,11.76l6.607,6.593,6.607-6.593,2.03,2.03-8.637,8.637L9,13.79Z"
              transform="translate(-9 -11.76)"
            />
          </svg>
        </div>
      </div>
      {open && (
        <div className="w-full bg-transparent px-4 py-2">{children}</div>
      )}
    </div>
  );
};

export default Accordian;
