import { AxiosResponse } from "axios";
import { InpatientProductivityAnalysis } from "../interfaces/analytics/inpatientProductivityAnalysis";
import HttpCommonService from "./httpCommonService";

const InpatientProductivityService = {
  getProductivityAnalysis: async (
    careProviderId: number,
    scenarioId: number,
    year: number
  ): Promise<AxiosResponse<InpatientProductivityAnalysis[]>> => {
    return await HttpCommonService.getAsync(
      `api/inpatient/productivity/analysis/${careProviderId}/${year}/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default InpatientProductivityService;
