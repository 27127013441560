import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { MultiSelect, Select } from "../../../../components";
import { ISelectOption, MultiSelectOption } from "../../../../interfaces";
import { ICDAnalysisSidePanelProps } from "../../../../interfaces/props/icdAnalysisSidePanelProps";
import { icdAnalysisSidePanelReducer } from "./state/icdAnalysisSidePanelReducer";
import { icdAnalysisSidePanelInitialState } from "./state/icdAnalysisSidePanelInitialState";
import { ICDAnalysisSidePanelActions } from "../../../../common/enums/icdAnalysisSidePanelActions";

const ICDAnalysisSidePanel = ({
  ages,
  chapter,
  genders,
  isLoading,
  regions,
  years,
  setIcdAnalysisRequest,
}: ICDAnalysisSidePanelProps) => {
  const { t: translate } = useTranslation();

  const [state, dispatch] = useReducer(icdAnalysisSidePanelReducer, icdAnalysisSidePanelInitialState);

  const isFirstRequest = React.useRef(true);

  useEffect(() => {
    if (!isLoading) {
      const agesAsOptions = [
        { id: uuid(), label: "All", value: "null" },
        ...ages.map((age) => ({ id: uuid(), label: age.name, value: age.id })),
      ];

      dispatch({ type: ICDAnalysisSidePanelActions.SET_AGE_OPTIONS, payload: agesAsOptions });
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_AGES, payload: agesAsOptions });

      const chaptersAsOptions = [
        { id: uuid(), label: "All", value: "null" },
        ...chapter.map((chapter) => ({
          id: uuid(),
          label: `${chapter.id} - ${chapter.name.substring(0, 15)}...`,
          value: chapter.id,
        })),
      ];

      dispatch({ type: ICDAnalysisSidePanelActions.SET_CHAPTER_OPTIONS, payload: chaptersAsOptions });
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_CHAPTERS, payload: chaptersAsOptions });

      const referenceRegionsAsOptions = [
        { id: uuid(), label: "All", value: "null" },
        ...regions.map((region) => ({ id: uuid(), label: String(region.region), value: region.regionId })),
      ];

      dispatch({ type: ICDAnalysisSidePanelActions.SET_REFERENCEREGIONS_OPTIONS, payload: referenceRegionsAsOptions });
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_REFERNCEREGIONS, payload: referenceRegionsAsOptions });

      const gendersAsOptions = [
        { id: uuid(), label: "All", value: "null" },
        ...genders.map((gender) => ({ id: uuid(), label: gender.name, value: gender.id })),
      ];

      dispatch({ type: ICDAnalysisSidePanelActions.SET_GENDER_OPTIONS, payload: gendersAsOptions });
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_GENDERS, payload: gendersAsOptions });

      const regionsAsOptions = [...regions.map((region) => ({ label: region.region, value: String(region.regionId) }))];

      dispatch({ type: ICDAnalysisSidePanelActions.SET_REGION_OPTIONS, payload: regionsAsOptions });
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_REGION, payload: regionsAsOptions[0] });

      const yearsAsOptions = [
        { id: uuid(), label: "All", value: "null" },
        ...years.map((year) => ({ id: uuid(), label: String(year), value: year })),
      ];

      dispatch({ type: ICDAnalysisSidePanelActions.SET_YEAR_OPTIONS, payload: yearsAsOptions });
      dispatch({
        type: ICDAnalysisSidePanelActions.SET_SELECTED_YEARS,
        payload: [yearsAsOptions[yearsAsOptions.length - 1]],
      });
    }
  }, [ages, chapter, genders, regions, years, isLoading]);

  useEffect(() => {
    if (
      (state.selectedAges.length > 0 &&
        state.selectedChapters.length > 0 &&
        state.selectedGenders.length > 0 &&
        state.selectedYears.length > 0 &&
        state.selectedRegion?.value !== null) ||
      !isFirstRequest.current
    ) {
      setIcdAnalysisRequest({
        years:
          state.selectedYears.length === 0
            ? state.yearOptions.filter((option) => option.value !== "null").map((option) => Number(option.value))
            : state.selectedYears.filter((option) => option.value !== "null").map((option) => Number(option.value)),
        regionId: Number(state.selectedRegion?.value),
        referenceRegions: state.selectedReferenceRegions
          .filter((option) => option.value !== "null")
          .map((option) => Number(option.value)),
        chapterDiagnoses:
          state.selectedChapters.length === 0
            ? state.chapterOptions.filter((option) => option.value !== "null").map((option) => option.value as string)
            : state.selectedChapters
                .filter((option) => option.value !== "null")
                .map((option) => option.value as string),
        genders:
          state.selectedGenders.length === 0
            ? state.genderOptions.filter((option) => option.value !== "null").map((option) => Number(option.value))
            : state.selectedGenders.filter((option) => option.value !== "null").map((option) => Number(option.value)),
        ages:
          state.selectedAges.length === 0
            ? state.ageOptions.filter((option) => option.value !== "null").map((option) => Number(option.value))
            : state.selectedAges.filter((option) => option.value !== "null").map((option) => Number(option.value)),
        topNo: Number(state.selectedTopOption.value),
      });

      if (isFirstRequest.current) {
        isFirstRequest.current = false;
      }
    }
  }, [
    state.selectedYears,
    state.selectedRegion,
    state.selectedTopOption,
    state.selectedChapters,
    state.selectedGenders,
    state.selectedAges,
    state.selectedReferenceRegions,
  ]);

  const handleAgesSelect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_AGES, payload: state.ageOptions });
      return;
    }

    dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_AGES, payload: [...state.selectedAges, selectedOption] });
  };

  const handleAgesDeselect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_AGES, payload: [] });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_AGES,
      payload: state.selectedAges.filter((option) => option.id !== selectedOption.id && option.value !== "null"),
    });
  };

  const handleChaptersSelect = (selectedOption: MultiSelectOption) => {
    // if the selected option is "All" then remove null other options
    if (selectedOption.value === "null" || state.selectedChapters.length + 2 === state.chapterOptions.length) {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_CHAPTERS, payload: state.chapterOptions });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_CHAPTERS,
      payload: [...state.selectedChapters, selectedOption],
    });
  };

  const handleChaptersDeselect = (selectedOption: MultiSelectOption) => {
    // if the deselected option is "All" then remove null other options
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_CHAPTERS, payload: [] });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_CHAPTERS,
      payload: state.selectedChapters.filter((option) => option.id !== selectedOption.id && option.value !== "null"),
    });
  };

  const handleReferenceRegionsSelect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({
        type: ICDAnalysisSidePanelActions.SET_SELECTED_REFERNCEREGIONS,
        payload: state.referenceRegionOptions,
      });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_REFERNCEREGIONS,
      payload: [...state.selectedReferenceRegions, selectedOption],
    });
  };

  const handleReferenceRegionsDeselect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_REFERNCEREGIONS, payload: [] });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_REFERNCEREGIONS,
      payload: state.selectedReferenceRegions.filter(
        (option) => option.id !== selectedOption.id && option.value !== "null"
      ),
    });
  };

  const handleRegionsSelect = (selectedOption: ISelectOption) => {
    dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_REGION, payload: selectedOption });
  };

  const handleYearsSelect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_YEARS, payload: state.yearOptions });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_YEARS,
      payload: [...state.selectedYears, selectedOption],
    });
  };

  const handleYearsDeselect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_YEARS, payload: [] });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_YEARS,
      payload: state.selectedYears.filter((option) => option.id !== selectedOption.id && option.value !== "null"),
    });
  };

  const handleGendersSelect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_GENDERS, payload: state.genderOptions });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_GENDERS,
      payload: [...state.selectedGenders, selectedOption],
    });
  };

  const handleGendersDeselect = (selectedOption: MultiSelectOption) => {
    if (selectedOption.value === "null") {
      dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_GENDERS, payload: [] });
      return;
    }

    dispatch({
      type: ICDAnalysisSidePanelActions.SET_SELECTED_GENDERS,
      payload: state.selectedGenders.filter((option) => option.id !== selectedOption.id && option.value !== "null"),
    });
  };

  const handleTopSelect = (selectedOption: ISelectOption) => {
    dispatch({ type: ICDAnalysisSidePanelActions.SET_SELECTED_TOP_OPTION, payload: selectedOption });
  };

  return (
    <div>
      <Select
        placeholder={translate("region")}
        selectedOption={state.selectedRegion}
        options={state.regionOptions}
        onSelectOption={handleRegionsSelect}
        selectStyles="bg-transparent"
      />

      <MultiSelect
        label={translate("icd_chart_params_referenceRegion")}
        selectedOptions={state.selectedReferenceRegions}
        options={state.referenceRegionOptions}
        onSelectItem={handleReferenceRegionsSelect}
        onRemoveSelectItem={handleReferenceRegionsDeselect}
        maxHeight="max-h-64"
        isSmallFont
      />

      <MultiSelect
        label={translate("chapter")}
        selectedOptions={state.selectedChapters}
        options={state.chapterOptions}
        onSelectItem={handleChaptersSelect}
        onRemoveSelectItem={handleChaptersDeselect}
        maxHeight="max-h-64"
        isSmallFont
      />

      <MultiSelect
        label={translate("age")}
        selectedOptions={state.selectedAges}
        options={state.ageOptions}
        onSelectItem={handleAgesSelect}
        onRemoveSelectItem={handleAgesDeselect}
        maxHeight="max-h-64"
      />

      <MultiSelect
        label={translate("gender")}
        selectedOptions={state.selectedGenders}
        options={state.genderOptions}
        onSelectItem={handleGendersSelect}
        onRemoveSelectItem={handleGendersDeselect}
      />

      <MultiSelect
        label={translate("year")}
        selectedOptions={state.selectedYears}
        options={state.yearOptions}
        onSelectItem={handleYearsSelect}
        onRemoveSelectItem={handleYearsDeselect}
        maxHeight="max-h-64"
      />

      <Select
        placeholder={translate("top")}
        selectedOption={state.selectedTopOption}
        options={state.topOptions}
        onSelectOption={handleTopSelect}
        selectStyles="bg-transparent"
      />
    </div>
  );
};

export default ICDAnalysisSidePanel;
