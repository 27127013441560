import { useEffect, useState } from "react";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import { FormalCompetenceService } from "../../../../services/formalCompetenceService";

interface ReturnFormalCompetenceData<T> {
  data: T;
  fetch(): void;
  isLoading: boolean;
}

const useGetFormalCompetenceData = (): ReturnFormalCompetenceData<FormalCompetence[]> => {
  const [formalCompetences, setFormalCompetences] = useState<FormalCompetence[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    FormalCompetenceService.getFormalCompetences()
      .then((res) => {
        const responseData: FormalCompetence[] = res.data;

        setFormalCompetences(responseData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data: formalCompetences,
    fetch: fetchData,
    isLoading,
  };
};

export default useGetFormalCompetenceData;
