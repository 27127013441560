import React from "react";
import useGetOutpatientProdAnalysisData from "../hooks/useGetOutpatientProdAnalysisData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import OverviewGrid from "./OverviewGrid/OverviewGrid";
import OverviewKPI from "./OverviewKPI/OverviewKPI";

const OutpatientOverview = () => {
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const outpatientProductivityAnalysisDataHandler = useGetOutpatientProdAnalysisData(scenario, year);

  return (
    <div className="flex h-full w-full">
      <div className="h-dvh mx-4 my-4 w-5/6">
        <OverviewGrid data={outpatientProductivityAnalysisDataHandler.data} />
      </div>
      <div className="h-dvh mx-4 my-4  flex w-1/6 flex-col justify-normal">
        <div className="mx-8 mt-16">
          <OverviewKPI data={outpatientProductivityAnalysisDataHandler.data} />
        </div>
      </div>
    </div>
  );
};

export default OutpatientOverview;
