export enum UserRoleNames {
  care_provider_reader = "Care Provider Reader",
  global_admin = "Global Admin",
  care_provider_admin = "Care Provider Admin",
  global_reader = "Global Reader",
  care_unit_user = "Care Unit User",
  department_user = "Department User",
}

export enum UserTypes {
  Hospital = "Hospital",
  Global = "Global",
  CareUnit = "CareUnit",
  Department = "Department",
}
