import React from "react";
import { InformationBoxProps } from "../../interfaces/props";

const InformationBox: React.FC<InformationBoxProps> = ({
  items,
  className,
}) => {
  const getClassName = (baseStyles: string) => {
    if (baseStyles.length === 0) {
      return className || "";
    }

    return className ? `${baseStyles} ${className}` : baseStyles;
  };

  return (
    <ul>
      {items.map((item, index) => (
        <li key={index} className={getClassName("")}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default InformationBox;
