export const ADD_HOSPITAL = "ADD_CARE_PROVIDER";
export const UPDATE_HOSPITAL = "UPDATE_CARE_PROVIDER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const ADD_DIVISION = "ADD_DIVISION";
export const UPDATE_DIVISION = "UPDATE_DIVISION";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const ADD_SECTION = "ADD_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const ADD_CARE_UNIT = "ADD_CARE_UNIT";
export const UPDATE_CARE_UNIT = "UPDATE_CARE_UNIT";
export const ADD_PHYSICAL_CARE_UNIT = "ADD_PHYSICAL_CARE_UNIT";
export const UPDATE_PHYSICAL_CARE_UNIT = "UPDATE_PHYSICAL_CARE_UNIT";
