import { DropdownOrgTreeNode } from "../../interfaces/components/dropdown-tree";
import { OrgNodeResponse } from "../../interfaces/responses/org-structure-tree-response";

const isExpanded = (id: number, parents: number[], selectedNode: number | null) => {
  if (!selectedNode) {
    return false;
  }

  return selectedNode === id ? true : parents.findIndex((r) => r === id) >= 0 ? true : false;
};

export const convertOrgNodeToDropdownData = (
  response: OrgNodeResponse[],
  parentIds: number[],
  selectedCareUnitId: number | null
): DropdownOrgTreeNode[] => {
  const currentDataNodes = response.map((element) => {
    const currentDataNode: DropdownOrgTreeNode = {
      careUnitTypeId: element.careUnitTypeId,
      value: element.careUnitId,
      label: element.careUnitName,
      type: element.children && element.children.length > 0 ? "group" : "leaf",
      expanded: isExpanded(element.careUnitId, parentIds, selectedCareUnitId), // TODO Maintain expanded state
      disabled: element.careUnitId === selectedCareUnitId ? true : false,
      children: element.children.map((child: OrgNodeResponse) => {
        return {
          label: child.careUnitName,
          value: child.careUnitId,
          type: child.children && child.children.length > 0 ? "group" : "leaf",
          expanded: isExpanded(child.careUnitId, parentIds, selectedCareUnitId), // TODO Maintain expanded state
          careUnitTypeId: child.careUnitTypeId,
          children: child.children ? convertOrgNodeToDropdownData(child.children, parentIds, selectedCareUnitId) : [],
          disabled: child.careUnitId === selectedCareUnitId ? true : false,
        };
      }),
    };

    return currentDataNode;
  });

  return currentDataNodes;
};

export const setExpandState = (
  response: DropdownOrgTreeNode[],
  parentIds: number[],
  selectedCareUnitId: number | null
): DropdownOrgTreeNode[] => {
  const currentDataNodes = response.map((element) => {
    const currentDataNode: DropdownOrgTreeNode = {
      careUnitTypeId: element.careUnitTypeId,
      value: element.value,
      label: element.label,
      type: element.children && element.children.length > 0 ? "group" : "leaf",
      expanded: isExpanded(element.value, parentIds, selectedCareUnitId),
      disabled: element.value === selectedCareUnitId ? true : false,
      children: element.children
        ? element.children.map((child: DropdownOrgTreeNode) => {
            return {
              label: child.label,
              value: child.value,
              type: child.children && child.children.length > 0 ? "group" : "leaf",
              expanded: isExpanded(child.value, parentIds, selectedCareUnitId),
              careUnitTypeId: child.careUnitTypeId,
              children: child.children ? setExpandState(child.children, parentIds, selectedCareUnitId) : [],
              disabled: child.value === selectedCareUnitId ? true : false,
            };
          })
        : null,
    };

    return currentDataNode;
  });

  return currentDataNodes;
};
