import HttpCommonService from "./httpCommonService";

const SettingsService = {
  getYears: async () => {
    return await HttpCommonService.getAsync(`api/years`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default SettingsService;
