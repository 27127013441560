import { HotTableClass as HotTable } from "@handsontable/react";
import React from "react";

const useAddNewRow = (dataTable: React.RefObject<HotTable | null>) => {
  const addNewRow = () => {
    const data = dataTable.current?.hotInstance?.getSourceData();

    let index;
    let amount;

    if (data) {
      if (data.length === 0) {
        index = data.length;
        amount = 1;
      } else {
        index = data.length - 1;
        amount = 1;
      }
    }

    dataTable.current?.hotInstance?.alter("insert_row_above", index, amount);
  };

  return { addNewRow };
};

export default useAddNewRow;
