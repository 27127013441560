import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FeatureFlagState } from "../../interfaces/redux/states/featureFlagsState";
import { FeatureFlag } from "../../interfaces/featureFlags/featureFlag";

const createInitialState = (): FeatureFlagState => {
  return {
    featureFlags: [],
  };
};

const name = "featureFlags";
const initialState = createInitialState();

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setFetaureFlags: (state: FeatureFlagState, action: PayloadAction<FeatureFlag[]>) => {
      state.featureFlags = action.payload;
    },
  },
});

export const { setFetaureFlags } = slice.actions;
export const featureFlagsReducer = slice.reducer;
