import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "../../../../components";
import { AuthForm } from "../../layouts";
import { AuthFormValidator } from "../../utils/validators";

const ResetPassword = () => {
  const { t: translate } = useTranslation();
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    passwordError: "",
    confirmPasswordError: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const authFormValidator = new AuthFormValidator();

    const isPasswordEmpty = authFormValidator.isEmptyString(password.password);
    const isConfirmPasswordEmpty = authFormValidator.isEmptyString(
      password.confirmPassword
    );

    if (isPasswordEmpty) {
      setPasswordError((prevValue) => ({
        ...prevValue,
        passwordError: translate("field_empty_error"),
      }));
    }

    if (isConfirmPasswordEmpty) {
      setPasswordError((prevValue) => ({
        ...prevValue,
        confirmPasswordError: translate("field_empty_error"),
      }));
    }

    if (
      authFormValidator.matchPasswords(
        passwordError.passwordError,
        passwordError.confirmPasswordError
      )
    ) {
      setPasswordError((prevValue) => ({
        ...prevValue,
        passwordError: translate("pw_confirm_pw_match_error"),
        confirmPasswordError: translate("pw_confirm_pw_match_error"),
      }));
    }

    setIsLoading(false);
    // Login request here
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPassword({
      ...password,
      [name]: value,
    });
  };

  return (
    <AuthForm title="Reset Password">
      <form onSubmit={handleSubmit}>
        <Input
          name="password"
          value={password.password}
          handleInputChange={onChange}
          placeholder={translate("new_password")}
          error={passwordError.passwordError}
          type="password"
        />

        <Input
          name="confirmPassword"
          value={password.confirmPassword}
          handleInputChange={onChange}
          placeholder={translate("retype_password")}
          error={passwordError.confirmPasswordError}
          type="password"
        />

        <Button
          variant="primary"
          text={translate("change_password")}
          type="submit"
          disabled={isLoading}
          additionalStyles="authform__button"
        />
      </form>
    </AuthForm>
  );
};

export default ResetPassword;
