import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useStaffGapByWorkCompetenceChartConfigurator from "./hooks/useStaffComparisonChartConfigurator";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import useWorkCompetencesWithStaffingGaps from "./hooks/useWorkCompetencesWithStaffingGaps";
import WorkCompetenceStaffGap from "../../../interfaces/competence/WorkCompetenceStaffGap";

const StaffGapByWorkCompetenceChart = ({
  careUnitId,
  scenarioId,
  fetchData,
  setFetchData,
}: {
  careUnitId: number;
  scenarioId: number;
  fetchData: boolean;
  setFetchData: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const [isComponentLoading, setIsComponentLoading] = useState<boolean>(true);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options | null>(
    null
  );

  const dataHandler = useWorkCompetencesWithStaffingGaps(
    careUnitId,
    scenarioId
  );

  const chartConfigurator = useStaffGapByWorkCompetenceChartConfigurator();

  useEffect(() => {
    if (!dataHandler.isLoading) {
      const workComptenceDisplayName = dataHandler.data.map((r) => {
        return getWorkCompetenceDisplayName(
          r.formalCompetenceShortName,
          r.shortName
        );
      });

      const staffAvailabilities: number[] = getStaffAvailabilities(
        dataHandler.data
      );
      const staffTargets: number[] = getStaffTargets(dataHandler.data);

      const chartOptions = chartConfigurator.getOptions(
        workComptenceDisplayName,
        staffAvailabilities,
        staffTargets
      );

      setFetchData(false);

      setChartOptions(chartOptions);
    }
  }, [dataHandler.isLoading]);

  useEffect(() => {
    if (fetchData) {
      dataHandler.fetch();
    }
  }, [fetchData]);

  useEffect(() => {
    if (careUnitId && scenarioId) {
      setIsComponentLoading(false);
    }
  }, [careUnitId, scenarioId]);

  const getWorkCompetenceDisplayName = (
    formalCompetenceShortName: string | null,
    workCompetenceShortName: string | null
  ): string => {
    let displayName = "";

    if (formalCompetenceShortName && workCompetenceShortName) {
      formalCompetenceShortName = formalCompetenceShortName
        .replace("<", "")
        .replace(">", "");

      workCompetenceShortName = workCompetenceShortName
        .replace("<", "")
        .replace(">", "");

      displayName = `${formalCompetenceShortName} - ${workCompetenceShortName}`;
    }

    return displayName;
  };

  const getStaffAvailabilities = (data: WorkCompetenceStaffGap[]): number[] => {
    const availabilities: number[] = data.map((r) =>
      r.staffingAvailability ? r.staffingAvailability : 0
    );

    return availabilities;
  };

  const getStaffTargets = (data: WorkCompetenceStaffGap[]): number[] => {
    const targets: number[] = data.map((r) =>
      r.staffingTarget ? r.staffingTarget : 0
    );

    return targets;
  };

  return (
    <>
      {isComponentLoading && chartOptions && (
        <LoadingWithGrey sizeInPixel={40} />
      )}
      {!isComponentLoading && chartOptions && (
        <HighchartsReact
          containerProps={{ style: { height: "90%" } }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </>
  );
};

export default StaffGapByWorkCompetenceChart;
