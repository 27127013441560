import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { AppInsightService } from "../../../services";
import { RootState } from "../../../redux/store";
import { ISelectOption, MultiSelectOption } from "../../../interfaces";
import { v4 as uuid } from "uuid";
import { FollowUpDetails } from "../../../interfaces/productivity";
import WorksHourFilter from "./Filters/WorkhourFilter";
import { MONTHS } from "../../../utils/constants/months";
import WorkAnalysisService from "../../../services/workAnalysisService";
import useGetFormalCompetencesByCareUnit from "../../../common/hooks/data/useGetFormalCompetencesByCareUnit";
import FormalCompetence from "../../../interfaces/competence/FormalCompetence";

const FollowUp = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "FollowUp" });
  }, []);

  const workAnalysisCategoriesColors: { [key: string]: string } = {
    ActualHours: "#416031",
    ActualHoursOvertime: "#E5A32B",
    ActualHoursExternalStaff: "#8A181C",
    ScheduledHours: "#739768",
    PlannedHoursOvertime: "#F5C773",
    PlannedHoursExternalStaff: "#BD4B4F",
    Need: "#426FA2",
  };

  const { t: translate } = useTranslation();
  const [chartCategories, setChartCategories] = useState<string[]>([]);

  const type_of_inputs: ISelectOption[] = [
    {
      label: translate("hours"),
      value: "1",
    },
    {
      label: translate("fte"),
      value: "2",
    },
  ];

  const [typesOfInputs] = useState(type_of_inputs);
  const [selectedInput, setSelectedInput] = useState<ISelectOption>(type_of_inputs[0]);
  const [workanalysisDetails, setWorkanalysisDetails] = useState([]);
  const [selectedCompetenceShareOptions, setSelectedCompetenceShareOptions] = useState<MultiSelectOption[]>([]);

  const [competenceShareOptions, setCompetenceShareOptions] = useState<MultiSelectOption[]>([]);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const scenarioId = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const { data: formalCompetences, isLoading: isFormalCompetencesLoading } =
    useGetFormalCompetencesByCareUnit(careUnitId);

  const handleCompetenceSelect = (option: MultiSelectOption) => {
    const selectedCompetence = [...selectedCompetenceShareOptions, option];
    setSelectedCompetenceShareOptions(selectedCompetence);
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedInput(selectedOption);
  };

  const handleCompetenceDeselect = (optionToDeselect: MultiSelectOption) => {
    if (selectedCompetenceShareOptions.length > 1) {
      setSelectedCompetenceShareOptions(() => {
        return selectedCompetenceShareOptions.filter((option) => {
          return option.id !== optionToDeselect.id;
        });
      });
    }
  };

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const getMonthsTranslation = (month: string) => {
    return translate(`month_${MONTHS[month.slice(-2)]}`);
  };

  useEffect(() => {
    if (careUnitId !== null && selectedCompetenceShareOptions.length !== 0) {
      const competences = selectedCompetenceShareOptions.map((share) => {
        return share.value;
      });

      WorkAnalysisService.getWorkingHoursAnalysis(
        careUnitId,
        year,
        scenarioId,
        Number(selectedInput.value),
        competences as number[]
      ).then((res) => {
        const data = res.chartData.map((followup: FollowUpDetails) => {
          return {
            name: translate(followup.name),
            data: followup.data,
            color: workAnalysisCategoriesColors[followup.name],
            type: followup.name === "Need" ? "line" : "",
          };
        });

        const categories: string[] = [];
        res.months.map((month: string) => {
          categories.push(translate(getMonthsTranslation(month)));
        });
        setWorkanalysisDetails(data);
        setChartCategories(categories);
      });
    }
  }, [careUnitId, selectedCompetenceShareOptions, scenarioId, year, selectedInput]);

  useEffect(() => {
    if (formalCompetences && !isFormalCompetencesLoading) {
      const formalCompetencesOptions: MultiSelectOption[] = formalCompetences.map((competence: FormalCompetence) => {
        return {
          id: uuid(),
          label: competence.shortName,
          value: competence.id,
        };
      });

      setCompetenceShareOptions(formalCompetencesOptions);
      setSelectedCompetenceShareOptions(formalCompetencesOptions);
    }
  }, [isFormalCompetencesLoading, formalCompetences]);

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    colors: ["#2C461E", "#638B53", "#F5C773", "#BD4B4F", "#8A181C", "#C9C9C9", "#747474"],

    title: {
      text: undefined,
    },

    xAxis: {
      categories: chartCategories,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const value = Math.round((this.point as Highcharts.Point).y ?? 0);
        const series = this.series as Highcharts.Series;
        const tooltipInfo = `${series.name} <br /> ${value}`;
        return tooltipInfo;
      },
    },

    plotOptions: {
      series: {
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 70,
      },
    },

    series: workanalysisDetails,
  };

  return (
    <div className="flex h-full w-full">
      <div className="w-5/6 p-6">
        <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
      </div>

      <div className="h-full w-1/6 px-1 pr-4 pt-6">
        <div>
          <WorksHourFilter
            competenceShareOptions={competenceShareOptions}
            selectedCompetenceShareOptions={selectedCompetenceShareOptions}
            handleCompetenceDeselect={handleCompetenceDeselect}
            handleCompetenceSelect={handleCompetenceSelect}
            handleTypesOfInputSelect={handleTypesOfInputSelect}
            selectedInput={selectedInput}
            typesOfInputs={typesOfInputs}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowUp;
