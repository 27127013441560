import React, { useEffect, useState } from "react";
import { Button, LoadingWithColor, UserManagementIconButton } from "../../../../components";
import { GridLayout } from "../../../../layouts";
import SidePanel from "../side-panal/side-panal";
import { ScrollBarConstants } from "../../utils";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import UserManagementService from "../../../../services/userManagementService";
import { USER_ENDPOINT } from "../../../../utils/apiendpoints";
import "./user-list.css";
import { useTranslation } from "react-i18next";
import { User } from "../../../../interfaces/user-management";
import NotificationBox from "../../../../components/NotificationBox/NotificationBox";
import { startCase } from "lodash";
import { UserReadDto } from "../../../../interfaces/user-management/User";

interface SingleTableDataType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  permission: string;
  userRoles: {
    careProviderId: number;
    careUnitId: number;
    departmentId: number | null;
    hospitalId: number | null;
    role: string;
    roleId: string;
    type: string;
  }[];
}

const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="delete-button-small__svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
    />
  </svg>
);

const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="edit-button-small__svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
    />
  </svg>
);

const UsersList = () => {
  const { t: translate } = useTranslation();
  const [tableData, setTableData] = useState<SingleTableDataType[]>([]);
  const [userToEdit, setUserToEdit] = useState<UserReadDto>({
    email: "",
    firstName: "",
    hospital: "",
    lastName: "",
    phoneNumber: "",
    permission: "",
    userRoleId: "",
    careProviderId: null,
    assignedCareUnits: [],
    userRoles: [],
  });
  const [notificationBoxState, setNotificationBoxState] = useState<{
    isOpen: boolean;
    entityId: string | undefined;
    entityName: string | undefined;
  }>({
    isOpen: false,
    entityId: undefined,
    entityName: undefined,
  });

  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [sideBarVariant, setSideBarVariant] = useState<string | undefined>();
  const [successNotification, setSuccessNotification] = useState("");

  useEffect(() => {
    getAndSetUser();
  }, []);

  useEffect(() => {
    if (successNotification !== "") {
      getAndSetUser();
      const timer = setTimeout(() => {
        setSuccessNotification("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successNotification]);

  const handleAddUserClick = () => {
    setIsSideBarOpen(true);
    setSideBarVariant(ScrollBarConstants.ADD_USER);
  };

  const deleteUserPrompt = async (user: any) => {
    setNotificationBoxState(() => ({
      entityName: user.firstName,
      entityId: user.id,
      isOpen: true,
    }));
  };

  const editUser = async (user: any) => {
    const assignedUnits: number[] = [];

    user.userRoles?.map((role: any) => {
      assignedUnits.push(role.careUnitId);
    });

    const userToEdit: UserReadDto = {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      hospital: user.hospital || "",
      lastName: user.lastName,
      phoneNumber: user.phoneNumber || "",
      permission: user.permission,
      userRoleId: user.userRoles[0]?.roleId || "",
      careProviderId: user.careProviderId || null,
      assignedCareUnits: assignedUnits,
      userRoles: user.userRoles,
    };

    setUserToEdit(userToEdit);

    setIsSideBarOpen(true);
    setSideBarVariant(ScrollBarConstants.EDIT_USER);
  };

  const getAndSetUser = async () => {
    setIsDataLoading(true);
    await UserManagementService.getUsersAsync(USER_ENDPOINT).then((res) => {
      setTableData(res);
    });
    setIsDataLoading(false);
  };

  const handleNotificationBoxLeftButtonClick = async () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityId: undefined,
      isOpen: false,
    }));
    await UserManagementService.deleteUsersAsync(notificationBoxState.entityId || "").then(() => {
      getAndSetUser();
    });
  };

  const handleNotificationBoxRightButtonClick = () => {
    setNotificationBoxState(() => ({
      entityName: undefined,
      entityId: undefined,
      isOpen: false,
    }));
  };

  return (
    <>
      {notificationBoxState.isOpen && (
        <NotificationBox
          content={translate("delete_confirmation")}
          title={`${translate("delete")} ${startCase(notificationBoxState.entityName)}`}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxLeftButtonClick}
          onRightButtonClick={handleNotificationBoxRightButtonClick}
        />
      )}

      <GridLayout additionalStyles="mt-2">
        <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
          {successNotification !== "" && (
            <NotificationFloat varaint="success" content={`Successfully saved the ${successNotification}`} />
          )}
        </div>
        <div className="col-start-1 col-end-13">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between gap-3">
              <h1 className="heading-2">{translate("Users")}</h1>
            </div>

            <div className="flex items-center gap-[1.25rem]">
              <Button
                additionalStyles="xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"
                text={translate("add_user")}
                type="button"
                variant="primary"
                onClick={handleAddUserClick}
              />
            </div>
          </div>
        </div>

        <div className="z-0 col-start-1 col-end-13 mt-4 w-full">
          {isDataLoading ? (
            <LoadingWithColor sizeInPixel={50} additionalStyles="user-list-loader" />
          ) : (
            <table className="w-full">
              <thead>
                <tr>
                  <th className="pr-10 text-left">{translate("No")}</th>
                  <th className="pr-10 text-left">{translate("FirstName")}</th>
                  <th className="pr-10 text-left">{translate("LastName")}</th>
                  <th className="pr-10 text-left">{translate("email")}</th>
                  <th className="pr-10 text-left">{translate("permission")}</th>
                  <th className="pr-10 text-center">{translate("action")}</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((user: SingleTableDataType, index: number) => (
                  <tr key={user.id} className="h-12 border-y-2 hover:bg-slate-100">
                    <td>{index + 1}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.permission}</td>
                    <td className="text-right">
                      <div className="flex flex-row justify-center">
                        <UserManagementIconButton
                          type="delete"
                          icon={deleteIcon}
                          onClick={() => deleteUserPrompt(user)}
                        />
                        <UserManagementIconButton type="edit" icon={editIcon} onClick={() => editUser(user)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Left Sroll Bar */}
        <SidePanel
          userToEdit={userToEdit}
          isScrollBarOpen={isSideBarOpen}
          setIsScrollBarOpen={setIsSideBarOpen}
          setSideBarVariant={setSideBarVariant}
          variant={sideBarVariant}
          setSuccessNotification={setSuccessNotification}
          setUserToEdit={setUserToEdit}
        />
      </GridLayout>
    </>
  );
};

export default UsersList;
