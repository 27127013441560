import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PhysicianSubCareUnit } from "../../../interfaces/careunits/physician-subunits";
import { RootState } from "../../../redux/store";
import CareUnitService from "../../../services/careUnitService";
import { useTranslation } from "react-i18next";
import { usePhysicianSubUnitsReturn } from "../../../interfaces/components/use-physiciansubunit-return";

const usePhysicianSubUnits = (): usePhysicianSubUnitsReturn => {
  const [physicanSubUnits, setPhysicanSubUnits] = useState<string[]>([]);
  const { t: translate } = useTranslation();

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const setSubUnits = (units: PhysicianSubCareUnit[]): void => {
    if (units.length > 0) {
      setPhysicanSubUnits(units.map((unit) => unit.careUnitName));
    } else {
      setPhysicanSubUnits([translate("no_connected_care_units")]);
    }
  };

  useEffect(() => {
    if (careUnitId !== null) {
      CareUnitService.getPhycianSubCareUnits(careUnitId)
        .then((res) => {
          setSubUnits(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [careUnitId]);

  return { physicanSubUnits };
};

export default usePhysicianSubUnits;
