import React from "react";
import InPatientPlan from "../features/production-plan/InpatientPlan/InpatientPlan";
import SurgeryPlan from "../features/production-plan/SurgeryPlan/SurgeryPlan";
import OutPatientPlan from "../features/production-plan/OutpatientPlan/OutpatientPlan";

const ProductionRoutes = [
  {
    path: "inpatient",
    element: <InPatientPlan />,
  },
  {
    path: "outpatient",
    element: <OutPatientPlan />,
  },
  {
    path: "surgery",
    element: <SurgeryPlan />,
  },
];

export default ProductionRoutes;
