import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const useFeatureFlagIsEnabled = (feature: string): boolean => {
  const featureFlags = useSelector((state: RootState) => state.featureFlagsState.featureFlags);

  const isEnabled = (): boolean => {
    const featureFlag = featureFlags.find((r) => r.name === feature);

    if (!featureFlag) {
      return false;
    }

    return featureFlag.isEnabled;
  };

  const isFeatureFlagEnabled = isEnabled();

  return isFeatureFlagEnabled;
};
