import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Input, LoadingWithColor } from "../../../../components";
import { AuthForm } from "../../layouts";
import AuthService from "../../../../services/authService";
import { AuthFormValidator } from "../../utils/validators";

const ForgotPassword = () => {
  const { t: translate } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nagivate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const authFormValidator = new AuthFormValidator();

    const isEmailEmpty = authFormValidator.isEmptyString(email);

    if (isEmailEmpty) {
      setEmailError("Please enter a valid email address");
      setIsLoading(false);
      return;
    }

    const locale = localStorage.getItem("i18nextLng") || "";

    await AuthService.forgotPasswordAsync(email, locale)
      .then(() => {
        nagivate(`/auth/forgot-password/email-sent/${email}`);
      })
      .catch((err) => {
        throw err;
      });

    setIsLoading(false);
    // Login request here
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <AuthForm title={translate("forgot_password")}>
      {isLoading ? (
        <>
          <div className="empty-loading-container" />
          <LoadingWithColor sizeInPixel={50} additionalStyles="login-loader" />
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <span className="p-1-v-1">{translate("enter_email")}</span>
          <Input
            name="email"
            value={email}
            placeholder={translate("email")}
            handleInputChange={onChange}
            error={emailError}
            type="email"
          />

          <span className="p-1-v-1">{translate("send_reset_password_link")}</span>

          <Button
            variant="primary"
            text={translate("request_pw_reset_link")}
            type="submit"
            disabled={isLoading}
            additionalStyles="authform__button"
          />
        </form>
      )}
    </AuthForm>
  );
};

export default ForgotPassword;
