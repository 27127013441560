import { HotTableClass as HotTable } from "@handsontable/react";
import _ from "lodash";
import { CellChange, ChangeSource, RowObject } from "handsontable/common";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import WorkCompetenceMappingConfigReturn from "../../../../interfaces/parameters/workCompetenceMapping/WorkCompetenceMappingConfigReturn";
import useGetWorkCompetenceMappingTableColumns from "./useGetWorkCompetenceMappingTableColumns";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import useAdjustColumnHeader from "./useAdjustColumnHeader";
import { DataTableProps } from "../../../../interfaces/props/data-table-props";
import useAddNewRow from "../../../analytics/ActivityAnalysis/hooks/useAddNewRow";

const useGetWorkCompetenceMappingTableConfigs = (
  dataTable: RefObject<HotTable>,
  formalCompetences: FormalCompetence[],
  careUnitId: number
): WorkCompetenceMappingConfigReturn => {
  let culture = localStorage.getItem("i18nextLng");

  if (culture === "en") {
    culture = "en-US";
  } else if (culture === "en-US") {
    culture = "en-US";
  } else {
    culture = "sv-SE";
  }

  const cols = useGetWorkCompetenceMappingTableColumns(formalCompetences);
  const { t: translate } = useTranslation();

  const adjustShiftsColumnHeaderColours = useAdjustColumnHeader();

  const defaultData: RowObject = {
    id: -1,
    formalCompetenceId: null,
    name: null,
    shortName: null,
    careUnitId: careUnitId,
  };

  const { addNewRow } = useAddNewRow(dataTable);

  const afterChange = (changes: CellChange[] | null, source: ChangeSource) => {
    if (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste") {
      dataTable.current?.hotInstance?.validateCells();
    }
  };

  const props: DataTableProps = {
    afterChange: afterChange,
    afterGetColHeader: adjustShiftsColumnHeaderColours,
    cells: () => {
      return {
        className: "htMiddle",
      };
    },
    columns: cols.map((r) => r.meta),
    colHeaders: cols.map((r) => translate(r.headerTitle).replace("\n", "<br />")),
    colWidths: cols.map((r) => r.width),
    columnSorting: true,
    contextMenu: {
      items: {
        row_above: {},
        remove_row: {},
        row_below: {},
        alignment: {},
        copy: {},
        cut: {},
      },
    },
    dataSchema: defaultData,
    dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
    height: "79vh",
    hiddenColumns: {
      columns: [3],
      indicators: false,
    },
    language: culture,
    licenseKey: "non-commercial-and-evaluation",
    manualColumnResize: true,
    manualRowMove: false,
    numericFormat: { culture, pattern: {} },
    ref: dataTable,
    rowHeaders: true,
    rowHeaderWidth: 30,
    rowHeights: 35,
    wordWrap: false,
  };

  return {
    props,
    addNewRow,
  };
};

export default useGetWorkCompetenceMappingTableConfigs;
