import { useEffect, useState } from "react";

import ICDAnalysisService from "../../../../services/icdAnalysisService";
import { Gender } from "../../../../interfaces/analytics/icdAnalysis/gender";
import { Age } from "../../../../interfaces/analytics/icdAnalysis/age";
import { Chapter } from "../../../../interfaces/analytics/icdAnalysis/chapter";
import { Region } from "../../../../interfaces/analytics/icdAnalysis/region";

const useFetchDropdownOptionsData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [genders, setGenders] = useState<Gender[]>([]);
  const [ages, setAges] = useState<Age[]>([]);
  const [chapter, setChapter] = useState<Chapter[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const gendersData = await ICDAnalysisService.fetchGenders();
        setGenders(gendersData);

        const agesData = await ICDAnalysisService.fetchAges();
        setAges(agesData);

        const chaptersData = await ICDAnalysisService.fetchChapters();
        setChapter(chaptersData);

        const regionsData = await ICDAnalysisService.fetchRegions();
        setRegions(regionsData);

        const yearsData = await ICDAnalysisService.fetchYears();
        setYears(yearsData);
      } catch (error: unknown) {
        console.error("Error fetching", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    ages,
    chapter,
    genders,
    isLoading,
    regions,
    years,
  };
};

export default useFetchDropdownOptionsData;
