import { RowObject } from "handsontable/common";
import numbro from "numbro";

export const useWorkShiftValidators = () => {
  const competenceAndProductionsSharesSumValidator = (
    data: RowObject[] | undefined,
    competencesSharesColumns: string[],
    productionsSharesColumns: string[]
  ) => {
    const isValid = data?.every((col) => {
      let productionShareColumnsTotal = numbro(0);
      let competenceShareColumnsTotal = numbro(0);

      Object.keys(col).forEach((key) => {
        if (productionsSharesColumns.includes(key)) {
          if (col[key] === null || col[key] === " " || col[key] === "") {
            col[key] = 0;
          }

          productionShareColumnsTotal = productionShareColumnsTotal.add(
            col[key]
          );
        } else if (competencesSharesColumns.includes(key)) {
          if (col[key] === null || col[key] === " " || col[key] === "") {
            col[key] = 0;
          }
          competenceShareColumnsTotal = competenceShareColumnsTotal.add(
            col[key]
          );
        }
      });

      if (productionShareColumnsTotal.value() !== 1) {
        return false;
      }

      if (competenceShareColumnsTotal.value() !== 1) {
        return false;
      }

      return true;
    });

    return isValid;
  };

  return { competenceAndProductionsSharesSumValidator };
};
