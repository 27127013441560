import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const GlobalFilterReadyGuard = ({ children }: { children: JSX.Element }) => {
  const globalFilterIsLoading = useSelector((state: RootState) => state.globalFilter.isLoading);

  return !globalFilterIsLoading ? children : <></>;
};

export default GlobalFilterReadyGuard;
