import React from "react";
import { ControlIconTypes } from "./icon-types";

interface ControlIconProps {
  type: string;
}

const ControlIcon = ({ type }: ControlIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-4 w-4"
    >
      {type === ControlIconTypes.next && (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      )}
      {type === ControlIconTypes.perv && (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      )}
    </svg>
  );
};

export default ControlIcon;
