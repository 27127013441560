import { RowObject } from "handsontable/common";
import HttpCommonService from "./httpCommonService";

const ShiftService = {
  getShifts: async (careUnitId: number | null, scenarioId: number) => {
    return await HttpCommonService.getAsync(`api/shifts/${careUnitId}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getShiftsBudgetResult: async (careUnitId: number | null, scenarioId: number) => {
    return await HttpCommonService.getAsync(`api/shifts/budget/${careUnitId}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getShiftsAndBudgets: async (careUnitId: number | null, scenarioId: number) => {
    const paths = [`api/shifts/${careUnitId}/${scenarioId}`, `api/shifts/budget/${careUnitId}/${scenarioId}`];

    return await HttpCommonService.getParallelAsync(paths)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveShifts: async (
    shifts: RowObject[] | undefined,
    careUnitId: number | null,
    rowOrderList: number[] | undefined,
    scenarioId: number
  ) => {
    return await HttpCommonService.postAsync(`api/shifts`, {
      careUnitId: careUnitId,
      rowOrderList: rowOrderList,
      scenarioId: scenarioId,
      createShiftsList: {
        createShiftList: shifts,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getShiftsWorkCompetenceAllocations: async (careUnitId: number | null, scenarioId: number | null) => {
    return await HttpCommonService.getAsync(
      `api/shift/workCompetenceAllocations/CareUnit/${careUnitId}/Scenario/${scenarioId}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default ShiftService;
