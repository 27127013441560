import { useEffect, useState } from "react";
import { FilterGroupSchema } from "../../../../../interfaces/filter-group/filter-group-schema";
import FilterGroupService from "../../../../../services/filterGroupService";

interface ReturnGetFilterGroupsData<T> {
  data: T;
  fetch(): void;
}

const UseGetFilterGroups = (careUnitIds: number[]): ReturnGetFilterGroupsData<FilterGroupSchema[]> => {
  const [filterGroups, setFilterGroups] = useState<FilterGroupSchema[]>([]);

  const fetchData = async (): Promise<void> => {
    await FilterGroupService.getFilterGroup(careUnitIds).then((res) => {
      const responseData: FilterGroupSchema[] = res?.data;

      setFilterGroups(responseData);
    });
  };

  useEffect(() => {
    if (careUnitIds.length > 0) {
      fetchData();
    }
  }, [careUnitIds]);

  return { data: filterGroups, fetch: fetchData };
};

export default UseGetFilterGroups;
