import React, { useEffect, useState } from "react";
import { ReturnGetHistoricQueueCuttingPlan } from "../../../../interfaces/production-plan/outpatient/returnGetHistoricQueueCuttingPlan";
import HistoricQueueDetailService from "../../../../services/historicQueueDetailService";
import { OutPatientMultiSelectRequest } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { DelayedVisitsRequest } from "../../../../interfaces/production-plan/outpatient/queue/queueDetail";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";

const useGetHistoricQueueCuttingPlan = (
  careUnitId: number | null,
  queueDetailsRequest?: OutPatientMultiSelectRequest | null
): ReturnGetHistoricQueueCuttingPlan<number> => {
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.QueueDetailsMultiSelect);
  const [historicQueueCuttingPlan, setHistoricQueueCuttingPlan] = useState<number>(0);

  const fetchData = async () => {
    if (careUnitId !== null) {
      let requestPayload: DelayedVisitsRequest | null = null;

      if (isFeatureFlagEnabled && queueDetailsRequest != null && queueDetailsRequest.professionIds.length > 0) {
        requestPayload = {
          careUnitId: careUnitId,
          professionIds: queueDetailsRequest?.professionIds[0] === -1 ? [] : queueDetailsRequest?.professionIds,
          contactTypeIds: queueDetailsRequest?.contactTypeIds[0] === -1 ? [] : queueDetailsRequest?.contactTypeIds,
          contactReasonGroupingIds:
            queueDetailsRequest?.contactReasonGroupingIds[0] === -1
              ? []
              : queueDetailsRequest?.contactReasonGroupingIds,
          typesOfVisit: queueDetailsRequest?.typesOfVisit[0] === -1 ? [] : queueDetailsRequest?.typesOfVisit,
          careTypeIds: queueDetailsRequest?.careTypeIds[0] === -1 ? [] : queueDetailsRequest?.careTypeIds,
          typesOfEmergency:
            queueDetailsRequest?.typesOfEmergency[0] === -1 ? [] : queueDetailsRequest?.typesOfEmergency,
          medicalSpecialtyIds:
            queueDetailsRequest?.medicalSpecialtyIds[0] === -1 ? [] : queueDetailsRequest?.medicalSpecialtyIds,
        };
      } else if (!isFeatureFlagEnabled) {
        requestPayload = {
          careUnitId: careUnitId,
          professionIds: [],
          contactTypeIds: [],
          contactReasonGroupingIds: [],
          typesOfVisit: [],
          careTypeIds: [],
          typesOfEmergency: [],
          medicalSpecialtyIds: [],
        };
      }

      if (requestPayload) {
        await HistoricQueueDetailService.getHistoricQueueCuttingPlan(requestPayload)
          .then((res: number) => {
            setHistoricQueueCuttingPlan(res);
          })
          .catch((err) => {
            throw err;
          });
      }
    }
  };

  useEffect(() => {
    if (careUnitId !== null) {
      fetchData();
    }
  }, [careUnitId, queueDetailsRequest]);

  return {
    data: historicQueueCuttingPlan,
    fetch: fetchData,
  };
};

export default useGetHistoricQueueCuttingPlan;
