import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import OutpatientMultiselectFilter from "../../Components/OutpatientMultiselectFilter/OutpatientMultiselectFilter";
import { OutPatientMultiSelectRequest } from "../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import ResultsChart from "./ResultsChart/ResultChart";
import OutPatientResultPanel from "./ResultsPanel/OutpatientPlanResultPanel";

export interface ResultProps {
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  isTabBarClicked: boolean;
  setIsTabBarClicked: Dispatch<SetStateAction<boolean>>;
  clickedTabIndex: number;
}

const OutPatientPlanResult = ({
  setSelectedTabIndex,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
}: ResultProps) => {
  const [outPatientMultiSelectRequest, setOutPatientMultiSelectRequest] = useState<OutPatientMultiSelectRequest | null>(
    null
  );

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.OutpatientResultMultiSelect);

  useEffect(() => {
    if (isTabBarClicked) {
      setSelectedTabIndex(() => clickedTabIndex);
      setIsTabBarClicked(false);
    }
  }, [isTabBarClicked]);

  return (
    <>
      <div className="h-full w-5/6">
        <ResultsChart outpatientMultiSelectFilterRequest={outPatientMultiSelectRequest} />
      </div>
      <div className="ml-5 w-1/6">
        <OutPatientResultPanel outpatientMultiSelectFilterRequest={outPatientMultiSelectRequest} />
        {isFeatureFlagEnabled && (
          <OutpatientMultiselectFilter
            setOutPatientMultiSelectRequest={setOutPatientMultiSelectRequest}
            outPatientMultiSelectRequest={outPatientMultiSelectRequest}
          />
        )}
      </div>
    </>
  );
};

export default OutPatientPlanResult;
