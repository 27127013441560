import React, { useEffect, useState } from "react";
import { GridLayout } from "../../../../layouts";
import { Button, LoadingWithColor, UserManagementIconButton } from "../../../../components";
import { useTranslation } from "react-i18next";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";
import ScenarioService from "../../../../services/scenarioService";
import { ScenarioRead } from "../../../../interfaces/parameters/scenario";
import { ScrollBarConstants } from "../Utils";
import SidePanel from "../Components/Sidepanel/Sidepanel";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="edit-button-small__svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
    />
  </svg>
);

const ScenarioHome = () => {
  const { t: translate } = useTranslation();
  const [tableData, setTableData] = useState<ScenarioRead[]>([]);
  const [scenarioToEdit, setScenarioToEdit] = useState<ScenarioRead>({
    id: 0,
    scenarioName: "",
    createdBy: "",
    dateCreated: new Date(),
    lastModifiedBy: "",
    lastModifiedDate: new Date(),
    lockUntil: null,
  });

  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [sideBarVariant, setSideBarVariant] = useState<string>();
  const [successNotification, setSuccessNotification] = useState("");
  const locale = localStorage.getItem("i18nextLng") === "en" ? "en" : "sv-SE";

  useEffect(() => {
    getAndSetScenario();
  }, []);

  const isGlobalAdminAccess = useSelector((state: RootState) => state.permission.globalSettingEnabled);

  useEffect(() => {
    if (successNotification !== "") {
      getAndSetScenario();
      const timer = setTimeout(() => {
        setSuccessNotification("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successNotification]);

  const handleAddScenariorClick = () => {
    setIsSideBarOpen(true);
    setSideBarVariant("ADD_SCENARIO");
  };

  const editScenario = async (scenario: ScenarioRead) => {
    const scenarioToEdit: ScenarioRead = {
      id: scenario.id,
      scenarioName: scenario.scenarioName,
      createdBy: scenario.createdBy,
      dateCreated: scenario.dateCreated,
      lastModifiedBy: scenario.lastModifiedBy,
      lastModifiedDate: scenario.lastModifiedDate,
      lockUntil: scenario.lockUntil ? new Date(scenario.lockUntil) : null,
    };

    setScenarioToEdit(scenarioToEdit);

    setIsSideBarOpen(true);
    setSideBarVariant(ScrollBarConstants.UPDATE_SCENARIO);
  };
  const getAndSetScenario = async () => {
    setIsDataLoading(true);
    await ScenarioService.getScenario().then((res: ScenarioRead[]) => {
      setTableData(res);
    });
    setIsDataLoading(false);
  };

  return (
    <>
      <GridLayout additionalStyles="mt-0 border-2 rounded-r rounded-b h-[86vh] px-0 bg-[#F8F8F8]">
        <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
          {successNotification !== "" && (
            <NotificationFloat varaint="success" content={`Successfully saved the ${successNotification}`} />
          )}
        </div>
        <div className="col-start-1 col-end-13">
          <div className="flex items-center justify-between bg-white px-2 py-2">
            <div className="col-start-1 col-end-7 items-center justify-between gap-3"></div>
            <div className="col-start-7 col-end-13 mt-0 flex pr-0">
              <div className="flex items-center gap-[1.25rem]">
                <Button
                  additionalStyles="xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"
                  text={translate("add_scenario")}
                  type="button"
                  variant="primary"
                  onClick={handleAddScenariorClick}
                />
              </div>
            </div>
          </div>

          <div className="rounded px-2">
            {isDataLoading ? (
              <LoadingWithColor sizeInPixel={50} additionalStyles="user-list-loader" />
            ) : (
              <table className="w-full">
                <thead className="h-10">
                  <tr>
                    <th className="pl-4 text-left">{translate("No")}</th>
                    <th className="pr-10 text-left">{"Scenario"}</th>

                    {isGlobalAdminAccess && <th className="pr-10 text-left">{translate("locked_until")}</th>}
                    {/* NOTE - Commendted to satify Sormland Reqs */}
                    {/* <th className="pr-10 text-left">
                      {translate("created_by")}
                    </th>
                    <th className="pr-10 text-left">
                      {translate("created_date")}
                    </th> */}
                    <th className="pr-10 text-left">{translate("modified_by")}</th>
                    <th className="pr-10 text-left">{translate("modified_date")}</th>
                    <th className="pr-10 text-right">{translate("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((scenario: ScenarioRead, index: number) => (
                    <tr key={scenario.id} className="h-12 border-y-2 hover:bg-slate-100">
                      <td className="pl-4">{index + 1}</td>
                      <td>{scenario.scenarioName}</td>
                      {isGlobalAdminAccess && (
                        <td>{scenario.lockUntil ? scenario.lockUntil.toString().substring(0, 10) : null}</td>
                      )}
                      {/* NOTE - Commendted to satify Sormland Reqs */}
                      {/* <td>{scenario.createdBy}</td>
                      <td>
                        {scenario.dateCreated
                          .toLocaleString(locale)
                          .split("T", 2)[0] +
                          " " +
                          scenario.dateCreated
                            .toLocaleString(locale)
                            .split("T", 2)[1]
                            .split(".", 2)[0]}
                      </td> */}
                      <td>{scenario.lastModifiedBy}</td>
                      <td>
                        {scenario.lastModifiedDate.toLocaleString(locale).split("T", 2)[0] +
                          " " +
                          scenario.lastModifiedDate.toLocaleString(locale).split("T", 2)[1].split(".", 2)[0]}
                      </td>
                      <td className="items-center">
                        <div className="flex flex-row justify-center">
                          <UserManagementIconButton
                            type="edit"
                            icon={editIcon}
                            onClick={() => editScenario(scenario)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {/* Left Sroll Bar */}
        <SidePanel
          scenarioToEdit={scenarioToEdit}
          isScrollBarOpen={isSideBarOpen}
          setIsScrollBarOpen={setIsSideBarOpen}
          setSideBarVariant={setSideBarVariant}
          variant={sideBarVariant}
          setSuccessNotification={setSuccessNotification}
          setScenarioToEdit={setScenarioToEdit}
        />
      </GridLayout>
    </>
  );
};

export default ScenarioHome;
