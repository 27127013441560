import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import OutsideClickHandler from "react-outside-click-handler";

import { ISelectOption } from "../../interfaces";
import { SelectProps } from "../../interfaces/props";
import { useTranslation } from "react-i18next";
import Truncate from "react-truncate";
import clsx from "clsx";

const Select = ({
  options,
  onSelectOption,
  selectStyles,
  placeholder,
  disabled,
  selectedOption: defaultSelected,
  selectedOptions,
  isMulti,
  isSmallDropDownIcon,
  onRemoveOption,
}: SelectProps) => {
  const { t: translate } = useTranslation();
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null | undefined>(null);
  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    setSelectedOption(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    if (selectedOptions) {
      setSelectOptions(selectedOptions);
    }
  }, [selectedOptions]);

  const handleSelectElementClick = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleOptionClick = (option: ISelectOption) => {
    setSelectedOption(option);
    onSelectOption(option);
    !isMulti && setIsOptionsOpen(!isOptionsOpen);
    isMulti && options.length === 1 ? setIsOptionsOpen(false) : null;
  };

  const handleOptionCloseClick = (value: string) => {
    onRemoveOption?.(value);
  };

  /*
   * To track the hovered option and remove the borders on it and it's upper element
   */
  const [hoveredPosition, setHoveredPosition] = useState<number | null>(null);

  return (
    <>
      {isMulti && selectOptions.length > 0 ? (
        <span className="p-3-v-2 mb-3 mt-4 block text-paragraph-lg-1">{translate("selected_care_units")}</span>
      ) : null}
      {isMulti &&
        selectOptions.map((opt) => {
          return (
            <div
              key={opt.value}
              className="leading-sm mb-3 mr-1 inline-flex items-center rounded-full border bg-white px-3 py-1 text-paragraph-xl-3 font-bold text-gray-700"
            >
              {opt.label}
              <button
                type="button"
                onClick={() => handleOptionCloseClick(opt.value)}
                className="ml-3 inline-flex items-center rounded-full bg-gray-300 p-1.5 text-center text-sm font-medium text-black hover:bg-gray-500 focus:outline-none"
              >
                <svg className="h-2 w-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="Close"
                    d="M27.729,9.544l-2.02-2.02-8.082,8.082L9.544,7.523l-2.02,2.02,8.082,8.082L7.523,25.708l2.02,2.02,8.082-8.082,8.082,8.082,2.02-2.02-8.082-8.082Z"
                    transform="translate(-7.523 -7.523)"
                  />
                </svg>
              </button>
            </div>
          );
        })}

      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOptionsOpen(false);
        }}
      >
        <div
          className={clsx("select__container", {
            "select--disabled": disabled,
          })}
        >
          <div
            className={clsx("select", selectStyles, {
              "select--expand": isOptionsOpen,
              "select--closed": !isOptionsOpen,
            })}
            onClick={() => {
              if (!disabled) handleSelectElementClick();
            }}
          >
            {!isMulti ? (
              <div className="select__placeholder p-2-v-1 mt-2">
                <span>
                  <Truncate lines={1} width={120}>
                    {selectedOption && selectedOption.label}
                  </Truncate>
                </span>
              </div>
            ) : (
              <div className="select__placeholder p-2-v-1 mt-2 overflow-x-auto"></div>
            )}

            {!isSmallDropDownIcon && (
              <div className="select__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`select__icon__svg ${isOptionsOpen && "select__icon__svg--expanded"}`}
                  viewBox="0 0 17.274 10.667"
                >
                  <path
                    id="Dropdown_Arrow"
                    data-name="Dropdown Arrow"
                    d="M11.03,11.76l6.607,6.593,6.607-6.593,2.03,2.03-8.637,8.637L9,13.79Z"
                    transform="translate(-9 -11.76)"
                  />
                </svg>
              </div>
            )}

            {isSmallDropDownIcon && (
              <div className="select__icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`select__icon__svg ${isOptionsOpen && "select__icon__svg--expanded"}`}
                  viewBox="0 0 22.274 9.667"
                >
                  <path
                    id="Dropdown_Arrow"
                    data-name="Dropdown Arrow"
                    d="M11.03,11.76l6.607,6.593,6.607-6.593,2.03,2.03-8.637,8.637L9,13.79Z"
                    transform="translate(-9 -11.76)"
                  />
                </svg>
              </div>
            )}

            {!isMulti ? (
              <label
                className={`input__label mb-1 ml-1 ${
                  !selectedOption ? "p-1-v-1 top-1/2 text-base" : "p-3-v-1"
                } pl-[0.2rem]`}
              >
                {placeholder}
              </label>
            ) : (
              <label className={`input__label p-1-v-1 top-1/2 pl-2 text-base`}>{translate("select_care_units")}</label>
            )}
          </div>

          {!disabled && (
            <div
              className={`select__options  ${isOptionsOpen && "select__options--expanded"}  ${
                options.length > 5 && isOptionsOpen && "select__options--scrollable"
              }`}
            >
              {isOptionsOpen && (
                <ul className="">
                  {options &&
                    options.map((option, i) => (
                      <div key={uuidv4()} className="cursor-pointer">
                        <li
                          onMouseEnter={() => setHoveredPosition(i)}
                          onMouseOut={() => setHoveredPosition(null)}
                          onClick={() => handleOptionClick(option)}
                          className="select__option"
                        >
                          {option.label}
                        </li>

                        {/* Hide the hr when on the last option */}
                        {!(i + 1 === options.length) && <hr className="select__option__underline" />}
                      </div>
                    ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Select;
