import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

import { OrgStrctureHome } from "../features/orgstructure/pages";
import UsersList from "../features/orgstructure/pages/userslist/userlist";

import { Homepage } from "../pages";

import { AnalyticsHome } from "../features/analytics";
import { ParametersHome } from "../features/parameters";
import { GlobalSettingsHome } from "../features/settings";
import AuthManagementHome from "../features/user-management/pages/auth-management";
import { ChangePasswordHome } from "../features/user-management/pages";
import { PasswordOperation } from "../features/user-management/utils/change-password";
import { ProductivityHomePage } from "../features/productivity";
import { ProductionPlanHomePage } from "../features/production-plan";
import ApplicationLayout from "../layouts/ApplicationLayout";
import AuthenticatedRoutes from "./guards/AuthenticatedRoutes";
import AuthorizedRoutes from "./guards/AuthorizedRoutes";

import ProductivityRoutes from "./ProductivityRoutes";
import AnalyticsRoutes from "./AnalyticsRoutes";
import ProductionRoutes from "./ProductionRoutes";
import AuthRoutes from "./AuthRoutes";
import PeopleRoutes from "./PeopleRoutes";
import PeopleHome from "../features/people/PeopleHome/PeopleHome";
import KanbanBoard from "../features/improve/kanban/pages/KanbanBoard";
import GlobalFilterReadyGuard from "./guards/GlobalFilterReadyGuard";

const router = createBrowserRouter([
  {
    element: <ApplicationLayout />,
    children: [
      {
        element: <AuthenticatedRoutes />,
        children: [
          {
            path: "",
            element: (
              <GlobalFilterReadyGuard>
                <Homepage />
              </GlobalFilterReadyGuard>
            ),
          },
          {
            path: "/org",
            element: <OrgStrctureHome />,
          },
          {
            path: "/userslist",
            element: <UsersList />,
          },
          {
            path: "/settings/global",
            element: <GlobalSettingsHome />,
          },
          {
            path: "/production",
            element: <ProductionPlanHomePage />,
            children: ProductionRoutes,
          },
          {
            path: "/productivity",
            element: <ProductivityHomePage />,
            children: ProductivityRoutes,
          },
          {
            path: "/parameters",
            element: <ParametersHome />,
          },
          {
            path: "/people",
            element: <PeopleHome />,
            children: PeopleRoutes,
          },
          {
            path: "/changepassword",
            element: <ChangePasswordHome type={PasswordOperation.change} />,
          },
          {
            path: "/analytics",
            element: <AnalyticsHome />,
            children: AnalyticsRoutes,
          },
          {
            path: "improve",
            element: <KanbanBoard />,
          },
          {
            element: <AuthorizedRoutes />,
            children: [
              {
                path: "/usermanagement",
                element: <AuthManagementHome />,
              },
            ],
          },
        ],
      },
      {
        path: "/auth",
        children: AuthRoutes,
      },
      {
        path: "/setpassword/:id",
        element: <ChangePasswordHome type={PasswordOperation.set} />,
      },
      {
        path: "/forgotpassword/:id",
        element: <ChangePasswordHome type={PasswordOperation.reset} />,
      },

      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export default router;
