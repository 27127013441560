import React from "react";
import { CloseButtonProps } from "../../interfaces/props/close-buttob";
import "./close-button.css";

const CloseButton = ({ onClose }: CloseButtonProps) => {
  return (
    <button className="" onClick={onClose}>
      <svg viewBox="0 0 512 512" fill="currentColor" className="h-6 w-6">
        <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
      </svg>
    </button>
  );
};

export default CloseButton;
