import HttpCommonService from "./httpCommonService";

const AbsenceService = {
  getAbsenceHourAnalysis: async (
    careUnitId: number | null,
    year: number,
    scenarioId: number,
    formalCompetences: number[],
    typeId: number
  ) => {
    return await HttpCommonService.postAsync(`api/absence/hourly-analysis`, {
      careUnitId,
      year,
      scenarioId,
      formalCompetences: formalCompetences,
      typeId,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default AbsenceService;
