import React from "react";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowUpIcon from "./ArrowUpIcon";

interface IconProps {
  variant: "arrowup" | "arrowdown";
  height?: number;
  width?: number;
}

const Icon: React.FC<IconProps> = ({
  variant,
  height,
  width,
}: IconProps): JSX.Element => {
  let Component: React.ReactNode;

  switch (variant) {
    case "arrowup":
      Component = <ArrowUpIcon height={height} width={width} />;
      break;
    case "arrowdown":
      Component = <ArrowDownIcon height={height} width={width} />;
      break;
    // Add more cases for other variants
    default:
      Component = <></>;
      break;
  }

  return Component;
};

export default Icon;
