export const FeatureFlags = {
  ActivityAnalysis: "Activity Analysis",
  DetailedPlanMultiSelect: "Detailed Plan Multi Select",
  EmploymentTypeMultiSelect: "Employment Type Multi Select",
  BenchmarkGoals: "Benchmark Goals",
  CompensationsByCareunit: "Compensations By Careunit",
  InpatientProductivityAnalysis: "Inpatient Productivity Analysis",
  ActualProductivity: "Actual Productivity",
  QueueDetailsMultiSelect: "Queue Details Multi Select",
  OohCompType: "Ooh Comp Type",
  ICDAnalysis: "ICD Analysis",
  OutpatientResultMultiSelect: "Outpatient Result Multiselect",
  MultipleQueueCuttingPlans: "Multiple Queue Cutting Plans",
  CreateCareUnitWithParent: "Create Care Unit with Parent",
  LOSReferenceKpi: "LOS Reference KPI",
  ProductionVsPlanFilters: "Production Vs Plan Filters",
  Heatmaps: "Heatmaps",
  BenchmarkCareLevelFilter: "Benchmark Care Level Filter",
  CareUnitDataSourceAssignment: "Care Unit Data Source Assignment",
  inpatientResultWeekdayDropdown: "Inpatient Result Weekday Dropdown",
  prodVsPlanExplnatoryTable: "ProdVsPlan Outpatient Explanatory Table",
  GroupRefferalsByProfessions: "Group Referrals By Profession",
  NoOfWeekendShifts: "No Of Weekend Shifts",
  InpatientOrgOverviewV2: "Inpatient Org Overview V2",
  OutpatientOrgOverview: "Outpatient Org Overview",
  Kanban: "Kanban",
};
