interface FuncDate {
  getMonthMmm: (input: Date) => string | null;
}

export const useDate = (): FuncDate => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getMonthMmm = (input: Date): string | null => {
    if (input) {
      return months[input.getMonth()];
    } else {
      return null;
    }
  };

  return { getMonthMmm };
};
