import HttpCommonService from "./httpCommonService";
import {
  CARE_LEVELS_ENDPOINT,
  CARE_UNIT_ENDPOINT,
  CARE_UNIT_TYPES_ENDPOINT,
  DEPARTMENT_ENDPOINT,
  DIVISION_ENDPOINT,
  HOSPITAL_ENDPOINT,
  MEDICAL_SPECIALITIES_ENDPOINT,
  PHYSICAL_CARE_UNIT_ENDPOINT,
  SECTION_ENDPOINT,
  SITE_ENDPOINT,
} from "../utils/apiendpoints";

import {
  CareLevelDto,
  CareUnitDto,
  CareUnitTypeDto,
  DepartmentDto,
  DivisionDto,
  HospitalDto,
  MedicalSpecialityDto,
  CareUnitGroupDto,
  SectionDto,
  SiteDto,
} from "../interfaces/orgstructure/dtos";

const OrgService = {
  getOrgTreeAsync: async (path: string) => {
    return await HttpCommonService.getAsync(path)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getOrgCareProvidersAsync: async (year: number) => {
    return await HttpCommonService.getAsync(`api/org/parents/year/${year}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getOrgChildrenHierarchyAsync: async (parentId: number, year: number) => {
    return await HttpCommonService.getAsync(`api/org/parent/${parentId}/year/${year}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getCareUnitsByParentAsync: async (parentId: number, year: number) => {
    return await HttpCommonService.getAsync(`api/org/careunits/parent/${parentId}/year/${year}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getOrgAsync: async (path: string) => {
    return await HttpCommonService.getAsync(path)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getSitesAsync: async (careProviderId: number) => {
    return await HttpCommonService.getAsync(`api/sites/${careProviderId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getAllSitesAsync: async () => {
    return await HttpCommonService.getAsync(`api/sites/`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  createSiteAsync: async (siteDto: SiteDto) => {
    return await HttpCommonService.postAsync(SITE_ENDPOINT, siteDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createCareLevelAsync: async (careLevelDto: CareLevelDto) => {
    return await HttpCommonService.postAsync(CARE_LEVELS_ENDPOINT, careLevelDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createCareUnitTypeAsync: async (careUnitTypeDto: CareUnitTypeDto) => {
    return await HttpCommonService.postAsync(CARE_UNIT_TYPES_ENDPOINT, careUnitTypeDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createMedicalSpecialityAsync: async (medicalSpecialityDto: MedicalSpecialityDto) => {
    return await HttpCommonService.postAsync(MEDICAL_SPECIALITIES_ENDPOINT, medicalSpecialityDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createHospitalAsync: async (hospitalDto: HospitalDto) => {
    return await HttpCommonService.postAsync(HOSPITAL_ENDPOINT, hospitalDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createDivisionAsync: async (divisionDto: DivisionDto) => {
    return await HttpCommonService.postAsync(DIVISION_ENDPOINT, divisionDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createDepartmentAsync: async (departmentDto: DepartmentDto) => {
    return await HttpCommonService.postAsync(DEPARTMENT_ENDPOINT, departmentDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createSectionAsync: async (sectionDto: SectionDto) => {
    return await HttpCommonService.postAsync(SECTION_ENDPOINT, sectionDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createCareUnitAsync: async (careUnitDto: CareUnitDto, physicianSubCareUnits: number[] | null) => {
    const body = {
      careUnitDto,
      physicianSubCareUnits: physicianSubCareUnits,
    };

    return await HttpCommonService.postAsync(CARE_UNIT_ENDPOINT, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  updateCareUnitAsync: async (careUnitDto: CareUnitDto, physicianSubCareUnits: number[] | null) => {
    const body = {
      careUnitDto,
      physicianSubCareUnits: physicianSubCareUnits,
    };
    return await HttpCommonService.putAsync(CARE_UNIT_ENDPOINT, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  createPhysicalCareUnitAsync: async (physicalCareUnitDto: CareUnitGroupDto) => {
    return await HttpCommonService.postAsync(PHYSICAL_CARE_UNIT_ENDPOINT, physicalCareUnitDto)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  deleteOrgStrutctureItemAsync: async (entityType: string, entityId: number) => {
    return await HttpCommonService.deletAsync(`api/${entityType}/${entityId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default OrgService;
