import { HotTableClass as HotTable } from "@handsontable/react";
import React, { RefObject, useEffect } from "react";
import StaffGridConfigReturn from "../../../../interfaces/staff-availabilities/staffGridConfigReturn";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useAddNewRow from "../../../../common/hooks/handsontable/useAddNewRow";
import FormalCompetence from "../../../../interfaces/competence/FormalCompetence";
import { EmploymentType } from "../../../../interfaces/employment-type/employment-type";
import { DataTableProps } from "../../../../interfaces/props/data-table-props";
import { HandsOnTableColumn } from "../../../../interfaces/handsOnTable/handsOnTableColumn";
import useStaffColumnsConfigurator from "./useStaffColumnsConfigurator";
import StaffGridDynamicRowType from "../../../../interfaces/staff-availabilities/staffGridDynamicRowType";
import { FormalCompetenceColumnSpan } from "../../../../interfaces/handsOnTable/formalCompetenceColumnSpan";
import { CellChange, ChangeSource } from "handsontable/common";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

const useStaffGridConfigurator = (dataTable: RefObject<HotTable>): StaffGridConfigReturn => {
  const { addNewRow } = useAddNewRow(dataTable);
  const { t: translate, i18n } = useTranslation();
  const culture = localStorage.getItem("i18nextLng");

  const staffColumnsConfigurator = useStaffColumnsConfigurator(i18n.language);

  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const configCells = (row: number, col: number, formalCompetences: FormalCompetence[]) => {
    type cp = { className?: string; readOnly?: boolean };
    //  get visual row index
    const visualRow = Number(dataTable.current?.__hotInstance?.rowIndexMapper?.getVisualFromPhysicalIndex(row));

    const cp: cp = {};

    if (visualRow % 2 === 0) {
      cp.className = clsx(cp.className, "grey-row");
    }

    cp.className = clsx(cp.className, "htMiddle");

    return cp;
  };

  const createNestedHeaders = (colConfigs: HandsOnTableColumn[], formalCompetences: FormalCompetence[]) => {
    const formalCompetenceColumnSpan: FormalCompetenceColumnSpan[] = [];

    formalCompetences.forEach((formalCompetence) => {
      formalCompetenceColumnSpan.push({
        formalCompetenceId: formalCompetence.id as number,
        colspan: formalCompetence.workCompetences.length,
        formalCompetenceName: formalCompetence.name,
      });
    });

    const competenceMixHeaders = formalCompetenceColumnSpan.map((fc) => {
      return {
        colspan: fc.colspan,
        label: fc.formalCompetenceName || "",
      };
    });

    const firstLayer = [
      {
        colspan: 8,
        label: translate(""),
      },
      {
        colspan: 52,
        label: translate("weekly_availability"),
      },
      ...competenceMixHeaders,
    ];

    const secondLayer = colConfigs.map((col) => {
      return {
        label: translate(col.headerTitle),
        colspan: 1,
      };
    });

    const nestedHeaders = [firstLayer, secondLayer];

    return nestedHeaders;
  };

  const getConfigs = (
    formalCompetences: FormalCompetence[],
    employmentTypes: EmploymentType[],
    defaultDataSchema: StaffGridDynamicRowType,
    setIsTableDataEdited: React.Dispatch<React.SetStateAction<boolean>>
  ): DataTableProps => {
    const cols: HandsOnTableColumn[] = staffColumnsConfigurator.getColumns(
      culture ? culture : "",
      formalCompetences,
      employmentTypes
    );

    const nestedHeaders = createNestedHeaders(cols, formalCompetences);

    const props: DataTableProps = {
      afterChange: function (changes: CellChange[] | null, source: ChangeSource) {
        if (changes && (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste")) {
          dataTable.current?.__hotInstance?.batch(() => {
            changes.forEach(function (change) {
              const row = change[0];
              const prop = change[1];
              const newValue = change[3];

              const workCompetenceIds: number[] = [];

              formalCompetences.forEach((competence) => {
                competence.workCompetences.forEach((workCompetence) => {
                  if (workCompetence.id) {
                    workCompetenceIds.push(workCompetence.id);
                  }
                });
              });

              if (prop === "formalCompetenceShortName") {
                const newFormalCompetence = formalCompetences.find((r) => r.shortName === newValue);
                let firstWorkCompetenceId: number | null = null;

                if (newFormalCompetence && newFormalCompetence.workCompetences[0]) {
                  firstWorkCompetenceId = newFormalCompetence.workCompetences[0].id;
                }

                workCompetenceIds.forEach((workCompetenceId) => {
                  const column = dataTable.current?.__hotInstance?.propToCol(workCompetenceId.toString());

                  const newAllocationValue =
                    firstWorkCompetenceId === null ? "" : firstWorkCompetenceId === workCompetenceId ? "100" : "";

                  if (column) {
                    dataTable.current?.__hotInstance?.setDataAtCell(row, column as number, newAllocationValue);
                  }
                });
              }
            });
          });

          setIsTableDataEdited(true);
        }
      },
      afterGetColHeader: (col: number, TH) => {
        const colConfig = cols[col];

        if (colConfig && colConfig.headerColor) {
          TH.style.backgroundColor = colConfig.headerColor;
          TH.style.color = "white";
        } else {
          TH.classList.add("dark-blue-column");
        }

        TH.classList.add("htMiddle");

        if (colConfig && !colConfig.isFilterable) {
          TH.classList.add("remove-filter");
        }
      },
      afterRemoveRow: () => {
        setTimeout(() => {
          dataTable.current?.hotInstance?.validateCells((valid) => {
            if (valid) {
              setIsTableDataEdited(true);
            }
          });
        }, 100);
      },
      cells: (row: number, col: number) => configCells(row, col, formalCompetences),
      columns: cols.map((r) => r.meta),
      nestedHeaders: nestedHeaders,
      colWidths: cols.map((r) => r.width),
      contextMenu: ["row_above", "row_below", "remove_row", "alignment", "copy", "cut"],
      dataSchema: defaultDataSchema,
      dropdownMenu: true,
      filters: true,
      fixedColumnsLeft: 2,
      height: "100%",
      readOnly: isCareUnitReadOnly ? isCareUnitReadOnly : false,
      ref: dataTable,
      rowHeaders: true,
      rowHeights: 35,
    };

    return props;
  };

  return {
    getConfigs,
    addNewRow,
  };
};

export default useStaffGridConfigurator;
