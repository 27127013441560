import React from "react";
import { Shift } from "../../../../interfaces/work-shifts/shift";
import { RowObject } from "handsontable/common";
import { WorkShiftsPayload } from "../../../../interfaces/work-shifts/workShiftsPayload";
import { ShiftWorkCompetenceAllocationPayload } from "../../../../interfaces/work-shifts/shiftWorkCompetenceAllocationPayload";
import { ShiftFormalCompetenceAllocation } from "../../../../interfaces/work-shifts/shiftFormalCompetenceAllocation";
import { DynamicType } from "../../../../types/components";

interface useWorkShiftsUtilitiesReturn {
  shift: WorkShiftsPayload;
  allocations: ShiftWorkCompetenceAllocationPayload[];
}

const useWorkShiftsUtilities = () => {
  const transformCompetenceAllocations = (
    data: RowObject[],
    propertyNamesRef: React.MutableRefObject<string[]>
  ): useWorkShiftsUtilitiesReturn[] => {
    const modifiedData: {
      shift: WorkShiftsPayload;
      allocations: ShiftWorkCompetenceAllocationPayload[];
    }[] = [];

    if (data) {
      data.forEach((row) => {
        const shift = { ...row };

        const shiftAllocations: ShiftWorkCompetenceAllocationPayload[] = [];

        shift.id = parseInt(shift.id) || 0;

        propertyNamesRef.current.forEach((propertyName) => {
          const allocation: ShiftWorkCompetenceAllocationPayload = {
            workCompetenceId: parseInt(propertyName),
            distribution: shift[propertyName],
            shiftId: parseInt(shift.id) || 0,
          };

          if (allocation.distribution !== 0) {
            shiftAllocations.push(allocation);
          }

          delete shift[propertyName];
          delete shift["totalCost"];
          delete shift["totalFte"];
        });

        modifiedData.push({
          shift: shift as WorkShiftsPayload,
          allocations: shiftAllocations,
        });
      });
    }

    return modifiedData;
  };

  const createDynamicType = <T extends string[]>(
    propertyNames: T,
    shift: Shift,
    allocations: ShiftFormalCompetenceAllocation[]
  ): DynamicType<T> => {
    const dynamicObject = {} as DynamicType<T>;

    if (shift.id === "total_row") {
      dynamicObject["id"] = shift.id.toString();
      dynamicObject["fteWeekendShifts"] = shift.fteWeekendShifts;
      dynamicObject["totalCost"] = shift.totalCost;
      dynamicObject["totalFte"] = shift.totalFte;
      dynamicObject["unit"] = shift.unit;
      dynamicObject["weekendShifts"] = shift.weekendShifts;
      return dynamicObject;
    }

    dynamicObject["id"] = shift.id.toString();
    dynamicObject["careUnitId"] = shift.careUnitId.toString();
    dynamicObject["rowOrder"] = shift.rowOrder.toString();
    dynamicObject["unit"] = shift.unit;
    dynamicObject["shiftName"] = shift.shiftName;
    dynamicObject["shiftStart"] = shift.shiftStart;
    dynamicObject["shiftStop"] = shift.shiftStop;
    dynamicObject["shiftBreak"] = shift.shiftBreak;
    dynamicObject["activeWeekDayMon"] = shift.activeWeekDayMon || 0;
    dynamicObject["activeWeekDayTue"] = shift.activeWeekDayTue || 0;
    dynamicObject["activeWeekDayWed"] = shift.activeWeekDayWed || 0;
    dynamicObject["activeWeekDayThu"] = shift.activeWeekDayThu || 0;
    dynamicObject["activeWeekDayFri"] = shift.activeWeekDayFri || 0;
    dynamicObject["activeWeekDaySat"] = shift.activeWeekDaySat || 0;
    dynamicObject["activeWeekDaySun"] = shift.activeWeekDaySun || 0;
    dynamicObject["comment"] = shift.comment || "";
    dynamicObject["backfillPercentage"] = shift.backfillPercentage;
    dynamicObject["onCall"] = shift.onCall;
    dynamicObject["onCallUtilization"] = shift.onCallUtilization || 0;
    dynamicObject["onCallIdleComp"] = shift.onCallIdleComp || 0;
    dynamicObject["budget"] = shift.budget;
    dynamicObject["staff"] = shift.staff;
    dynamicObject["weekendShifts"] = shift.weekendShifts;
    dynamicObject["scenarioId"] = shift.scenarioId;

    const allocationsForShift = allocations.filter(
      (item) => item.shiftId === shift.id
    );

    propertyNames.forEach((propertyName) => {
      allocationsForShift.forEach((item) => {
        const workCompetenceAllocation =
          item.shiftsWorkCompetenceAllocations.find(
            (allocation) =>
              allocation.workCompetenceId === parseInt(propertyName)
          );

        if (workCompetenceAllocation) {
          dynamicObject[propertyName] = workCompetenceAllocation.distribution;
        }
      });

      if (!dynamicObject[propertyName]) {
        dynamicObject[propertyName] = 0;
      }
    });

    dynamicObject["competenceShareOl"] = shift.competenceShareOl;
    dynamicObject["competenceShareBol"] = shift.competenceShareBol;
    dynamicObject["competenceShareSpec"] = shift.competenceShareSpec;
    dynamicObject["competenceShareSeniorSt"] = shift.competenceShareSeniorSt;
    dynamicObject["competenceShareJuniorSt"] = shift.competenceShareJuniorSt;
    dynamicObject["competenceShareRand"] = shift.competenceShareRand;
    dynamicObject["competenceShareLeg"] = shift.competenceShareLeg;
    dynamicObject["competenceShareAt"] = shift.competenceShareAt;
    dynamicObject["competenceShareUl"] = shift.competenceShareUl;
    dynamicObject["competenceShareRnSpecialist"] =
      shift.competenceShareRnSpecialist;
    dynamicObject["competenceShareRnSenior"] = shift.competenceShareRnSenior;
    dynamicObject["competenceShareRnJunior"] = shift.competenceShareRnJunior;
    dynamicObject["competenceShareRnNight"] = shift.competenceShareRnNight;
    dynamicObject["competenceShareAnSenior"] = shift.competenceShareAnSenior;
    dynamicObject["competenceShareAnJunior"] = shift.competenceShareAnJunior;
    dynamicObject["competenceShareAnNight"] = shift.competenceShareAnNight;
    dynamicObject["competenceShareOther"] = shift.competenceShareOther;

    dynamicObject["productionShareEdVisit"] = shift.productionShareEdVisit;
    dynamicObject["productionShareOutpatientVisit"] =
      shift.productionShareOutpatientVisit;
    dynamicObject["productionShareWard"] = shift.productionShareWard;
    dynamicObject["productionShareSurgery"] = shift.productionShareSurgery;
    dynamicObject["productionShareConsultant"] =
      shift.productionShareConsultant;
    dynamicObject["productionShareStExternalTraining"] =
      shift.productionShareStExternalTraining;
    dynamicObject["productionShareManagement"] =
      shift.productionShareManagement;
    dynamicObject["productionShareNonClinicalTime"] =
      shift.productionShareNonClinicalTime;

    dynamicObject["activeWeek1"] = shift.activeWeek1;
    dynamicObject["activeWeek2"] = shift.activeWeek2;
    dynamicObject["activeWeek3"] = shift.activeWeek3;
    dynamicObject["activeWeek4"] = shift.activeWeek4;
    dynamicObject["activeWeek5"] = shift.activeWeek5;
    dynamicObject["activeWeek6"] = shift.activeWeek6;
    dynamicObject["activeWeek7"] = shift.activeWeek7;
    dynamicObject["activeWeek8"] = shift.activeWeek8;
    dynamicObject["activeWeek9"] = shift.activeWeek9;
    dynamicObject["activeWeek10"] = shift.activeWeek10;
    dynamicObject["activeWeek11"] = shift.activeWeek11;
    dynamicObject["activeWeek12"] = shift.activeWeek12;
    dynamicObject["activeWeek13"] = shift.activeWeek13;
    dynamicObject["activeWeek14"] = shift.activeWeek14;
    dynamicObject["activeWeek15"] = shift.activeWeek15;
    dynamicObject["activeWeek16"] = shift.activeWeek16;
    dynamicObject["activeWeek17"] = shift.activeWeek17;
    dynamicObject["activeWeek18"] = shift.activeWeek18;
    dynamicObject["activeWeek19"] = shift.activeWeek19;
    dynamicObject["activeWeek20"] = shift.activeWeek20;
    dynamicObject["activeWeek21"] = shift.activeWeek21;
    dynamicObject["activeWeek22"] = shift.activeWeek22;
    dynamicObject["activeWeek23"] = shift.activeWeek23;
    dynamicObject["activeWeek24"] = shift.activeWeek24;
    dynamicObject["activeWeek25"] = shift.activeWeek25;
    dynamicObject["activeWeek26"] = shift.activeWeek26;
    dynamicObject["activeWeek27"] = shift.activeWeek27;
    dynamicObject["activeWeek28"] = shift.activeWeek28;
    dynamicObject["activeWeek29"] = shift.activeWeek29;
    dynamicObject["activeWeek30"] = shift.activeWeek30;
    dynamicObject["activeWeek31"] = shift.activeWeek31;
    dynamicObject["activeWeek32"] = shift.activeWeek32;
    dynamicObject["activeWeek33"] = shift.activeWeek33;
    dynamicObject["activeWeek34"] = shift.activeWeek34;
    dynamicObject["activeWeek35"] = shift.activeWeek35;
    dynamicObject["activeWeek36"] = shift.activeWeek36;
    dynamicObject["activeWeek37"] = shift.activeWeek37;
    dynamicObject["activeWeek38"] = shift.activeWeek38;
    dynamicObject["activeWeek39"] = shift.activeWeek39;
    dynamicObject["activeWeek40"] = shift.activeWeek40;
    dynamicObject["activeWeek41"] = shift.activeWeek41;
    dynamicObject["activeWeek42"] = shift.activeWeek42;
    dynamicObject["activeWeek43"] = shift.activeWeek43;
    dynamicObject["activeWeek44"] = shift.activeWeek44;
    dynamicObject["activeWeek45"] = shift.activeWeek45;
    dynamicObject["activeWeek46"] = shift.activeWeek46;
    dynamicObject["activeWeek47"] = shift.activeWeek47;
    dynamicObject["activeWeek48"] = shift.activeWeek48;
    dynamicObject["activeWeek49"] = shift.activeWeek49;
    dynamicObject["activeWeek50"] = shift.activeWeek50;
    dynamicObject["activeWeek51"] = shift.activeWeek51;
    dynamicObject["activeWeek52"] = shift.activeWeek52;
    dynamicObject["totalCost"] = shift.totalCost;
    dynamicObject["totalFte"] = shift.totalFte;

    dynamicObject["fteWeekendShifts"] = shift.fteWeekendShifts;

    return dynamicObject;
  };

  return {
    createDynamicType,
    transformCompetenceAllocations,
  };
};

export default useWorkShiftsUtilities;
