import React from "react";
import "./hide-toggle.css";

interface ToggleProps {
  isVisible: boolean;
  onToggle: () => void;
  isFlipType?: boolean;
  className?: string;
  disabled?: boolean;
}

const HideToggle = ({
  isVisible,
  onToggle,
  isFlipType,
  className,
  disabled,
}: ToggleProps) => {
  return (
    <label
      className={`relative inline-flex items-center ${
        disabled ? "cursor-default opacity-20" : "cursor-pointer"
      } ${className}`}
    >
      <input
        type="checkbox"
        value=""
        className="peer sr-only"
        checked={isVisible}
        onChange={onToggle}
        disabled={disabled}
      ></input>
      <div
        className={`toogle-div  ${
          isFlipType ? "toggle--flip" : "toggle--switch"
        } `}
      ></div>
    </label>
  );
};

export default HideToggle;
