import React, { useState } from "react";
import { AccordianProps } from "../../interfaces/props";

const ChartInfo = ({ children, title }: AccordianProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`chart-info__container ${
        !open && "chart-info__container--collapsed"
      }`}
    >
      <div onClick={() => setOpen(!open)} className="chart-info">
        <div className="accordian__icon">
          <svg
            className="chart-info__svg-flex"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#052D5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 16V12"
              stroke="#052D5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 8H12.01"
              stroke="#052D5A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div>
            <h4 className="chart-info__title p-1-v-2">{title}</h4>
          </div>
        </div>

        <div className="accordian__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`select__icon__svg accordian__icon ${
              open && "select__icon__svg--expanded"
            }`}
            viewBox="0 0 17.274 10.667"
          >
            <path
              id="Dropdown_Arrow"
              data-name="Dropdown Arrow"
              d="M11.03,11.76l6.607,6.593,6.607-6.593,2.03,2.03-8.637,8.637L9,13.79Z"
              transform="translate(-9 -11.76)"
            />
          </svg>
        </div>
      </div>

      <div
        className={`chart-info__content p-2-v-1 ${
          open
            ? "chart-info__content--expanded"
            : "chart-info__content--collapsed"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ChartInfo;
