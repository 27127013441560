import React from "react";

import { GridLayout } from "../../../layouts";
import { AuthFormProps } from "../../authentication/interfaces/Props";
import { PasswordOperation } from "../utils/change-password";

const ChangePasswordForm = ({
  children,
  title,
  alignment,
  operationType,
}: AuthFormProps) => {
  return (
    <GridLayout additionalStyles="authform">
      <div className="authform__container">
        <article className="authform__layout">
          <div className={`${alignment === "center" && "authform__center"}`}>
            {operationType === PasswordOperation.set ? (
              <div>
                {/* Width: 190px */}
                <img
                  src="/images/logo/Evidence_Logo.svg"
                  alt="Careopti Logo"
                  className={`authform__logo ${
                    alignment === "center" && "authform__center"
                  }`}
                />
              </div>
            ) : null}

            <h1>{title}</h1>
            {children}
          </div>
        </article>
      </div>
    </GridLayout>
  );
};

export default ChangePasswordForm;
