import React, { useEffect, useState } from "react";

import { ISelectOption } from "../../../../../interfaces";
import { Input, Select } from "../../../../../components";
import TextArea from "../../../../../components/textArea/textArea";

interface KanbanDialogFormTemplateProps {
  id: number;
  title: string;
  statusId: number;
  description: string;
  assigneeId: string;
}

const KanbanDialogTemplate: React.FC<KanbanDialogFormTemplateProps> = ({
  id,
  statusId,
  title,
  description,
  assigneeId,
}) => {
  const [state, setState] = useState<KanbanDialogFormTemplateProps>({
    id: 0,
    statusId: 0,
    title: "",
    description: "",
    assigneeId: "",
  });

  useEffect(() => {
    setState({
      id,
      statusId,
      title,
      description,
      assigneeId,
    });
  }, [id, statusId, title, description, assigneeId]);

  const typesOfPlans: ISelectOption[] = [
    {
      label: "New",
      value: "1",
    },
    {
      label: "Open",
      value: "2",
    },
    {
      label: "InProgress",
      value: "3",
    },
    {
      label: "Testing",
      value: "4",
    },
    {
      label: "Close",
      value: "5",
    },
  ];

  const [selectedTypeOfPlan, setSelectedTypeOfPlan] = useState<ISelectOption | null>(
    typesOfPlans.find((x) => Number(x.value) === statusId) || null
  );

  const onChange = (args: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = args.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTypeOfPlanChange = (option: ISelectOption) => {
    setSelectedTypeOfPlan(option);

    setState((prevState) => {
      return {
        ...prevState,
        statusId: Number(option.value),
      };
    });
  };

  return (
    <div className="flex flex-col">
      {/* <div>
        <Input
          // name="id"
          value={String(state.id)}
          className="e-field"
          placeholder={"Id"}
          handleInputChange={() => {
            console.log("handleInputChange");
          }}
          error=""
          success=""
          type="number"
          disabled={true}
        />
      </div> */}

      <div>
        <Input
          id="title"
          className="e-field"
          name="title"
          value={state.title}
          placeholder="Title"
          type="text"
          handleInputChange={(e) => {
            setState((prevState) => {
              return {
                ...prevState,
                title: e.target.value,
              };
            });
          }}
        />
      </div>

      <div>
        {/* <Input
          id="description"
          className="e-field"
          name="description"
          value={state.description}
          placeholder={"Description"}
          handleInputChange={(e) => {
            setState((prevState) => {
              return {
                ...prevState,
                description: e.target.value,
              };
            });
          }}
          error=""
          success=""
          type="text"
        /> */}

        <TextArea
          id="description"
          name="description"
          className="e-field"
          value={state.description}
          defaultRows={6}
          placeholder="Description"
          onChange={(e) => {
            setState((prevState) => {
              return {
                ...prevState,
                description: e.target.value,
              };
            });
          }}
        />
      </div>

      <div>
        <Input
          id="assigneeId"
          className="e-field"
          name="assigneeId"
          value={"dfbebc9b-38fd-4a39-984b-c1b69cac0f49"}
          placeholder="Assignee"
          type="text"
          handleInputChange={(e) => {
            setState((prevState) => {
              return {
                ...prevState,
                title: e.target.value,
              };
            });
          }}
        />
      </div>

      {/* <div className="my-[2rem] flex">
        <div className="w-full">
          <select
            className="e-field"
            id="statusId"
            name="statusId"
            value={Number(selectedTypeOfPlan?.value)}
            onChange={(e) => {
              const selectedPLan = typesOfPlans.find((p) => p.value === e.target.value);
              setSelectedTypeOfPlan(selectedPLan as any);
            }}
          >
            {typesOfPlans.map((plan) => (
              <option key={plan.value} value={plan.value}>
                {plan.label}
              </option>
            ))}
          </select>
        </div>
      </div> */}

      {/* <DropDownListComponent
        className="e-field"
        id="statusId"
        name="statusId"
        dataSource={statusData}
        placeholder="Status"
        value={statusData[statusId]}
      ></DropDownListComponent>

      <div className="flex">
        <div className="w-full">
          <Select
            placeholder={"Assignee"}
            options={typesOfPlans}
            onSelectOption={handleTypeOfPlanChange}
            selectedOption={selectedTypeOfPlan}
          />
        </div>
      </div> */}
    </div>
  );
};

export default KanbanDialogTemplate;
