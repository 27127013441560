import axios from "axios";
import store, { logout } from "../redux/store";

const getConnfig = (type?: string) => {
  const token = JSON.parse(localStorage.getItem("user") || "{}").token;

  if (type === "file") {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  } else
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
};

const { REACT_APP_BACKEND_URL } = process.env;
const HttpCommonService = {
  getAsync: async (path: string) => {
    return await axios
      .get(`${REACT_APP_BACKEND_URL}/${path}`, getConnfig())
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      });
  },

  postAsync: async (path: string, payload: any, type?: string) => {
    return await axios
      .post(`${REACT_APP_BACKEND_URL}/${path}`, payload, getConnfig(type))
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      });
  },

  putAsync: async (path: string, payload: any) => {
    return await axios
      .put(`${REACT_APP_BACKEND_URL}/${path}`, payload, getConnfig())
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      });
  },

  deletAsync: async (path: string) => {
    return await axios
      .delete(`${REACT_APP_BACKEND_URL}/${path}`, getConnfig())
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      });
  },

  getParallelAsync: async (paths: string[]) => {
    const requests = paths.map((path) =>
      axios.get(`${REACT_APP_BACKEND_URL}/${path}`, getConnfig())
    );

    return await axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          return responses;
        })
      )
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      });
  },

  postParallelAsync: async <T>(paths: string[], payloads: T[]) => {
    const requests = paths.map((path, i) =>
      axios.post(`${REACT_APP_BACKEND_URL}/${path}`, payloads[i], getConnfig())
    );

    return await axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          return responses;
        })
      )
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch(logout());
        }
        throw error;
      });
  },
};

export default HttpCommonService;
