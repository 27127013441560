import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import HotTable from "@handsontable/react";
import { useTranslation } from "react-i18next";

import ExpandableButton from "../../../../components/expandable-button";
import { Button } from "../../../../components";
import ControlIcon from "../../../../components/control-icon/control-icon";
import { ControlIconTypes } from "../../../../components/control-icon/icon-types";
import { ActivityAnalysisProductionProps } from "../../../../interfaces/props/activity-analysis-production";
import { ActivityAnalysisSubmitEventRef } from "../../../../interfaces/analytics/activity-analysis-submit-event-ref";
import ActivityAnalysisService from "../../../../services/activityAnalysisService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PenIcon from "../../../../components/toast/icons/pen-icon";
import useGetWorkCompetencesForCareUnit from "../hooks/useGetWorkCompetencesForCareUnit";
import UseGetFilterGroups from "../FilterGroup/hooks/useGetFilterGroups";
import useGetActivityProductionData from "./hooks/useGetActivityProductionData";
import useActivityAnalysisProductionConfigs from "./hooks/useActivityAnalysisProductionConfigs";
import UseActivityAnalysisModifiedProductionTable from "./hooks/useActivityAnalysisModifiedProductionTable";

const ActivityAnalysisProduction = forwardRef<ActivityAnalysisSubmitEventRef, ActivityAnalysisProductionProps>(
  (
    {
      careUnitId,
      year,
      scenarioId,
      setIsSubmitting,
      setSubmitStatus,
      onNextButtonClick,
      isEditedRef,
    }: ActivityAnalysisProductionProps,
    ref
  ) => {
    const [isContainsModifieddata, setIsContainsModifieddata] = useState<boolean>(false);

    const [careUnitIds, setCareUnitIds] = useState<number[]>([]);

    const filterGroups = UseGetFilterGroups(careUnitIds);

    const hook = useGetActivityProductionData(careUnitId, scenarioId, year, isEditedRef);

    const workCompetences = useGetWorkCompetencesForCareUnit(careUnitId);

    const configs = useActivityAnalysisProductionConfigs(
      hook.setData,
      hook.data,
      filterGroups.data,
      careUnitId,
      scenarioId,
      year,
      isEditedRef,
      workCompetences
    );

    useEffect(() => {
      setCareUnitIds([careUnitId]);
    }, [careUnitId]);

    useEffect(() => {
      if (hook.data.length > 0) {
        const dataWithoutTotalRow = hook.data.filter((x) => x?.id !== "total_row");

        if (dataWithoutTotalRow !== null && dataWithoutTotalRow.length > 0) {
          const hasFalseProductionPlanReloaded: boolean = dataWithoutTotalRow.some(
            (item) => item.isProductionPlanReloaded === true
          );

          if (hasFalseProductionPlanReloaded) {
            setIsContainsModifieddata(true);

            setTimeout(() => {
              setIsContainsModifieddata(false);
            }, 5000);
          }
        }
      }
    }, [hook.data]);

    useEffect(() => {
      if (isContainsModifieddata) {
        notify();
      }
    }, [isContainsModifieddata]);

    const saveProductionData = async () => {
      setIsSubmitting(true);

      const sumRowRemoved = hook.data.filter((x) => x?.id !== "total_row");

      const modifiedDataWithFilterGroups = UseActivityAnalysisModifiedProductionTable(sumRowRemoved, filterGroups.data);

      const modifiedData = modifiedDataWithFilterGroups?.map((x) => {
        const { timeSpent, fteProductionPlan, ...rest } = x;
        return rest;
      });

      await ActivityAnalysisService.postActivityAnalysisProduction(careUnitId as number, scenarioId, year, modifiedData)
        .then(() => {
          setIsSubmitting(false);
          setSubmitStatus(1);
          hook.fetch();
        })
        .catch(() => {
          setIsSubmitting(false);
          setSubmitStatus(2);
        });
    };

    useImperativeHandle(ref, () => ({ ...ref, saveProductionData }));

    const handleAddNewRowClick = () => {
      configs.addNewRow();
    };

    const { t: translate } = useTranslation();

    const handleNextButtonClick = () => {
      onNextButtonClick(0);
    };

    const notify = () => {
      toast(
        <div className="flex justify-center">
          <div className="mt-4">
            <PenIcon strokeWidth={3} />
          </div>
          <span>{translate("latest_prod_plan_loaded")}</span>
        </div>
      );
    };

    return (
      <>
        <ToastContainer position="top-left" hideProgressBar={true} closeButton={false} />
        <div className="col-start-1 col-end-13">
          <div className="rounded px-2">
            <div className="h-[79vh]">
              <div className="relative h-[65vh] overflow-y-scroll">
                <HotTable data={hook.data} {...configs.props}></HotTable>
              </div>
              <div className="mt-4 flex w-full items-center justify-between gap-4 pr-4">
                <div className="z-[1000] mt-2">
                  <ExpandableButton text={translate("add_new_row")} onClick={handleAddNewRowClick} />
                </div>

                <div className="flex items-center gap-4">
                  <Button
                    onClick={handleNextButtonClick}
                    text={translate("next")}
                    variant={"button--secondary-optional-with-border"}
                    additionalStyles={"xl:w-[10.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[8.313rem] float-right"}
                    icon={<ControlIcon type={ControlIconTypes.next} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

ActivityAnalysisProduction.displayName = "ActivityAnalysisProduction";

export default ActivityAnalysisProduction;
