import React from "react";

type OrgStructureTooltipProps = {
  handleOrganizationStructureItemDeleteClick: () => void;
  handleOrganizationStructureItemEditClick: () => void;
};

const OrgStructureTooltip = ({
  handleOrganizationStructureItemDeleteClick,
  handleOrganizationStructureItemEditClick,
}: OrgStructureTooltipProps) => {
  return (
    <div
      className={`org-structure-tooltip absolute left-2 right-2 z-10 lg:top-16 xl:top-17 `}
    >
      <div
        className={`child`}
        onClick={handleOrganizationStructureItemEditClick}
      >
        Edit
      </div>
      <div
        className={`child danger`}
        onClick={handleOrganizationStructureItemDeleteClick}
      >
        Delete
      </div>
    </div>
  );
};

export default OrgStructureTooltip;
