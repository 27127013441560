import React from "react";

import ICDAnalysisChart from "./ICDAnalysisChart/ICDAnalysisChart";
import ICDAnalysisSidePanel from "./ICDAnalysisSidePanel/ICDAnalysisSidePanel";
import useFetchDropdownOptionsData from "./hooks/useFetchDropdownOptionsData";
import { ICDAnalysisRequest } from "../../../interfaces/analytics/icdAnalysis/icdAnalysisRequest";
import useChartData from "./hooks/useChartData";
import ICDAnalysisChartLayout from "./ICDAnalysisChartLayout/ICDAnalysisChartLayout";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "../../../hooks/use-number-format";

const ICDAnalysisHome = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const formatter = useNumberFormat();

  const { ages, chapter, genders, isLoading, regions, years } = useFetchDropdownOptionsData();

  const [icdAnalysisRequest, setIcdAnalysisRequest] = React.useState<ICDAnalysisRequest | null>(null);

  const { data: chartData } = useChartData(icdAnalysisRequest);

  const avgCareTimeVsInpatientCareEpisodes = chartData.map((item) => ({
    x: item.prevalence,
    y: item.avgCareTime,
    z: formatter.round(item.carebedDemand, 1),

    name: item.diagnosis,
    diagId: item.diagnosisId,
  }));

  const portentialChangeBasedOnCareTime = chartData.map((item) => ({
    x: item.savingBasedOnPrevalence,
    y: item.savingBasedOnCareTime,
    z: formatter.round(item.carebedDemand, 1),
    potential: formatter.round(item.savingBasedOnPrevalence + item.savingBasedOnCareTime, 1),
    name: item.diagnosis,
    diagId: item.diagnosisId,
  }));

  return (
    <div className="my-4 flex h-full w-full">
      <div className="w-5/6">
        <ICDAnalysisChartLayout title={translate("average_care_time_vs_inpatient_care_episodes")}>
          <ICDAnalysisChart
            data={avgCareTimeVsInpatientCareEpisodes}
            xAxisName={translate("inpatient_care_episodes_per_100k_inhabitants")}
            yAxisName={translate("average_care_time")}
            isAvgCareTimeVsInpatientCareEpisodes={true}
          />
        </ICDAnalysisChartLayout>

        <ICDAnalysisChartLayout title={translate("potential_change_in_care_beds")}>
          <ICDAnalysisChart
            data={portentialChangeBasedOnCareTime}
            xAxisName={translate("potential_change_based_on_inpatient_care_episodes")}
            yAxisName={translate("potential_change_based_on_care_time")}
            isZeroAxisHighlighted={true}
            isPotentialChangeBasedOnCareTime={true}
          />
        </ICDAnalysisChartLayout>
      </div>
      <div className="flex w-1/6 flex-col  justify-between px-4">
        <div className="w-full">
          <ICDAnalysisSidePanel
            ages={ages}
            chapter={chapter}
            genders={genders}
            isLoading={isLoading}
            regions={regions}
            years={years}
            setIcdAnalysisRequest={setIcdAnalysisRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default ICDAnalysisHome;
