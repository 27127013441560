import Highcharts from "highcharts";
import React from "react";
import { useTranslation } from "react-i18next";

const useStaffComparisonChartConfigurator = (): {
  getOptions: (
    workCompetences: string[],
    available: number[],
    target: number[]
  ) => any;
} => {
  const { t: translate, i18n } = useTranslation();

  const getOptions = (
    workCompetences: string[],
    available: number[],
    target: number[]
  ) => {
    const options = {
      chart: {
        backgroundColor: "transparent",
        style: {
          fontFamily: "Open Sans",
        },
      },
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: translate("Competence"),
        },
        categories: workCompetences,
        labels: {
          style: {
            fontSize: 14,
          },
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },

        labels: {
          style: {
            fontSize: 14,
          },
        },
      },
      legend: {
        verticalAlign: "top",
        itemStyle: {
          fontSize: 14,
        },
        style: {
          fontSize: 14,
        },
      },
      series: [
        {
          type: "column",
          name: translate("competence_available"),
          data: available,
          color: "#44546a",
        },
        {
          type: "column",
          name: translate("competence_need"),
          data: target,
          color: "#BFBFBF",
        },
      ],
    };

    return options;
  };

  return { getOptions };
};

export default useStaffComparisonChartConfigurator;
