import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const AppInsightService = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
  },
});

AppInsightService.loadAppInsights();

export { AppInsightService };
