import {
  ModifyOutpatientPhasingRequest,
  RetrieveOutpatientPhasingRequest,
} from "../interfaces/production-plan/outpatient/Phasing/outpatientPhasing";
import { OutPatientMultiSelectWithTogglesRequest } from "../interfaces/production-plan/outpatient/outpatientMultiselectFilterWithToggles";
import HttpCommonService from "./httpCommonService";

const PhasingService = {
  getWeeklyPhasing: async (careUnitId: number, year: number, scenario: number): Promise<number[]> => {
    return await HttpCommonService.getAsync(`api/weeklyphasing/${careUnitId}/${year}/${scenario}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getHistoricalWeeklyPhasing: async (careUnitId: number) => {
    return await HttpCommonService.getAsync(`api/outpatientproduction/historicalweeklyphasing/${careUnitId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getWeekdayPhasing: async (careUnitId: number, year: number, scenario: number): Promise<number[]> => {
    return await HttpCommonService.getAsync(`api/weekdayphasing/${careUnitId}/${year}/${scenario}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getPhasingTemplates: async () => {
    return await HttpCommonService.getAsync(`api/phasing/phasing-templates`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getWeeklyPhasingWithTemplates: async (templateId: number) => {
    return await HttpCommonService.getAsync(`api/phasing/weekly-phasing-templates/${templateId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getWeekdayPhasingWithTemplates: async (templateId: number) => {
    return await HttpCommonService.getAsync(`api/phasing/weekday-phasing-templates/${templateId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  updateWeeklyPhasing: async (
    careUnitId: number,
    year: number,
    weeklyPhasing: number[],
    weekdayPhasing: number[],
    scenario: number
  ) => {
    const payload = {
      careUnitId: careUnitId,
      year: year,
      weeklyPhasingList: weeklyPhasing,
      weekdayPhasingList: weekdayPhasing,
      scenarioId: scenario,
    };

    return await HttpCommonService.putAsync(`api/weeklyphasing`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  updateWeekDayPhasing: async (careUnitId: number, year: number, weekdayPhasing: number[], scenario: number) => {
    const payload = {
      careUnitId: careUnitId,
      year: year,
      updateWeekDayPhasingsList: weekdayPhasing,
      scenarioId: scenario,
    };

    return await HttpCommonService.putAsync(`api/weekdayphasing`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getOutpatientPhasing: async (
    careUnitId: number,
    year: number,
    scenario: number,
    request: OutPatientMultiSelectWithTogglesRequest
  ) => {
    const payload: RetrieveOutpatientPhasingRequest = {
      careUnitId: careUnitId,
      year: year,
      scenarioId: scenario,
      professionIds: request.professionIds,
      professionEnabled: request.professionEnabled,
      contactTypeIds: request.contactTypeIds,
      contactTypeEnabled: request.contactTypeEnabled,
      typesOfVisit: request.typesOfVisit,
      newVisitEnabled: request.newVisitEnabled,
      contactReasonGroupingIds: request.contactReasonGroupingIds,
      contactReasonGroupingEnabled: request.contactReasonGroupingEnabled,
      careTypeIds: request.careTypeIds,
      careTypeEnabled: request.careTypeEnabled,
      typesOfEmergency: request.typesOfEmergency,
      typeOfEmergencyEnabled: request.typeOfEmergencyEnabled,
      medicalSpecialtyIds: request.medicalSpecialtyIds,
      medicalSpecialtyEnabled: request.medicalSpecialtyEnabled,
    };

    return await HttpCommonService.postAsync(`api/outpatient/phasing`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  modifyOutpatientPhasing: async (request: ModifyOutpatientPhasingRequest) => {
    const payload: ModifyOutpatientPhasingRequest = request;

    return await HttpCommonService.putAsync(`api/outpatient/phasing`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default PhasingService;
