import React from "react";
import { useTranslation } from "react-i18next";
import { HotTableClass as HotTable } from "@handsontable/react";

import ExpandableButton from "../expandable-button";

const DataTableAddButton = ({
  dataTable,
}: {
  dataTable: React.MutableRefObject<HotTable | null>;
}) => {
  const { t: translate } = useTranslation();

  const handleButtonClick = () => {
    const data = dataTable.current?.hotInstance?.getSourceData();

    let index;
    let amount;

    if (data) {
      if (data.length === 0) {
        index = data.length;
        amount = 1;
      } else {
        index = data.length - 1;
        amount = 1;
      }
    }

    dataTable.current?.hotInstance?.alter(
      "insert_row_below",
      index,
      amount,
      "ContextMenu.rowBelow"
    );
  };

  return (
    <div className="absolute bottom-8 left-4 z-[1000]">
      <ExpandableButton
        text={translate("add_new_row")}
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default DataTableAddButton;
