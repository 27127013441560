const useTimeFormats = () => {
  const toISOIgnoreTimezone = (inputDate: Date) => {
    return (
      inputDate.getFullYear() +
      "-" +
      ("0" + (inputDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + inputDate.getDate()).slice(-2) +
      "T00:00:00.000Z"
    );
  };

  return { toISOIgnoreTimezone };
};

export default useTimeFormats;
