import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, DownloadButton, UploadButton } from "../../../components";
import { Competence } from "../../../interfaces/parameters/Competence";
import { UploadParamterProps } from "../../../interfaces/parameters/ParameterProps";
import { GridLayout } from "../../../layouts";
import ParameterService from "../../../services/parameterService";
import { COMPETENCE_ENDPOINT } from "../../../utils/apiendpoints";
import ExportExcel from "../../../utils/ExportExcel";

const CompetenceHome = ({
  setFileAddSuccessNotification,
  setFileAddFailNotification,
  setLoading,
  loadingStat,
  readOnly,
}: UploadParamterProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<Competence[]>([]);
  const { t: translate, i18n } = useTranslation();

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      await ParameterService.uploadExcelAsync(file[0], COMPETENCE_ENDPOINT)
        .then(() => {
          if (setFileAddSuccessNotification) setFileAddSuccessNotification("File Saved Successfully");
        })
        .catch((err) => {
          if (setFileAddFailNotification && err.response.data)
            setFileAddFailNotification(err.response.data.ErrorMessage);
        });
      e.target.value = "";
    } else setFileAddFailNotification && setFileAddFailNotification("file is not selected");
  };

  const uploadCompetenceExcel = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    const getParameterData = async () => {
      if (setLoading) {
        setLoading("Loading Parameter Data");
        await ParameterService.getParametersAsync(COMPETENCE_ENDPOINT)
          .then((res) => {
            if (res.length > 0) setData(res);
            else {
              const Headings = {
                id: null,
                competenceName: "",
                competenceGroupLvl1: "",
                competenceGroupLvl2: "",
                profession: "",
                competenceVariableName: "",
              };
              setData((prevArray) => [...prevArray, Headings]);
            }
            setLoading("");
          })
          .catch((err) => {
            setLoading("");
            console.log(err);
          });
      }
    };

    getParameterData();
  }, []);

  return (
    <GridLayout additionalStyles="mt-0 border-2 h-[86vh] rounded-r rounded-b px-2 bg-[#F8F8F8]">
      <div className="col-start-1 col-end-13">
        <div className="mt-2 flex items-center justify-between">
          <div className="col-start-1 col-end-7 items-center justify-between gap-3"></div>
          <div className="col-start-7 col-end-13 flex">
            <div className="items-center justify-between gap-3">
              <ExportExcel apiData={data} fileName={"Competence"} disabled={loadingStat !== ""} />
            </div>
            {!readOnly && (
              <>
                <div className="mx-4 items-center justify-between gap-3">
                  <UploadButton text={translate("UploadData")} onClick={uploadCompetenceExcel} />
                  <input hidden ref={inputRef} type="file" onInput={onFileInputChange} />
                </div>
                <div className="items-center justify-between gap-3">
                  <Button
                    text={"Save Changes"}
                    variant={"primary"}
                    additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                    disabled={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </GridLayout>
  );
};

export default CompetenceHome;
