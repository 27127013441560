import { DelayedVisitsRequest, QueueDetailRequest } from "../interfaces/production-plan/outpatient/queue/queueDetail";
import HttpCommonService from "./httpCommonService";

const HistoricQueueDetailService = {
  getQueueDetails: async (queueDetailRequest: QueueDetailRequest) => {
    const payload = queueDetailRequest;

    return await HttpCommonService.postAsync(`api/historic/queue/details`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getHistoricQueueCuttingPlan: async (delayedVisitsRequest: DelayedVisitsRequest) => {
    const payload = delayedVisitsRequest;

    return await HttpCommonService.postAsync(`api/historic/queue/details/delayedvisits`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default HistoricQueueDetailService;
