import React from "react";
import { LoadingTypeProps } from "../../utils/types/loading-types";
import "./loading-styles.css";

const LoadingWithColor = ({
  sizeInPixel,
  additionalStyles,
}: LoadingTypeProps) => {
  return (
    <div className={`loader-mid ${additionalStyles}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="equilizer"
        viewBox="0 0 128 128"
        style={{ height: sizeInPixel, width: sizeInPixel }}
      >
        <g>
          <rect
            className="bar bar-1"
            transform="translate(0,0)"
            rx="10"
            fill="#416031"
          ></rect>
          <rect
            className="bar bar-2"
            transform="translate(25,0)"
            rx="10"
            fill="#416031"
          ></rect>
          <rect
            className="bar bar-3"
            transform="translate(50,0)"
            rx="10"
            fill="#e5a32b"
          ></rect>
          <rect
            className="bar bar-4"
            transform="translate(75,0)"
            rx="10"
            fill="#ad1e23"
          ></rect>
        </g>
      </svg>
    </div>
  );
};

export default LoadingWithColor;
