import { HotTableClass as HotTable } from "@handsontable/react";
import { useTranslation } from "react-i18next";
import { HyperFormula } from "hyperformula";
import _ from "lodash";
import { useRef } from "react";
import { RowObject } from "handsontable/common";

import { useGetActivityShiftTableColumns } from "./useGetActivityShiftTableColumns";
import { ActivityAnalysisShiftSchema } from "../../../../../interfaces/analytics/activity-analysis-shift-schema";
import useAdjustColumnHeader from "../../hooks/useAdjustColumnHeader";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import { ActivityAnalysisOutSideShiftsConfigReturntype } from "../../../../../interfaces/analytics/activity-analysis-outside-shifts-returntype";
import useBeforeCreateRow from "../../hooks/useBeforeCreateRow";
import useAfterChange from "../../hooks/useAfterChange";
import useAfterCreateRow from "../../hooks/useAfterCreateRow";
import useBeforePaste from "../../hooks/useBeforePaste";
import useBeforeValidate from "../../hooks/useBeforeValidate";
import useCells from "../../hooks/useCells";
import useAfterRemoveRow from "../../hooks/useAfterRemoveRow";
import useContextMenu from "../../hooks/useContextMenu";
import useModifyColumnWidth from "../../hooks/useAfterModifyColumnWidth";
import useAddNewRow from "../../hooks/useAddNewRow";
import { ActivityAnalysisIsEditedRefType } from "../../../../../interfaces/analytics/activity-analysis-is-edited-ref-type";
import WorkCompetence from "../../../../../interfaces/competence/WorkCompetence";
import useRenderers from "../../../../../common/hooks/handsontable/useRenderers";

const useGetActivityShiftTableConfigs = (
  data: ActivityAnalysisShiftSchema[],
  setData: React.Dispatch<React.SetStateAction<ActivityAnalysisShiftSchema[]>>,
  isWithinShifts: boolean,
  isEditedRef: React.MutableRefObject<ActivityAnalysisIsEditedRefType>,
  workCompetences: WorkCompetence[]
): ActivityAnalysisOutSideShiftsConfigReturntype => {
  let culture = localStorage.getItem("i18nextLng");

  if (culture === "en") {
    culture = "en-US";
  } else if (culture === "en-US") {
    culture = "en-US";
  } else {
    culture = "sv-SE";
  }

  const cols = useGetActivityShiftTableColumns(culture, workCompetences);

  const { t: translate } = useTranslation();

  const adjustShiftsColumnHeaderColours = useAdjustColumnHeader();

  const defaultData: RowObject = {
    activity: "",
    competenceGroupLvl2: "",
    timePerActivity: 0,
    activitiesPerWeek: 0,
    numberPresence: 0,
    weeksPerYear: 0,
    timeSpent: 0,
    weeklyWorkingHours: "",
    fte: "",
  };

  const dataTable = useRef<HotTable>(null);

  const ACTIVITY_OUTISDE_SHIFTS_COLUMN_WIDTHS =
    "activity_analysis_outsideshifts_column_widths";

  const { afterColumnResize, modifyColWidth } = useModifyColumnWidth(
    ACTIVITY_OUTISDE_SHIFTS_COLUMN_WIDTHS
  );

  const { beforeCreateRow } = useBeforeCreateRow(dataTable);
  const { afterChange } = useAfterChange(
    data,
    dataTable,
    setData,
    isWithinShifts,
    isEditedRef
  );
  const { afterCreateRow } = useAfterCreateRow(data, dataTable, setData);
  const { beforePaste } = useBeforePaste(dataTable);
  const { beforeValidate } = useBeforeValidate(dataTable);
  const { cells } = useCells(dataTable);
  const { afterRemoveRow } = useAfterRemoveRow(dataTable);
  const { disableRemoveRow, disableRowBelow } = useContextMenu();
  const { addNewRow } = useAddNewRow(dataTable);
  const { afterRenderer } = useRenderers();

  const props: DataTableProps = {
    afterChange: afterChange,
    afterColumnResize: afterColumnResize,
    afterCreateRow: afterCreateRow,
    afterGetColHeader: adjustShiftsColumnHeaderColours,
    afterRemoveRow: afterRemoveRow,
    afterRenderer: (TD, row, col) =>
      afterRenderer(TD, row, col, dataTable, translate("total")),
    beforeCreateRow: beforeCreateRow,
    beforePaste: beforePaste,
    beforeValidate: beforeValidate,
    cells: cells,
    columns: cols.map((r) => r.meta),
    colHeaders: cols.map((r) =>
      translate(r.headerTitle).replace("\n", "<br />")
    ),
    colWidths: cols.map((r) => r.width),
    columnSorting: true,
    contextMenu: {
      items: {
        row_above: {},
        remove_row: {
          disabled: disableRemoveRow,
        },
        row_below: {
          disabled: disableRowBelow,
        },
        alignment: {},
        copy: {},
        cut: {},
      },
    },
    dataSchema: defaultData,
    dropdownMenu: [
      "filter_by_condition",
      "filter_action_bar",
      "filter_by_value",
    ],
    formulas: { engine: HyperFormula },
    height: "65vh",
    language: culture,
    licenseKey: "non-commercial-and-evaluation",
    manualColumnResize: true,
    manualRowMove: false,
    modifyColWidth: modifyColWidth,
    numericFormat: { culture, pattern: {} },
    ref: dataTable,
    rowHeaders: true,
    rowHeaderWidth: 30,
    rowHeights: 35,
    wordWrap: false,
  };

  return { props, addNewRow };
};

export default useGetActivityShiftTableConfigs;
