export const BenchmarkingChartType = {
  Inpatient: "Inpatient",
  Outpatient: "Outpatient",
  Emergency: "Emergency",
};

export interface ChartDataObject {
  name: string;
  color: string | null;
  sortOrder?: number;
  data: number[] | (number | string)[][];
  type?: string;
}

export interface ChartDataResponse {
  chartData: ChartDataObject[];
  careUnits: string[];
  tagetProductivity: TargetProductivityForCareUnit[];
  actualProuctivity: ActualProductivityForCareUnit[];
}

export interface ChartData {
  chartData: ChartDataObject[];
  careUnits: string[];
}

export interface ActualProductivityForCareUnit {
  formalCompetenceId: number;
  formalCompetenceName: string;
  actualProductivity: number;
  sortOrder: number;
  formalCompetenceColor: string;
}

export interface TargetProductivityForCareUnit {
  careUnitId: string;
  careUnitName: string;
  targetProductivity: number;
}

export interface BenchmarkDataObj {
  id?: string;
  name: string;
  color: string | null;
  sortOrder?: number;
  data: number[];
}
