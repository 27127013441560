import { AxiosResponse } from "axios";
import HttpCommonService from "./httpCommonService";
import { PhysicianSubCareUnit } from "../interfaces/careunits/physician-subunits";

const CareUnitService = {
  getCareUnitsByCareProviderId: async (id: number) => {
    return await HttpCommonService.getAsync(`api/careunitsforprovider/${id}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getPhysicianCareUnits: async (id: number, careUnitId: number | null, isWithoutPhysicianUnit: boolean) => {
    return await HttpCommonService.getAsync(`api/nonphysicalcareunits/${id}/${careUnitId}/${isWithoutPhysicianUnit}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getCareUnitById: async (id: number, year?: number) => {
    const path = year ? `api/careunit/${id}/${year}` : `api/careunit/${id}`;
    
    return await HttpCommonService.getAsync(path)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getPhycianSubCareUnits: async (physicianCareUnitId: number): Promise<AxiosResponse<PhysicianSubCareUnit[]>> => {
    return await HttpCommonService.getAsync(`api/careunits/physiciansubunits/${physicianCareUnitId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },
};

export default CareUnitService;
