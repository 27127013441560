export const CARE_UNIT_TYPES = {
  OUTPATIENT: 1,
  INPATIENT: 2,
  PHYSICIAN: 3,
  EMERGENCY: 4,
};

export enum CareUnitTypeEnum {
  OUTPATIENT = 1,
  INPATIENT = 2,
  PHYSICIAN = 3,
  EMERGENCY = 4,
}
