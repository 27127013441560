import HttpCommonService from "./httpCommonService";

const AnalyticsService = {
  getNumberOfCareBeds: async (
    siteId: number | null,
    careUnitId: number | null,
    careUnitIds: number[] | null,
    year: number,
    scenario: number,
    isOnDashboard?: boolean
  ) => {
    return await HttpCommonService.postAsync(`api/results/numberofbeds`, {
      siteId: siteId,
      careUnitId: careUnitId,
      careUnitIds: careUnitIds,
      year: year,
      scenario: scenario,
      isOnDashboard: isOnDashboard,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getNumberOfCareBedsByWeekdays: async (
    siteId: number | null,
    careUnitId: number | null,
    careUnitIds: number[] | null,
    year: number,
    scenario: number,
    weekdayIds: number[] | null
  ) => {
    return await HttpCommonService.postAsync(`api/results/numberofbedsbyweekdays`, {
      siteId: siteId,
      careUnitId: careUnitId,
      careUnitIds: careUnitIds,
      year: year,
      scenario: scenario,
      weekdayIds,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getNumberOfCareBedsV2: async (careUnitId: number | null, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/results/numberofcarebedsv2/${careUnitId}/${year}/${scenario}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getNumberOfCareBedsV2ByWeekday: async (
    careUnitId: number | null,
    year: number,
    scenario: number,
    weekdayIds: number[]
  ) => {
    return await HttpCommonService.postAsync(`api/inpatientplans/carebeds/filter/weekday`, {
      careUnitId: careUnitId,
      year: year,
      scenarioId: scenario,
      weekdayIds: weekdayIds,
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getNoOfHistoricCareBeds: async (
    siteId: number | null,
    careUnitList: string[] | null,
    CareUnitIds: number[] | null,
    year: number,
    scenarioId: number,
    weekdayIds: number[] | null
  ) => {
    return await HttpCommonService.postAsync(`api/results/historicnumberofcarebeds`, {
      careUnitList: careUnitList,
      CareUnitIds: CareUnitIds,
      siteId: siteId,
      year: year,
      scenarioId: scenarioId,
      weekdayIds: weekdayIds,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getLastThreeYearActualResults: async (careUnitId: number, scenarioId: number, year: number) => {
    return await HttpCommonService.getAsync(
      `api/inpatientproduction/lastthreeyearactualdata/${careUnitId}/${scenarioId}/${year}`
    )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getLastThreeYearActualResultsByWeekday: async (
    careUnitId: number,
    scenarioId: number,
    year: number,
    weekdayIds: number[]
  ) => {
    return await HttpCommonService.postAsync(`api/inpatientproduction/lastthreeyearactualdata/filter/weekdays`, {
      careUnitId: careUnitId,
      year: year,
      scenarioId: scenarioId,
      weekdayIds: weekdayIds,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default AnalyticsService;
