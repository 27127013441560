import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { AppInsightService } from "../../../../../services";
import { ChartDataYearFormat, QueueDetails } from "../../../../../interfaces/production-plan";
import _ from "lodash";
import { ChartData } from "../../../../../interfaces/common/chartdata";
import {
  QueueCategory,
  QueueDetailRequest,
} from "../../../../../interfaces/production-plan/outpatient/queue/queueDetail";
import { OutPatientMultiSelectRequest } from "../../../../../interfaces/production-plan/outpatient/outpatient-multiselect-filter";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";
import HistoricQueueDetailService from "../../../../../services/historicQueueDetailService";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";

const QueueDetailsChart = ({ queueDetailsRequest }: { queueDetailsRequest: OutPatientMultiSelectRequest | null }) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "QueueChart - Outpatient" });
  }, []);

  const queueCategoriesColors: string[] = [
    "#2C461E",
    "#638B53",
    "#739768",
    "#A9C9A0",
    "#F5C773",
    "#BD4B4F",
    "#8A181C",
    "#C9C9C9",
    "#747474",
  ];

  const { t: translate } = useTranslation();
  let prevLevelNumber = "0";

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const [queueDetails, setQueueDetails] = useState<ChartData[]>([]);
  const [isChartEmpty, setIsChartEmpty] = useState<boolean>(false);
  const [queueCategories, setQueueCategories] = useState<ChartDataYearFormat[]>([]);
  const [isChartLoading, setIsChartLoading] = useState(true);

  const getTranslation = (level: string, prevLevel: string) => {
    if (!isNaN(Number(prevLevel))) {
      prevLevelNumber = prevLevel;
    }

    const translationFirstVisit = translate("first_visit");
    const translationDays = translate("days");

    if (!isNaN(Number(level))) {
      if (level === "9999") {
        return `${translationFirstVisit} > 180 ${translationDays}`;
      } else if (prevLevelNumber == "0") {
        return `${translationFirstVisit} < ${level} ${translationDays}`;
      } else {
        return `${translationFirstVisit} ${prevLevelNumber} - ${level} ${translationDays}`;
      }
    } else {
      return translate(level);
    }
  };

  const getSeriesVisibility = (level: string): boolean => {
    if (level === "9999" || level === "180" || level === "return_visit_after_goal_date") {
      return true;
    }
    return false;
  };

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.QueueDetailsMultiSelect);

  useEffect(() => {
    if (careUnitId !== null) {
      setIsChartLoading(true);

      let queueDetailsReq: QueueDetailRequest | null = null;

      if (isFeatureFlagEnabled && queueDetailsRequest != null && queueDetailsRequest.professionIds.length > 0) {
        queueDetailsReq = {
          careUnitId: careUnitId,
          year: year,
          professionIds: queueDetailsRequest?.professionIds[0] === -1 ? [] : queueDetailsRequest?.professionIds,
          contactTypeIds: queueDetailsRequest?.contactTypeIds[0] === -1 ? [] : queueDetailsRequest?.contactTypeIds,
          contactReasonGroupingIds:
            queueDetailsRequest?.contactReasonGroupingIds[0] === -1
              ? []
              : queueDetailsRequest?.contactReasonGroupingIds,
          typesOfVisit: queueDetailsRequest?.typesOfVisit[0] === -1 ? [] : queueDetailsRequest?.typesOfVisit,
          careTypeIds: queueDetailsRequest?.careTypeIds[0] === -1 ? [] : queueDetailsRequest?.careTypeIds,
          typesOfEmergency:
            queueDetailsRequest?.typesOfEmergency[0] === -1 ? [] : queueDetailsRequest?.typesOfEmergency,
          medicalSpecialtyIds:
            queueDetailsRequest?.medicalSpecialtyIds[0] === -1 ? [] : queueDetailsRequest?.medicalSpecialtyIds,
        };
      } else if (!isFeatureFlagEnabled) {
        queueDetailsReq = {
          careUnitId: careUnitId,
          year: year,
          professionIds: [],
          contactTypeIds: [],
          contactReasonGroupingIds: [],
          typesOfVisit: [],
          careTypeIds: [],
          typesOfEmergency: [],
          medicalSpecialtyIds: [],
        };
      }

      if (queueDetailsReq) {
        HistoricQueueDetailService.getQueueDetails(queueDetailsReq)
          .then((res) => {
            const data = res.details.map((queue: QueueDetails, index: number) => {
              return {
                name: getTranslation(queue.level, index - 1 >= 0 ? res.details[index - 1].level : "0"),
                data: queue.outcome,
                color: queueCategoriesColors[index],
                visible: getSeriesVisibility(queue.level),
              };
            });

            const modifiedChartCategories: ChartDataYearFormat[] = [];
            res.categories.map((category: QueueCategory) => {
              modifiedChartCategories.push({
                name: category.year,
                categories: category.weeks,
              });
            });

            setChartSeries(data, modifiedChartCategories);
            setQueueCategories(modifiedChartCategories);
            setIsChartLoading(false);
          })
          .catch((ex) => {
            console.error(ex);
            setIsChartLoading(false);
          });
      }
    }
  }, [careUnitId, year, queueDetailsRequest]);

  const setChartSeries = (data: ChartData[], categories: ChartDataYearFormat[]) => {
    if (data.length === 0) {
      const emptyData = categories.map((c) => {
        return {
          name: "No Data",
          data: Array(c.categories.length).fill(0),
          color: "#000",
        };
      });

      setQueueDetails(emptyData);
      setIsChartEmpty(true);
    } else {
      setQueueDetails(data);
      setIsChartEmpty(false);
    }
  };

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Open Sans",
      },
      backgroundColor: "transparent",
    },

    colors: ["#2C461E", "#638B53", "#739768", "#A9C9A0", "#F5C773", "#BD4B4F", "#8A181C", "#C9C9C9", "#747474"],

    title: {
      text: undefined,
    },

    xAxis: {
      categories: queueCategories,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      reversedStacks: false,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      enabled: isChartEmpty ? false : true,
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point as Highcharts.Point;
        const series = this.series as Highcharts.Series;
        const tooltipInfo = `${series.name} <br /> ${point.y}`;
        return tooltipInfo;
      },
    },

    plotOptions: {
      series: {
        stacking: "normal",
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 8,
      },
    },

    series: queueDetails,
  };

  return isChartLoading ? (
    <div className="flex h-full items-center justify-center">
      <LoadingWithGrey sizeInPixel={40} />
    </div>
  ) : (
    <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
  );
};

export default QueueDetailsChart;
