import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/common-chart-styles.css";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import WaitingListChart from "./wating-list-chart";
import { WaitingListProps } from "../../../interfaces/props/wating-list";
import WaitingListKpis from "./wating-list-kpis";

const WaitingList = ({ careUnitList }: WaitingListProps) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  // chart loading state
  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const handleChartClick = () => {
    navigate("/production", { state: { selectedTabIndex: 1 } });
  };

  const isLoading = isChartDataLoading;

  const handleChartDataFetching = useCallback((isChartDataLoading: boolean) => {
    setIsChartDataLoading(isChartDataLoading);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="dashboard__chart__loading-container">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}

      <div className={`${isLoading ? "hidden" : "flex"} !h-full w-full cursor-pointer`} onClick={handleChartClick}>
        <div className="!h-full w-full flex-col">
          <div className="!h-full">
            <div className="flex !h-full flex-col">
              <div className="chart-title">
                <span className="">{translate("waiting_list")}</span>
              </div>
              <div className="flex h-full w-full">
                <div className="chart-bg mr-5 !h-full basis-full">
                  <WaitingListChart careUnitList={careUnitList} onChartDataFetching={handleChartDataFetching} />
                </div>
                <div className="basis-1/5">
                  <WaitingListKpis careUnitList={careUnitList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingList;
