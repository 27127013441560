import { RowObject } from "handsontable/common";
import { AGREEMENT_ENDPOINT, COMPENSATION_ENDPOINT } from "../utils/apiendpoints";
import HttpCommonService from "./httpCommonService";

const ParameterService = {
  uploadExcelAsync: async (file: File, parameterPath: string) => {
    return await HttpCommonService.postAsync(`${parameterPath}/upload`, { files: file }, "file")
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getParametersAsync: async (parameterPath: string) => {
    return await HttpCommonService.getAsync(parameterPath)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getParametersAsyncById: async (parameterPath: string) => {
    return await HttpCommonService.getAsync(parameterPath)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  saveCompensationsAsync: async (
    compensations: RowObject[] | undefined,
    updatedCompensationList: number[],
    careProviderId: number
  ) => {
    return await HttpCommonService.putAsync(COMPENSATION_ENDPOINT, {
      updateCompensations: {
        careProviderId: careProviderId,
        updatedCompensationIds: updatedCompensationList,
        updateCompensationList: compensations,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveCompensationsByCareUnitIdAsync: async (
    compensations: RowObject[] | undefined,
    updatedCompensationList: number[],
    careUnitId: number
  ) => {
    return await HttpCommonService.putAsync(COMPENSATION_ENDPOINT + "/" + careUnitId, {
      updateCompensations: {
        updatedCompensationIds: updatedCompensationList,
        updateCompensationList: compensations,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  saveAgreementsAsync: async (agreements: RowObject[] | undefined, updatedAgreementsList: number[]) => {
    return await HttpCommonService.putAsync(AGREEMENT_ENDPOINT, {
      updateAgreements: {
        updatedAgreementIds: updatedAgreementsList,
        updateAgreementList: agreements,
      },
    })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getCompetenceGroupLvl2: async () => {
    return await HttpCommonService.getAsync(`api/competencesGroupLvl2`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getOohCompTypes: async () => {
    return await HttpCommonService.getAsync(`api/oohcomptype`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default ParameterService;
