import HttpCommonService from "./httpCommonService";

const InpatientPlanService = {
  getWeeklyPhasing: async (careUnitId: number, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/weeklyphasing/${careUnitId}/${year}/${scenario}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getCareBedsForecastData: async (careUnitId: number, year: number) => {
    return await HttpCommonService.getAsync(`api/inpatientproduction/inpatientproductionforecast/${careUnitId}/${year}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getHistoricalWeeklyPhasing: async (careUnitId: number) => {
    return await HttpCommonService.getAsync(`api/weeklyphasing/historical/${careUnitId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getWeekdayPhasing: async (careUnitId: number, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/weekdayphasing/${careUnitId}/${year}/${scenario}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAgreedCareBeds: async (careUnitId: number, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/agreed_carebeds/${careUnitId}/${year}/${scenario}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getAgreedCareBedsByWeekday: async (careUnitId: number, year: number, scenario: number, weekdayIds: number[]) => {
    return await HttpCommonService.postAsync(`api/inpatientplans/agreed_carebeds/filter/weekday`, {
      careUnitId: careUnitId,
      year: year,
      scenarioId: scenario,
      weekdayIds: weekdayIds,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  updateWeeklyPhasing: async (
    careUnitId: number,
    year: number,
    weeklyPhasing: number[],
    weekdayPhasing: number[],
    scenario: number
  ) => {
    const payload = {
      careUnitId: careUnitId,
      year: year,
      weeklyPhasingList: weeklyPhasing,
      weekdayPhasingList: weekdayPhasing,
      scenarioId: scenario,
    };

    return await HttpCommonService.putAsync(`api/weeklyphasing`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  updateWeekDayPhasing: async (careUnitId: number, year: number, weekdayPhasing: number[], scenario: number) => {
    const payload = {
      careUnitId: careUnitId,
      year: year,
      updateWeekDayPhasingsList: weekdayPhasing,
      ScenarioId: scenario,
    };

    return await HttpCommonService.putAsync(`api/weekdayphasing`, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default InpatientPlanService;
