import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import loadsh from "lodash";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import "./productivity.css";

HighchartsMore(Highcharts);

import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { TotalProductivityFteResponse } from "../../../interfaces/productivity";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import { AppInsightService, ProductivityService } from "../../../services";
import { FteTotalChartData } from "../../../interfaces/productivity";
import { productionShares, productionSharesColors } from "../../../utils/highcharts/productivity/constants";
import { ISelectOption } from "../../../interfaces";
import { ProductivityCompetenceResponse } from "../../../interfaces/productivity/productivity-competence-response";
import { PhysicianSubUnits } from "../../../components";
import { CARE_UNIT_TYPES } from "../../../utils/constants/careUnitTypes";
import { WaterfallChartData } from "../../../interfaces/common/chartdata";
import CompetenceFilters from "./Filters/CompetenceFilters";
import ProductionFilters from "./Filters/ProductionFilters";
import WaterfallKpis from "./Kpis/WaterfallKpis";

const Productivity = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "Productivity" });
  }, []);
  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);
  const { t: translate, i18n } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [totalFteByProduction, setTotalFteByProduction] = useState<FteTotalChartData[] | undefined>(undefined);
  const [totalsByCompetence, setTotalsByCompetence] = useState<FteTotalChartData[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const type_of_inputs: ISelectOption[] = [
    {
      label: translate("fte"),
      value: "fte",
    },

    {
      label: translate("cost") + " (kSEK)",
      value: "cost",
    },

    {
      label: translate("working_hours"),
      value: "working_hours",
    },
  ];

  const type_of_competence_with_formal_competence: ISelectOption[] = [
    {
      label: translate("detailCompetence"),
      value: "WorkCompetence",
    },

    {
      label: translate("competence"),
      value: "FormalCompetence",
    },

    {
      label: translate("competenceGroup"),
      value: "FormalCompetenceGroup",
    },

    {
      label: translate("professionGroup"),
      value: "ProfessionGroup",
    },
  ];

  const [typesOfInputs, setTypesOfInputs] = useState(type_of_inputs);

  const [selectedInput, setSelectedInput] = useState<ISelectOption>(type_of_inputs[0]);
  const [typesOfCompetence, setTypesOfCompetence] = useState(type_of_competence_with_formal_competence);
  const [selectedCompetenceInput, setSelectedCompetenceInput] = useState<ISelectOption>(
    type_of_competence_with_formal_competence[0]
  );

  const tabs = [translate("CompetenceWaterfall"), translate("ProductionWaterfall")];

  const handleTabbarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  useEffect(() => {
    if (careUnitId !== null && scenario) {
      setLoading(true);
      ProductivityService.getTotalsByProductionShares(selectedInput?.value, careUnitId, scenario).then(
        (res: AxiosResponse<TotalProductivityFteResponse[]>) => {
          let formattedData: any = [];

          res.data.forEach((data: TotalProductivityFteResponse, i) => {
            let hasProductionSumCalculated = false;
            formattedData.forEach((d: any) => {
              if (d.name === data.productionType) {
                hasProductionSumCalculated = true;
              }
            });

            if (!hasProductionSumCalculated) {
              const fteSum = {
                color: productionSharesColors[data.productionType],
                name: data.productionType,
                y: loadsh.round(
                  res.data.reduce((accumulator, currentElement) => {
                    if (currentElement.productionType === data.productionType) {
                      return accumulator + currentElement.total;
                    }

                    return accumulator;
                  }, 0),
                  selectedInput.value === "working_hours" ? 0 : 1
                ),
              };

              formattedData.push(fteSum);

              if (data.productionType === "ProductionShareSurgery") {
                const intermediateSum = {
                  name: "Total - Direct care",
                  isIntermediateSum: true,
                  color: productionSharesColors["Total - Direct care"],
                };

                formattedData.push(intermediateSum);
              }
            }
          });

          formattedData = [
            ...formattedData,
            {
              name: "Total",
              isSum: true,
              color: productionSharesColors["Total"],
            },
          ];

          formattedData.sort((a: any, b: any) => productionShares.indexOf(a.name) - productionShares.indexOf(b.name));

          const translated = formattedData.map((data: any) => {
            return {
              ...data,
              name: translate(data.name),
            };
          });

          setTotalFteByProduction(translated);
        }
      );

      setLoading(false);
    }
  }, [careUnitId, selectedInput.value, scenario]);

  useEffect(() => {
    if (careUnitId !== null) {
      setLoading(true);
      ProductivityService.getTotalsByCompetences(
        selectedInput?.value,
        selectedCompetenceInput?.value,
        careUnitId,
        scenario
      ).then((res: AxiosResponse<ProductivityCompetenceResponse[]>) => {
        const competenceFormattedData: WaterfallChartData[] = [];

        res.data.forEach((data: ProductivityCompetenceResponse) => {
          const competenceFteSum = {
            color: data.formalCompetenceColor,
            name: data.competenceTypeShortName,
            sortOrder: data.formalCompetenceSortOrder,
            y: loadsh.round(data.total, 1),
          };

          competenceFormattedData.push(competenceFteSum);
        });

        competenceFormattedData.sort((a: WaterfallChartData, b: WaterfallChartData) => a.sortOrder - b.sortOrder);

        const translated = competenceFormattedData.map((data: WaterfallChartData) => {
          return {
            ...data,
            name: translate(data.name),
          };
        });

        setTotalsByCompetence(translated);
      });

      setLoading(false);
    }
  }, [careUnitId, selectedInput.value, selectedCompetenceInput.value, scenario]);

  useEffect(() => {
    if (i18n.language === "sv") {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ",",
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
        },
      });
    }
  }, [i18n]);

  const options = {
    chart: {
      type: "waterfall",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
      },
      backgroundColor: "transparent",
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (selectedInput.value === "cost") {
          return this.key + "</br>" + this.y + " K";
        } else {
          return this.key + "</br>" + this.y;
        }
      },
    },

    title: {
      text: undefined,
    },

    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      title: {
        text: translate(selectedInput.value),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string | number {
          if (selectedInput.value === "cost") {
            return this.value + "K";
          } else {
            return this.value;
          }
        },

        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        data: totalFteByProduction,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          inside: false,
          style: {
            textOutline: 0,
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        pointPadding: 0,
      },
    ],
  };

  const competenceOptions = {
    chart: {
      type: "waterfall",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
      },
      backgroundColor: "transparent",
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (selectedInput.value === "cost") {
          return this.key + "</br>" + this.y + " K";
        } else {
          return this.key + "</br>" + this.y;
        }
      },
    },

    title: {
      text: undefined,
    },

    xAxis: {
      type: "category",
      categories: totalsByCompetence?.map((x) => x.name),
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      title: {
        text: translate(selectedInput.value),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string | number {
          if (selectedInput.value === "cost") {
            return this.value + "K";
          } else {
            return this.value;
          }
        },

        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        data: totalsByCompetence,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          inside: false,
          style: {
            textOutline: 0,
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        pointPadding: 0,
      },
    ],
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedInput(selectedOption);
  };

  const handleCompetenceInputSelect = (selectedOption: ISelectOption) => {
    setSelectedCompetenceInput(selectedOption);
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} />
        </div>

        <div className="mt-0 w-full bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
          <div className="flex h-full w-full flex-row" id="wraper">
            <div className="h-full w-5/6">
              {selectedTabIndex === 0 && !loading && (
                <HighchartsReact
                  containerProps={{ style: { height: "100%" } }}
                  highcharts={Highcharts}
                  options={competenceOptions}
                />
              )}
              {selectedTabIndex === 1 && !loading && (
                <HighchartsReact
                  containerProps={{ style: { height: "100%" } }}
                  highcharts={Highcharts}
                  options={options}
                />
              )}
            </div>

            {/* right side pane for filters and KPIs */}
            <div className="ml-5 flex  w-1/6 flex-col justify-between">
              <div>
                {selectedTabIndex === 0 && (
                  <CompetenceFilters
                    handleCompetenceInputSelect={handleCompetenceInputSelect}
                    handleTypesOfInputSelect={handleTypesOfInputSelect}
                    selectedCompetenceInput={selectedCompetenceInput}
                    selectedInput={selectedInput}
                    typesOfCompetence={typesOfCompetence}
                    typesOfInputs={typesOfInputs}
                  />
                )}
                {selectedTabIndex === 1 && (
                  <>
                    <ProductionFilters
                      handleTypesOfInputSelect={handleTypesOfInputSelect}
                      selectedInput={selectedInput}
                      typesOfInputs={typesOfInputs}
                    />
                    <div className="mt-5">
                      <WaterfallKpis />
                    </div>
                  </>
                )}
              </div>

              <div>{careUnitTypeId === CARE_UNIT_TYPES.PHYSICIAN && <PhysicianSubUnits />}</div>
            </div>
          </div>
        </div>
      </div>
    </GridLayout>
  );
};

export default Productivity;
