import React from "react";
import { useTranslation } from "react-i18next";

import ChartInfo from "../chart-info/chart-info";
import usePhysicianSubUnits from "./hooks/usePhysicianSubUnits";
import InformationBox from "../information-box/InformationBox";

const PhysicianSubUnits = () => {
  const { physicanSubUnits } = usePhysicianSubUnits();
  const { t: translate } = useTranslation();

  return (
    <div className="mb-4">
      <ChartInfo title={translate("connected_care_units")}>
        <InformationBox
          className="p-3-v-2 text-[#052D5A]"
          items={physicanSubUnits}
        />
      </ChartInfo>
    </div>
  );
};

export default PhysicianSubUnits;
