import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "../../../redux/store";
import AnalyticsService from "../../../services/analyticsService";
import { CareBedsSeriesData, NumberOfBeds } from "../../../interfaces/analytics/number-of-beds";
import CarebedsPlanChart from "../../analytics/CarebedsPlan/CareBedsPlanChart";
import { DashboardPlannedVsRealProps } from "../../../interfaces/props/dashboard-production-vs-plan";
import PlanVsRealKpiResponse from "../../../interfaces/dashboard/plan-vs-real-kpi-response";
import PlanVsRealKpis from "./plan-vs-real-kpis";

import "../styles/common-chart-styles.css";
import DashboardService from "../../../services/dashboardService";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";

const PlanVsRealComponent = ({ careUnitList }: DashboardPlannedVsRealProps) => {
  const { t: translate } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const navigate = useNavigate();
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const [chartData, setChartData] = useState<CareBedsSeriesData[]>();
  const defaultWeekdays = [1, 2, 3, 4, 5, 6, 7];

  // chart loading state
  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const [kpis, setKpis] = useState<PlanVsRealKpiResponse>({
    averageLOSActual: 0,
    averageLOSPlanned: 0,
    occupancyPlanned: 0,
  });

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  useEffect(() => {
    const fetchChartData = async () => {
      setIsChartDataLoading(true);
      await AnalyticsService.getNumberOfCareBeds(0, 0, careUnitList, year, scenario, true)
        .then(async (res) => {
          const data: CareBedsSeriesData[] = res.data.map((bed: NumberOfBeds) => {
            return {
              type: "column",
              name: bed.careUnit,
              data: bed.numberOfBeds,
            };
          });
          const filteredArray = data.filter((item) => item.data.length > 0);

          await DashboardService.getPlanRealKpis(careUnitList, year, scenario)
            .then(async (resx) => {
              const resData: PlanVsRealKpiResponse = resx.data;
              setKpis(resData);
            })
            .catch((err) => {
              console.error(err);
            });

          await AnalyticsService.getNoOfHistoricCareBeds(null, null, careUnitList, year, scenario, defaultWeekdays)
            .then((res) => {
              setCareBedChartSeries(filteredArray, res);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });

      setIsChartDataLoading(false);
    };

    if (careUnitList.length > 0 && careUnitList[0] != 0 && year !== null && year > 0) {
      fetchChartData();
    }
  }, [scenario, careUnitList]);

  const setCareBedChartSeries = (existingData: CareBedsSeriesData[], historicData: NumberOfBeds[]) => {
    setChartData(() => [
      ...existingData,
      {
        type: "line",
        name: translate("last_52_weeks"),
        data: historicData,
        color: "#AD1E23",
        shadow: {
          enabled: true,
          color: "white",
          opacity: 1,
          offsetX: 0,
          offsetY: 0,
          width: 4,
        },
      },
    ]);
  };

  const handleChartClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLElement).closest(".highcharts-legend-item") ||
      (event.target as HTMLElement).closest(".highcharts-legend")
    ) {
      return;
    }
    navigate("/analytics/carebeds-plan", { state: { selectedTabIndex: 1 } });
  };

  const isLoading = isChartDataLoading;

  return (
    <>
      {isLoading && (
        <div className="dashboard__chart__loading-container">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}

      <div className={`${isLoading ? "hidden" : "flex"} !h-full w-full cursor-pointer`} onClick={handleChartClick}>
        <div className="!h-full w-full flex-col">
          <div className="!h-full">
            <div className="flex !h-full flex-col">
              {/* change basis-full to basis-4/5 and remove mr-5 after adding KPIs */}
              <div className="chart-title">
                <span className="">{translate("planned_care_beds")}</span>
              </div>
              <div className="flex h-full w-full">
                <div className="chart-bg mr-5 !h-full basis-full">
                  <CarebedsPlanChart
                    chartComponentRef={chartComponentRef}
                    chartTitle=""
                    chartData={chartData}
                    pointWidth={6}
                    isOnDashboard={true}
                  />
                </div>

                {/* uncomment the below line and change this to basis-1/5 after adding the KPIs */}
                <div className="basis-1/5">
                  <PlanVsRealKpis
                    averageLosActual={kpis.averageLOSActual}
                    averageLosPlan={kpis.averageLOSPlanned}
                    occupancyAverage={kpis.occupancyPlanned}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanVsRealComponent;
