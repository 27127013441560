import { HotTableClass as HotTable } from "@handsontable/react";
import { RowObject } from "handsontable/common";
import React from "react";
import { useAfterRemoveRowReturnType } from "../../../../interfaces/analytics/handsontable/use-after-removerow-return-type";

const useAfterRemoveRow = (
  dataTable: React.RefObject<HotTable | null>
): useAfterRemoveRowReturnType => {
  const afterRemoveRow = () => {
    const data: RowObject | undefined =
      dataTable.current?.hotInstance?.getSourceData();
    if (data?.length === 1) {
      if (data[0]?.id === "total_row") {
        dataTable.current?.hotInstance?.alter("remove_row");
      }
    }
  };

  return { afterRemoveRow };
};

export default useAfterRemoveRow;
