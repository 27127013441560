import React from "react";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import { Outlet } from "react-router-dom";
import { useGlobalConfig } from "../hooks";

const ApplicationLayout = () => {
  const { isLoading } = useGlobalConfig();

  return (
    <div className="flex h-screen flex-col">
      {!isLoading && (
        <>
          <div className="">
            <NavigationBar />
          </div>
          <div className="flex-1">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicationLayout;
