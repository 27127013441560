import { GridLayout } from "../../../layouts";
import React, { useEffect, useState } from "react";
import AgreementHome from "../Agreement/AgreementHome";
import CompensationsHome from "../Compensation/CompensationsHome";
import PatientFlowHome from "../Patientflow/PatientFlowHome";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";
import TabbarSecondary from "../../../components/tab-bar/tabbar-secondary/TabbarSecondary";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { ScenarioHome } from "../Scenario/Pages";
import WorkCompetenceMapping from "../WorkCompetence/WorkCompetenceMapping";
import CompetenceHome from "../Competence/CompetenceHome";
import OohAdderHome from "../OohAdders/OohadderHome";

const ParametersHome = () => {
  const [fileAddSuccessNotification, setFileAddSuccessNotification] = useState("");
  const [fileAddFailNotification, setFileAddFailNotification] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [loading, setLoading] = useState("");
  const { t: translate, i18n } = useTranslation();

  const tabs = [
    translate("Agreement"),
    translate("Compensations"),
    translate("Competence"),
    translate("OOHAdders"),
    translate("PatientFlow"),
    translate("Scenario"),
    translate("DetailedCompetence"),
  ];

  const parameterReadOnly = useSelector((state: RootState) => state.permission.parameterReadOnly);

  const handleTabbarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  useEffect(() => {
    if (fileAddSuccessNotification) {
      const timer = setTimeout(() => {
        setFileAddSuccessNotification("");
      }, 4000);

      return () => clearTimeout(timer);
    } else if (fileAddFailNotification !== "") {
      const timer = setTimeout(() => {
        setFileAddFailNotification("");
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [fileAddSuccessNotification, fileAddFailNotification]);
  return (
    <GridLayout additionalStyles="mt-0">
      <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
        {fileAddSuccessNotification !== "" && (
          <NotificationFloat varaint="success" content={fileAddSuccessNotification} />
        )}
        {fileAddFailNotification !== "" && <NotificationFloat varaint="error" content={fileAddFailNotification} />}
        {loading !== "" && <NotificationFloat varaint="loading" content={loading} />}
      </div>
      <div className="col-start-1 col-end-13">
        <div className="flex items-center justify-between">
          <TabbarSecondary tabs={tabs} onClick={handleTabbarClick} />
        </div>

        {selectedTabIndex == 0 && (
          <AgreementHome
            setFileAddSuccessNotification={setFileAddSuccessNotification}
            setFileAddFailNotification={setFileAddFailNotification}
            setLoading={setLoading}
            loadingStat={loading}
            readOnly={parameterReadOnly ?? false}
          ></AgreementHome>
        )}

        {selectedTabIndex == 1 && (
          <CompensationsHome
            setFileAddSuccessNotification={setFileAddSuccessNotification}
            setFileAddFailNotification={setFileAddFailNotification}
            setLoading={setLoading}
            loadingStat={loading}
            readOnly={parameterReadOnly ?? false}
          ></CompensationsHome>
        )}

        {selectedTabIndex == 2 && (
          <CompetenceHome
            setFileAddSuccessNotification={setFileAddSuccessNotification}
            setFileAddFailNotification={setFileAddFailNotification}
            setLoading={setLoading}
            loadingStat={loading}
            readOnly={parameterReadOnly ?? false}
          ></CompetenceHome>
        )}

        {selectedTabIndex == 3 && (
          <OohAdderHome
            setFileAddSuccessNotification={setFileAddSuccessNotification}
            setFileAddFailNotification={setFileAddFailNotification}
            setLoading={setLoading}
            loadingStat={loading}
            readOnly={parameterReadOnly ?? false}
          ></OohAdderHome>
        )}

        {selectedTabIndex == 4 && (
          <PatientFlowHome
            setFileAddSuccessNotification={setFileAddSuccessNotification}
            setFileAddFailNotification={setFileAddFailNotification}
            setLoading={setLoading}
            loadingStat={loading}
            readOnly={parameterReadOnly ?? false}
          ></PatientFlowHome>
        )}
        {selectedTabIndex == 5 && <ScenarioHome></ScenarioHome>}
        {selectedTabIndex == 6 && <WorkCompetenceMapping />}
      </div>
    </GridLayout>
  );
};

export default ParametersHome;
