import { useRef } from "react";
import { HotTableClass as HotTable } from "@handsontable/react";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import { ActivityAnalysisResultsGridConfig } from "../../../../../interfaces/analytics/activity-analysis-results-grid-config";
import { ActivityAnalysisResultsType } from "../../../../../interfaces/analytics/activity-analysis-results";
import useAdjustResultsColumnHeader from "./useAdjustResultsColumnHeader";
import useGetResultsPivotData from "./useGetResultsPivotData";
import useGetResultsPivotNestedHeaders from "./useGetResultPivotNestedHeaders";
import useAdjustResultsCellHeader from "./useAdjustResultsCellHeader";

const useActivityAnalysisResultConfigs = (
  tableData: ActivityAnalysisResultsType[]
): ActivityAnalysisResultsGridConfig => {
  let culture = localStorage.getItem("i18nextLng");

  const dataTable = useRef<HotTable>(null);

  const adjustShiftsColumnHeaderColours = useAdjustResultsColumnHeader();

  if (culture === "en") {
    culture = "en-US";
  } else if (culture === "en-US") {
    culture = "en-US";
  } else {
    culture = "sv-SE";
  }

  const nestedColumnConfigs = useGetResultsPivotNestedHeaders(tableData);

  const props: DataTableProps = {
    colWidths: (col) => {
      if (col === 0) {
        return 250;
      } else {
        return 100;
      }
    },
    contextMenu: false,
    readOnly: true,
    afterGetColHeader: (col: number, TH: HTMLElement, row: number) =>
      adjustShiftsColumnHeaderColours(col, TH, row, nestedColumnConfigs.columnHeaders[1].length),
    data: useGetResultsPivotData(tableData),
    cells: (row, column) => useAdjustResultsCellHeader(tableData, row, column),
    columns: nestedColumnConfigs.columns,
    height: "79vh",
    licenseKey: "non-commercial-and-evaluation",
    manualRowMove: false,
    nestedHeaders: nestedColumnConfigs.columnHeaders,
    ref: dataTable,
    rowHeaders: false,
    rowHeaderWidth: 35,
    rowHeights: 35,
    wordWrap: false,
  };

  return { props };
};

export default useActivityAnalysisResultConfigs;
