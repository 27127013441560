import HttpCommonService from "./httpCommonService";
import { Region } from "../interfaces/analytics/icdAnalysis/region";
import { Chapter } from "../interfaces/analytics/icdAnalysis/chapter";
import { Age } from "../interfaces/analytics/icdAnalysis/age";
import { Gender } from "../interfaces/analytics/icdAnalysis/gender";
import { ICDAnalysisRequest } from "../interfaces/analytics/icdAnalysis/icdAnalysisRequest";
import { ICDAnalysisChartData } from "../interfaces/analytics/icdAnalysis/icdAnalysisChartData";

export const ICDAnalysisService = {
  fetchRegions: async (): Promise<Region[]> => {
    return await HttpCommonService.getAsync(`api/icd/regions`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  fetchChapters: async (): Promise<Chapter[]> => {
    return await HttpCommonService.getAsync(`api/icd/chapters`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  fetchAges: async (): Promise<Age[]> => {
    return await HttpCommonService.getAsync(`api/icd/ages`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  fetchYears: async (): Promise<number[]> => {
    return await HttpCommonService.getAsync(`api/icd/years`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  fetchGenders: async (): Promise<Gender[]> => {
    return await HttpCommonService.getAsync(`api/icd/genders`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  fetchIcdAnalysisChartData: async (icdAnalysisRequest: ICDAnalysisRequest): Promise<ICDAnalysisChartData[]> => {
    return await HttpCommonService.postAsync(`api/icd/analysis`, icdAnalysisRequest)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default ICDAnalysisService;
