import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToolTipType } from "../../interfaces";
import { logout } from "../../redux/slices/auth.slice";
import { resetPermission, RootState } from "../../redux/store";

interface ToolTipProps {
  handleItemClick: () => void;
  type: ToolTipType;
}

const Tooltip = ({ handleItemClick, type }: ToolTipProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userManagmentAccess = useSelector((state: RootState) => state.permission.userManagmentEnabled);

  const handleLogoutClick = () => {
    dispatch(logout());
    dispatch(resetPermission());
    // dispatch(resetOrgData());
    handleTooltipIconClick();
  };

  const onOrgStructureClick = () => {
    handleTooltipIconClick();
    navigate("/org");
  };

  const onParametersClick = () => {
    handleTooltipIconClick();
    navigate("/parameters");
  };

  const onUserManagementClick = () => {
    handleTooltipIconClick();
    userManagmentAccess && navigate("/usermanagement");
  };

  const onChangePasswordClick = () => {
    handleTooltipIconClick();
    navigate("/changepassword");
  };

  const handleTooltipIconClick = () => {
    handleItemClick();
  };

  const handleGlobalSettingsClick = () => {
    handleTooltipIconClick();
    navigate("/settings/global");
  };

  return (
    <div className={`tooltip absolute right-2 z-10 lg:top-20 xl:top-24 ${type === "parameters" && "right-[3.25rem]"}`}>
      {type === "parameters" && (
        <>
          <div className={`child ${location.pathname === "/org" && "selected"}`} onClick={onOrgStructureClick}>
            {translate("OrganizationStructure")}
          </div>
          <div className={`child ${location.pathname === "/parameters" && "selected"}`} onClick={onParametersClick}>
            {translate("Parameters")}
          </div>
          {userManagmentAccess && (
            <div
              className={`child ${location.pathname === "/usermanagement" && "selected"}`}
              onClick={onUserManagementClick}
            >
              {translate("UserManagment")}
            </div>
          )}

          <>
            <hr />

            <div
              className={`child ${location.pathname === "/settings/global" && "selected"}`}
              onClick={handleGlobalSettingsClick}
            >
              {translate("GlobalSettings")}
            </div>
          </>
        </>
      )}

      {type === "usersettings" && (
        <>
          <div className="child">{translate("EditContactInfo")}</div>
          <div
            className={`child ${location.pathname === "/changepassword" && "selected"}`}
            onClick={onChangePasswordClick}
          >
            {translate("ChangePassword")}
          </div>
          <hr />

          <div className="child danger logout group" onClick={handleLogoutClick}>
            {translate("Logout")}
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20.658 18.076">
              <path
                id="Logout"
                d="M7.747,0V2.582H18.076V15.494H7.747v2.582H20.658V0ZM5.165,5.165,0,9.038l5.165,3.873V10.329H15.494V7.747H5.165Z"
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};

export default Tooltip;
