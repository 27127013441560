import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingWithGrey from "../../../components/loading/LoadingWithGrey";
import { RootState } from "../../../redux/store";
import { BENCHMARKING } from "../../../utils/constants/routes";
import "../styles/common-chart-styles.css";
import { ISelectOption } from "../../../interfaces";
import ProductivityKpis from "./productivity-kpis";
import WaterfallKpiService from "../../../services/kpiService";
import { WaterfallKpi } from "../../../interfaces/productivity";
import { inpatientProductivityKps } from "../../../interfaces/productivity/waterfall-kpi";
import BenchmarkingChart from "../../productivity/Benchmarkings/Chart/BenchmarkingChart";

const ProductionComponent = ({
  careUnits,
  selectedCareUnitType,
}: {
  careUnits: number[];
  selectedCareUnitType: ISelectOption;
}) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [error, setErrorNotification] = React.useState("");
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const [isChartDataLoading, setIsChartDataLoading] = React.useState<boolean>(false);

  const [isKpiDataLoading, setIsKpiDataLoading] = React.useState<boolean>(false);

  const [inpatientKpis, setInpatientKpis] = useState<inpatientProductivityKps>({
    costPerCareDay: 0,
    hoursPerCareDay: 0,
    patientsPerRn: 0,
  });

  const [outpatientKpis, setOutpatientKpis] = useState<WaterfallKpi[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [selectedProductionTypes, setSelectedProductionTypes] = useState<string[] | null>(null);

  useEffect(() => {
    fetchKpis();
  }, [careUnits, scenario, selectedCareUnitType]);

  useEffect(() => {
    setIsLoading(isChartDataLoading || isKpiDataLoading);
  }, [isChartDataLoading, isKpiDataLoading]);

  const fetchKpis = () => {
    const kpisIds = ["WorkingHoursPerCareDay/Visit", "Patients/DailyVisitsPerFTE", "WorkingHoursKpi"];

    if (careUnits && careUnits.length > 0) {
      setIsKpiDataLoading(true);
      if (Number(selectedCareUnitType?.value) === 1) {
        WaterfallKpiService.getWaterfallOutPatientKPIs(careUnits, year, scenario).then((res) => {
          const newKpis: WaterfallKpi[] = [];
          const resData: WaterfallKpi[] = res as WaterfallKpi[];

          kpisIds.map((r) => {
            const items = resData.filter((rr) => rr.kpiName == r);
            if (items && items.length > 0) {
              newKpis.push(items[0]);
            } else {
              const emaptyKPI: WaterfallKpi = { kpiName: r, kpiValue: 0 };
              newKpis.push(emaptyKPI);
            }
          });

          setOutpatientKpis(() => newKpis);
        });
      } else {
        WaterfallKpiService.getWaterfallInPatientKPIs(careUnits, year, scenario).then((res: WaterfallKpi[]) => {
          if (res.length > 0) {
            const newKpis: inpatientProductivityKps = {
              costPerCareDay: Math.round(res.find((kpi) => kpi.kpiName === "StaffingCostPerCareDay")?.kpiValue || 0),
              hoursPerCareDay: Math.round(res.find((kpi) => kpi.kpiName === "WorkingHoursPerCareDay")?.kpiValue || 0),
              patientsPerRn: Math.round(res.find((kpi) => kpi.kpiName === "PatientsPerFTE")?.kpiValue || 0),
            };

            setInpatientKpis(() => newKpis);
          }
        });
      }
      setIsKpiDataLoading(false);
    }
  };

  const handleChatClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLElement).closest(".highcharts-legend-item") ||
      (event.target as HTMLElement).closest(".highcharts-legend")
    ) {
      return;
    }
    navigate(`/productivity/${BENCHMARKING}`);
  };

  return (
    <>
      {isLoading && (
        <div className="dashboard__chart__loading-container">
          <LoadingWithGrey sizeInPixel={40} />
        </div>
      )}

      <div className={`${isLoading ? "hidden" : "flex"} !h-full w-full cursor-pointer`} onClick={handleChatClick}>
        <div className="flex h-full w-full flex-col">
          <div className="chart-title">
            <span className="">{translate("productivity")}</span>
          </div>
          <div className="chart-bg">
            <BenchmarkingChart
              careUnitsList={careUnits}
              isPlacedOnDashboard={true}
              selectedCareUnitType={selectedCareUnitType}
              dateRange={{
                startDate: new Date(new Date().getFullYear(), 0, 2),
                endDate: new Date(new Date().getFullYear(), 11, 32),
              }}
              selectedCareLevel={{
                label: "all",
                value: "",
                isSelected: false,
              }}
              selectedMedicalSpeciality={{
                label: "all",
                value: "",
                isSelected: false,
              }}
              selectedCompetenceInput={{
                label: translate("competenceGroupLvl2"),
                value: "competenceGroupLvl2",
              }}
              selectedCompetenceType={{
                label: translate("all"),
                value: "",
              }}
              selectedContactType={{
                label: translate("all"),
                value: "",
              }}
              selectedInput={{
                label: translate("working_hours"),
                value: "working_hours",
              }}
              selectedProductionTypes={selectedProductionTypes || []}
              year={year}
              setErrorNotification={setErrorNotification}
              endDateErrors={[]}
              onChartDataFetching={(isLoading: boolean) => {
                setIsChartDataLoading(isLoading);
              }}
            />
          </div>
          <div className="chart-kpi-bottom">
            <ProductivityKpis
              outpatientKpis={outpatientKpis}
              inpatientKpis={inpatientKpis}
              careUnitType={Number(selectedCareUnitType?.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductionComponent;
