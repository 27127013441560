export enum ICDAnalysisSidePanelActions {
  SET_AGE_OPTIONS = "SET_AGE_OPTIONS",
  SET_CHAPTER_OPTIONS = "SET_CHAPTER_OPTIONS",
  SET_REGION_OPTIONS = "SET_REGION_OPTIONS",
  SET_REFERENCEREGIONS_OPTIONS = "SET_REFERENCEREGIONS_OPTIONS",
  SET_YEAR_OPTIONS = "SET_YEAR_OPTIONS",
  SET_GENDER_OPTIONS = "SET_GENDER_OPTIONS",
  SET_SELECTED_TOP_OPTION = "SET_SELECTED_TOP_OPTION",
  SET_SELECTED_AGES = "SET_SELECTED_AGES",
  SET_SELECTED_CHAPTERS = "SET_SELECTED_CHAPTERS",
  SET_SELECTED_REFERNCEREGIONS = "SET_SELECTED_REFERENCEREGIONS",
  SET_SELECTED_REGION = "SET_SELECTED_REGION",
  SET_SELECTED_YEARS = "SET_SELECTED_YEARS",
  SET_SELECTED_GENDERS = "SET_SELECTED_GENDERS",
}
