import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RootState } from "../../redux/store";

const AuthorizedRoutes = () => {
  const userManagementAccess = useSelector(
    (state: RootState) => state.permission.userManagmentEnabled
  );

  const location = useLocation();
  const nextLocation = location.pathname;

  const canAccess =
    userManagementAccess && nextLocation.includes("/usermanagement");

  return canAccess ? <Outlet /> : <Navigate to="/auth/login" replace />;
};

export default AuthorizedRoutes;
