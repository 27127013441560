import React from "react";

const ArrowDownIcon = ({
  height,
  width,
}: {
  height?: number;
  width?: number;
}): JSX.Element => {
  return (
    <i>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="#747474"
        className="h-5 w-5"
        height={height}
        width={width}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 15.75l7.5-7.5 7.5 7.5"
        />
      </svg>
    </i>
  );
};

export default ArrowDownIcon;
