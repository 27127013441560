import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "../../../../../components";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import { DivisionState } from "../../../../../interfaces/orgstructure";
import { DivisionDto } from "../../../../../interfaces/orgstructure/dtos";
import { DivisionFormProps } from "../../../../../interfaces/props/org-structure-scrollbar";
import OrgService from "../../../../../services/orgService";
import { ScrollBarConstants } from "../../../utils/constants";

const Division = ({
  setDivisions,
  setIsScrollBarOpen,
  setSuccessNotification,
  selectedHospital,
  variant,
}: DivisionFormProps) => {
  const { t: translate } = useTranslation();

  const [isAddDivisionFailed, setIsAddDivisionFailed] = useState<boolean>(false);

  const [division, setDivision] = useState<DivisionState>({
    id: 0,
    name: "",
  });

  const handleDivisionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setDivision((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddDivisionSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const divisionDto: DivisionDto = {
      divisionDto: {
        divisionName: division.name,
        hospitalId: selectedHospital?.id,
      },
    };

    await OrgService.createDivisionAsync(divisionDto)
      .then((res) => {
        if (setDivisions)
          setDivisions((prevArray) => [
            ...prevArray,
            {
              id: res.id,
              divisionName: division.name,
            },
          ]);
        setDivision({ id: 0, name: "" });
        setIsAddDivisionFailed(false);
        setIsScrollBarOpen(false);
        setSuccessNotification && setSuccessNotification("Division");
      })
      .catch((err) => {
        setIsAddDivisionFailed(true);
        throw err;
      });
  };

  return (
    <>
      {isAddDivisionFailed && (
        <div className="my-3 h-12">
          <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
        </div>
      )}

      <div className="flex h-screen w-full flex-col justify-center">
        <h3 className="heading-3">
          {variant === ScrollBarConstants.ADD_DIVISION ? translate("add_a_new") + " " : translate("edit") + " "}
          {translate("division")}
        </h3>

        <form onSubmit={handleAddDivisionSubmit}>
          <div>
            <Input
              name="name"
              value={division.name}
              placeholder={translate("name")}
              handleInputChange={handleDivisionInputChange}
              error=""
              success=""
              type="text"
            />
          </div>

          <Button
            additionalStyles="w-1/2 h-11"
            variant="primary"
            text={ScrollBarConstants.ADD_DIVISION ? translate("save_division") : translate("edit_division")}
          />
        </form>
      </div>
    </>
  );
};

export default Division;
