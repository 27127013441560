import React, { useEffect } from "react";
import { KanbanStatus } from "../../../../../interfaces/kanban/responses/kanbanStatus";
import { KanbanTaskByStatus } from "../../../../../interfaces/kanban/responses/kanbanTaskByStatuses";
import { KanbanCard as Card } from "../../../../../interfaces/kanban/models/kanbanTask";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import KanbanService from "../../../../../services/kanbanService";

const useKanbanBoardData = () => {
  const [kanbanStatuses, setKanbanStatuses] = React.useState<KanbanStatus[]>([]);
  const [tasksByStatus, setTasksByStatus] = React.useState<KanbanTaskByStatus[]>([]);
  const [boardTasks, setBoardTasks] = React.useState<Card[]>([]);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const fetchKanbanStatuses = async () => {
    const statuses: AxiosResponse<KanbanStatus[]> = await KanbanService.getKanbanStatuses();
    setKanbanStatuses(statuses.data);
  };

  const fetchKanbanTasksByStatuses = async (careUnitId: number) => {
    const response: AxiosResponse<KanbanTaskByStatus[]> = await KanbanService.getKanbaTasksByStatuses(careUnitId);
    setTasksByStatus(response.data);
  };

  const fetchData = async () => {
    if (careUnitId) {
      await fetchKanbanStatuses();
      await fetchKanbanTasksByStatuses(careUnitId);
    }
  };

  useEffect(() => {
    fetchData();
  }, [careUnitId]);

  useEffect(() => {
    const Tasks: Card[] = [];

    tasksByStatus.forEach((status) => {
      status.tasks.forEach((task) => {
        Tasks.push({
          id: task.id,
          statusId: task.statusId,
          title: task.title,
          description: task.description,
          assigneeId: task.assigneeId,
        });
      });
    });

    if (tasksByStatus.length === 0) {
      setBoardTasks([
        {
          id: 0,
          statusId: 0,
          title: "",
          description: "",
          assigneeId: "",
        },
      ]);
    } else {
      setBoardTasks(Tasks);
    }
  }, [tasksByStatus]);

  return {
    kanbanStatuses,
    boardTasks,
    fetchTasks: fetchKanbanTasksByStatuses,
  };
};

export default useKanbanBoardData;
