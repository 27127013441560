export const kanBanBoardTranslations = {
  "en-US": {
    kanban: {
      items: "items",
      noCard: "No cards to display",
    },
  },
  "sv-SE": {
    kanban: {
      items: "uppgifter",
      noCard: "Inga kort att visa",
    },
  },
};
