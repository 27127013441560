import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { IconButtonSmall } from "../../../../components";
import { Division, Department, CareUnit, CareUnitStateType } from "../../../../interfaces/orgstructure";
import { RootState } from "../../../../redux/store";
import OrgService from "../../../../services/orgService";
import { CARE_UNIT_ENDPOINT, PHYSICAL_CARE_UNIT_ENDPOINT } from "../../../../utils/apiendpoints";
import OrgStructureItem from "../../components/OrgStructureItem/OrgStructureItem";
import { OrganizationStructureItem } from "../../interfaces";

interface OrgStructureItemLayoutProps {
  addButtonAction: string;
  additionalStyles?: string;
  clickedOrganizationLevel: number;
  disabled?: boolean;
  deleteOrganizationStructureEntity: (orgStructureItemId: number, entityType: string) => void;
  editButtonAction: string;
  entityType: string;
  isArrowHidden?: boolean;
  isMaxLevel?: boolean;
  oraganizationStructureLevel: number;
  orgStructureItems?: OrganizationStructureItem[];
  selectedItem: OrganizationStructureItem | undefined;
  setSelectedItem: Dispatch<SetStateAction<OrganizationStructureItem | undefined>>;
  setClikedOrganizationLevel: Dispatch<SetStateAction<number>>;
  setSideBarOpen: Dispatch<SetStateAction<boolean>>;
  setSideBarVariant: Dispatch<SetStateAction<string | undefined>>;
  title: string;
  localizedTitle: string;
  titleSingular: string;
  setDivisions?: Dispatch<SetStateAction<Division[]>>;
  setDepartments?: Dispatch<SetStateAction<Department[]>>;
  setSections?: Dispatch<SetStateAction<any[]>>;
  setCareunits?: Dispatch<SetStateAction<any[]>>;
  setPhysicalcareunits?: Dispatch<SetStateAction<any[]>>;
  editOrganizationStructureEntity: (orgStructureItemId: number, entityType: string) => void;
}

const OrgStructureItemLayout = ({
  additionalStyles,
  addButtonAction,
  clickedOrganizationLevel,
  disabled,
  editButtonAction,
  entityType,
  isArrowHidden,
  isMaxLevel,
  oraganizationStructureLevel,
  orgStructureItems,
  selectedItem,
  setSelectedItem,
  setClikedOrganizationLevel,
  setSideBarOpen,
  setSideBarVariant,
  setDivisions,
  setDepartments,
  setSections,
  setCareunits,
  setPhysicalcareunits,
  title,
  localizedTitle,
  titleSingular,
  deleteOrganizationStructureEntity,
  editOrganizationStructureEntity,
}: OrgStructureItemLayoutProps) => {
  const onAddIconClick = () => {
    setSideBarOpen(true);
    setSideBarVariant(addButtonAction);
  };

  const orgStructureLevel = [
    "careprovider",
    "divisions",
    "departments",
    "sections",
    "careunits",
    "careunitgroup",
  ] as const;

  const handleOrgStructureItemClick = async (item: OrganizationStructureItem) => {
    if (/*!item.disabled &&*/ item.id !== selectedItem?.id) {
      setSelectedItem(item);
      const nextOrgLevel = orgStructureLevel[oraganizationStructureLevel];

      if (title == "Care Unit Group") {
        await OrgService.getOrgAsync(`${CARE_UNIT_ENDPOINT}/${item.id}`)
          .then((res) => {
            const orgArray: any[] = res;
            if (res.length > 0) {
              if (setCareunits) setCareunits(orgArray);
            } else {
              if (setCareunits) setCareunits([]);
            }
          })
          .catch((err) => {
            throw err;
          });
      } else if (title == "Section") {
        await OrgService.getOrgAsync(`${PHYSICAL_CARE_UNIT_ENDPOINT}/${item.id}`)
          .then((res) => {
            const orgArray: any[] = res;
            if (res.length > 0) {
              if (setPhysicalcareunits) setPhysicalcareunits(orgArray);
            } else {
              if (setPhysicalcareunits) setPhysicalcareunits([]);
            }
          })
          .catch((err) => {
            throw err;
          });
      } else if (title == "Care Provider" || title == "Division" || title == "Department") {
        await OrgService.getOrgAsync(`api/${nextOrgLevel}`)
          .then((res) => {
            const orgArray: any[] = res;
            if (res.length > 0) {
              switch (title) {
                case "Care Provider": {
                  const userDivisions = orgArray.filter(({ hospitalId }) => hospitalId == item.id);
                  if (setDivisions) setDivisions(userDivisions);
                  break;
                }
                case "Division": {
                  const userDepartments = orgArray.filter(({ divisionId }) => divisionId == item.id);
                  if (setDepartments) setDepartments(userDepartments);
                  break;
                }
                case "Department": {
                  const userSections = orgArray.filter(({ departmentId }) => departmentId == item.id);
                  if (setSections) setSections(userSections);
                  break;
                }
                default:
                  break;
              }
            }
          })
          .catch((err) => {
            throw err;
          });
      }

      if (clickedOrganizationLevel !== oraganizationStructureLevel + 1)
        setClikedOrganizationLevel(oraganizationStructureLevel + 1);
    }
  };

  useEffect(() => {
    // To unselect items when the user change the selection
    if (oraganizationStructureLevel + 1 > clickedOrganizationLevel) {
      setSelectedItem(undefined);
    }
  }, [clickedOrganizationLevel]);

  return (
    <div className={`orgstructureitemlayout ${disabled && "orgstructureitemlayout--disabled"}`}>
      <header>
        <div className="orgstructureitemlayout__headline-container">
          <h3 className="heading-3 orgstructureitemlayout__headline">{localizedTitle}</h3>
          <div>
            <IconButtonSmall onClick={onAddIconClick} />
          </div>
        </div>
        <hr className="orgstructureitemlayout__divider" />
      </header>

      <div className="orgstructureitemlayout-items">
        {orgStructureItems?.map((item) => (
          <OrgStructureItem
            key={uuid()}
            disabled={item.disabled}
            editButtonAction={editButtonAction}
            entityType={entityType}
            isArrowHidden={isArrowHidden}
            isMaxLevel={isMaxLevel}
            isExpanded={item.id === selectedItem?.id}
            item={item}
            onClick={handleOrgStructureItemClick}
            setSideBarOpen={setSideBarOpen}
            editOrganizationStructureEntity={editOrganizationStructureEntity}
            setSideBarVariant={setSideBarVariant}
            type={title}
            titleSingular={titleSingular}
            deleteOrganizationStructureEntity={deleteOrganizationStructureEntity}
          />
        ))}
      </div>
    </div>
  );
};

export default OrgStructureItemLayout;
