import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input } from "../../../../../components";
import { useTranslation } from "react-i18next";
import ControlIcon from "../../../Components/ControlIcon/ControlIcon";
import { ControlIconTypes } from "../../../Components/ControlIcon/IconTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import useGetHistoricQueueCuttingPlan from "../../Hooks/useGetHistoricQueueCuttingPlan";
import "./QueueDetailsSidePanel.css";
import { QueueDetailSidePanelProps } from "../../../../../interfaces/props";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { ToastContainer, toast } from "react-toastify";
import NotificationBox from "../../../../../components/NotificationBox/NotificationBox";
import { AxiosResponse } from "axios";
import OutpatientPlanService from "../../../../../services/outpatientPlanService";
import { OutpatientPlanDataResponse } from "../../../../../interfaces/responses/outpatient-plan-data-response";
import {
  OutpatientPlanDataRequest,
  OutpatientPlanPayload,
  OutpatientPlanRequest,
} from "../../../../../interfaces/productivity/outpatient-plan-data-props";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";

const nextIcon = <ControlIcon type={ControlIconTypes.next} />;
const prevIcon = <ControlIcon type={ControlIconTypes.perv} />;

const QueueDetailsSidePanel = ({
  selectedTabIndex,
  setSelectedTabIndex,
  setNextAvailableTabIndex,
  nextAvailableTabIndex,
  isSaveButtonClicked,
  setIsSaveButtonClicked,
  isOutpatientParametersModifying,
  setIsOutpatientParametersModifying,
  isTabBarClicked,
  setIsTabBarClicked,
  clickedTabIndex,
}: QueueDetailSidePanelProps) => {
  const { t: translate } = useTranslation();
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const [queueCuttingReductionGoalDateError, setQueueCuttingReductionGoalDateError] = useState<string | null>(null);
  const [isSaveConfirmationModalBoxOpen, setIsSaveConfirmationModalBoxOpen] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const isDetailPlanFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.DetailedPlanMultiSelect);
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.QueueDetailsMultiSelect);

  const locale = localStorage.getItem("i18nextLng") === "en" ? "en" : "sv-SE";

  const [outpatientRequest, setOutpatientRequest] = useState<OutpatientPlanPayload>({
    productionPlan: 0,
    queueCuttingPlan: 0,
    id: 0,
    queueCuttingReductionGoalDate: null,
    overloadPlanAverage: 0,
  });

  const [outpatientRequestBeforeEdit, setOutpatientRequestBeforeEdit] = useState<OutpatientPlanPayload>({
    productionPlan: 0,
    queueCuttingPlan: 0,
    id: 0,
    queueCuttingReductionGoalDate: null,
    overloadPlanAverage: 0,
  });

  const saveOutpatientPlanData = async (): Promise<boolean> => {
    isSaveConfirmationModalBoxOpen ? setIsSaveConfirmationModalBoxOpen(false) : null;

    if (outpatientRequest.queueCuttingReductionGoalDate) {
      const date = new Date(outpatientRequest.queueCuttingReductionGoalDate);

      if (date.getFullYear() !== year) {
        setQueueCuttingReductionGoalDateError(`${translate("goal_date_has_to_be_within")} ${year}`);
        return false;
      } else {
        setQueueCuttingReductionGoalDateError(null);
      }
    }

    const chartData: OutpatientPlanDataRequest = {
      id: outpatientRequest.id,
      careUnitId: careUnitId || 0,
      queueCuttingPlan: outpatientRequest.queueCuttingPlan,
      productionPlan: outpatientRequest.productionPlan,
      queueCuttingReductionGoalDate: outpatientRequest.queueCuttingReductionGoalDate,
    };

    const data: OutpatientPlanRequest = {
      year: year,
      scenarioId: scenario,
      updateOutPatintPlanDto: chartData,
    };

    let response = true;
    if (queueCuttingReductionGoalDateError === null) {
      const toastId = toast(
        <NotificationFloat varaint="loading" content={translate("saving_outpatient_parameters")} />,
        {
          icon: false,
          className: "custom-toast",
          containerId: "notificationBox",
          autoClose: false,
        }
      );

      setIsOutpatientParametersModifying(true);

      await OutpatientPlanService.saveProductionPlan(data)
        .then(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="success" content={translate("data_save_success_message")} />,
            type: "success",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = true;
        })
        .catch(() => {
          toast.update(toastId, {
            render: <NotificationFloat varaint="error" content={translate("something_went_wrong")} />,
            type: "error",
            autoClose: 3000,
          });
          setIsSaveButtonClicked(false);
          setIsOutpatientParametersModifying(false);
          response = false;
        });
    }

    return response;
  };

  const fetchOutpatientPlan = async (careUnitId: number) => {
    try {
      //setIsOutpatientPlanLoading(true);

      const res: AxiosResponse<OutpatientPlanDataResponse> = await OutpatientPlanService.getOutpatientPlan(
        careUnitId,
        year,
        scenario
      );

      if (res.data.id === 0) {
        setOutpatientRequest({
          productionPlan: 0,
          queueCuttingPlan: 0,
          id: 0,
          queueCuttingReductionGoalDate: null,
          overloadPlanAverage: res.data.overloadPlanAverage,
        });
      } else {
        //setIsDefaultValue(false);
        res.data.productionOutpatientPlan = res.data.productionOutpatientPlan
          ? res.data.productionOutpatientPlan * 100 || 0
          : 0;
        setOutpatientRequest(res.data);
        setOutpatientRequestBeforeEdit(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (careUnitId !== null && careUnitId > 0 && year > 0 && scenario > 0) {
      fetchOutpatientPlan(careUnitId);
    }
  }, [careUnitId, selectedTabIndex, year, scenario]);

  useEffect(() => {
    if (isSaveButtonClicked && (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2)) {
      saveOutpatientPlanData();
    }
  }, [isSaveButtonClicked]);

  useEffect(() => {
    if (isTabBarClicked) {
      if (isEdited) {
        setIsSaveConfirmationModalBoxOpen(true);
      } else {
        setSelectedTabIndex(() => clickedTabIndex);
        setIsTabBarClicked(false);
      }
    }
  }, [isTabBarClicked]);

  const handleProductionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdited(true);
    const { name, value } = e.target;

    if (value === "") {
      setOutpatientRequest((prevState) => {
        return {
          ...prevState,
          [name]: parseInt("0"),
        };
      });
    } else {
      setOutpatientRequest((prevState) => {
        return {
          ...prevState,
          [name]: parseInt(value),
        };
      });
    }
  };

  const displayOutpatientPlanSaveNotification = async (nextTabIndex: number) => {
    if (isEdited && (isDetailPlanFeatureFlagEnabled ? selectedTabIndex === 3 : selectedTabIndex === 2)) {
      if (
        !isFeatureFlagEnabled &&
        (outpatientRequest.queueCuttingPlan !== outpatientRequestBeforeEdit.queueCuttingPlan ||
          outpatientRequest.queueCuttingReductionGoalDate !== outpatientRequestBeforeEdit.queueCuttingReductionGoalDate)
      ) {
        setNextAvailableTabIndex(() => nextTabIndex);
        setIsSaveConfirmationModalBoxOpen(true);
      } else {
        setNextAvailableTabIndex(() => nextTabIndex);
        setIsSaveConfirmationModalBoxOpen(true);
      }
    } else {
      setSelectedTabIndex(() => nextTabIndex);
    }
  };

  const handleNotificationBoxSaveButtonClick = async () => {
    if (selectedTabIndex === 3) {
      await saveOutpatientPlanData();

      if (isTabBarClicked) {
        setIsTabBarClicked(false);
        setSelectedTabIndex(() => clickedTabIndex);
      } else {
        setSelectedTabIndex(nextAvailableTabIndex);
      }
    }
  };

  const handleNotificationBoxDiscardSaveButtonClick = () => {
    setIsSaveConfirmationModalBoxOpen(false);
    setOutpatientRequest(outpatientRequestBeforeEdit);

    if (isTabBarClicked) {
      setIsTabBarClicked(false);
      setSelectedTabIndex(() => clickedTabIndex);
    } else {
      setSelectedTabIndex(nextAvailableTabIndex);
    }
  };

  const getNumberOfDelayedUpToDate = () => {
    const currentDateObj = new Date();
    currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() % 7));
    return currentDateObj;
  };

  const historicQueueCuttingPlan = useGetHistoricQueueCuttingPlan(careUnitId);

  const toISOIgnoreTimezone = (inputDate: Date) => {
    return (
      inputDate.getFullYear() +
      "-" +
      ("0" + (inputDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + inputDate.getDate()).slice(-2) +
      "T00:00:00.000Z"
    );
  };

  const handleProductionQueueEndDateChange = (date: Date) => {
    setIsEdited(true);
    setOutpatientRequest((prevState) => {
      return {
        ...prevState,
        ["queueCuttingReductionGoalDate"]: date,
      };
    });
  };

  return (
    <div className="info-wrapper">
      <ToastContainer
        containerId={"notificationBox"}
        style={{ width: "400px" }}
        position="top-right"
        hideProgressBar={true}
        closeButton={false}
      />
      {isSaveConfirmationModalBoxOpen ? (
        <NotificationBox
          content={translate("do_you_want_to_save_changes")}
          title={translate("save_changes")}
          key={12}
          leftButtonLabel={translate("yes")}
          rightButtonLabel={translate("no")}
          variant="primary"
          onLeftButtonClick={handleNotificationBoxSaveButtonClick}
          onRightButtonClick={handleNotificationBoxDiscardSaveButtonClick}
          isLeftButtonDisabled={false}
          isRightButtonDisabled={false}
        />
      ) : null}
      <div className="px-4">
        <div className="sidearea__text--container--v4">
          <h1 className="sidearea__text--title--v4">
            {translate("number_of_delayed_visits")} <br />
            {getNumberOfDelayedUpToDate().toLocaleDateString(locale)}
          </h1>
          <p className="sidearea__text--value--v4">{historicQueueCuttingPlan.data}</p>
        </div>
      </div>
      <hr />
      <div className="input-area">
        <span className="graph-type-selector p-1-v-2 mt-3 block">{translate("backlog_decrease_plan")}</span>
        <div className="mb-4">
          <Input
            name="queueCuttingPlan"
            value={outpatientRequest?.queueCuttingPlan?.toString()}
            handleInputChange={handleProductionInputChange}
            placeholder={translate("number_of_visits_backlog")}
            disabled={isCareUnitReadOnly || isOutpatientParametersModifying ? true : false}
          />
        </div>
        <div className="mb-4">
          <DatePicker
            placement="bottom"
            handleInputChange={handleProductionQueueEndDateChange}
            id="queueCuttingReductionGoalDate"
            name="queueCuttingReductionGoalDate"
            placeholder={translate("queue_cutting_reduction_goal_date")}
            value={toISOIgnoreTimezone(
              outpatientRequest?.queueCuttingReductionGoalDate
                ? new Date(outpatientRequest?.queueCuttingReductionGoalDate)
                : new Date()
            )}
            error={queueCuttingReductionGoalDateError}
          />
        </div>

        <div className="mt-4 flex w-full items-center gap-4">
          <Button
            onClick={() => {
              setQueueCuttingReductionGoalDateError(null);
              displayOutpatientPlanSaveNotification(selectedTabIndex - 1);
            }}
            text={translate("prev")}
            variant={"button--secondary-optional"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
            icon={prevIcon}
            disabled={
              ((isDetailPlanFeatureFlagEnabled && selectedTabIndex === 3) ||
                (!isDetailPlanFeatureFlagEnabled && selectedTabIndex === 2)) &&
              isOutpatientParametersModifying
            }
          />

          <Button
            onClick={() => {
              setQueueCuttingReductionGoalDateError(null);
              displayOutpatientPlanSaveNotification(selectedTabIndex + 1);
            }}
            text={translate("next")}
            variant={"button--secondary-optional-with-border"}
            additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem] float-right"}
            icon={nextIcon}
            disabled={
              ((isDetailPlanFeatureFlagEnabled && selectedTabIndex === 3) ||
                (!isDetailPlanFeatureFlagEnabled && selectedTabIndex === 2)) &&
              isOutpatientParametersModifying
            }
          />
        </div>
      </div>
    </div>
  );
};

export default QueueDetailsSidePanel;
