import Handsontable from "handsontable";
import { useContextMenuReturnType } from "../../../../interfaces/analytics/handsontable/use-contextmenu-return-type";

const useContextMenu = (): useContextMenuReturnType => {
  function disableRemoveRow(this: Handsontable): boolean {
    const [startRow, , endRow] = this.getSelectedLast() as number[];

    const lastRow = this.countRows() - 1;

    if (startRow === lastRow || endRow === lastRow) {
      return true;
    } else {
      return false;
    }
  }

  function disableRowBelow(this: Handsontable): boolean {
    // disabled if the row is last row
    const [startRow, , endRow] = this.getSelectedLast() as number[];

    const lastRow = this.countRows() - 1;

    if (endRow > 0) {
      if (startRow === lastRow || endRow === lastRow) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return { disableRemoveRow, disableRowBelow };
};

export default useContextMenu;
