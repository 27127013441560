import { useEffect, useState } from "react";
import { ICDAnalysisChartData } from "../../../../interfaces/analytics/icdAnalysis/icdAnalysisChartData";
import { ICDAnalysisRequest } from "../../../../interfaces/analytics/icdAnalysis/icdAnalysisRequest";
import ICDAnalysisService from "../../../../services/icdAnalysisService";

const useChartData = (icdAnalysisRequest: ICDAnalysisRequest | null) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ICDAnalysisChartData[]>([]);

  useEffect(() => {
    const fetchData = async (icdAnalysisRequest: ICDAnalysisRequest) => {
      setIsLoading(true);

      const icdAnalysis = await ICDAnalysisService.fetchIcdAnalysisChartData(icdAnalysisRequest);

      setData(icdAnalysis);
      setIsLoading(false);
    };

    if (icdAnalysisRequest) {
      fetchData(icdAnalysisRequest);
    }
  }, [icdAnalysisRequest]);

  return { data, isLoading };
};

export default useChartData;
