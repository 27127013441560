import { HotTableClass as HotTable } from "@handsontable/react";
import { CellChange } from "handsontable/common";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, DataTableAddButton, UploadButton } from "../../../components";
import { Agreement } from "../../../interfaces/parameters/Agreement";
import { Compensation } from "../../../interfaces/parameters/Compensation";
import { Competence } from "../../../interfaces/parameters/Competence";
import { UploadParamterProps } from "../../../interfaces/parameters/ParameterProps";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import ParameterService from "../../../services/parameterService";
import { AGREEMENT_ENDPOINT, COMPENSATION_ENDPOINT, COMPETENCE_ENDPOINT } from "../../../utils/apiendpoints";
import ExportExcel from "../../../utils/ExportExcel";
import {
  adjustCompensationColumnHeaderColour,
  compensations_data_schema,
} from "../../../utils/handsontable-configuration";
import useGetFormalCompetencesData from "../WorkCompetence/hooks/useGetFormalCompetenceData";
import FormalCompetence from "../../../interfaces/competence/FormalCompetence";
import useGetCompensationGridColumns from "./hooks/useGetCompensationGridColumns";

const CompensationsHome = ({
  setFileAddSuccessNotification,
  setFileAddFailNotification,
  setLoading,
  loadingStat,
  readOnly,
}: UploadParamterProps) => {
  const { t: translate } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<Compensation[]>([]);
  const [rawData, setRawData] = useState<Compensation[]>([]);
  const [saveStatus, setSaveStatus] = useState<boolean>(false);
  const [saveFailedStatus, setSaveFailedStatus] = useState<boolean>(false);
  const [isPercentageMarkAtTheEnd, setIsPercentageMarkAtTheEnd] = useState(false);

  const [tableData, setTableData] = useState<Compensation[]>([]);
  const dataTable = useRef<HotTable>(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  dataTable.current?.__hotInstance?.updateSettings({
    readOnly: readOnly,
  });

  const [agreements, setAgreements] = useState<string[]>([]);
  const [competences, setCompetences] = useState<string[]>([]);

  const [updatedIndexes, setUpdatedIndexes] = useState<number[]>([]);

  const [formalCompetences, setFormalCompetences] = useState<FormalCompetence[]>([]);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const parameterReadOnly = useSelector((state: RootState) => state.permission.parameterReadOnly);
  const formalCompetenceHook = useGetFormalCompetencesData();

  const cols = useGetCompensationGridColumns(
    formalCompetences.map((e) => e.name),
    competences,
    agreements,
    localStorage.getItem("i18nextLng") === "en" || localStorage.getItem("i18nextLng") === "en-US" ? "en-US" : "sv-SE"
  );

  useEffect(() => {
    if (!formalCompetenceHook.isLoading) {
      setFormalCompetences(formalCompetenceHook.data);
    }
  }, [formalCompetenceHook.isLoading]);

  useEffect(() => {
    ParameterService.getParametersAsync(AGREEMENT_ENDPOINT).then((res: Agreement[]) => {
      setAgreements(res.map((e) => e.agreementName));
    });

    ParameterService.getParametersAsync(COMPETENCE_ENDPOINT).then((res: Competence[]) => {
      setCompetences(res.map((e) => e.competenceName));
    });
  }, [tableData]);

  const getParameterData = async () => {
    const compensationsApiEndPoint = COMPENSATION_ENDPOINT + "/" + careUnitId;

    if (setLoading) {
      setLoading("Loading Parameter Data");
      await ParameterService.getParametersAsync(compensationsApiEndPoint)
        .then((res: Compensation[]) => {
          if (res.length > 0) {
            setData(res);
          }
          setLoading("");
        })
        .catch((err) => {
          setLoading("");
          throw err;
        });
    }
  };

  //get compensation data to excels/table
  useEffect(() => {
    getParameterData();
    if (saveStatus === true) setSaveStatus(false);
    if (saveFailedStatus === true) setSaveFailedStatus(false);
  }, [saveStatus, saveFailedStatus, careUnitId]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleOnSaveClick = () => {
    setIsSaveButtonDisabled(true);
    setLoading && setLoading("Saving Parameter Data");
    const filteredTableData = tableData.map(({ ...Keepatrs }) => {
      return {
        ...Keepatrs,
        formalCompetenceId:
          Keepatrs.formalCompetenceName !== ""
            ? formalCompetences.find((r) => r.name == Keepatrs.formalCompetenceName)?.id
            : null,
      };
    });

    const uniqueUpdatedIndexes = updatedIndexes.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    if (careUnitId !== null) {
      ParameterService.saveCompensationsByCareUnitIdAsync(filteredTableData, uniqueUpdatedIndexes, careUnitId)
        .then(() => {
          setFileAddSuccessNotification && setFileAddSuccessNotification("Compensation Data Saved Successfully");
          setLoading && setLoading("");
          setSaveStatus(true);
          setUpdatedIndexes([]);
        })
        .catch((err) => {
          setFileAddFailNotification && setFileAddFailNotification("Something went wrong");
          setLoading && setLoading("");
          setSaveFailedStatus(true);
          throw err;
        });
    }
  };

  //handle the updated row indexes
  const handleUpdatedIndexes = (changes: CellChange[]) => {
    if (changes !== null) {
      changes.forEach(([row]) => {
        const physicalRowIndex = dataTable.current?.__hotInstance?.toPhysicalRow(row);
        if (physicalRowIndex != undefined) {
          setUpdatedIndexes((prevArray) => [...prevArray, physicalRowIndex + 1]);
        }
      });
    }
  };

  //upload compensation parameter excels
  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      await ParameterService.uploadExcelAsync(file[0], COMPENSATION_ENDPOINT)
        .then(() => {
          if (setFileAddSuccessNotification) setFileAddSuccessNotification("File Saved Successfully");
        })
        .catch((err) => {
          if (setFileAddFailNotification && err.response.data)
            setFileAddFailNotification(err.response.data.ErrorMessage);
        });
      e.target.value = "";
    } else setFileAddFailNotification && setFileAddFailNotification("file is not selected");
  };

  const uploadCompensationExcel = () => {
    inputRef.current?.click();
  };

  return (
    <GridLayout additionalStyles="mt-0 border-2 rounded-r rounded-b h-[86vh] px-0 bg-[#F8F8F8]">
      <div className="col-start-1 col-end-13 ">
        <div className="flex items-center justify-between bg-white px-2 py-2">
          <div className="col-start-1 col-end-7 items-center justify-between gap-3"></div>
          <div className="col-start-7 col-end-13 flex">
            <div className="items-center justify-between gap-3">
              <ExportExcel apiData={rawData} fileName={"Compensation"} disabled={loadingStat !== ""} />
            </div>

            {!readOnly && (
              <>
                <div className="mx-4 items-center justify-between gap-3">
                  <UploadButton text={translate("UploadData")} onClick={uploadCompensationExcel} />
                  <input hidden ref={inputRef} type="file" onInput={onFileInputChange} />
                </div>
                <div className="items-center justify-between gap-3">
                  <Button
                    onClick={handleOnSaveClick}
                    text={"Save Changes"}
                    variant={"primary"}
                    disabled={isSaveButtonDisabled}
                    additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="relative px-2">
          <HotTable
            language={localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE"}
            numericFormat={{
              culture: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
              pattern: {},
            }}
            data={tableData}
            dataSchema={compensations_data_schema}
            cells={() => {
              return {
                className: "htMiddle",
              };
            }}
            columns={cols.map((r) => r.meta)}
            colHeaders={cols.map((r) => r.headerTitle)}
            colWidths={cols.map((r) => r.width)}
            ref={dataTable}
            readOnly={parameterReadOnly ? parameterReadOnly : false}
            licenseKey="non-commercial-and-evaluation"
            contextMenu={
              readOnly === false
                ? ["row_above", "row_below", "remove_row", "alignment", "copy", "cut"]
                : ["alignment", "copy"]
            }
            height="79vh"
            rowHeights={35}
            fixedColumnsLeft={3}
            afterGetColHeader={adjustCompensationColumnHeaderColour}
            rowHeaders={true}
            rowHeaderWidth={30}
            columnSorting={true}
            manualRowMove={true}
            filters={true}
            dropdownMenu={["filter_by_condition", "filter_action_bar", "filter_by_value"]}
            beforeChange={(changes, source) => {
              if (changes?.length) {
                changes.forEach((change) => {
                  if (change) {
                    const [row, prop, , newValue] = change;
                    const hotInstance = dataTable.current?.__hotInstance;

                    if (hotInstance) {
                      const columnIndex = hotInstance.propToCol(prop as number) as number;
                      const cellProperties = hotInstance.getCellMeta(row, columnIndex);

                      if (cellProperties?.type === "numeric" && newValue?.toString().endsWith("%")) {
                        change[3] = parseFloat(newValue) / 100.0;
                      }
                    }
                  }
                });
              }
            }}
            afterSelection={(row, column) => {
              const hotInstance = dataTable.current?.__hotInstance;

              if (hotInstance) {
                hotInstance.batch(() => {
                  const selectedCell = hotInstance.getCell(row, column)?.innerText;
                  if (selectedCell !== null && selectedCell?.toString().endsWith("%")) {
                    setIsPercentageMarkAtTheEnd(true);
                  } else {
                    setIsPercentageMarkAtTheEnd(false);
                  }
                });
              }
            }}
            afterBeginEditing={() => {
              if (isPercentageMarkAtTheEnd === true) {
                // eslint-disable-next-line prefer-const
                let currentEditor = dataTable.current?.hotInstance?.getActiveEditor() as any;

                currentEditor.TEXTAREA.value = "%";
                currentEditor.TEXTAREA.setSelectionRange(0, 0);
                currentEditor.TEXTAREA.focus();
                setIsPercentageMarkAtTheEnd(false);
              }
            }}
            afterChange={(changes, source) => {
              if (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste") {
                dataTable.current?.hotInstance?.validateCells((valid) => {
                  if (valid) {
                    setIsSaveButtonDisabled(false);
                    changes && handleUpdatedIndexes(changes);
                  } else {
                    setIsSaveButtonDisabled(true);
                  }
                });
              }
            }}
            afterCreateRow={() => {
              setIsSaveButtonDisabled(false);
            }}
            afterRemoveRow={() => {
              setIsSaveButtonDisabled(false);
            }}
            afterRowMove={() => {
              setIsSaveButtonDisabled(false);
            }}
          />
          <DataTableAddButton dataTable={dataTable} />
        </div>
      </div>
    </GridLayout>
  );
};

export default CompensationsHome;
