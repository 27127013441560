import React, { useState } from "react";
import { ToolTipType } from "../../interfaces";

interface NavBarIconButtonProps {
  isActive: boolean;
  toolTipType: ToolTipType;
  onClick: (type: ToolTipType) => void;
}

const NavBarIconButton = ({
  isActive,
  toolTipType,
  onClick,
}: NavBarIconButtonProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick(toolTipType)}
      className={`group flex items-center justify-center  gap-2 rounded-full border-2 border-[#D1D1D1] hover:bg-[#D1D1D1]  active:border-[#00283C]  active:bg-[#00283C] lg:h-[2.25rem] lg:w-[2.25rem]  xl:h-[2.5rem] xl:w-[2.5rem] ${
        isActive && !isHovered && "border-[#00283C] bg-[#00283C]"
      }`}
    >
      {toolTipType === "parameters" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21.599 21.605"
          className={`fill-[#010101] ${
            isActive && !isHovered && "fill-white"
          } group-active:fill-white lg:h-[1.125rem] lg:w-[1.125rem] xl:h-[1.375rem] xl:w-[1.375rem]`}
        >
          <path
            id="Settings"
            d="M24.316,15.3A2.779,2.779,0,0,1,26.1,12.707a11.017,11.017,0,0,0-1.333-3.212,2.817,2.817,0,0,1-1.131.242,2.773,2.773,0,0,1-2.537-3.9A10.984,10.984,0,0,0,17.893,4.5a2.777,2.777,0,0,1-5.186,0A11.017,11.017,0,0,0,9.495,5.833a2.773,2.773,0,0,1-2.537,3.9,2.725,2.725,0,0,1-1.131-.242A11.26,11.26,0,0,0,4.5,12.712,2.779,2.779,0,0,1,4.506,17.9,11.017,11.017,0,0,0,5.839,21.11,2.774,2.774,0,0,1,9.5,24.772,11.081,11.081,0,0,0,12.712,26.1a2.772,2.772,0,0,1,5.175,0A11.017,11.017,0,0,0,21.1,24.772,2.777,2.777,0,0,1,24.76,21.11,11.081,11.081,0,0,0,26.094,17.9,2.793,2.793,0,0,1,24.316,15.3ZM15.35,19.794a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,15.35,19.794Z"
            transform="translate(-4.5 -4.5)"
          />
        </svg>
      )}

      {toolTipType === "usersettings" && (
        <svg
          className={`fill-[#010101] group-active:fill-white lg:h-[0.766rem] lg:w-[0.766rem] xl:h-[1.125rem] xl:w-[1.125rem] ${
            isActive && !isHovered && "fill-white"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17.274 17.274"
        >
          <path
            id="User"
            d="M8.637,9.717A4.858,4.858,0,1,0,3.779,4.858,4.86,4.86,0,0,0,8.637,9.717Zm4.319,1.08H11.1a5.873,5.873,0,0,1-4.919,0H4.319A4.318,4.318,0,0,0,0,15.115v.54a1.62,1.62,0,0,0,1.619,1.619H15.655a1.62,1.62,0,0,0,1.619-1.619v-.54A4.318,4.318,0,0,0,12.956,10.8Z"
          />
        </svg>
      )}
    </div>
  );
};

export default NavBarIconButton;
