import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { GridLayout } from "../../../layouts";

const SurgeryPlan = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  
    const { t: translate, i18n } = useTranslation();
  
    const tabs = [
      translate("Demand"),
      translate("Phasing"),
      translate("Workload"),
      translate("Result"),
    ];
  
    const handleTabbarClick = (clickedIndex: number) => {
      setSelectedTabIndex(() => clickedIndex);
    };

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(true);
  
    return (
        <GridLayout additionalStyles="mt-0 px-0">
          <div className="col-start-1 col-end-13">
            <div className="flex items-center justify-between px-2 mt-0">
              <TabbarTeritary tabs={tabs} onClick={handleTabbarClick}/>
    
              <div className="my-2">
                <Button
                  disabled={isSaveButtonDisabled}
                  text={"Save Changes"}
                  variant={"primary"}
                  additionalStyles={
                    "xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"
                  }
                />
              </div>
            </div>
            <div className="mt-0 pt-4 px-2 bg-[#F8F8F8] lg:h-[79vh] xl:h-[80vh]">
            </div>
          </div>
        </GridLayout>
      );
  };
  
  export default SurgeryPlan;