import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import IUserError from "../../interfaces/IUserError";
import IUserLogin from "../../interfaces/IUserLogin";

import { Button, Checkbox, Input, LoadingWithColor } from "../../../../components";
import { AuthForm } from "../../layouts";
import { AuthFormValidator } from "../../utils/validators";

import { useDispatch } from "react-redux";
import {
  login,
  setCareProviderId,
  setCareUnitReadOnly,
  setGlobalFilter,
  setGlobalFilterIsLoadingTrue,
  setGlobalSettingEnabled,
  setParameterReadOnly,
  setUserManagmentEnabled,
} from "../../../../redux/store";
import AuthService from "../../../../services/authService";
import { useNavigate } from "react-router-dom";
import NotificationFloat from "../../../../components/NotificationFloat/NotificationFloat";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const [user, setUser] = useState<IUserLogin>({
    email: "",
    password: "",
    isPersistent: false,
  });

  const [formError, setFormError] = useState<IUserError>({
    emailError: "",
    passwordError: "",
  });

  const [emailSuccess, setEmailSuccess] = useState("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isUserRemeber, setIsUserRemember] = useState<boolean>(false);

  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const authFormValidator = new AuthFormValidator();

    const isEmailEmpty = authFormValidator.isEmptyString(user.email);
    const isPasswordEmpty = authFormValidator.isEmptyString(user.password);

    if (isEmailEmpty) {
      setFormError((prevState) => ({
        ...prevState,
        emailError: translate("email_empty_error"),
      }));
    } else {
      setFormError((prevState) => ({
        ...prevState,
        emailError: "",
      }));
    }

    if (isPasswordEmpty) {
      setFormError((prevState) => ({
        ...prevState,
        passwordError: translate("password_empty_error"),
      }));
    } else {
      setFormError((prevState) => ({
        ...prevState,
        passwordError: "",
      }));
    }

    if (isEmailEmpty || isPasswordEmpty) {
      setIsLoading(false);
      return;
    }

    user.isPersistent = isUserRemeber;

    await AuthService.loginAsync(user)
      .then((res) => {
        dispatch(setGlobalFilterIsLoadingTrue());
        localStorage.setItem("user", JSON.stringify(res));

        dispatch(setGlobalFilter());

        dispatch(login(res));
        const role = res.roles[0].role;

        //set parameter permissions
        const parameterReadOnly = role === "Care Provider Admin" || role === "Global Admin" ? false : true;

        dispatch(setParameterReadOnly(parameterReadOnly));

        //set user managment access permissions
        const userManagmentEnabled = role === "Global Admin" || role === "Care Provider Admin" ? true : false;

        dispatch(setUserManagmentEnabled(userManagmentEnabled));

        //set global setting access permissions
        const globalSettingEnabled = role === "Global Admin" || role === "Global Reader" ? true : false;

        dispatch(setGlobalSettingEnabled(globalSettingEnabled));

        //set global setting access permissions
        const careUnitReadOnly = role === "Global Admin" ? false : true;

        dispatch(setCareUnitReadOnly(careUnitReadOnly));

        //set care provider id if user is not a global admin/user
        const parentCareUnitId = res.roles[0].parentCareUnitId;

        if (role !== "Global Admin") {
          dispatch(setCareProviderId(parentCareUnitId));
        }

        navigate("/", { replace: true });
      })
      .catch((err) => {
        setLoginFailed(true);
        setIsLoading(false);
        throw err;
      });

    setIsLoading(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;

    /*
     * To remove the previous errors
     */

    if (name === "email" && formError.emailError.length > 0) {
      setFormError((prevState) => ({
        ...prevState,
        emailError: "",
      }));
    }

    if (name === "password" && formError.passwordError.length > 0) {
      setFormError((prevState) => ({
        ...prevState,
        passwordError: "",
      }));
    }
  };

  return (
    <AuthForm title={translate("login")}>
      {isLoading ? (
        <>
          <div className="empty-loading-container" />
          <LoadingWithColor sizeInPixel={50} additionalStyles="login-loader" />
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Input
            name="email"
            value={user.email}
            placeholder={translate("email") + " *"}
            handleInputChange={onChange}
            error={formError.emailError}
            success={emailSuccess}
            onFocus={onInputFocus}
            type="email"
          />
          <Input
            name="password"
            value={user.password}
            handleInputChange={onChange}
            placeholder={translate("password") + " *"}
            error={formError.passwordError}
            onFocus={onInputFocus}
            type="password"
          />

          <div className="login__utilities">
            <div className="login__remember p-2-v-1">
              <Checkbox checked={isUserRemeber} setChecked={setIsUserRemember} />
              <span className={`link ${!isUserRemeber && "link--disabled"}`}>{translate("remember_me")}</span>
            </div>

            <div className="login_forgotpw">
              <span
                className="login-forgotpw__text link p-2-v-1"
                onClick={() => {
                  navigate("/auth/forgot-password");
                }}
              >
                {translate("forgot_password") + " ?"}
              </span>
            </div>
          </div>

          {loginFailed && <NotificationFloat varaint="error" content={translate("invalid_email_pw")} />}

          <Button
            variant="primary"
            text={translate("login")}
            type="submit"
            disabled={isLoading}
            additionalStyles="authform__button"
          />
        </form>
      )}
    </AuthForm>
  );
};

export default Login;
