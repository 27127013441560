import React from "react";
import "./user-management-icon-button.css";

interface UserManagementIconButtonProps {
  onClick: (e: React.FormEvent) => void;
  icon: JSX.Element;
  type: string;
}

const UserManagementIconButton = ({
  onClick,
  icon,
  type,
}: UserManagementIconButtonProps) => {
  return (
    <button
      className={
        type === "edit" ? `edit-button-small` : `delete-button-small mr-5`
      }
      onClick={onClick}
    >
      {icon}
    </button>
  );
};

export default UserManagementIconButton;
