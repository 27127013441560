import React, { useEffect, useState } from "react";
import CareUnitSelector from "../CareUnitSelector/CareUnitSelector";
import Select from "../Select/Select";
import { ISelectOption } from "../../interfaces";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState, setFilterScenario, setFilterYear } from "../../redux/store";
import ScenarioService from "../../services/scenarioService";
import { ScenarioRead } from "../../interfaces/parameters/scenario";
import SettingsService from "../../services/settingsService";

const GlobalFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const careProviderId = useSelector((state: RootState) => state.globalFilter.careProviderId);
  const loginState = useSelector((state: RootState) => state.auth.isLoggedIn);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [scenarioOptions, setScenarioOptions] = useState<ISelectOption[]>([]);
  const [selectedTypeOfScenario, setSelectedTypeOfScenario] = useState<ISelectOption>();

  const [yearOptions, setYearOptions] = useState<ISelectOption[]>([]);
  const [selectedTypeOfYear, setSelectedTypeOfYear] = useState<ISelectOption>();

  useEffect(() => {
    getScenario();
    getYears();
  }, [loginState, careProviderId]);

  const getScenario = async () => {
    const scenarioList: ISelectOption[] = [];
    let defaultIndex = 0;

    await ScenarioService.getScenario()
      .then((res: ScenarioRead[]) => {
        res.map((opt: ScenarioRead, index: number) => {
          if (res.length > 0) {
            if (opt.id == scenario) {
              defaultIndex = index;
            }
            scenarioList.push({
              value: opt.id.toString(),
              label: opt.scenarioName,
            });
          }
        });

        setScenarioOptions(scenarioList);
        setSelectedTypeOfScenario(scenarioList[defaultIndex]);
      })
      .catch((err) => {
        throw err;
      });
  };

  const getYears = async () => {
    const yearList: ISelectOption[] = [];
    let defaultIndex = 0;
    await SettingsService.getYears()
      .then((res) => {
        res.map((opt: number, index: number) => {
          if (res.length > 0) {
            if (opt == year) {
              defaultIndex = index;
            }
            yearList.push({
              value: opt.toString(),
              label: opt.toString(),
            });
          }
        });

        setYearOptions(yearList);
        setSelectedTypeOfYear(yearList[defaultIndex]);
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleTypesOfScenarioSelect = (selectedOption: ISelectOption) => {
    setSelectedTypeOfScenario(selectedOption);

    dispatch(setFilterScenario(Number(selectedOption.value)));
  };

  const handleTypesOfYearSelect = (selectedOption: ISelectOption) => {
    setSelectedTypeOfYear(selectedOption);
    dispatch(setFilterYear(Number(selectedOption.value)));
  };

  return (
    <>
      <div className="mr-2 lg:w-36 xl:w-44">
        <Select
          placeholder={translate("year")}
          options={yearOptions}
          onSelectOption={handleTypesOfYearSelect}
          selectedOption={selectedTypeOfYear}
          isSmallDropDownIcon={true}
        />
      </div>
      <div className="mr-2 lg:w-48 xl:w-56">
        <Select
          placeholder={translate("Scenario")}
          options={scenarioOptions}
          onSelectOption={handleTypesOfScenarioSelect}
          selectedOption={selectedTypeOfScenario}
          isSmallDropDownIcon={true}
        />
      </div>
      <CareUnitSelector />
    </>
  );
};

export default GlobalFilter;
