import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "../../../../../components";
import NotificationFloat from "../../../../../components/NotificationFloat/NotificationFloat";
import { DepartmentStateType } from "../../../../../interfaces/orgstructure";
import { DepartmentDto } from "../../../../../interfaces/orgstructure/dtos";
import { DepartmentFormProps } from "../../../../../interfaces/props/org-structure-scrollbar";
import OrgService from "../../../../../services/orgService";
import { ScrollBarConstants } from "../../../utils/constants";

const Department = ({
  selectedDivision,
  setDepartments,
  variant,
  setSuccessNotification,
  setIsScrollBarOpen,
}: DepartmentFormProps) => {
  const { t: translate } = useTranslation();

  const [department, setDepartment] = useState<DepartmentStateType>({
    id: 0,
    name: "",
  });

  const [isAddDepartmentFailed, setIsAddDepartmentFailed] = useState<boolean>(false);

  const handleDepartmentInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setDepartment((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddDepartmentSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const departmentDto: DepartmentDto = {
      departmentDto: {
        departmentName: department.name,
        divisionId: selectedDivision?.id,
      },
    };

    await OrgService.createDepartmentAsync(departmentDto)
      .then((res) => {
        if (setDepartments)
          setDepartments((prevArray) => [
            ...prevArray,
            {
              id: res.id,
              departmentName: department.name,
            },
          ]);
        setDepartment({
          id: 0,
          name: "",
        });
        setIsAddDepartmentFailed(false);
        setIsScrollBarOpen(false);
        setSuccessNotification && setSuccessNotification("Department");
      })
      .catch((err) => {
        setIsAddDepartmentFailed(true);
        throw err;
      });
  };

  return (
    <>
      {isAddDepartmentFailed && (
        <div className="my-3 h-12">
          <NotificationFloat varaint="error" content={translate("something_went_wrong")} />
        </div>
      )}
      <div className="flex h-screen w-full flex-col justify-center">
        <h3 className="heading-3">
          {variant === ScrollBarConstants.ADD_DEPARTMENT ? translate("add_a_new") + " " : translate("edit") + " "}
          {translate("department")}
        </h3>

        <form onSubmit={handleAddDepartmentSubmit}>
          <div>
            <Input
              name="name"
              value={department.name}
              placeholder={translate("name")}
              handleInputChange={handleDepartmentInputChange}
              error=""
              success=""
              type="text"
            />
          </div>

          <Button
            additionalStyles="w-1/2 h-11"
            variant="primary"
            text={ScrollBarConstants.ADD_DEPARTMENT ? translate("save_department") : translate("edit_department")}
          />
        </form>
      </div>
    </>
  );
};

export default Department;
