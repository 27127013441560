import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import ResultService from "../../../../services/resultService";
import { LOSChartData, LOSChartDataYearFormat, losProductionPlanResponse } from "./losTypes";
import { AppInsightService } from "../../../../services";

const LosPlan = ({
  sendLOSHistoricAverage,
}: {
  sendLOSHistoricAverage: (average: number, minDate: string, maxDate: string) => void;
}) => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "LosPlan" });
  }, []);
  const { t: translate } = useTranslation();
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const [losChartData, setLosChartData] = useState<LOSChartData>();
  const [losChartAvgData, setLosAvgChartData] = useState(0);
  const [minDate, setMinDate] = useState<string>("");
  const [maxDate, setMaxDate] = useState<string>("");

  useEffect(() => {
    ResultService.getAverageOfLosPlanChartData(careUnitId).then((res) => {
      processLOSData(res.data);
    });
  }, [careUnitId]);

  useEffect(() => {
    sendLOSHistoricAverage(losChartAvgData, minDate, maxDate);
  }, [losChartAvgData]);

  const processLOSData = (chartData: losProductionPlanResponse[]) => {
    const modifiedChartData: number[] = [];

    const modifiedChartCategories: LOSChartDataYearFormat[] = [];

    chartData.slice().forEach((item) => {
      const minDateResult = item.minDate;
      const maxDateResult = item.maxDate;
      setMaxDate(maxDateResult.toString().slice(0, 10));
      setMinDate(minDateResult.toString().slice(0, 10));
      modifiedChartCategories.push({
        name: item.year,
        categories: item.month,
      });

      item.plan.map((oct) => modifiedChartData.push(oct));
    });

    setLosAvgChartData(modifiedChartData.reduce((sum, el) => (sum += el), 0) / modifiedChartData.length);

    setLosChartData({
      categories: modifiedChartCategories,
      data: modifiedChartData,
    });
  };

  const losPlanOption = {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: losChartData?.categories,
    },
    yAxis: {
      title: {
        text: "",
      },
      plotLines: [
        {
          color: "#426FA2",
          value: losChartAvgData?.toFixed(1),
          customTooltip: true,
          width: "3",
          zIndex: 100,
        },
      ],
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },
    plotOptions: {
      line: {
        animation: false,
        dataLabels: {
          enabled: true,
        },
        lineWidth: 0,
        enableMouseTracking: false,
      },
      series: {
        stickyTracking: false,
        marker: {
          enabled: true,
          size: 20,
        },
      },
    },
    series: [
      {
        name: translate("avg"),
        data: losChartData?.data,
        color: "#ad1e23",
      },
    ],
    annotations: [
      {
        // Text labels
        draggable: "",
        labelOptions: {
          backgroundColor: "#000000",
          shape: "rect",
        },
        labels: [
          {
            point: {
              x: 0,
              y: 2,
              xAxis: 0,
              yAxis: 0,
            },
            text: "Text 1",
          },
        ],
      },
    ],
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={losPlanOption} containerProps={{ style: { height: "100%" } }} />
  );
};

export default LosPlan;
