import React, { Dispatch, SetStateAction } from "react";
import { Button, Select } from "../../../../../components";
import WeekdayPhasingSet from "../WeekdayPhasing/WeekdayPhasing";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "../../../../../interfaces";
import ControlIcon from "../../../Components/ControlIcon/ControlIcon";
import { ControlIconTypes } from "../../../Components/ControlIcon/IconTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { WeekdayPhasing } from "../../../../../interfaces/production-plan";
import { CURRENT_PLAN, FLAT_100 } from "../../../../../utils/constants/inpatient-plan";

const nextIcon = <ControlIcon type={ControlIconTypes.next} />;
const prevIcon = <ControlIcon type={ControlIconTypes.perv} />;

export interface PhasingSidePanel {
  weekDayPhasing: WeekdayPhasing;
  setWeekdayPhasing: Dispatch<SetStateAction<WeekdayPhasing>>;
  fetchCurrentPlanWeeklyPhasingData: (careUnitId: number, year: number, scenario: number) => Promise<void>;
  convertToDefaultPhasingTemplate: () => Promise<void>;
  fetchTemplateWeekdayPhasingData: (templateId: number) => Promise<void>;
  fetchTemplateWeeklyPhasing: (templateId: number) => Promise<void>;
  selectedTemplate: ISelectOption;
  setSelectedTemplate: Dispatch<SetStateAction<ISelectOption>>;
  typeOfTemplates: ISelectOption[];
  displayOutpatientPhasingSaveNotification: (nextIndex: number) => void;
  selectedTabIndex: number;
  isOutpatientParametersModifying: boolean;
}

const PhasingSidePanel = ({
  weekDayPhasing,
  setWeekdayPhasing,
  fetchCurrentPlanWeeklyPhasingData,
  convertToDefaultPhasingTemplate,
  fetchTemplateWeekdayPhasingData,
  fetchTemplateWeeklyPhasing,
  selectedTemplate,
  setSelectedTemplate,
  typeOfTemplates,
  displayOutpatientPhasingSaveNotification,
  selectedTabIndex,
  isOutpatientParametersModifying,
}: PhasingSidePanel) => {
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const { t: translate } = useTranslation();
  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const handleWeekdayPhasingChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    setWeekdayPhasing((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePhasingTemplatesSelect = async (selectedTemplate: ISelectOption) => {
    if (selectedTemplate.value === CURRENT_PLAN && careUnitId) {
      await fetchCurrentPlanWeeklyPhasingData(careUnitId, year, scenario);
    } else if (selectedTemplate.value === FLAT_100) {
      await convertToDefaultPhasingTemplate();
    } else {
      await fetchTemplateWeeklyPhasing(Number(selectedTemplate.value));
      await fetchTemplateWeekdayPhasingData(Number(selectedTemplate.value));
    }
    setSelectedTemplate(selectedTemplate);
  };

  const handleWeekdayPhasingLeftIconClick = (key: string) => {
    const value = Number(weekDayPhasing[key as keyof WeekdayPhasing]);

    if (!(value <= 0)) {
      setWeekdayPhasing((prevState) => {
        return {
          ...prevState,
          [key]: value - 10,
        };
      });
    }
  };

  const handleWeekdayPhasingRightIconClick = (key: string) => {
    const value = Number(weekDayPhasing[key as keyof WeekdayPhasing]);
    const numberToIncrement = value + 10;

    setWeekdayPhasing((prevState) => {
      return {
        ...prevState,
        [key]: numberToIncrement,
      };
    });
  };

  return (
    <>
      <span className="graph-type-selector p-1-v-2 mb-4 mt-3 block">{translate("plan_phasing")}</span>

      <div className="mb-4 mt-4">
        <Select
          placeholder={translate("phasing_templates")}
          options={typeOfTemplates}
          onSelectOption={handlePhasingTemplatesSelect}
          selectedOption={selectedTemplate}
        />
      </div>

      <div className="mt-4">
        <WeekdayPhasingSet
          handleWeekdayPhasingChange={handleWeekdayPhasingChange}
          handleWeekdayPhasingLeftIconClick={
            isCareUnitReadOnly === false ? handleWeekdayPhasingLeftIconClick : () => ""
          }
          handleWeekdayPhasingRightIconClick={
            isCareUnitReadOnly === false ? handleWeekdayPhasingRightIconClick : () => ""
          }
          weekDayPhasing={weekDayPhasing}
          readonly={isCareUnitReadOnly !== null ? isCareUnitReadOnly : false}
        />
      </div>

      <div className="mt-4 flex items-center gap-4">
        <Button
          onClick={() => displayOutpatientPhasingSaveNotification(selectedTabIndex - 1)}
          text={translate("prev")}
          variant={"button--secondary-optional"}
          additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
          icon={prevIcon}
          disabled={isOutpatientParametersModifying}
        />

        <Button
          onClick={() => displayOutpatientPhasingSaveNotification(selectedTabIndex + 1)}
          text={translate("next")}
          variant={"button--secondary-optional-with-border"}
          additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
          icon={nextIcon}
          disabled={isOutpatientParametersModifying}
        />
      </div>
    </>
  );
};

export default PhasingSidePanel;
