import React, { useEffect, useRef, useState } from "react";
import careLevelService from "../../../../services/careLevelService";
import { careLevel } from "../../../../interfaces/analytics/careLevel";
import { HotTableClass as HotTable } from "@handsontable/react";
import { DataTableProps } from "../../../../interfaces/props/data-table-props";
import useCareLevelTargetGridConfigs from "./hooks/useCareLevelTargetGridConfigs";

const CareLevelTargetGrid = () => {
  const [data, setData] = useState<careLevel[]>([]);
  const dataTable = useRef<HotTable>(null);

  const [tableProps, setTableProps] = useState<DataTableProps>({
    ref: dataTable,
  });

  const tableConfiguration = useCareLevelTargetGridConfigs(dataTable);

  useEffect(() => {
    const tableConfigs = tableConfiguration.getConfigs();
    setTableProps(tableConfigs);
  }, [data]);

  useEffect(() => {
    const fetchCareLevelTargetData = async () => {
      await careLevelService
        .getCareLevels()
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchCareLevelTargetData();
  }, []);

  return (
    <div>
      <HotTable
        data={data}
        {...tableProps}
        licenseKey="non-commercial-and-evaluation"
      />
    </div>
  );
};

export default CareLevelTargetGrid;
