import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "../../../components";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import { GridLayout } from "../../../layouts";
import { RootState } from "../../../redux/store";
import { ActivityAnalysisSubmitEventRef } from "../../../interfaces/analytics/activity-analysis-submit-event-ref";
import ActivityAnalysisNotification from "./ActivityAnalysisNotification";
import NotificationBox from "../../../components/NotificationBox/NotificationBox";
import useActivityAnalysisNavigation from "./hooks/useActivityAnalysisNavigation";

import "react-toastify/dist/ReactToastify.css";
import ActivityAnalysisProduction from "./Production/ActivityAnalysisProduction";
import ActivityAnalysisWithinShifts from "./WithinShift/ActivityAnalysisWithinShifts";
import ActivityAnalysisOutsideShifts from "./OutsideShift/ActivityAnalysisOutsideShifts";
import ActivityAnalysisResults from "./Result/ActivityAnalysisResult";

const ActivityAnalysisHome = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isSaveNotificationOpen, setIsSaveNotificationOpen] = useState<boolean>(false);
  const {
    validateData,
    selectedTabIndex,
    setSelectedTabIndex,
    setNextTabIndex,
    resetTheEditStatus,
    redirectToRelavantTab,
    handlePrevButtonClick,
    handleNextButtonClick,
    isEditedRef,
  } = useActivityAnalysisNavigation(setIsSaveNotificationOpen);
  const ref = useRef<ActivityAnalysisSubmitEventRef | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<number>(0);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const year = useSelector((state: RootState) => state.globalFilter.filterYear);

  const tabs = [
    translate("activity_analysis_production"),
    translate("activity_within_shift"),
    translate("activity_analysis_other_activities"),
    translate("results"),
  ];

  const handleTabbarClick = (clickedIndex: number) => {
    const changes = validateData();

    if (changes) {
      setNextTabIndex(clickedIndex);
      setIsSaveNotificationOpen(true);
      return;
    }

    setSelectedTabIndex(clickedIndex);
  };

  const handleSaveButtonClick = async () => {
    await saveData();
    setNextTabIndex(-1);
  };

  const saveData = async () => {
    if (ref.current) {
      if (ref.current.saveProductionData && selectedTabIndex == 0) {
        await ref.current.saveProductionData();
      } else if (ref.current.saveWithinShiftData && selectedTabIndex == 1) {
        await ref.current.saveWithinShiftData();
      } else if (ref.current.saveOutsideShiftData && selectedTabIndex == 2) {
        await ref.current.saveOutsideShiftData();
      }

      resetTheEditStatus();
    }
  };

  const handleAlertDiscardClick = () => {
    setIsSaveNotificationOpen(false);
    redirectToRelavantTab();
    resetTheEditStatus();
  };

  const handleAlertSubmitClick = async () => {
    setIsSaveNotificationOpen(false);
    await saveData();
    redirectToRelavantTab();
  };

  useEffect(() => {
    if (submitStatus > 0) {
      const timer = setTimeout(() => {
        setSubmitStatus(0);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [submitStatus]);

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        {isSaveNotificationOpen && (
          <NotificationBox
            content={translate("do_you_want_to_save_changes")}
            title={translate("save_changes")}
            key={12}
            leftButtonLabel={translate("yes")}
            rightButtonLabel={translate("no")}
            variant="primary"
            onLeftButtonClick={() => {
              handleAlertSubmitClick();
            }}
            onRightButtonClick={() => {
              handleAlertDiscardClick();
            }}
          />
        )}

        <ActivityAnalysisNotification isSubmitting={isSubmitting} submitStatus={submitStatus} />
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} index={selectedTabIndex} />
          <div className="my-2">
            {selectedTabIndex === 3 || selectedTabIndex === 4 ? null : (
              <Button
                onClick={handleSaveButtonClick}
                disabled={isSubmitting}
                text={translate("save_changes")}
                variant={"primary"}
                additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
              />
            )}
          </div>
        </div>

        <div className="mt-0 bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
          <div>
            {selectedTabIndex === 0 && careUnitId && (
              <ActivityAnalysisProduction
                careUnitId={careUnitId as number}
                scenarioId={scenario}
                year={year}
                ref={ref}
                setIsSubmitting={setIsSubmitting}
                setSubmitStatus={setSubmitStatus}
                onNextButtonClick={() => {
                  handleNextButtonClick();
                }}
                isEditedRef={isEditedRef}
              />
            )}
            {selectedTabIndex === 1 && careUnitId && (
              <ActivityAnalysisWithinShifts
                careUnitId={careUnitId}
                scenarioId={scenario}
                year={year}
                ref={ref}
                setIsSubmitting={setIsSubmitting}
                setSubmitStatus={setSubmitStatus}
                onNextButtonClick={handleNextButtonClick}
                onPrevButtonClick={handlePrevButtonClick}
                isEditedRef={isEditedRef}
              />
            )}
            {selectedTabIndex === 2 && careUnitId && (
              <ActivityAnalysisOutsideShifts
                careUnitId={careUnitId}
                scenarioId={scenario}
                year={year}
                ref={ref}
                setIsSubmitting={setIsSubmitting}
                setSubmitStatus={setSubmitStatus}
                onNextButtonClick={handleNextButtonClick}
                onPrevButtonClick={handlePrevButtonClick}
                isEditedRef={isEditedRef}
              />
            )}
            {selectedTabIndex === 3 && careUnitId && (
              <ActivityAnalysisResults
                careUnitId={careUnitId}
                scenarioId={scenario}
                year={year}
                onPrevButtonClick={handlePrevButtonClick}
              />
            )}
          </div>
        </div>
      </div>
    </GridLayout>
  );
};

export default ActivityAnalysisHome;
