import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../redux/store";
import { ChartDataYearFormat } from "../../../../../interfaces/production-plan";
import { OutpatientHistoricalDataService } from "../../../../../services";
import {
  OutPatinetDateRangeWeek,
  OutPatinetDateRangeYear,
  ReferralIntakePredictionSeries,
  ReferralYearData,
  ReferrelResult,
  ReferrelsByProfessionData,
  ChartSeries,
} from "../../../../../interfaces/production-plan/outpatient/referral-intake-chart-data";
import OutpatientPlanService from "../../../../../services/outpatientPlanService";
import LoadingWithGrey from "../../../../../components/loading/LoadingWithGrey";
import { shadesOfBlue, shadesOfGreen } from "../../../../../utils/constants/colours";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import { OutpatientPlanFilteredRequestPayload } from "../../../../../interfaces/production-plan/outpatient/detailedPlan/detailed-plan";

const ReferralIntakeChart = ({ sendLastWeekAverage }: { sendLastWeekAverage: (average: number) => void }) => {
  const { t: translate } = useTranslation();
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const [chartDateRange, setChartDateRange] = useState<ChartDataYearFormat[]>([]);

  const isReferralByProfessionFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.GroupRefferalsByProfessions);

  const [lastWeekAverage, setLastWeekAverage] = useState<number>(0);

  const [weekRange, setWeekRange] = useState<OutPatinetDateRangeWeek[]>([]);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [chartSeries, setChartSeries] = useState<ChartSeries<number[]>[]>([]);

  useEffect(() => {
    const series: ChartSeries<(number | null)[] | number[]>[] = [] as ChartSeries<(number | null)[] | number[]>[];

    const getRollingAverageOutPatientProduction = async () => {
      if (careUnitId !== null && year != null) {
        const outPatinetOutcomePayload: OutpatientPlanFilteredRequestPayload = {
          careUnitId: careUnitId || 0,
          year: year,
        };
        await OutpatientHistoricalDataService.getSumOfOutpatientOutcomeData(
          "api/outpatientproduction/sumofoutpatientproductionoutcome",
          outPatinetOutcomePayload
        ).then(async (res) => {
          const dataOutPatientProductionRollingAvg: ReferralYearData<number>[] = res.data;
          const outPatientProductionRollingAvg: number[] = [];

          dataOutPatientProductionRollingAvg.forEach((year) => {
            year.weeks.forEach((week) => {
              outPatientProductionRollingAvg.push(week.value);
            });
          });

          const sumOfOutpatientSeries = {
            data: outPatientProductionRollingAvg,
            type: "line",
            color: "#AD1E23",
            name: translate("carried_out_visits_average"),
            tooltip: {
              valueDecimals: 1,
            },
          };

          series.push(sumOfOutpatientSeries);
        });
      }
    };

    const getRollingAverageReferrals = async () => {
      if (careUnitId !== null && year !== null) {
        const referralAvaragePayload: OutpatientPlanFilteredRequestPayload = {
          careUnitId: careUnitId || 0,
          year: year,
        };

        await OutpatientHistoricalDataService.getAvgOfOutpatientOutcomeData(
          "api/outpatientproduction/rolling-avarage",
          referralAvaragePayload
        ).then(async (avgData) => {
          const dataHistoricalRollingAvg: ReferralYearData<number>[] = avgData.data;
          const historicalRollingAvg: number[] = [];

          dataHistoricalRollingAvg.forEach((year) => {
            year.weeks.forEach((week) => {
              historicalRollingAvg.push(week.value);
            });
          });

          const historicRollAverageSeries = {
            data: historicalRollingAvg,
            type: "line",
            color: "#416031",
            name: translate("moving_average_52_weeks_chart"),
            tooltip: {
              valueDecimals: 0,
            },
          };

          setLastWeekAverage((historicalRollingAvg[historicalRollingAvg.length - 1] * 365) / 7);

          series.push(historicRollAverageSeries);
        });

        await OutpatientHistoricalDataService.getAvgOfOutpatientOutcomeForecastData(careUnitId, year).then(
          async (avgData) => {
            const lengthHistoricalData = weekRange.filter((r) => r.isHistoricWeek == true).length;

            const dataForecastRollingAvg: ReferralYearData<number | null>[] = avgData.data;

            let forecastRollingAvg: (number | null)[] = [];

            forecastRollingAvg = Array(lengthHistoricalData).fill(null);

            dataForecastRollingAvg.forEach((year) => {
              year.weeks.forEach((week) => {
                forecastRollingAvg.push(week.value);
              });
            });

            const forecastRollingAvgSeries = {
              data: forecastRollingAvg,
              type: "line",
              color: "#548147",
              name: translate("moving_average_52_weeks_forecast_chart"),
              tooltip: {
                valueDecimals: 0,
              },
            };

            series.push(forecastRollingAvgSeries);
          }
        );
      }
    };

    const getReferralHistoricalData = async () => {
      if (careUnitId !== null && year !== null) {
        if (isReferralByProfessionFeatureFlagEnabled) {
          if (careUnitId !== null && year !== null) {
            await OutpatientHistoricalDataService.getReferrelsByProfessionsData(careUnitId, year).then((res) => {
              const data: ReferrelsByProfessionData<number>[] = res;

              const seriesData: ChartSeries<number[]>[] = data.map((profession, index) => {
                const series: ChartSeries<number[]> = {
                  data: profession.referrals.map((year) => year.weeks.map((week) => week.value)).flat(),
                  type: "column",
                  color: shadesOfGreen.concat(shadesOfBlue)[index],
                  name: profession.professionName,
                  tooltip: {
                    valueDecimals: 0,
                  },
                };

                return series;
              });

              series.push(...seriesData);
            });
          }
        } else {
          await OutpatientHistoricalDataService.getReferralOfOutpatientData(careUnitId, year).then(async (res) => {
            const data: ReferrelResult = res;

            const referralHistoricalFollowUpVisitChartData: number[] = [];

            data.followUpVisits.forEach((year) => {
              year.weeks.forEach((week) => {
                referralHistoricalFollowUpVisitChartData.push(week.value);
              });
            });

            const followUpSeriesData = {
              data: referralHistoricalFollowUpVisitChartData || [],
              type: "column",
              color: "#426fa2",
              name: translate("follow_up_visit"),
              tooltip: {
                valueDecimals: 0,
              },
            };

            series.push(followUpSeriesData);

            const referralHistoricalNewVisitChartData: number[] = [];

            data.newVisits.forEach((year) => {
              year.weeks.forEach((week) => {
                referralHistoricalNewVisitChartData.push(week.value);
              });
            });

            const newVisitSeriesData = {
              data: referralHistoricalNewVisitChartData || [],
              type: "column",
              color: "#548147",
              name: translate("new_visit"),
              tooltip: {
                valueDecimals: 0,
              },
            };

            series.push(newVisitSeriesData);
          });
        }
      }
    };

    const getRefferelForecastData = async () => {
      if (careUnitId !== null && year !== null) {
        await OutpatientPlanService.getRefferelForecastPrediction(careUnitId, year).then((res) => {
          const data: ReferralIntakePredictionSeries[] = res;

          const lengthHistoricalData = weekRange.filter((r) => r.isHistoricWeek == true).length;

          const referralForecastChartData: (number | null)[] = Array(lengthHistoricalData).fill(null);

          data.forEach((year) => {
            year.weeks.forEach((week) => {
              referralForecastChartData.push(week.value);
            });
          });

          const referrelForecastSeries = {
            data: referralForecastChartData || [],
            type: "column",
            color: "#7ea4cf",
            name: translate("prediction"),
            visible: false,
            tooltip: {
              valueDecimals: 1,
            },
          };

          series.push(referrelForecastSeries);
        });
      }
    };

    const fetchData = async () => {
      setIsDataLoading(true);
      if (weekRange.length > 0) {
        await getReferralHistoricalData();
        await getRefferelForecastData();
        await getRollingAverageReferrals();
        await getRollingAverageOutPatientProduction();

        setChartSeries(series as ChartSeries<number[]>[]);
      }

      setIsDataLoading(false);
    };

    fetchData();
  }, [weekRange]);

  useEffect(() => {
    const getXAxisData = async () => {
      if (year !== null && year > 0 && careUnitId !== null && careUnitId > 0) {
        await OutpatientHistoricalDataService.getOutPatientDataDateRange(year).then((res) => {
          const years: OutPatinetDateRangeYear[] = res.data;
          const chartXAxisData: ChartDataYearFormat[] = years.map((date) => {
            const convertedDate: ChartDataYearFormat = {
              name: date.id,
              categories: date.weeks.map((week) => {
                return week.theISOWeek;
              }),
            };

            return convertedDate;
          });

          const populatingWeekRange: OutPatinetDateRangeWeek[] = [];

          years.forEach((year) => {
            year.weeks.forEach((week) => {
              populatingWeekRange.push(week);
            });
          });

          setChartDateRange(chartXAxisData);
          setWeekRange(populatingWeekRange);
        });
      }
    };

    getXAxisData();
  }, [careUnitId, year]);

  useEffect(() => {
    sendLastWeekAverage(lastWeekAverage);
  }, [lastWeekAverage]);

  const referralIntakeChartOption = {
    chart: {
      animation: false,
    },

    title: {
      text: "",
    },

    xAxis: {
      categories: chartDateRange,
    },
    yAxis: {
      reversedStacks: false,
      title: {
        text: "",
        style: {
          fontSize: 16,
        },
      },

      labels: {
        style: {
          fontSize: 16,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        fontSize: 16,
      },
      style: {
        fontSize: 16,
      },
    },

    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        pointPlacement: "between",
        stickyTracking: false,
        marker: {
          enabled: false,
        },
      },
      column: {
        stacking: "normal",
        minPointLength: 2,
      },
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (this.series.name === translate("moving_average_52_weeks_chart")) {
          const point = this.point as Highcharts.Point;

          const info = `${translate("moving_average_52_weeks")}: ${Math.round(point.y || 0)} <br /> ${translate(
            "resulting_annual_rate"
          )}: ${Math.round(Number(((point.y || 0) * 365) / 7))}`;

          return info;
        } else if (this.series.name === translate("moving_average_52_weeks_forecast_chart")) {
          const point = this.point as Highcharts.Point;

          const info = `${translate("moving_average_52_weeks_forecast")}: ${Math.round(
            point.y || 0
          )} <br /> ${translate("resulting_annual_rate")}: ${Math.round(Number((point.y || 0) * 365) / 7)}`;

          return info;
        } else if (this.series.name === translate("carried_out_visits")) {
          return `${translate("carried_out_visits")}: ${this.y}`;
        } else if (this.series.name === translate("carried_out_visits_forecast")) {
          return `${translate("carried_out_visits_forecast_tooltip")}: ${this.y}`;
        } else if (this.series.name === translate("carried_out_visits_average")) {
          return `${translate("carried_out_visits_average")}: ${this.y}`;
        } else {
          return `${this.series.name}: ${this.y}`;
        }
      },
    },

    series: chartSeries,
  };

  return !isDataLoading ? (
    <HighchartsReact
      highcharts={Highcharts}
      options={referralIntakeChartOption}
      containerProps={{ style: { height: "100%" } }}
    />
  ) : (
    <div className="flex h-full items-center justify-center">
      <LoadingWithGrey sizeInPixel={40} />
    </div>
  );
};

export default ReferralIntakeChart;
