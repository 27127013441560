import React, { RefObject } from "react";
import { DataTableProps } from "../../../../../../interfaces/props/data-table-props";
import { HandsOnTableColumn } from "../../../../../../interfaces/handsOnTable/handsOnTableColumn";
import { HotTableClass as HotTable } from "@handsontable/react";
import useOverviewColumnConfigs from "./useOverviewColumnConfigs";
import { useTranslation } from "react-i18next";
import { useFeatureFlagIsEnabled } from "../../../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../../../utils/constants/featureFlags";

const useOverviewGridConfigs = (dataTable: RefObject<HotTable>) => {
  const culture = localStorage.getItem("i18nextLng");
  const OverviewColumnConfigs = useOverviewColumnConfigs();

  const { t: translate } = useTranslation();

  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.InpatientOrgOverviewV2);

  const secondLayerHeaders: string[] = [
    "careunit",
    "care_level",
    "care_beds_need",
    "plan_need",
    "plan_need",
    "plan_vs_goal",
    "plan",
    "goal",
    "staffing",
    "cost",
  ];

  const secondLayerHeadersV2: string[] = [
    "careunit",
    "care_level",
    "care_episodes_plan",
    "avg_length_of_stay_Plan",
    "occupancy_rate_plan",
    "care_beds_need",
    "care_beds_need_r12",
    "prod_vs_plan",
    "working_hours_plan",
    "working_hours_r12",
    "clinical_share_of_working_hours",
    "avg_rental_coverage",
    "avg_overtime_coverage",
    "actual_vs_plan_percentage",
    "staffing_cost_plan",
    "staffing_cost",
    "staffing_budget",
    "actual_vs_plan_percentage",
    "plan",
    "goal",
    "plan_vs_goal",
    "staffing",
    "cost",
  ];

  const firstLayer = [
    {
      colspan: 2,
      label: translate(""),
    },
    {
      colspan: 1,
      label: translate("production"),
    },
    {
      colspan: 1,
      label: translate("staffing"),
    },
    {
      colspan: 1,
      label: translate("cost"),
    },
    {
      colspan: 3,
      label: translate("productivity"),
    },
    {
      colspan: 2,
      label: translate("potential"),
    },
  ];

  const firstLayerV2 = [
    {
      colspan: 2,
      label: translate(""),
    },
    {
      colspan: 6,
      label: translate("production"),
    },
    {
      colspan: 6,
      label: translate("staffing"),
    },
    {
      colspan: 4,
      label: translate("cost"),
    },
    {
      colspan: 3,
      label: translate("productivity"),
    },
    {
      colspan: 2,
      label: translate("potential"),
    },
  ];

  const createNestedHeads = () => {
    const secondLayer = (isFeatureFlagEnabled ? secondLayerHeadersV2 : secondLayerHeaders).map((col) => ({
      colspan: 1,
      label: translate(col).replace("\n", "<br />"),
    }));

    const nestedHeaders = [isFeatureFlagEnabled ? firstLayerV2 : firstLayer, secondLayer];

    return nestedHeaders;
  };

  const collapsibleColumns = [
    { row: 0, col: 0, collapsible: false },
    { row: 0, col: 2, collapsible: true },
    { row: 0, col: 8, collapsible: true },
    { row: 0, col: 14, collapsible: true },
    { row: 0, col: 18, collapsible: true },
    { row: 0, col: 21, collapsible: true },
    { row: 1, col: 0, collapsible: false },
    { row: 1, col: 1, collapsible: false },
    ...Array.from({ length: 22 }, (_, i) => ({ row: 1, col: i + 2, collapsible: true })),
  ];

  const getConfigs = (): DataTableProps => {
    const cols: HandsOnTableColumn[] = OverviewColumnConfigs.getColumns(culture ? culture : "");

    const props: DataTableProps = {
      language: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
      numericFormat: {
        culture: localStorage.getItem("i18nextLng") === "en" ? "en-US" : "sv-SE",
        pattern: {},
      },
      columns: cols.map((r) => r.meta),
      nestedHeaders: createNestedHeads(),
      collapsibleColumns: isFeatureFlagEnabled ? collapsibleColumns : false,
      columnHeaderHeight: 35,
      ref: dataTable,
      licenseKey: "non-commercial-and-evaluation",
      contextMenu: ["alignment", "copy"],
      rowHeights: 30,
      height: "100%",
      afterGetColHeader: (col: number, TH) => {
        const colConfig = cols[col];

        if (colConfig && colConfig.headerColor) {
          TH.style.backgroundColor = colConfig.headerColor;
          TH.style.color = "white";
        } else {
          TH.classList.add("dark-blue-column");
        }

        TH.classList.add("htMiddle");

        if (colConfig && !colConfig.isFilterable) {
          TH.classList.add("remove-filter");
        }
      },
      cells: (row, col, prop) => {
        type cp = {
          className?: string;
          readOnly?: boolean;
        };
        const cp: cp = {};

        cp.className = "htMiddle";

        if (col === 0 || col === 1) {
          cp.className = cp.className + " medium-dark-grey-row";
        } else {
          cp.className = cp.className + " htRight";
        }

        return cp;
      },
      colWidths: cols.map((x) => x.width),
      rowHeaders: true,
      rowHeaderWidth: 30,
      columnSorting: true,
      manualRowMove: true,
      filters: true,
      dropdownMenu: ["filter_by_condition", "filter_action_bar", "filter_by_value"],
    };

    return props;
  };

  return { getConfigs };
};

export default useOverviewGridConfigs;
