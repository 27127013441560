import React, { useState } from "react";
import { Button } from "../../../../components";
import { GridLayout } from "../../../../layouts";
import SidePanel from "../../components/sidepanel/sidepanel";
import { ScrollBarConstants } from "../../utils/constants";

const UsersList = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [sideBarVariant, setSideBarVariant] = useState<string | undefined>();

  const handleAddUserClick = () => {
    setIsSideBarOpen(true);
    setSideBarVariant(ScrollBarConstants.ADD_USER);
  };

  return (
    <GridLayout additionalStyles="mt-9">
      <div className="col-start-1 col-end-13">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-3">
            <h1 className="heading-1"> Users </h1>

            <span className="p-1-v-1 ml-2">
              Last Update
              <span className="p-1-v-2 ml-1">4th July 2022 | 9:45 PM </span> by
              <span className="p-1-v-2 ml-1">James Anderson</span>
            </span>
          </div>

          <div className="flex items-center gap-[1.25rem]">
            <Button
              additionalStyles="xl:w-[13.25rem] xl:h-[3.125rem] lg:h-[2.25rem] lg:w-[10.313rem]"
              text="Add new user"
              type="button"
              variant="territory"
              onClick={handleAddUserClick}
            />
            <Button
              additionalStyles="xl:w-[13.25rem] xl:h-[3.125rem] lg:h-[2.25rem] lg:w-[10.313rem]"
              text="Save Changes"
              type="button"
              variant="primary"
            />
          </div>
        </div>
      </div>

      {/* Left Sroll Bar */}
      <SidePanel
        isScrollBarOpen={isSideBarOpen}
        setIsScrollBarOpen={setIsSideBarOpen}
        setSideBarVariant={setSideBarVariant}
        variant={sideBarVariant}
      />
    </GridLayout>
  );
};

export default UsersList;
