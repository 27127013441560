import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "../../../../components";
import { BenchmarkDateRange } from "../../../../interfaces/productivity/benchmarkingDateRange";

interface DateFilterProps {
  dateRange: BenchmarkDateRange;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  endDateErrors: string[];
}

const DateFilters = ({ dateRange, handleStartDateChange, handleEndDateChange, endDateErrors }: DateFilterProps) => {
  const { t: translate } = useTranslation();

  const toISOIgnoreTimezone = (inputDate: Date) => {
    return (
      inputDate.getFullYear() +
      "-" +
      ("0" + (inputDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + inputDate.getDate()).slice(-2) +
      "T00:00:00.000Z"
    );
  };

  return (
    <div className="mb-4 flex">
      <div className="mr-1 basis-1/2">
        <DatePicker
          placement="bottom"
          handleInputChange={handleStartDateChange}
          id="start-date"
          name="start-date"
          placeholder={translate("start_date")}
          value={toISOIgnoreTimezone(dateRange.startDate)}
          error={null}
        />
      </div>
      <div className="ml-1 basis-1/2">
        <DatePicker
          placement="bottom"
          handleInputChange={handleEndDateChange}
          id="end-date"
          name="end-date"
          placeholder={translate("end_date")}
          value={toISOIgnoreTimezone(dateRange.endDate)}
          error={null}
        />
        {endDateErrors.length > 0 ? (
          <ul className="text-input-error">
            {endDateErrors.map((error, key) => {
              return <li key={key}>{error}</li>;
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default DateFilters;
