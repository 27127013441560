import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";

import { ToolTipType } from "../../interfaces";
import Button from "../Button/Button";
import Tooltip from "../tooltip/tooltip";
import { NavLink, useMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavBarIconButton from "../navbarIconButton/NavBarIconButton";
import { RootState, setCareUnitReadOnly } from "../../redux/store";

import "react-dropdown-tree-select/dist/styles.css";

import GlobalFilter from "../GlobalFilter/GlobalFilter";
import { useFeatureFlagIsEnabled } from "../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../utils/constants/featureFlags";

const NavigationBar = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const loginState = useSelector((state: RootState) => state.auth.isLoggedIn);
  const role = useSelector((state: RootState) => state.auth.role);
  const orgIds: number[] | null = useSelector((state: RootState) => state.auth.orgIds);

  const [isNavBarTooltipOpen, setIsNavbarTooltipOpen] = useState<boolean>(false);
  const [toolTipType, setToolTipType] = useState<ToolTipType>("usersettings");

  const isKanbanFeatureEnabled = useFeatureFlagIsEnabled(FeatureFlags.Kanban);

  const handleUserButtonClick = (type: ToolTipType) => {
    setToolTipType(type);
    setIsNavbarTooltipOpen(!isNavBarTooltipOpen);
  };

  const handleTooltipItemClick = () => {
    setIsNavbarTooltipOpen(false);
  };

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  useEffect(() => {
    const handleCareUnit = () => {
      //set care unit user permissions when getting care unit from local storage
      if (role === "Care Unit User" && careUnitId) {
        const isCUWriteAccess = orgIds && orgIds.includes(careUnitId);
        dispatch(setCareUnitReadOnly(!isCUWriteAccess));
      } else if (role === "Global Admin") {
        dispatch(setCareUnitReadOnly(false));
      }
    };

    loginState && handleCareUnit();
  }, [loginState]);

  const isProductivityTabActive = useMatch("/productivity/*");
  const isPeopleTabActive = useMatch("/people/*");
  const isAnalyticsTabActive = useMatch("/analytics/*");
  const isImproveTabActive = useMatch("/improve/*");

  return !loginState ? (
    <nav className="navigation relative">
      <div className="navigation__left">
        <ul className="navigation__items p-1-v-1 h-11">
          <li className="navigation__item">
            <NavLink to="/">
              <img src="/images/logo/Evidence_Logo.svg" className="navigation__logo" alt="Evidence Logo" />
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="button-area">
        <Button
          additionalStyles="navigation__button"
          text={translate("contact_us")}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              aria-hidden="true"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-[1.125rem] w-[1.125rem]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
              />
            </svg>
          }
        />
      </div>
    </nav>
  ) : (
    <nav className="flex w-full items-center justify-between bg-[#F8F8F8] px-8 lg:h-[3.4375rem] xl:h-[4.375rem]">
      <div className="flex w-full items-center gap-4">
        <div className="-mt-2 flex h-9">
          <NavLink to="/">
            <img src="/images/logo/Evidence_Logo.svg" className="navigation__logo" alt="Careopti Logo" />
          </NavLink>
        </div>

        <ul className="flex gap-[0.05rem] text-[#747474] lg:h-[2rem] xl:h-[2.5rem]">
          <NavLink
            to="production"
            className={({ isActive }) =>
              `p-1-v-2 flex items-center justify-center rounded-[0.438rem] lg:px-[1rem] xl:px-[1.875rem] ${
                isActive && "bg-[#00283C] text-white"
              } ${!isActive && "hover:bg-[#EDEDED]"}`
            }
          >
            {translate("production")}
          </NavLink>
          <NavLink
            to="productivity/shifts"
            className={`p-1-v-2 flex items-center justify-center rounded-[0.438rem] lg:px-[1rem] xl:px-[1.875rem]  ${
              isProductivityTabActive && "bg-[#00283C] text-white"
            } ${!isProductivityTabActive && "hover:bg-[#EDEDED]"}`}
          >
            {translate("Capacity")}
          </NavLink>

          <NavLink
            to="people/staffing"
            className={`p-1-v-2 flex items-center justify-center rounded-[0.438rem] lg:px-[1rem] xl:px-[1.875rem]  ${
              isPeopleTabActive && "bg-[#00283C] text-white"
            } ${!isPeopleTabActive && "hover:bg-[#EDEDED]"}`}
          >
            {translate("people")}
          </NavLink>
          <NavLink
            to="analytics/production-vs-plan"
            className={`p-1-v-2 flex items-center justify-center rounded-[0.438rem] lg:px-[1rem] xl:px-[1.875rem] ${
              isAnalyticsTabActive && "bg-[#00283C] text-white"
            } ${!isAnalyticsTabActive && "hover:bg-[#EDEDED]"}`}
          >
            {translate("analytics")}
          </NavLink>
          {isKanbanFeatureEnabled ? (
            <NavLink
              to="improve"
              className={`p-1-v-2 flex items-center justify-center rounded-[0.438rem] lg:px-[1rem] xl:px-[1.875rem] ${
                isImproveTabActive && "bg-[#00283C] text-white"
              } ${!isImproveTabActive && "hover:bg-[#EDEDED]"}`}
            >
              {translate("improve")}
            </NavLink>
          ) : null}
        </ul>
      </div>
      <div className="flex items-center gap-3">
        <GlobalFilter />
        <NavBarIconButton
          isActive={isNavBarTooltipOpen && toolTipType === "parameters"}
          toolTipType="parameters"
          onClick={handleUserButtonClick}
        />

        <NavBarIconButton
          isActive={isNavBarTooltipOpen && toolTipType === "usersettings"}
          toolTipType="usersettings"
          onClick={handleUserButtonClick}
        />
      </div>

      {isNavBarTooltipOpen && (
        <OutsideClickHandler onOutsideClick={handleTooltipItemClick}>
          <Tooltip handleItemClick={handleTooltipItemClick} type={toolTipType} />
        </OutsideClickHandler>
      )}
    </nav>
  );
};

export default NavigationBar;
