import React from "react";

interface ICDAnalysisChartLayoutProps {
  children: React.ReactNode;
  title: string;
}

const ICDAnalysisChartLayout = ({ title, children }: ICDAnalysisChartLayoutProps) => {
  return (
    <div className="mx-4 my-4 h-[86vh] rounded-[0.438rem] border-2 border-solid bg-[#F8F8F8]">
      <div className="chart-title">
        <span className="">{title}</span>
      </div>
      {children}
    </div>
  );
};

export default ICDAnalysisChartLayout;
