import { useEffect, useState } from "react";

export const usePlanvsActualResult = (lastYearData: number [], plan: number []) => {
    const [planVsActual, setPlanvsActual] = useState<number[]>([]);
  
  
    const getPlanVsActual = async (): Promise<void> => {
        const resultArray: number[] = [];
        if (lastYearData !== undefined && plan !== undefined) {
            for (let i = 0; i < plan.length; i++) {
                resultArray.push(plan[i] - lastYearData[i]);
            }
        }

        setPlanvsActual(resultArray);
    };
  
    useEffect(() => {
      getPlanVsActual();
    }, [lastYearData, plan]);
  
  
    return [
        planVsActual,
    ] as const;
  };