import { HotTableClass as HotTable } from "@handsontable/react";
import { CellValue, RangeType } from "handsontable/common";
import { UseBeforePasteReturnType } from "../../../../../interfaces/analytics/handsontable/use-beforecreate-return-type";

const useBeforePaste = (
  dataTable: React.RefObject<HotTable | null>
): UseBeforePasteReturnType => {
  const beforePaste = (data: CellValue[][], cords: RangeType[]): void => {
    // access all the columns of row to edit meta data

    const row = dataTable.current?.__hotInstance?.countRows();

    if (row && cords.length === 0) {
      dataTable.current?.__hotInstance?.alter("remove_row", row - 1, 1);
    } else if (
      row &&
      cords[0].startRow === row - 2 &&
      cords[0].endRow === row - 2 &&
      data.length === 1
    ) {
      //
    } else if (row) {
      dataTable.current?.__hotInstance?.alter("remove_row", row - 1, 1);
    }
  };

  return { beforePaste };
};

export default useBeforePaste;
