import { ColumnSettings } from "handsontable/settings";
import {
  nullStringValidator,
  numberBetweenZeroandFourtyValidator,
  numberBetweenZeroandFourValidator,
  numberBetWeenZeroAndOneInAvailabilityValidator,
  numberBetweenZeroandThreeValidator,
} from "../validators/validators";

export const getStaffingCols = (culture: string) => {
  const staff_availability_columns: ColumnSettings[] = [
    {
      data: "staffFirstName",
      validator: nullStringValidator,
      type: "text",
    },
    {
      data: "staffLastName",
      type: "text",
    },

    {
      data: "competence",
      validator: nullStringValidator,
      type: "dropdown",
    },

    {
      data: "employmentTypeName",
      type: "dropdown",
    },

    {
      data: "workingHoursPerWeek",
      validator: numberBetweenZeroandFourtyValidator,
      type: "numeric",
      numericFormat: {
        culture: culture,
        pattern: {
          mantissa: 2,
        },
      },
    },

    {
      data: "employmentRate",
      validator: numberBetweenZeroandThreeValidator,
      type: "numeric",
      numericFormat: {
        culture: culture,
        pattern: {
          mantissa: 2,
        },
      },
      allowEmpty: false,
    },

    {
      data: "weeksendsPerWeek",
      validator: numberBetweenZeroandFourValidator,
      type: "numeric",
      numericFormat: {
        culture: culture,
        pattern: {
          mantissa: 2,
        },
      },
    },

    {
      data: "comment",
    },

    {
      data: "staffAvailability1",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability2",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability3",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability4",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability5",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability6",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability7",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability8",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability9",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability10",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability11",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability12",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability13",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability14",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability15",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability16",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability17",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability18",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability19",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability20",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability21",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability22",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability23",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability24",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability25",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability26",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability27",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability28",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability29",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability30",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability31",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability32",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability33",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability34",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability35",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability36",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability37",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability38",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability39",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability40",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability41",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability42",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability43",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability44",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability45",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability46",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability47",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability48",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability49",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability50",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability51",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },

    {
      data: "staffAvailability52",
      validator: numberBetWeenZeroAndOneInAvailabilityValidator,
      type: "numeric",
    },
  ];

  return staff_availability_columns;
};

export const staff_availability_data_schema = {
  staffFirstName: null,
  staffLastName: null,
  competence: null,
  employmentTypeName: null,
  workingHoursPerWeek: null,
  employmentRate: null,
  weeksendsPerWeek: null,
  comment: null,
  staffAvailability1: 1,
  staffAvailability2: 1,
  staffAvailability3: 1,
  staffAvailability4: 1,
  staffAvailability5: 1,
  staffAvailability6: 1,
  staffAvailability7: 1,
  staffAvailability8: 1,
  staffAvailability9: 1,
  staffAvailability10: 1,
  staffAvailability11: 1,
  staffAvailability12: 1,
  staffAvailability13: 1,
  staffAvailability14: 1,
  staffAvailability15: 1,
  staffAvailability16: 1,
  staffAvailability17: 1,
  staffAvailability18: 1,
  staffAvailability19: 1,
  staffAvailability20: 1,
  staffAvailability21: 1,
  staffAvailability22: 1,
  staffAvailability23: 1,
  staffAvailability24: 1,
  staffAvailability25: 1,
  staffAvailability26: 1,
  staffAvailability27: 1,
  staffAvailability28: 1,
  staffAvailability29: 1,
  staffAvailability30: 1,
  staffAvailability31: 1,
  staffAvailability32: 1,
  staffAvailability33: 1,
  staffAvailability34: 1,
  staffAvailability35: 1,
  staffAvailability36: 1,
  staffAvailability37: 1,
  staffAvailability38: 1,
  staffAvailability39: 1,
  staffAvailability40: 1,
  staffAvailability41: 1,
  staffAvailability42: 1,
  staffAvailability43: 1,
  staffAvailability44: 1,
  staffAvailability45: 1,
  staffAvailability46: 1,
  staffAvailability47: 1,
  staffAvailability48: 1,
  staffAvailability49: 1,
  staffAvailability50: 1,
  staffAvailability51: 1,
  staffAvailability52: 1,
};
